<script lang="ts" setup>
import { computed, watch } from 'vue'

import { GamesGrid } from '@modules/casino/components/GamesGrid'
import TopFilter from '@modules/casino/components/TopFilter.vue'

import { useGetGames } from '@modules/casino/composables/api'

import { useCasinoStore } from '@modules/casino/casino.store'
import type { GameProvider, GameType } from '@modules/casino/casino.model'
import type { Tab } from '@/types/interface'

interface Props {
  tabs: Tab<GameType>[]
  perPage?: number
  noHeader?: boolean
  noSearch?: boolean
  noScroll?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  perPage: 40,
  noHeader: false,
  noSearch: false,
  noScroll: false,
})
const emit = defineEmits(['searchToggle'])

const casinoStore = useCasinoStore()

const { data } = useGetGames({
  currentPage: 1,
  perPage: 1,
  type: [],
})

const providers = computed(() => {
  if (!data.value)
    return []

  const firstPage = data.value.pages[0]
  const result: GameProvider[] = []
  result.push(...firstPage.response.data.providers)

  return result
})

const providersByType = computed(() => {
  if (!casinoStore.providersAll || !casinoStore.providersAll.length)
    return []

  return casinoStore.providersAll
})

watch(providers, (currentProviders) => {
  casinoStore.updateProviders(currentProviders, 'all')
}, { immediate: true })
</script>

<template>
  <TopFilter
    v-if="!props.noSearch"
    :providers="providersByType"
    @search-toggle="(e: boolean) => emit('searchToggle', e)"
  />

  <template
    v-for="tab in props.tabs"
    :key="`grid-row-${tab.type}`"
  >
    <slot :tab="tab.type">
      <GamesGrid
        :per-page="props.perPage"
        :tab="tab"
        no-search
        no-scroll
      />
    </slot>
  </template>
</template>
