<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

import { useModalLayer } from '@modules/casino/casino.dependencies'
import { GameCard } from '@modules/casino/components/GameCard'
import { GameCardPlaceholder } from '@modules/casino/components/GameCardPlaceholder'
import TopFilter from '@modules/casino/components/TopFilter.vue'
import { useCasinoStore } from '@modules/casino/casino.store'
import { useGetGames } from '@modules/casino/composables/api'
import type { GameListEntity, GameProvider, GameType, ProviderTypes } from '@modules/casino/casino.model'
import Logo from '@/assets/icon/logo.svg'
import { CentralSection } from '@/cypress.types'

import type { Tab } from '@/types/interface'
import { pathWithLocale, t } from '@/plugins/i18n'

interface Props {
  tab?: Tab<GameType>
  provider?: string
  perPage?: number
  exclude?: GameType[]
  static?: boolean
  noHeader?: boolean
  noSearch?: boolean
  noScroll?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  exclude: () => [],
  perPage: 40,
  static: false,
  noHeader: false,
  noSearch: false,
  noScroll: false,
})
const emit = defineEmits(['searchToggle', 'total'])
const currentPage = ref(1)
const search = ref('')

const casinoStore = useCasinoStore()
const modalLayer = useModalLayer()

const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useGetGames({
  currentPage: currentPage.value,
  perPage: props.perPage,
  search: search.value,
  provider: props.provider,
  type: (props.tab?.type && props.tab?.type !== 'all' as GameType) ? [props.tab?.type] : [],
  exclude: props.exclude,
}, props.static)

const games = computed(() => {
  if (!data.value)
    return []

  const result: GameListEntity[] = []
  data.value.pages.forEach(({ response }) => result.push(...response.data.games))

  return result
})
const lastMeta = computed<{ total: number; perPage: number; currentPage: number } | undefined>(() => {
  return data.value?.pages?.at(-1)?.response?.meta
})
const providers = computed(() => {
  if (!data.value)
    return []

  // if (!games.value.length)
  //   return []

  const firstPage = data.value.pages[0]
  const result: GameProvider[] = []
  result.push(...firstPage.response.data.providers)

  return result
})

const providersByType = computed(() => {
  let result = []
  switch (props.tab?.type) {
    case 'live':
      result = casinoStore.providersLive
      break

    case undefined:
    case 'bandana':
    case 'all':
      result = casinoStore.providersAll
      break

    default:
      result = casinoStore.providersSlots
      break
  }

  return result
})

const total = computed(() => {
  if (!data.value)
    return 0

  return data.value.pages[data.value.pages.length - 1].response.meta.total
})

watch(providers, (currentProviders) => {
  const providerType: ProviderTypes = (function () {
    switch (props.tab?.type) {
      case 'live':
        return 'live'

      case undefined:
      case 'all':
        return 'all'

      default:
        return 'slots'
    }
  })()

  casinoStore.updateProviders(currentProviders, providerType)
})

watch([total, data], ([value]) => {
  emit('total', value)
})

onMounted(() => {
  emit('total', total.value)
})

function onUnauthenticatedPlay(path?: string): void {
  modalLayer.show('auth', { type: 'signIn', path })
}
</script>

<template>
  <section class="games-grid">
    <TopFilter
      v-if="!props.noSearch"
      :curr-tab="tab?.type"
      :providers="providersByType"
      @search-toggle="(e: boolean) => emit('searchToggle', e)"
    />

    <div v-if="!props.noHeader && !props.provider" class="games-grid__header flex justify-between items-center">
      <div v-if="tab" class="lside">
        <component :is="tab.icon || ''" class="w-6 h-6 yellow" />

        <h2 class="white">
          {{ t(tab.name) }}
        </h2>
      </div>

      <template v-if="total > 0">
        <div v-if="props.provider">
          <RouterLink to="/" class="btn btn-link font-700">
            {{ t('all_games_amount', { amount: total }) }}
          </RouterLink>
        </div>

        <div v-else-if="tab?.url" class="rside" :data-cy="CentralSection.ALL_GAMES_CENTRAL_BUTTON">
          <RouterLink :to="pathWithLocale(tab.url)" class="btn btn-link font-700">
            {{ t('all_games_amount', { amount: total }) }}
          </RouterLink>
        </div>
      </template>
    </div>

    <div class="games-grid__catalog">
      <template v-if="props.static">
        <slot />
      </template>

      <template v-else>
        <GameCard
          v-for="item of games"
          :key="item.id"
          :item="item"
          @unauthenticated-play="onUnauthenticatedPlay"
        />

        <template v-if="isLoading">
          <GameCardPlaceholder v-for="i of 8" :key="i" />
        </template>
      </template>
    </div>

    <template v-if="!props.static">
      <div
        v-if="!isLoading && !games.length"
        class="games-grid__empty text-center py-10"
      >
        <Logo name="logo" class="w-[72px] h-[72px] mb-2" />

        <p>
          {{ t('empty.nothing') }}
        </p>
      </div>

      <div
        v-if="hasNextPage && !props.noScroll"
        class="games-grid__footer flex flex-col gap-2 justify-center items-center"
      >
        <div class="text-14 font-400 text-gray-2">
          {{ t('showing_games', { games: (Number(lastMeta?.currentPage || 0) * Number(lastMeta?.perPage || 0)).toFixed(), total: lastMeta?.total }) }}
        </div>

        <button
          class="btn-primary uppercase"
          :disabled="isFetchingNextPage"
          @click="() => fetchNextPage()"
        >
          <span>{{ t('load_more') }}</span>
        </button>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.games-grid{
  &__header {
    padding: 32px 0 0;

    .lside {
      display: flex;
      gap: 12px;
    }
  }

  &__catalog {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    padding-top: 32px;

    @include _767 {
      gap: 8px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    @include _500 {
      gap: 8px;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  }

  &__footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.app_main {
  &:not(.hideL):not(.hideR) {
    .games-grid__catalog {
      @media (max-width: 1420px) and (min-width: 1281px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      }
    }
  }
}
</style>
