import type { ViteSSGContext } from 'vite-ssg'
import type { UserModule } from '@/types'

export function installByType(type: 'plugins' | 'modules', ctx: ViteSSGContext) {
  if (type === 'plugins') {
    Object.values(import.meta.glob<{ install: UserModule }>('@/plugins/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))
  }
  else { // TODO: Fix install all modules in @modules/index
    Object.values(import.meta.glob<{ install: UserModule }>('@modules/index.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))
  }
}
