import type { NavigationGuardNext, RouteLocationNormalized } from '@modules/auth/auth.dependencies'
import { useLocalePath, useModalLayer, useUserStore } from '@modules/auth/auth.dependencies'

export async function authMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()

  // call auth modal
  if (to.meta.auth && !userStore.isLogged && userStore.isIdentificationEnd) {
    const modalLayer = useModalLayer()
    modalLayer.show('auth', { type: 'signIn', path: to.path })
    next(from)
  }

  // if this is auth via vk
  if (to.path.includes('auth/social/vk')) {
    const modalLayer = useModalLayer()

    setTimeout(() => {
      modalLayer.show('auth.continue_social_register', { social: 'vk' })
    }, 0)
  }

  // if this is auth via google
  if (to.path.includes('auth/social/google')) {
    const modalLayer = useModalLayer()

    setTimeout(() => {
      modalLayer.show('auth.continue_social_register', { social: 'google' })
    }, 0)
  }

  // if this is auth via steam
  if (to.path.includes('auth/social/steam')) {
    const modalLayer = useModalLayer()

    setTimeout(() => {
      modalLayer.show('auth.continue_social_register', { social: 'steam' })
    }, 0)
  }

  // if restore password
  if (to.path.includes('restore-password/email')) {
    const token = to.query.token
    const localePath = useLocalePath()

    if (!token) {
      next(localePath('/'))
      return
    }

    const modalLayer = useModalLayer()

    setTimeout(() => {
      modalLayer.show('restore')
    }, 0)
  }

  // if tfa sign in
  // if (to.path.includes('2fa/email/sign-in')) {
  //   const modalLayer = useModalLayer()
  //
  //   setTimeout(() => {
  //     modalLayer.show('tfa', 'email')
  //   }, 0)
  // }

  next()
}
