import {
  defineStore,
  useState,
} from '@modules/casino/casino.dependencies'

import type { Ref } from '@modules/casino/casino.dependencies'
import type { Favorites, GameEntity, GameProvider, ProviderTypes } from '@modules/casino/casino.model'

interface ICasinoStore {
  providersAll: Ref<GameProvider[]>
  providersLive: Ref<GameProvider[]>
  providersSlots: Ref<GameProvider[]>

  updateProviders: (payload: GameProvider[], type: ProviderTypes) => void

  favorites: Ref<Favorites>
  addFavorite: (gameId: GameEntity['id'], isFavorite: boolean) => void
}

export const useCasinoStore = defineStore<string, ICasinoStore>('casinoStore', () => {
  const [providersAll, setProvidersAll] = useState<GameProvider[]>([])
  const [providersLive, setProvidersLive] = useState<GameProvider[]>([])
  const [providersSlots, setProvidersSlots] = useState<GameProvider[]>([])

  const [favorites, setFavorites] = useState({})

  // mutations
  const updateProviders = (payload: GameProvider[], type: ProviderTypes) => {
    const setters = {
      all: setProvidersAll,
      live: setProvidersLive,
      slots: setProvidersSlots,
    }

    setters[type] && setters[type](payload)
  }

  const addFavorite = (gameId: GameEntity['id'], isFavorite: boolean) => {
    const newFavs: Favorites = {
      ...favorites.value,
      [gameId]: isFavorite,
    }
    setFavorites(newFavs)
  }

  return {
    providersAll,
    providersLive,
    providersSlots,

    updateProviders,

    favorites,
    addFavorite,
  }
})
