import { useUserStore } from '@modules/user/user.store'
import { useError, useSuccess } from '@modules/user/user.dependencies'
import type { NavigationGuardNext, RouteLocationNormalized } from '@modules/user/user.dependencies'
import { t } from '@/plugins/i18n'

export function getUserMiddleware() {
  return async function userMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  // verify email

    if (to.path.includes('email-verification/email')) {
      const code = to.query?.code
      if (!code) {
        useError(t('notify.email_verify_code_not_found'))

        next('/')
      }
      else {
        const userStore = useUserStore()
        const isSuccess = await userStore.verifyEmail({ emailVerificationCode: String(code) })

        if (isSuccess)
          useSuccess(t('notify.email_verified'))

        next('/')
      }
    }

    next()
  }
}
