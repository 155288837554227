<script lang="ts" setup>
import type { Message } from '@modules/chat/chat.model'
import {
  Username,
  computed,
  useModalLayer,
  useToggle,
  useUserStore,
} from '@modules/chat/chat.dependencies'
import { useChatStore } from '@modules/chat'
import { UserAvatar } from '@modules/user'
import { getUserRank } from '@modules/user/user.helpers'
import Translate from '@components/Translate.vue'
import { t } from '@/plugins/i18n'
import Dots from '@/assets/icon/dots.svg'
import Reply from '@/assets/icon/reply.svg'
import System from '@/assets/icon/system.svg'

interface IMessageBan extends Message {
  reason?: string
  date?: string
  username?: string
}

interface Props {
  message: IMessageBan
}

const props = defineProps<Props>()
const emit = defineEmits(['reply'])
const modalLayer = useModalLayer()
const userStore = useUserStore()
const chatStore = useChatStore()
const [edit, toggleEdit] = useToggle(false)

const onReply = () => emit('reply', props.message)

const answered = computed<string>(() =>
  props.message?.parent?.user?.name || '',
)

function onBan() {
  modalLayer.show('chat_ban', { userId: props.message?.userId || '', messageId: props.message.id })
  toggleEdit()
}
const onDeleteOne = () => chatStore.deleteMessage(chatStore.current?.id || '', props.message.id)
const onDeleteAll = () => chatStore.deleteMessages(chatStore.current?.id || '', props.message.userId)
</script>

<template>
  <div
    :class="{ moder: userStore.getIsModerator(message.user.role), admin: userStore.getIsAdmin(message.user.role), system: message.type === 'ban' }"
    class="message"
  >
    <div v-if="message.type !== 'ban'" class="message_wrap">
      <div class="message_inner">
        <div class="message__content">
          <div class="message__flex">
            <UserAvatar
              type="chat"
              :rank="getUserRank(message.user.vipStatus.slug)"
              :src="message.user?.avatar"
              class="message__image"
            />

            <Username :name="message.user?.name || ''" class="flex-grow" :class="{ '!text-blue': userStore.getIsModerator(message.user.role), '!text-red-4': userStore.getIsAdmin(message.user.role) }" />

            <div v-if="userStore.getIsAdmin(message.user.role) || userStore.getIsModerator(message.user.role)" class="rounded-[4px] py-[5px] px-1 text-12 leading-14 text-white font-700 h-[18px] flex items-center" :class="$style.gradient">
              {{ t(`chat.${userStore.getIsAdmin(message.user.role) ? 'admin' : 'moderator'}`) }}
            </div>

            <div class="message_toolbar flex justify-between items-center gap8">
              <button
                v-if="userStore.isLogged"
                class="btn-default"
                :aria-label="t('chat.reply')"
                @click="onReply"
              >
                <Reply name="reply" />
              </button>

              <button
                v-if="userStore.isLogged && (userStore.isModerator || userStore.isAdmin)"
                class="btn-default"
                aria-label="Moderation"
                @click="toggleEdit"
              >
                <Dots name="dots" />
              </button>
            </div>
          </div>
        </div>

        <div class="message__text text-white">
          <p>
            <b v-if="answered" class="answered">@{{ answered }}</b>

            {{ message.text }}
          </p>
        </div>
      </div>
    </div>

    <!--    -->
    <div v-else-if="message.type === 'ban'" class="message_wrap">
      <div class="message__content">
        <div class="message__flex">
          <System name="system" class="w-4 h-4" />

          <h4>{{ t('chat.system') }}</h4>
        </div>
      </div>

      <div class="message-text">
        <div class="mx-3">
          <marquee behavior="scroll" direction="left">
            <Translate tag="span" path="chat.was_banned">
              <template #reason>
                <b>{{ message.reason }}</b>
              </template>

              <template #date>
                <b>{{ message.date }}</b>
              </template>

              <template #username>
                <b>{{ message.username }}</b>
              </template>
            </Translate>
          </marquee>
        </div>
      </div>
    </div>

    <div v-if="edit" class="msg_moderate">
      <div class="msg_moderate__wrap">
        <button class="msg_moderate-button" :disabled="userStore.user?.id === message.userId" @click="onBan">
          {{ t('ban') }}
        </button>

        <button class="msg_moderate-button" :disabled="chatStore.loading" @click="onDeleteOne">
          {{ t('remove') }}
        </button>

        <button class="msg_moderate-button" :disabled="chatStore.loading" @click="onDeleteAll">
          {{ t('remove_all') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.gradient {
  background: var(--red-gradient);
}
</style>

<style lang="scss">
.message {
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--gray-7); */
  background: var(--gray-5);
  border-radius: $radiusM;
  position: relative;
  padding: 12px;
  &.moder {
    background-color: #252B30;
    .username-nick {
      color: var(--blue);
    }
  }
  &.admin {
    background-color: #252B30;
    .username-nick {
      color: var(--red-4);
    }
  }
  &.system {
    .message {
      &__flex {
        color: var(--red-1);
        gap: 8px;
      }
      &-text {
        font-weight: 300;
        color: var(--gray-11);
        b {
          color: var(--white);
        }
      }
    }
  }
  &_wrap {
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__flex {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    .username {
      &-rang {
        left: -39px;
        bottom: -18px;
      }
      &-nick {
        padding-left: 0;
      }
    }
  }
  &__image {
    height: 24px;
    width: 24px;
    border-radius: $radius;
    background-color: var(--gray-5);
    object-fit: cover;
  }

  &__name {
    margin-left: 8px;
  }

  &__btn {
    display: flex;
    align-items: flex-start;
    height: max-content;

    svg {
      path {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--yellow);
        }
      }
    }
  }

  &__text {
    display: flex;
    margin: 12px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    word-break: break-word;
    &.onstart {
      display: block;
      margin-top: 4px;
      .username {
        display: inline-flex;
        &-rang {
          top: -3px;
        }
        &-nick {
          &:after {
            content: ':';
          }
        }
      }
    }
    .answered {
      color: #fff8b9;
    }
  }
  &-text {
    margin: 12px 0 0 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: var(--white);
    word-break: break-word;
  }
  .msg_moderate {
    overflow: hidden;
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      background: var(--dark);
      border-radius: $radius;
      margin-top: 8px;
    }
    &-button {
      padding: 10px;
      height: 36px;
      flex-grow: 1;
      font-size: 12px;
      color: var(--white);
      font-weight: 700;
      text-transform: uppercase;
      flex-grow: 1;
      &:hover {
        color: var(--yellow);
      }
      &:nth-child(2) {
        border-left: 1px solid var(--gray-5);
        border-right: 1px solid var(--gray-5);
      }
    }
  }
}
</style>
