import { defineStore } from 'pinia'
import { useToggle } from '@composable/useToggle'
import { useState } from '@composable/useState'
import { useWindowSize } from '@vueuse/core'
import { computed, watch } from 'vue'
import { isSSR } from '@helpers/utils'
import { useOpenModals } from '@composable/useModal'

export const useLayoutStore = defineStore('layout', () => {
  // progress bar

  const [progress, setProgress] = useState<number>(100)
  const [progressInterval, setProgressInterval] = useState<ReturnType<typeof setInterval> | null>(null)
  const [progressTimeout, setProgressTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const progressDone = () => {
    clearInterval(progressInterval.value || setInterval(() => null, 500000))
    clearTimeout(progressTimeout.value || setTimeout(() => null, 500000))

    setProgress(100)
  }
  const progressStart = () => {
    setProgress(0)

    setProgressTimeout(
      setTimeout(() => {
        const random = Math.random() / 0.05

        setProgress(random < 10 ? random + 5 : random)
      }, 100),
    )

    setProgressInterval(
      setInterval(() => {
        const increaseValue = Math.random() * 4

        const newValue = Math.min(progress.value + increaseValue, 98)

        setProgress(newValue)
      }, 1000),
    )
  }

  const windowSize = useWindowSize()
  /**
   * Hide left
   * width <= 1279 - true is open, false is closed
   * width >= 1280 - true is closed, false is open
   * */
  const [hideLeft, toggleLeft, setLeft] = useToggle(false)
  /**
   * Hide Right
   * width <= 1399 - false is closed, true is open
   * 1400 <= width - false is open, true is closed
   * !! NOTE - route.meta.game - 1400 will change to 1600px
   * */
  const [hideRight, toggleRight, setRight] = useToggle(false)
  const [gameOneSidebarMax, setGameOneSidebarMax] = useState<number>(1400)

  const [isHideHeaderOn, setIsHideHeaderOn] = useState<boolean>(false)
  const [isOpenMobileNav, toggleIsOpenMobileNav, setIsOpenMobileNav] = useToggle(false)

  const reverseBreakpoint = computed<number>(() => gameOneSidebarMax.value === -1 ? 1400 : gameOneSidebarMax.value)
  const rightOpen = computed<boolean>(() => {
    return windowSize.width.value < reverseBreakpoint.value
      ? hideRight.value
      : !hideRight.value
  })
  const leftOpen = computed<boolean>(() => {
    return windowSize.width.value < 1280 ? hideLeft.value : !hideLeft.value
  })

  const [loopTimeout, setLoopTimeout] = useState<any>(null)
  function toggleAnimation() {
    document.body.classList.add('toggle_sidebar')
    clearTimeout(loopTimeout.value)
    setLoopTimeout(setTimeout(() => {
      document.body.classList.remove('toggle_sidebar')
    }, 300))
  }
  const setLeftSide = (state: 'open' | 'closed') => {
    toggleAnimation()
    const newValue = state === 'open'
      ? windowSize.width.value < 1280
      : windowSize.width.value >= 1280

    setLeft(newValue)
  }

  const setRightSide = (state: 'open' | 'closed') => {
    toggleAnimation()
    document.body.classList.add('toggle_sidebar')
    clearTimeout(loopTimeout.value)
    setLoopTimeout(setTimeout(() => {
      document.body.classList.remove('toggle_sidebar')
    }, 300))
    const newValue = state === 'open'
      ? windowSize.width.value < reverseBreakpoint.value
      : windowSize.width.value >= reverseBreakpoint.value

    setRight(newValue)
  }

  const mountPipeline = () => {
    if (!isSSR()) {
      if (rightOpen.value)
        document.body.classList.add('rsidebar')
      else
        document.body.classList.remove('rsidebar')
    }
  }

  const closeAllIfMobile = () => {
    if (!isSSR()) {
      if (windowSize.width.value <= 1279) {
        setLeftSide('closed')
        setRightSide('closed')
      }
    }
  }

  const setLeftLocal = (newValue: boolean) => {
    if (!isSSR())
      setLeft(newValue)
  }
  const closeIfMobile = (side: 'left' | 'right') => {
    if (!isSSR()) {
      if (windowSize.width.value <= 1279) {
        if (side === 'left')
          setLeftSide('closed')
        else if (side === 'right')
          setRightSide('closed')
      }
    }
  }
  const toggleOneCloseAnother = (side: 'left' | 'right') => {
    if (!isSSR()) {
      if (side === 'left') {
        toggleLeft()
        setRightSide('closed')
      }
      else if (side === 'right') {
        toggleRight()
        setLeftSide('closed')
      }
    }
  }

  //
  watch(() => leftOpen.value, (newValue: boolean) => {
    if (!isSSR()) {
      const openModals = useOpenModals()

      if (windowSize.width.value < 1280) {
        if (newValue)
          window.document.body.classList.add('overflow-hidden')
        else if (!openModals.active.value)
          window.document.body.classList.remove('overflow-hidden')
      }

      if (newValue) {
        const isGame = gameOneSidebarMax.value !== -1
        if (isGame) {
          if (windowSize.width.value < reverseBreakpoint.value) {
            if (rightOpen.value)
              setRightSide('closed')
          }
        }
      }
    }
  })
  //
  watch(() => rightOpen.value, (isOpen: boolean) => {
    if (!isSSR()) {
      if (isOpen) {
        // close left
        const isGame = gameOneSidebarMax.value !== -1
        if (isGame) {
          if (windowSize.width.value < reverseBreakpoint.value) {
            if (leftOpen.value)
              setLeftSide('closed')
          }
        }
      }

      // overflow
      const isMobileOrTablet = windowSize.width.value < 1280
      if (isMobileOrTablet) {
        if (isOpen)
          window.document.body.classList.add('overflow-hidden')
        else
          window.document.body.classList.remove('overflow-hidden')
      }

      // sidebar toasts
      if (isOpen)
        document.body.classList.add('rsidebar')
      else
        document.body.classList.remove('rsidebar')
    }
  })

  watch(() => windowSize.width.value, (width: number, prevWidth: number) => {
    if (!isSSR()) {
      if (width >= 1280 && width > prevWidth && !leftOpen.value) // increase width
        setLeftSide('open')
      if (width < 1280 && width < prevWidth && leftOpen.value)
        setLeftSide('closed')

      if (width >= 1399 && width > prevWidth) // increase width
        setRight(false)
      if (width <= 1279 && width < prevWidth) // decrease width
        setRight(false)
      if (width >= 1280 && width > prevWidth) // increase width
        setRight(false)
      if (width <= reverseBreakpoint.value && width < prevWidth) // decrease width
        setRight(false)

      if (width <= reverseBreakpoint.value && width < prevWidth) { // decrease width
        if (rightOpen.value)
          document.body.classList.add('rsidebar')
        else
          document.body.classList.remove('rsidebar')
      }
    }
  })

  const isChatShowOnPageLoad = computed(() => {
    return windowSize.width.value >= reverseBreakpoint.value
  })

  return { progress, progressStart, progressDone, setLeftSide, isHideHeaderOn, setIsHideHeaderOn, leftOpen, setRightSide, rightOpen, isChatShowOnPageLoad, mountPipeline, closeAllIfMobile, windowSize, gameOneSidebarMax, setGameOneSidebarMax, hideLeft, toggleOneCloseAnother, closeIfMobile, toggleLeft, setLeft: setLeftLocal, hideRight, toggleRight, setRight, isOpenMobileNav, toggleIsOpenMobileNav, setIsOpenMobileNav }
})
