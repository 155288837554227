import type { LiveWinModel } from '@modules/live/live.model'
import { useCdn } from '@helpers/utils'
import { getOurGameById, ourGames } from '@/config/games'
import CasinoGameSlots from '@/assets/icon/casino-game-slots.svg'

export function isOurGame(winModel: LiveWinModel) {
  return winModel.bet.game.provider === 'BANDANA'
}

export function getGameUrl(winModel: LiveWinModel) {
  if (isOurGame(winModel)) {
    const path = ourGames.find(game => game.id === winModel.bet.game.name)?.path

    return path ? `/${path}` : ''
  }

  return `/all-games/${winModel.bet.game.provider}/${winModel.bet.game.id}-${winModel.bet.game.name}`
}

export function getGameImageUrl(winModel: LiveWinModel) {
  if (isOurGame(winModel)) {
    const image = getOurGameById(winModel.bet.game.name)?.entity?.image

    return image || ''
  }

  return useCdn(winModel.bet.game.image)
}

// For live wins table
export function getGameIconName(winModel: LiveWinModel) {
  const defaultIcon = CasinoGameSlots

  if (isOurGame(winModel)) {
    const game = ourGames.find(game => game.id === winModel.bet.game.name)

    return game?.icon || defaultIcon
  }

  return defaultIcon
}

export function getFormattedName(name: string) {
  return name?.length > 10 ? `${name.slice(0, 10)}...` : `${name.slice(0, -3)}...`
}
