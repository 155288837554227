import { install as InstallAuth } from '@modules/auth'
import { install as InstallGames } from '@modules/games'
import { install as InstallUser } from '@modules/user'
import type { UserModule } from '@/types'

const install: UserModule = (ctx) => {
  InstallAuth(ctx)
  InstallGames(ctx)
  InstallUser(ctx)

  import('./errors').then(x => x.install(ctx))

  const modules = import.meta.glob('./*/*.routes.ts', { import: 'route', eager: true })

  Object.values(modules).forEach((route) => {
    const result = Array.isArray(route) ? route : [route]

    result.forEach(child => ctx.router.addRoute('root', child))
  })
}

export {
  install,
}
