import type { Bonus, RewardedActionDeposit } from '@modules/bonus/bonus.model'
import { getCurrencySymbol } from '@helpers/currency'
import { getFormatBalance } from '@helpers/utils'
import type { Exchanger } from '@modules/wallet'
import { BonusStatus } from '@modules/bonus/bonus.model'

export interface BonusTitle {
  yellow?: string
  default: string
}

export function getBonusTitle(bonus?: Bonus) {
  const paths: Record<Bonus['activationCause'], BonusTitle> = {
    PROMO_CODE: {
      default: 'bonuses.active.name_default_promocode',
      yellow: 'bonuses.active.name_yellow_bonus',
    },
    CASHBACK: {
      default: 'bonuses.active.name_default_cashback',
    },
    JOIN_VK_GROUP: {
      default: 'bonuses.active.name_default_vk',
      yellow: 'bonuses.active.name_yellow_bonus',
    },
    JOIN_TG_CHANNEL: {
      default: 'bonuses.active.name_default_tg',
      yellow: 'bonuses.active.name_yellow_bonus',
    },
    RAKEBACK: {
      default: 'bonuses.active.name_default_rakeback',
    },
    RAFFLE: {
      default: 'bonuses.active.name_default_raffle',
      yellow: 'bonuses.active.name_yellow_gift',
    },
    DEPOSIT: {
      default: 'bonuses.active.name_default_deposit',
      yellow: 'bonuses.active.name_yellow_bonus',
    },
  }

  return paths[bonus?.activationCause || 'DEPOSIT']
}

export function getBonusAmount(bonus: Bonus | undefined, exchanger: Exchanger) {
  if (bonus?.payload?.amount && bonus?.payload?.currencyId) {
    const currencySymbol = getCurrencySymbol(exchanger.getCurrencyIsoById(bonus.payload.currencyId))

    return getFormatBalance(Number(bonus.payload.amount || 0)) + currencySymbol
  }

  return undefined
}

export function getWagerBonus(bonus?: Bonus) {
  return (bonus?.payload?.wager && Number(bonus?.payload?.wager)) ? `X${bonus?.payload?.wager}` : undefined
}

export function getCurrentWagerAmount(bonus: Bonus | undefined, exchanger: Exchanger) {
  if (bonus?.payload?.wageredAmount && bonus?.payload.amount && bonus?.payload.currencyId) {
    const currencySymbol = getCurrencySymbol(exchanger.getCurrencyIsoById(bonus.payload.currencyId))

    return getFormatBalance(Number(bonus.payload.wageredAmount || 0)) + currencySymbol
  }

  return undefined
}

export function getTotalWagerAmount(bonus: Bonus | undefined, exchanger: Exchanger) {
  if (bonus?.payload?.wager && bonus?.payload.amount && bonus?.payload.currencyId) {
    const currencySymbol = getCurrencySymbol(exchanger.getCurrencyIsoById(bonus.payload.currencyId))

    return getFormatBalance(Number(bonus.payload.amount || 0) * Number(bonus.payload.wager || 0)) + currencySymbol
  }

  return undefined
}

export function getWagerProgress(bonus?: Bonus) {
  return (Number(bonus?.payload?.wageredAmount || 0) / (Number(bonus?.payload?.wager || 0) * Number(bonus?.payload?.amount || 0)) * 100).toFixed()
}

export function getIsBonusInQueue(bonus?: Bonus) {
  return bonus?.status === BonusStatus.ACTIVATION_PENDING
}
export function getIsBonusActive(bonus?: Bonus) {
  return bonus?.status === BonusStatus.ACTIVATED
}

export function getBonusCashOut(bonus: Bonus | undefined, exchanger: Exchanger) {
  if (bonus?.payload?.maxWin && bonus.payload.currencyId) {
    const currencySymbol = getCurrencySymbol(exchanger.getCurrencyIsoById(bonus.payload.currencyId))

    return getFormatBalance(Number(bonus.payload?.maxWin || 0)) + currencySymbol
  }

  return undefined
}

export function getActionMinDeposit(action: RewardedActionDeposit | undefined, currentUserCurrencyId: string, exchanger: Exchanger) {
  return getFormatBalance(Number(action?.parameters.minimalDepositAmounts[currentUserCurrencyId] || 0)) + exchanger.getCurrencySymbolById(currentUserCurrencyId)
}

export function getActionMaxDeposit(action: RewardedActionDeposit | undefined, currentUserCurrencyId: string, exchanger: Exchanger) {
  return getFormatBalance(Number(action?.parameters.maximumDepositAmounts[currentUserCurrencyId] || 0)) + exchanger.getCurrencySymbolById(currentUserCurrencyId)
}

export function getActionWagerDeposit(action: RewardedActionDeposit | undefined) {
  return `X${action?.bonusParameters.at(0)?.payload?.wager || ''}`
}
