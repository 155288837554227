import type { Ref } from 'vue'
import { computed } from 'vue'
import type { UserRank } from '@modules/club/club.model'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import { useQuery } from '@tanstack/vue-query'
import { QUERY_RESOURSES } from '@modules/club/club.constants'
import { fetchUserRank } from '@modules/club/club.service'
import { useUserStore } from '@modules/user'
import { USER_RELATED_QUERY_MODIFIER } from '@/config/queries'
import { isBeta } from '@/config/env'

interface UseUserRank {
  data: Ref<UserRank | undefined>
  currentRakebackPercent: Ref<number>
  currentCashbackPercent: Ref<number>
  isCustomAvatarEnabled: Ref<boolean>
  isChatDisallowed: Ref<boolean>
  isFetching: Ref<boolean>
}
export function useUserRank(): UseUserRank {
  const gracefulDegradation = useGracefulDegradation()
  const userStore = useUserStore()
  const enabled = computed<boolean>(() => userStore.isLogged && userStore.isIdentificationEnd)

  const { data, isFetching } = useQuery(
    [QUERY_RESOURSES.GET_RANK_RESOURSE, USER_RELATED_QUERY_MODIFIER],
    async () => {
      try {
        const response = await fetchUserRank()

        gracefulDegradation.removeServiceFromOffline('vip', 'userRank')

        return response.response
      }
      catch {
        gracefulDegradation.addServiceToOffline('vip', 'userRank')
      }
    },
    {
      enabled,
      retry: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  )

  const findPercent = (code: 'rakeback' | 'cashback') => {
    const feature = data.value?.status?.features?.find(item => item.code === code)

    return +(feature?.meta?.fields?.find(field => field.field === 'percent')?.value || 0)
  }

  const currentRakebackPercent = computed<number>(() => findPercent('rakeback'))
  const currentCashbackPercent = computed<number>(() => findPercent('cashback'))

  const isCustomAvatarEnabled = computed<boolean>(() => {
    return !!data.value?.status?.features?.find(item => item.code === 'avatar' && item.isActive)
  })

  const isChatDisallowed = computed<boolean>(() => {
    return data.value?.status?.slug === 'newcomer1' && !isBeta
  })

  return {
    data,
    currentRakebackPercent,
    isCustomAvatarEnabled,
    isChatDisallowed,
    currentCashbackPercent,
    isFetching,
  }
}
