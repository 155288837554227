export enum AuthElement {
  MODAL_WINDOW = 'auth_modal_window',
  MODAL_WINDOW_NAME = 'auth_window_name',
  EMAIL_INPUT = 'email_input',
  SET_PASSWORD_INPUT = 'password_input',
  SIGNUP_TITLE = 'signup_title',
  LOGIN_TITLE = 'login_title',
  CLOSE_MODAL_BUTTON = 'close_button',
  SHOW_PASSWORD_BUTTON = 'show_password_button',
  CLEAR_EMAIL_BUTTON = 'clear_email_button',
  CLEAR_PASSWORD_BUTTON = 'clear_password_button',
  FORGET_PASSWORD_BUTTON = 'forget_password_button',
  CURRENCY_SELECT = 'currency_signup_select',
  LOGIN_BUTTON = 'login_button',
  REGISTER_BUTTON = 'register_button',
  GOOGLE_BUTTON = 'google_button',
  VK_BUTTON = 'vk_button',
  STEAM_BUTTON = 'steam_button',
  LOGIN_FOOTER_BUTTON = 'login_footer_button',
  REGISTER_FOOTER_BUTTON = 'register_footer_button',

}

export enum HeaderElement {
  LOGIN_BUTTON = 'header_login_button',
  REGISTER_BUTTON = 'header_register_button',
  DEPOSIT_BUTTON = 'header_deposit_button',
  AMOUNT_SELECTOR = 'header_amount_selector',
  CHAT_BUTTON = 'chat_button',
}

export enum UISelect {
  DROPDOWN_BUTTON = 'dropdown_button_',
  DROPDOWN_SELECT = 'select_option_',
}

export enum LeftMenu {
  LEFT_SIDEBAR = 'left_sidebar',
  BONUS_CABINET_LEFT_BANNER = 'bonus_cabinet_left_banner',
  RAKEBACK_LEFT_BANNER = 'rakeback_left_banner',
  CASHBACK_LEFT_BANNER = 'cashback_left_banner',
  BANDANA_GAMES_LEFT_DROPDOWN = 'bandana_games_left_dropdown',
  AIRBLAST_GAME_LEFT_SECTION = 'airblast_left_section',
  OUR_GAME_LEFT_SECTION = '_our_game_left_section',
  MENU_LEFT_BURGER = 'left_menu_burger',
  CASINO_GAMES_LEFT_DROPDOWN = 'casino_games_left_dropdown',
  ALL_GAMES_LEFT_SECTION = 'all_games_left_section',
  HOT_GAMES_LEFT_SECTION = 'hot_games_left_section',
  TOP_GAMES_LEFT_SECTION = 'top_games_left_section',
  NEW_GAMES_LEFT_SECTION = 'new_games_left_section',
  SLOTS_GAMES_LEFT_SECTION = 'slots_games_left_section',
  LIVE_GAMES_LEFT_SECTION = 'live_games_left_section',
  BONUS_GAMES_LEFT_SECTION = 'bonus_games_left_section',
  ROULETTE_GAMES_LEFT_SECTION = 'roulette_games_left_section',
  BANDANA_CLUB_LEFT_SECTION = 'bandana_club_left_section',
  REFERRAL_LEFT_SECTION = 'referral_left_section',
  SUPPORT_LEFT_SECTION = 'support_left_section',
  FAVOURITES_LEFT_SECTION = 'favourites_left_section',
  GIVEAWAY_LEFT_SECTION = 'giveaway_left_section',

}

export enum CentralSection {
  BACK_BANNER_ARROW = 'back_banner_arrow',
  FORWARD_BANNER_ARROW = 'forward_banner_arrow',
  CATEGORY_CENTRAL_BUTTON = 'category_central_button_',
  GAMES_SEARCH_INPUT = 'games_search_input',
  PROVIDERS_CENTRAL_DROPDOWN = 'providers_central_dropdown',
  PROVIDERS_BODY_DROPDOWN = 'providers_body_dropdown',
  PROVIDERS_SEARCH_INPUT = 'providers_search_input',
  PROVIDERS_NAME_BODY = 'providers_name_',
  ALL_GAMES_CENTRAL_BUTTON = 'all_games_central_button',
  LOAD_MORE_GAMES_BUTTON = 'load_more_games_button',
  GAMES_BODY_DROPDOWN = 'games_body_dropdown',
  GAMES_TITLE_DROPDOWN = 'games_title_dropdown',
}

export enum ErrorPage {
  ERROR_PAGE_TITLE = 'error_page_title',
}

export enum ProfileElements {
  PROFILE_MENU_DROPDOWN = 'profile_menu_dropdown',
  PROFILE_MENU_ELEMENT_MAIN = 'profile_menu_element_profile',
  PROFILE_MENU_ELEMENT_WITHDRAW = 'profile_menu_element_withdraw',
  PROFILE_MENU_ELEMENT_HISTORY = 'profile_menu_element_history',
  PROFILE_MENU_ELEMENT_BONUS = 'profile_menu_element_bonus',
  PROFILE_MENU_ELEMENT_LOGOUT = 'profile_menu_element_logout',
  PROFILE_LEFT_MENU_MAIN = 'profile_left_menu_main',
  PROFILE_LEFT_MENU_SECURITY = 'profile_left_menu_security',
  PROFILE_LEFT_MENU_HISTORY = 'profile_left_menu_history',
  PROFILE_MAIN_SETTINGS_WINDOW = 'profile_main_settings_window',
}

export enum ProfileSettings {
  ID_USER_VALUE = 'id_user_value',
  USERNAME_INPUT = 'display_name_input',
  FIRST_NAME_INPUT = 'first_name_input',
  LAST_NAME_INPUT = 'last_name_input',
  DOB_PICKER = 'dob_picker',
  COUNTRY_INPUT = 'country_input',
  STATE_INPUT = 'state_input',
  CITY_INPUT = 'city_input',
  ZIP_INPUT = 'zip_input',
  PROFILE_SAVE_BUTTON = 'profile_save_button',
  STREAMER_TOGGLE = 'streamer_toggle',
  CURRENT_USERNAME_TITLE = 'current_username_title',
  COPY_CLIPBOARD_ID_BUTTON = 'copy_clipboard_id_button',
}

export enum ProfileSecurity {
  USER_EMAIL_INPUT = 'user_email_input',
  EMAIL_VERIFY_BUTTON = 'email_verify_button',
  OLD_PASSWORD_INPUT = 'old_password_input',
  NEW_PASSWORD_INPUT = 'new_password_input',
  NEW_PASSWORD_REPEAT_INPUT = 'new_password_repeat_input',
  SECURITY_SAVE_BUTTON = 'security_save_button',
  SECURITY_EMAIL_STATUS = 'security_email_status',
  TFA_CONNECT_BUTTON = '2fa_connect_button',
  TFA_SECRET_KEY_INPUT = '2fa_secret_key_input',
  TFA_CODE_INPUT = '2fa_code_input',
  TFA_CONNECT_FINAL_BUTTON = '2fa_connect_final_button',
  TFA_QR_IMAGE = '2fa_qr_image',
}

export enum PaymentSection {
  PAYMENT_MODAL_WINDOW = 'payment_modal_window',
  PAYMENT_MODAL_BUTTON = 'payment_modal_button_',
  WITHDRAW_MODAL_BUTTON = 'withdraw_modal_button',
  PAYMENT_CURRENCY_SELECTOR = 'payment_currency_selector',
  PAYMENT_METHOD_TITLE = 'payment_method_title',
  PAYMENT_AMOUNT_INPUT = 'payment_deposit_amount_input',
  PAYMENT_DEPOSIT_SUGGEST_AMOUNT = 'payment_deposit_suggest_amount_',
  PAYMENT_DEPOSIT_BUTTON = 'payment_deposit_button',
  PAYMENT_DEPOSIT_METHOD_SELECT = '_payment_deposit_method_select',
  PAYMENT_DEPOSIT_SUGGEST_SECTION = 'payment_deposit_suggest_section',
  PAYMENT_DEPOSIT_SUBMETHOD_BUTTON = '_payment_deposit_submethod_button',
  PAYMENT_RECEIVE_AMOUNT = 'payment_receive_amount',
  WITHDRAW_UNVERIF_WINDOW = 'withdraw_unverif_window',
  WITHDRAW_VERIF_LINK = 'withdraw_verif_link',
  PAYMENT_METHOD_CONTAINER = 'payment_method_container',
  PAYMENT_WITHDRAW_ADDRESS_INPUT = 'payment_withdraw_address_input',
  PAYMENT_WITHDRAW_BUTTON = 'payment_withdraw_button',
  PAYMENT_WITHDRAW_BANK_SELECTOR = 'payment_withdraw_bank_selector',
  PAYMENT_WITHDRAW_BANK_OPTION = 'payment_withdraw_bank_option',
  PAYMENT_DEPOSIT_MINDEP_AMOUNT = 'payment_deposit_mindep_amount',
}

export enum NotificationsElements {
  VERIFY_EMAIL_ROW = 'verify_email_row',
  TOP_EMAIL_ROW_BUTTON = 'top_email_row_button',
  TOP_EMAIL_ROW_CLOSE_BUTTON = 'top_email_row_close_button',
  TOAST_TITLE = 'toast_title_',
  TOAST_CONTENT = 'toast_content',
}

export enum ProfileHistory {
  PROBLEM_BUTTON = 'problem_deposit_button',
  PROFILE_HISTORY_DEPOSIT_DATE = 'profile_history_deposit_date',
  PROFILE_HISTORY_DEPOSIT_ID = 'profile_history_deposit_id',
  PROFILE_HISTORY_DEPOSIT_METHOD = 'profile_history_deposit_method',
  PROFILE_HISTORY_DEPOSIT_AMOUNT = 'profile_history_deposit_amount',
  PROFILE_HISTORY_DEPOSIT_STATUS = 'profile_history_deposit_status',
  PROFILE_HISTORY_TABLE_NO_DEPOSIT = 'profile_history_table_no_deposit',
  PROFILE_HISTORY_AMOUNT = 'profile_history_amount',
  PROFILE_HISTORY_PROVIDER = '_profile_history_provider',
  PROFILE_HISTORY_STATUS = 'profile_history_status',
  PROFILE_HISTORY_WITHDRAW_AMOUNT = 'profile_history_withdraw_amount',
  PROFILE_HISTORY_WITHDRAW_PROVIDER = 'profile_history_withdraw_provider',
  PROFILE_HISTORY_WITHDRAW_STATUS = 'profile_history_withdraw_status',
  PROFILE_HISTORY_WITHDRAW_CANCEL_BUTTON = 'profile_history_withdraw_cancel_amount',
}

export enum TableElements {
  WHOLE_ELEMENT_TABLE = 'whole_element_table',
}
export enum UIElements {
  TABS_BUTTON = '_tabs_button',
  COPY_INPUT_BUTTON = 'copy_input_button',
  CLOSE_BUTTON = 'close_button',
  ACCORDION_ELEMENT = 'accordion_element',
}

export enum ConfirmationElements {
  QR_DEPOSIT_IMAGE = 'qr_deposit_image',
  ADDRESS_DEPOSIT_INPUT = 'address_deposit_input',
  CONFIRMATION_WINDOW = 'confirmation_window',
  CURRENCY_RATE = 'currency_rate',
  CONFIRMATION_DEPOSIT_AMOUNT = 'confirmation_deposit_amount',
  TRANSFER_TIME = 'transfer_time',
}

export enum DepositInfoCryptoScreen {
  DEPOSIT_INFO_WINDOW = 'deposit_info_window',
  DEPOSIT_INFO_EXP_WINDOW = 'deposit_info_exp_window',

}

export enum DepositInfoFiatScreen {
  DEPOSIT_INFO_WINDOW = 'deposit_info_window',
  DEPOSIT_CARD_NUMBER_INPUT = 'deposit_card_number_input',
  TRANSFER_INFO = '_transfer_info',
  DEPOSIT_RECIPIENT_INPUT = 'deposit_recipient_input',
  DEPOSIT_WARNING_DESC = 'deposit_warning_description',
  DEPOSIT_TRANSFER_TIME = 'deposit_transfer_time',
}

export enum DepositProblemScreen {
  DEPOSIT_PROBLEM_WINDOW = 'deposit_problem_window',
  DEPOSIT_PROBLEM_ID_VALUE = 'deposit_problem_ID_value',
  DEPOSIT_DROPDOWN = 'deposit_dropdown',
  DEPOSIT_DROPDOWN_OPTION = 'deposit_dropdown_option',
  DEPOSIT_CHECK_LOAD = 'deposit_check_load',
  DEPOSIT_PROBLEM_COMMENT = 'deposit_problem_comment',
  REQUEST_CREATE_BUTTON = 'request_create_button',
  LAST_REQUESTS_TABLE = 'last_request_table',
}

export enum ForgotPasswordScreen {
  FORGOT_PASSWORD_WINDOW = 'forgot_password_window',
  FORGOT_EMAIL_INPUT = 'forgot_email_input',
  FORGOT_SEND_REQUEST_BUTTON = 'forgot_send_request_button',
  SUCCESS_WINDOW = 'forgot_success_window',
  SUCCESS_CLOSE_BUTTON = 'forgot_close_success_button',
}

export enum AirblastElements {
  AIRBLAST_WINDOW = 'airblast_window',
  AIRBLAST_GAME_WINDOW = 'airblast_game_window',
  AIRBLAST_BET_FORM = 'airblast_bet_form_',
  AIRBLAST_BET_BUTTON = 'airblast_bet_button_',
  AIRBLAST_AUTOBET_CHECKPOINT = 'airblast_autobet_checkpoint_',
  AIRBLAST_AUTOOUT_CHECKPOINT = 'airblast_autoout_checkpoint_',
  MULTIPLY_AUTOOUT_INPUT = 'multiply_autoout_input_',
  AMOUNT_BET_INPUT = 'amount_bet_input_',
  ACTIVE_PLAYER_SECTION = 'active_player_section',
  AIRBLAST_HISTORY_RESULT = 'airblast_history_result',
  AIRBLAST_HISTORY_SECTION = 'airblast_history_section',
  AIRBLAST_OPENED_HISTORY_WRAP = 'airblast_opened_history_wrap',
  AIRBLAST_RULES_WINDOW = 'airblast_rules_window',
  AIRBLAST_RULES_WINDOW_TITLE = 'airblast_rules_window_title',
  AIRBLAST_SUGGEST_AMOUNT = '_airblast_suggest_amount_',
  AIRBLAST_ROUND_MULTIPLY_VALUE = 'airblast_round_multiply_value',
  AIRBLAST_CANCEL_BUTTON = 'airblast_cancel_button_',
  AIRBLAST_PASSENGER_AMOUNT = 'airblast_passenger_amount',
  AIRBLAST_PASSENGER_JUMP = 'airblast_passenger_jump',
  AIRBLAST_TAKE_BET_BUTTON = 'airblast_take_bet_button',
  AIRBLAST_ACTIVE_PLAYER_NICKNAME = 'airblast_active_player_nickname',
  AIRBLAST_FLYAWAY_TITLE = 'airblast_flyaway_title',
  AIRBLAST_SETTINGS_RULES_BUTTON = 'airblast_settings_rules_button',
  AIRBLAST_SERVER_SEED_NEXT_INPUT = 'airblast_server_seed_next_input',
  AIRBLAST_FOOTER_FAIR_BUTTON = 'airblast_footer_fair_button',
  AIRBLAST_SETTINGS_FAIR_CURRENT_SEED = 'airblast_settings_fair_current_seed',
  AIRBLAST_SETTINGS_FAIR_NEXT_SEED = 'airblast_settings_fair_next_seed',
  AIRBLAST_SETTINGS_FAIR_CLOSE_BUTTON = 'airblast_settings_fair_close_button',
  AIRBLAST_SETTINGS_CLIENT_SEED = 'airblast_settings_client_seed',
}

export enum AirblastBetsTable {
  AIRBLAST_TABLE_TABS = 'airblast_table_tabs',
  AIRBLAST_MY_BETS_ROW = 'airblast_my_bets_row',
  AIRBLAST_SHARE_BET_BUTTON = 'airblast_share_bet_button',
  AIRBLAST_CHECK_FAIR_BUTTON = 'airblast_check_fair_button',
  AIRBLAST_MY_BETS_DATE = 'airblast_my_bets_date',
  AIRBLAST_MY_BETS_AMOUNT = 'airblast_my_bets_amount',
  AIRBLAST_MY_BETS_MULTIPLY = 'airblast_my_bets_multiply',
  AIRBLAST_MY_BETS_WIN = 'airblast_my_bets_win',
}

export enum GamesUIElements {
  SETTINGS_BUTTON = 'settings_button',
  FAIR_BUTTON = 'fair_button',
  SETTINGS_DROPDOWN_WRAP = 'settings_dropdown_wrap',
  SOUND_BUTTON = 'sound_button',
  SOUND_SLIDER = 'sound_slider',
  RULES_BUTTON = 'rules_button',
  BET_HISTORY_BUTTON = 'bet_history_button',
}

export enum AirblastRoundCheck {
  ROUND_CHECK_WINDOW = 'round_check_window',
  ROUND_CHECK_SERVER_SEED = 'round_check_server_seed',
  ROUND_CHECK_CLIENT_SEED = 'round_check_client_seed',
  ROUND_CHECK_SHA512_INPUT = 'round_check_sha512_input',
  ROUND_CHECK_HEX_INPUT = 'round_check_hex_input',
  ROUND_CHECK_DEC_INPUT = 'round_check_dec_input',
  ROUND_CHECK_MULTIPLY_INPUT = 'round_check_multiply_input',

}

export enum TowerElements {
  TOWER_GAME_WINDOW = 'tower_game_window',
  TOWER_GAME_LEFT_LEVELS_WRAP = 'tower_game_left_levels_wrap',
  TOWER_GAME_BET_BUTTON = 'tower_game_bet_button',
  TOWER_GAME_TAKE_BET_BUTTON = 'tower_game_take_bet_button',
  TOWER_GAME_CHANGE_DIFFICULTY_BUTTON = 'tower_game_change_difficulty_button',
  TOWER_GAME_BET_UNPUT = 'tower_game_bet_input',
  TOWER_GAME_BET_MULTIPLY_BUTTON = 'tower_game_bet_multiply_button',
  TOWER_GAME_BET_DIVISION_BUTTON = 'tower_game_bet_division_button',
  TOWER_GAME_BET_MINUS_BUTTON = 'tower_game_bet_minus_button',
  TOWER_GAME_BET_PLUS_BUTTON = 'tower_game_bet_plus_button',
  TOWER_GAME_WIN_SCREEN = 'tower_game_win_screen',
  TOWER_GAME_DIFFICULTY_OPTION = '_tower_game_difficulty_option',
  TOWER_GAME_DOOR = 'tower_game_door_',
  TOWER_GAME_LEVEL = 'tower_game_level_',
  TOWER_GAME_MONSTER = 'tower_game_monster',
  MY_BETS_DATE = 'my_bets_date',
  MY_BETS_AMOUNT = 'my_bets_amount',
  MY_BETS_MULTIPLY = 'my_bets_multiply',
  MY_BETS_WIN = 'my_bets_win',
  MY_BETS_SHARE_BUTTON = 'my_bets_share_button',
  MY_BETS_FAIR_BUTTON = 'my_bets_fair_button',
  TOWER_GAME_CHAT_MESSAGE = 'tower_game_chat_message',
  TOWER_HISTORY_BET_ELEMENT = 'tower_history_bet_element',
  TOWER_FAQ_BUTTON = 'tower_faq_button',
  TOWER_FULLSCREEN_BUTTON = 'tower_fullscreen_button',
  TOWER_OPEN_BETS_BUTTON = 'tower_open_bets_button',
  TOWER_MENU_BUTTON = 'tower_menu_button',
  TOWER_OPEN_HISTORY_SECTION = 'tower_open_history_section',
  TOWER_RULES_WINDOW = 'tower_rules_window',
  TOWER_MENU_WINDOW = 'tower_menu_window',
}
export enum AirblastChatMessage {
  AIRBLAST_CHAT_MESSAGE = 'airblast_chat_message',
  AIRBLAST_CHAT_MESSAGE_USERNAME = 'airblast_chat_message_username',
}

export enum SupportElements {
  SUPPORT_WINDOW_TITLE = 'support_window_title',
  SUPPORT_CREATE_TICKET_BUTTON = 'support_create_ticket_button',
  SUPPORT_LEFT_SIDEBAR = 'support_left_sidebar',
  SUPPORT_MAIN_SECTION = 'support_main_section',
  SUPPORT_TICKET_TITLE = 'support_ticket_title',
  SUPPORT_TICKET_MESSAGE = 'support_ticket_message',
  SUPPORT_ATTACH_BUTTON = 'support_attach_button',
  SUPPORT_SEND_TICKET_BUTTON = 'support_send_ticket_button',
  SUPPORT_TICKET_STATUS_BUTTON = 'support_ticket_status_button_',
  SUPPORT_LIST_STATUS_BUTTON = 'support_list_status_button_',
  SUPPORT_TICKET_CLOSE_BUTTON = 'support_ticket_close_button',
  SUPPORT_TICKET_HEADER_TITLE = 'support_ticket_header_title',
  SUPPORT_TICKET_BODY_MESSAGE = 'support_ticket_body_message',
  SUPPORT_TICKET_FOOTER_TIME = 'support_ticket_footer_time',
  SUPPORT_TICKET_LIST_TITLE = 'support_ticket_list_title',
  SUPPORT_TICKET_FILE_PREVIEW = 'support_ticket_file_preview',
}

export enum PartnershipElements {
  REFERRAL_CARD = 'referral_card_',
  REFERRAL_CARD_TITLE = 'referral_card_title',
  PARTNER_CREATE_CAMPAIGN_BUTTON = 'partner_create_campaign_button',
  PARTNER_REVSHARE_CREATE_CAMPAIGN_BUTTON = 'partner_revshare_create_campaign_button',
  PARTNER_REFERRAL_LINK_INPUT = 'partner_referral_link_input',
  PARTNER_LINK_EDIT_BUTTON = 'partner_link_edit_button',
  PARTNER_LINK_DELETE_BUTTON = 'partner_link_delete_button',
  PARTNER_LINK_COPY_BUTTON = 'partner_link_copy_button',
  PARTNER_CAMPAIGN_TITLE = 'partner_campaign_title',
  PARTNER_CAMPAIGN_INCOME_AMOUNT = 'partner_campaign_income_amount',
  PARTNER_CAMPAIGN_CREATION_DATE = 'partner_campaign_creation_date',
  PARTNER_WINDOW_TITLE = 'partner_window_title',
  PARTNER_RENAME_MODAL_WINDOW = 'partner_rename_modal_window',
  PARTNER_EDIT_RENAME_INPUT = 'partner_edit_rename_input',
  PARTNER_EDIT_SAVE_BUTTON = 'partner_edit_save_button',
  PARTNER_MODAL_DELETE_BUTTON = 'partner_modal_delete_button',
  PARTNER_CREATE_NAME_INPUT = 'partner_create_name_input',
  PARTNER_CREATE_CURRENCY_SELECTOR = 'partner_create_currency_selector',
  PARTNER_CREATE_CONFIRM_BUTTON = 'partner_create_confirm_button',
  INVITED_TABLE_USERNAME = 'invited_table_username',
  MODEL_NAME_TITLE = 'model_name_title',
  MODEL_CLIENT_PERCENT = 'model_client_percent_',
  INFO_PERIOD_DROPDOWN = 'info_period_dropdown',
  INFO_PERIOD_OPTION = 'info_period_option_',
  INFO_TOTAL_STAT_TABLE = 'info_total_stat_table',
  INFO_TOTAL_PAYOUT_TABLE = 'info_total_payout_table',
  AVAILABLE_PAYOUT_AMOUNT = 'available_payout_amount',
  PAYOUT_REQUEST_BUTTON = 'payout_request_button',
  PAYOUT_MIN_AMOUNT_DESC = 'payout_min_amount_desc',
  PUBLIC_STAT_TOTAL_REFERRALS = 'public_stat_total_referrals',
  PUBLIC_STAT_REFERRALS_TODAY = 'public_stat_referrals_today',
  PUBLIC_STAT_INHOUSE_PROFIT = 'public_stat_inhouse_profit',
  PUBLIC_STAT_SLOTS_PROFIT = 'public_stat_slots_profit',
  PUBLIC_STAT_TODAY_PROFIT = 'public_stat_today_profit',
  PUBLIC_STAT_ALLTIME_PROFIT = 'public_stat_alltime_profit',
  PUBLIC_INVITE_DEP_AMOUNT = 'public_invite_dep_amount',
  PUBLIC_INVITE_INHOUSE_BET_AMOUNT = 'public_invite_inhouse_bet_amount',
  PUBLIC_INVITE_SLOTS_BET_AMOUNT = 'public_invite_slots_bet_amount',
  PUBLIC_INVITE_INHOUSE_WIN_AMOUNT = 'public_invite_inhouse_win_amount',
  PUBLIC_INVITE_INHOUSE_PROFIT_AMOUNT = 'public_invite_inhouse_profit_amount',
  PUBLIC_INVITE_SLOTS_WIN_AMOUNT = 'public_invite_slots_win_amount',
  PUBLIC_INVITE_SLOTS_PROFIT_AMOUNT = 'public_invite_slots_profit_amount',
  PUBLIC_INVITE_AGREGATOR_FEE = 'public_invite_agregator_fee',
  PUBLIC_INVITE_ALLTIME_PROFIT = 'public_invite_alltime_profit',
  PAYMENTS_TABLE_AMOUNT = 'payments_table_amount',
  PAYMENTS_TABLE_STATUS = 'payments_table_status_',
  REVSHARE_STAT_TOTAL_REFERRALS = 'revshare_stat_total_referrals',
  REVSHARE_STAT_FD = 'revshare_stat_fd',
  REVSHARE_STAT_RD = 'revshare_stat_rd',
  REVSHARE_STAT_DEPOSITS = 'revshare_stat_deposits',
  REVSHARE_STAT_WITHDRAWS = 'revshare_stat_withdraws',
  REVSHARE_STAT_MERCHANTS_FEE = 'revshare_stat_merchants_fee',
  REVSHARE_STAT_AGREGATORS_FEE = 'revshare_stat_agregators_fee',
  REVSHARE_STAT_CASINO_PROFIT = 'revshare_stat_casino_profit',
  REVSHARE_STAT_PARTNER_PROFIT = 'revshare_stat_partner_profit',
  REVSHARE_DETAILS_USERNAME = 'revshare_details_username',
  REVSHARE_DETAILS_DEPOSIT_SUM = 'revshare_details_deposit_sum',
  REVSHARE_DETAILS_WITHDRAW_SUM = 'revshare_details_withdraw_sum',
  REVSHARE_DETAILS_CASINO_BET_SUM = 'revshare_details_casino_bet_sum',
  REVSHARE_DETAILS_CASINO_WIN_SUM = 'revshare_details_casino_win_sum',
  REVSHARE_DETAILS_CASINO_INCOME = 'revshare_details_casino_income',
  REVSHARE_DETAILS_BONUS_SUM = 'revshare_details_bonus_sum',
  REVSHARE_DETAILS_MERCHANTS_FEE = 'revshare_details_merchants_fee',
  REVSHARE_DETAILS_AGREGATORS_FEE = 'revshare_details_agregators_fee',
  REVSHARE_DETAILS_CASINO_PROFIT = 'revshare_details_casino_profit',
  REVSHARE_DETAILS_PARTNER_PROFIT = 'revshare_details_partner_profit',
}

export enum GiveawayElements {
  GIVEAWAY_CREATE_WINDOW = 'giveaway_create_window',
  WINNERS_AMOUNT_SELECTOR = 'winners_amount_selector',
  WINNERS_AMOUNT_SELECTOR_OPTION = 'winners_amount_selector_option_',
  GIVEAWAY_TIME_SELECTOR = 'giveaway_time_selector',
  WINNERS_TIME_SELECTOR_OPTION = 'winners_time_selector_option_',
  GIVEAWAY_AMOUNT_INPUT = 'giveaway_amount_input',
  MINIMUM_DEPOSIT_SELECTOR = 'minimum_deposit_selector',
  WINNERS_DEPOSIT_SELECTOR_OPTION = 'winners_deposit_selector_option_',
  GIVEAWAY_START_BUTTON = 'giveaway_start_button',
  GIVEAWAY_LONG_TIME_SWITCHER = 'giveaway_long_time_switcher',
  GIVEAWAY_INFO_AMOUNT = 'giveaway_info_amount',
  GIVEAWAY_INFO_MIN_DEPOSIT = 'giveaway_info_min_deposit',
  GIVEAWAY_INFO_AVAILABLE = 'giveaway_info_available',
  GIVEAWAY_INFO_WINNERS = 'giveaway_info_winners',
  GIVEAWAY_INFO_START_FROM = 'giveaway_info_start_from',
  GIVEAWAY_INFO_CANCEL_BUTTON = 'giveaway_info_cancel_button',
  GIVEAWAY_INFO_LINK_INPUT = 'giveaway_info_link_input',
  GIVEAWAY_INFO_SHOW_LINK_BUTTON = 'giveaway_info_show_link_button',
  GIVEAWAY_INFO_USERNAME_PLAYER = 'giveaway_info_username_player',
  GIVEAWAY_INFO_TOTAL_AMOUNT_PLAYER = 'giveaway_info_total_amount_player',
  GIVEAWAY_INFO_JOIN_BUTTON = 'giveaway_info_join_button',
  GIVEAWAY_INFO_AUTH_BUTTON = 'giveaway_info_auth_button',
  GIVEAWAY_ROULETTE_WINDOW = 'giveaway_roulette_window',
  GIVEAWAY_WINNER_WINDOW = 'giveaway_winner_window',
}

export enum BonusElements {
  BONUS_DEPOSIT_CARD = 'bonus_deposit_card',
  BONUS_VK_CARD = 'bonus_vk_card',
  BONUS_PROMOCODE_CARD = 'bonus_promocode_card',
  BONUS_PROMOCODE_INPUT = 'bonus_promocode_input',
  BONUS_VK_GET_BUTTON = 'bonus_vk_get_button',
  BONUS_PROMOCODE_GET_BUTTON = 'bonus_promocode_get_button',
  BONUS_DEPOSIT_CARD_MINDEP_INFO = 'bonus_deposit_card_mindep_info',
  BONUS_DEPOSIT_CARD_WAGER_INFO = 'bonus_deposit_card_wager_info',
  BONUS_DEPOSIT_CARD_MAX_INFO = 'bonus_deposit_card_max_info',
  BONUS_DEPOSIT_CARD_GET_BUTTON = 'bonus_deposit_card_get_button',
  NEW_BONUS_CARD = 'new_bonus_card_',
  NEW_BONUS_AMOUNT_INFO = 'new_bonus_amount_info_',
  NEW_BONUS_WAGER_INFO = 'new_bonus_wager_info_',
  NEW_BONUS_TIME_INFO = 'new_bonus_time_info_',
  NEW_BONUS_CANCEL_BUTTON = 'new_bonus_cancel_button',
  NEW_BONUS_ACTIVATE_BUTTON = 'new_bonus_activate_button',
  BONUS_DEPOSIT_VALUE = 'bonus_deposit_value_',
  BONUS_BLOCKED_CARD = 'bonus_blocked_card',
  BONUS_BLOCKED_CARD_LINES = 'bonus_blocked_card_lines',
  BONUS_DETAILS_WINDOW = 'bonus_details_window',
  BONUS_DETAILS_BONUS_AMOUNT_INFO = 'bonus_details_bonus_amount_info',
  BONUS_DETAILS_WAGER_AMOUNT_INFO = 'bonus_details_wager_amount_info',
  BONUS_DETAILS_CASHOUT_AMOUNT_INFO = 'bonus_details_cashout_amount_info',
  BONUS_DETAILS_TIME_INFO = 'bonus_details_time_info',
  CANCEL_BONUS_WINDOW = 'cancel_bonus_window',
  CANCEL_BONUS_AGREE_BUTTON = 'cancel_bonus_agree_button',
  CANCEL_BONUS_DECLINE_BUTTON = 'cancel_bonus_decline_button',

}

export enum RewardElements {
  MIN_DEPOSIT_VALUE = 'min_deposit_value',
  MAX_BONUS_VALUE = 'max_bonus_value',
  WAGER_VALUE = 'wager_value',
}

export enum SingleGameElements {
  CHECK_FAIR_USERNAME = 'check_fair_username',
  CHECK_FAIR_BET_DATE = 'check_fair_bet_date',
  CHECK_FAIR_TITLE = 'check_fair_title_',
  CHECK_FAIR_VALUE = 'check_fair_value_',
  CHECK_FAIR_ID_BET_INPUT = 'check_fair_id_bet_input',
  CHECK_FAIR_LEVELS_SECTION = 'check_fair_levels_section',
  CHECK_FAIR_LEVELS_LINE = 'check_fair_levels_line_',
  CHECK_FAIR_MONSTER = 'check_fair_monster_',
  CHECK_FAIR_SHARE_LINK_BUTTON = 'check_fair_share_link_button',
  CHECK_FAIR_SHARE_VK_BUTTON = 'check_fair_share_vk_button',
  CHECK_FAIR_SHARE_TG_BUTTON = 'check_fair_share_tg_button',
  CHECK_FAIR_DROPDOWN = 'check_fair_dropdown',
  CHECK_FAIR_SERVER_SEED_INPUT = 'check_fair_server_seed_input',
}

export enum LoyaltyElements {
  CURRENT_RANG_SECTION = 'current_rang_section',
  CURRENT_RANG_NAME = 'current_rang_name',
  CURRENT_RANG_VALUE = 'current_rang_value',
  TOTAL_INCOMING_TABLE_VALUE = 'total_incoming_table_value',
  INCOMING_TABLE_CURRENCY = 'incoming_table_currency',
  INCOMING_TABLE_GET_BUTTON = 'incoming_table_get_button',
  INCOMING_TABLE_YOUR_VALUE = 'incoming_table_your_value',
  INCOMING_TABLE_INFO_BUTTON = 'incoming_table_info_button',
  INCOMING_TABLE_INFO_TOOLTIP = 'incoming_table_info_tooltip',
  RANK_TABLE_SECTION = 'rank_table_section',
  RANK_TABLE_ELEMENT_TITLE = 'rank_table_element_title_',
  RANK_TABLE_ELEMENT_CURRENT = 'rank_table_element_current_',
  RANK_TABLE_ELEMENT_POINTS = 'rank_table_element_points',
  RANK_TABLE_ELEMENT_CONDITION = 'rank_table_element_condition',
  RANK_TABLE_MORE_BUTTON = 'rank_table_more_button',
  INCOMING_HISTORY_TABLE_STATUS = 'incoming_history_table_status',
  INCOMING_HISTORY_TABLE_AMOUNT = 'incoming_history_table_amount',
  INCOMING_HISTORY_TABLE_DATE = 'incoming_history_table_date  ',
  RAKEBACK_MODAL_WINDOW = 'rakeback_modal_window',
  RAKEBACK_MODAL_CLOSE_BUTTON = 'rakeback_modal_close_button',
}

export enum ChangingCurrencyElements {
  CHANGING_MODAL_WINDOW_TOWER = 'changing_modal_window_tower',
  CHANGING_CURRENCY_TO = 'changing_currency_to',
  CHANGING_CURRENCY_FROM = 'changing_currency_from',
  CHANGING_CANCEL_BUTTON = 'changing_cancel_button',
  CHANGING_CONFIRM_BUTTON = 'changing_confirm_button',
}
export enum FinanceSelectorElements {
  FINANCE_CURRENT_FIAT_CURRENCY = 'finance_current_fiat_currency_',
  FINANCE_CURRENT_BONUS_CURRENCY = 'finance_current_bonus_currency',
  FINANCE_CURRENCY_SELECT = 'finance_currency_select_',
}
export enum CalculationElements {
  SERVER_SEED_INPUT = 'server_seed_input',
  FIRST_CLIENT_SEED = 'first_client_seed',
  SECOND_CLIENT_SEED = 'second_client_seed',
  THIRD_CLIENT_SEED = 'third_client_seed',
  MULTIPLY_ROUND_VALUE = 'multiply_round_value',
  FAIR_TABS = 'fair_tabs_',
}

export enum DefaultElements {
  BASE_SELECT_DROPDOWN = 'base_select_dropdown_',
  BASE_SELECT_OPTION = 'base_select_option_',
}
