import type { ServicePaginatedResponse, ServiceResponse } from '@modules/wallet/wallet.dependencies'
import { httpClient } from '@modules/wallet/wallet.dependencies'
import type {
  ExchangeRatesDtoResponse,
  WalletDtoCancelWithdrawRequest,
  WalletDtoCreate,
  WalletDtoDepositRequest,
  WalletDtoDepositResponse,
  WalletDtoGetCurrenciesResponse,
  WalletDtoGetMethodsRequest,
  WalletDtoGetMethodsResponse,
  WalletDtoGetResponse,
  WalletDtoGetStaticMethodRequest,
  WalletDtoGetStaticMethodResponse,
  WalletDtoGetTickets,
  WalletDtoGetTicketsResponse,
  WalletDtoListRequest,
  WalletDtoReferralWithdrawRequest,
  WalletDtoSelect,
} from '@modules/wallet/wallet.dto'
import type { HistoryDeposit } from '@modules/wallet/wallet.model'

export function getWallets(): Promise<ServiceResponse<WalletDtoGetResponse>> {
  return httpClient.get('wallet/')
}
export function funTopUp(): Promise<ServiceResponse<WalletDtoGetResponse>> {
  return httpClient.post('payment/fun-top-up')
}

export function getCurrencies(): Promise<ServiceResponse<WalletDtoGetCurrenciesResponse>> {
  return httpClient.get('wallet/currencies')
}

export function getPaymentsMethods(config: WalletDtoGetMethodsRequest): Promise<ServiceResponse<WalletDtoGetMethodsResponse>> {
  const correctUrl = config.params.type === 'DEPOSIT' ? 'deposit' : 'withdrawal'

  return httpClient.get(`/payment/methods/${correctUrl}`, config)
}

export function deposit(payload: WalletDtoDepositRequest): Promise<ServiceResponse<WalletDtoDepositResponse>> {
  return httpClient.post('/payment/deposit', payload)
}

export function withdraw(payload: WalletDtoReferralWithdrawRequest & WalletDtoDepositRequest): Promise<ServiceResponse<any>> {
  if (payload.campaignId)
    return referralWithdraw(payload as WalletDtoReferralWithdrawRequest)

  return commonWithdraw(payload as WalletDtoDepositRequest)
}

export function cancelWithdraw(payload: WalletDtoCancelWithdrawRequest): Promise<ServiceResponse<any>> {
  return httpClient.put('/payment/withdraw/cancel', payload)
}

function commonWithdraw(payload: WalletDtoDepositRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('/payment/withdraw', payload)
}

function referralWithdraw(payload: WalletDtoReferralWithdrawRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('/payment/referral/withdraw', payload)
}

export function getStaticMethod(config: WalletDtoGetStaticMethodRequest): Promise<ServiceResponse<WalletDtoGetStaticMethodResponse>> {
  return httpClient.get('/payment/static-wallet', config)
}

export function getHistoryDeposit(params: WalletDtoListRequest): Promise<ServiceResponse<ServicePaginatedResponse<HistoryDeposit>>> {
  return httpClient.get('/payment/deposit/history', { params })
}

export function getHistoryWithdraw(params: WalletDtoListRequest): Promise<ServiceResponse<ServicePaginatedResponse<HistoryDeposit>>> {
  return httpClient.get('/payment/withdraw/history', { params })
}

export function createTicket(payload: FormData): Promise<ServiceResponse<{ id: string }>> {
  return httpClient.post('/payment/ticket/create', payload)
}

export function getTickets(params: WalletDtoGetTickets): Promise<ServiceResponse<ServicePaginatedResponse<WalletDtoGetTicketsResponse>>> {
  return httpClient.get('/payment/ticket', { params })
}

export function getExchangeRates(): Promise<ServiceResponse<ExchangeRatesDtoResponse>> {
  return httpClient.get('/wallet/exchange/rates')
}

export function createWalletService({ currencyId }: WalletDtoCreate): Promise<ServiceResponse<WalletDtoGetResponse>> {
  return httpClient.post(`/wallet/${currencyId}`)
}

export function selectWalletService({ currencyId }: WalletDtoSelect): Promise<ServiceResponse<any>> {
  return httpClient.post(`/wallet/${currencyId}/select`)
}
