import type {
  CheckGameResponse,
  CreateGame,
  GameCommonInfo,
  SelectDoor,
  SelectDoorResponse,
  TowerHistoryParams,
  WithCurrency,
} from '@modules/games/tower/tower.dto'
import type { TowerGame, TowerGameHistory } from '@modules/games/tower/tower.model'
import type { ServicePaginatedResponse, ServiceResponse } from '@/api'
import { httpClient } from '@/api'

enum ServiceBase {
  value = 'games',
}

export function getRoundDetails(gameId: string): Promise<ServiceResponse<CheckGameResponse>> {
  return httpClient.get(`${ServiceBase.value}/tower/games/history/${gameId}`)
}
export function getGameInfo(): Promise<ServiceResponse<TowerGame | null>> {
  return httpClient.get(`${ServiceBase.value}/tower/init`)
}
export function createGame(data: CreateGame): Promise<ServiceResponse<TowerGame>> {
  return httpClient.post(`${ServiceBase.value}/tower/create`, data)
}

export function placeMine(data: SelectDoor): Promise<ServiceResponse<SelectDoorResponse<TowerGame>>> {
  return httpClient.post(`${ServiceBase.value}/tower/place`, data)
}

export function collectGame(data: WithCurrency): Promise<ServiceResponse<SelectDoorResponse<TowerGame>>> {
  return httpClient.post(`${ServiceBase.value}/tower/collect`, data)
}

export function getGameCommonInfo(): Promise<ServiceResponse<GameCommonInfo>> {
  return httpClient.get(`${ServiceBase.value}/tower/info`)
}

export function getGameHistory(params: TowerHistoryParams): Promise<ServiceResponse<ServicePaginatedResponse<TowerGameHistory>>> {
  return httpClient.get(`${ServiceBase.value}/tower/bets/history/my`, { params })
}

export function getGameTop(): Promise<ServiceResponse<TowerGameHistory[]>> {
  return httpClient.get(`${ServiceBase.value}/tower/bets/history/top`)
}
