<script setup lang="ts">
import {
  computed,
  getFormatBalance,
  getIconSymbolName,
  useExchanger,
} from '@modules/games/airblast/airblast.dependencies'
import { UserAvatar } from '@modules/user'
import type { MessageModel } from '@modules/games/airblast/airblast.dependencies'
import { getUserRank } from '@modules/user/user.helpers'
import { getSupportWebp } from '@helpers/webp'
import CasinoGameAirblast from '@/assets/icon/casino-game-airblast.svg'
import { AirblastChatMessage } from '@/cypress.types'
import { t } from '@/plugins/i18n'

interface Props {
  message: MessageModel
}
const props = defineProps<Props>()
const Exchanger = useExchanger()

function getAmount(amount: number, currencyId: string) {
  return Exchanger.exchangeToViewCurrency(amount, currencyId)
}
function getAmountCurrency(currencyId: string) {
  const viewCurrencyId = Exchanger.getViewCurrencyId()

  return getIconSymbolName(Exchanger.getCurrencyIsoById(viewCurrencyId || currencyId))
}
const crashInfo = computed(() => props.message.attachments?.at(0))
</script>

<template>
  <div class="crash-message" :data-cy="AirblastChatMessage.AIRBLAST_CHAT_MESSAGE">
    <div class="grid gap-2" :class="crashInfo?.bet?.username ? 'grid-cols-2 ' : 'grid-cols-1'">
      <div class="crash-message-user">
        <UserAvatar
          type="chat"
          :rank="getUserRank(message.user.vipStatus.slug)"
          class="crash-message__avatar"
          :src="getSupportWebp() ? (message?.user?.avatarWebp || message?.user?.avatar) : message?.user?.avatar"
        />

        <span class="crash-message__name text-ellipsis overflow-hidden whitespace-nowrap" :class="{ special: crashInfo?.bet?.username }" :data-cy="AirblastChatMessage.AIRBLAST_CHAT_MESSAGE_USERNAME">{{ message?.user?.name }}</span>
      </div>

      <div v-if="crashInfo?.bet?.username" class="flex flex-row-reverse items-center justify-between">
        <UserAvatar
          type="chat"
          :rank="getUserRank(crashInfo?.bet?.vipStatus?.slug || 'newcomer1')"
          class="crash-message__avatar"
          :src="getSupportWebp() ? (crashInfo?.bet?.avatar?.webpKey || crashInfo?.bet?.avatar?.key) : crashInfo?.bet?.avatar?.key"
        />

        <span class="crash-message__name text-ellipsis overflow-hidden whitespace-nowrap" :class="{ special: crashInfo?.bet?.username }">{{ crashInfo?.bet?.username }}</span>
      </div>
    </div>

    <div class="crash-message__row">
      <p class="crash-message-game">
        <CasinoGameAirblast name="casino-game-airblast" class="w-5 h-5" />

        Airblast
      </p>

      <p class="crash-message__shared">
        {{ t('airblast.message.shared') }}
      </p>
    </div>

    <div class="crash-message-blocks">
      <div class="crash-message__block">
        <h3>{{ t('airblast.message.out') }}</h3>

        <p>x{{ getFormatBalance(Number(crashInfo?.bet?.claimCoefficient || 0), false) }}</p>
      </div>

      <div class="crash-message__block">
        <h3>{{ t('airblast.message.round') }}</h3>

        <p>x{{ getFormatBalance(Number(crashInfo?.bet?.finalCoefficient || 0), false) }}</p>
      </div>

      <div class="crash-message__block">
        <h3>{{ t('airblast.message.bet') }}</h3>

        <p :class="getAmountCurrency(crashInfo?.bet?.currencyId || '643')">
          {{ getFormatBalance(getAmount(Number(crashInfo?.bet?.amount || 0), crashInfo?.bet?.currencyId || '643')) }}
        </p>
      </div>

      <div class="crash-message__block">
        <h3>{{ t('airblast.message.win') }}</h3>

        <p :class="getAmountCurrency(crashInfo?.bet?.currencyId || '643')">
          {{ getFormatBalance(getAmount(Number(crashInfo?.bet?.winAmount || 0), crashInfo?.bet?.currencyId || '643')) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.crash-message {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  background: var(--gray-5);

  &-blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  &__block {
    border-radius: 4px;
    background: var(--dark);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;

    color: var(--yellow);

    &:first-child {
      color: var(--blue-4);
    }
    &:last-child {
      color: var(--green-6);
    }
    &:nth-child(2) {
      color: var(--orange-light);
    }

    & h3 {
      color: inherit;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;

    }

    & p {
      color: var(--white);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
    }
  }

  &__shared {
    color: var(--light);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:nth-child(2) {
      flex-direction: row-reverse;
    }
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: $radius;
  }

  &-user {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-game {
    display: flex;
    align-items: center;
    gap: 6px;

    color: var(--white);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &__name {
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;

  }

}
</style>
