import type { GameRoute } from '@modules/games/games.types'
import type { UserModule } from '@/types'
import { getGameIsPublish, getPublishGameByPath } from '@/config/games'

export const install: UserModule = (ctx) => {
  const modules = import.meta.glob('./*/*.routes.ts', { import: 'route', eager: true })
  const routes = Object.values(modules) as GameRoute[]

  routes.forEach((route: GameRoute) => {
    const result = Array.isArray(route) ? route : [route]

    // if game is not publish we don't install this game
    const gamePath = route.path
    const isGamePublish = getGameIsPublish(getPublishGameByPath(gamePath))
    if (!isGamePublish)
      return

    result.forEach(child => ctx.router.addRoute('root', child))
  })
}
