<script lang="ts" setup>
import { ref, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { GameCardPlaceholder } from '@modules/casino/components/GameCardPlaceholder'
import { GameCard } from '@modules/casino/components/GameCard'
import { fetchGames } from '@modules/casino/casino.service'
import { useModalLayer } from '@composable/useModal'
import type { GameListEntity, GameType } from '../casino.model'
import Close from '@/assets/icon/close.svg'
import Logo from '@/assets/icon/logo.svg'
import SearchIcon from '@/assets/icon/search1.svg'

import { CentralSection } from '@/cypress.types'

import { t } from '@/plugins/i18n'

interface Props {
  placeholder?: string
  id: string
  name: string
  provider?: string
  tab?: GameType
  fetched?: boolean
  onPage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'input_search_by_games',
  id: 'search',
  name: 'search',
  fetched: false,
  onPage: false,
})
const emits = defineEmits<{
  (event: 'hideProviders'): void
  (event: any, search: string | boolean): void
}>()

const search = ref('')
const toggle = ref(false)
const loading = ref(true)
const showClear = ref(false)
const itemsAll = ref<GameListEntity[]>([])
const itemsOther = ref<GameListEntity[]>([])
const changeTimer = ref<number | boolean | NodeJS.Timeout>(false)
const timeout = ref(1000)

const input = ref()

watch(search, (val) => {
  if (val.length === 0)
    showClear.value = false
  else
    showClear.value = true

  if (props.fetched)
    emits(props.name, search.value)
  if (val.length >= 3 && !props.fetched) {
    toggle.value = true
    if (changeTimer.value !== false)
      clearTimeout(changeTimer.value as number)

    changeTimer.value = setTimeout(() => {
      if (props.onPage) {
        emits(props.name, search.value)
      }
      else {
        itemsAll.value = []
        itemsOther.value = []
        fetchResults()
        if (props.tab && !['all', 'bandana'].includes(props.tab))
          fetchResults(true)
      }
      changeTimer.value = false
    }, timeout.value)
  }
})

watch(toggle, (val) => {
  emits(props.name, val)
})

function onUpdateFavorite(id: string, favorite: boolean) {
  const game = itemsAll.value.find(item => item.id === id)

  if (game)
    game.favorite = favorite
}

// TODO rewrite to vue-query
async function fetchResults(exclude?: GameType[] | boolean) {
  loading.value = true
  try {
    const type: GameType[] = ['all', 'bandana'].includes(props.tab as GameType) ? [] : [props.tab as GameType]
    const { success, response: { data } } = await fetchGames({
      perPage: 50,
      search: search.value,
      provider: props.provider ?? undefined,
      type: exclude ? [] : type,
      exclude: exclude ? [props.tab as GameType] : [],
    }, 1)

    if (success && data?.games.length) {
      if (exclude)
        itemsOther.value = data.games
      else itemsAll.value = data.games
    }
  }
  catch (e) {
    // console.error(e)
  }
  finally {
    loading.value = false
  }
}

function enterSearch() {
  if (props.fetched || props.onPage)
    emits(props.name, search.value)

  if (input.value)
    input.value.blur()
}

function clearSearch() {
  search.value = ''
  toggle.value = false
  if (props.fetched || props.onPage)
    emits(props.name, search.value)
}
function onClose() {
  toggle.value = false
}
function openIfHasQuery() {
  if (itemsAll.value.length || itemsOther.value.length)
    toggle.value = true
}
function onUnauthenticatedPlay(path?: string): void {
  useModalLayer().show('auth', { type: 'signIn', path })
}
</script>

<template>
  <div class="slots--search">
    <div
      class="slots--search-input form_field input-with-btn"
      @click="id === 'search_slots' ? emits('hideProviders') : () => { }"
    >
      <input
        :id="props.id" :ref="input" v-model="search" type="text"
        name="search"
        autocomplete="off" :placeholder="t(props.placeholder)"
        :data-cy="CentralSection.GAMES_SEARCH_INPUT"
        @keyup.enter="enterSearch()" @click="openIfHasQuery()"
      >

      <label :for="props.id" class="m0">
        <i class="icon">
          <SearchIcon name="search1" />
        </i>
      </label>

      <button v-if="showClear" class="btn btn-default w-[48px] h-[48px] btn_clear input_btn" @click="clearSearch()">
        <Close name="close" />
      </button>
    </div>

    <div v-on-click-outside="onClose" class="slots--search_dropdown">
      <div v-if="toggle" class="dd-search">
        <div class="dd-search_wrap">
          <div v-if="!loading && (itemsAll.length || itemsOther.length)" class="dd-search__inner" :data-cy="CentralSection.GAMES_BODY_DROPDOWN">
            <GameCard v-for="item of itemsAll" :key="item.id" :item="item" @update="fetchResults" @update-favorite="onUpdateFavorite" @unauthenticated-play="onUnauthenticatedPlay" />

            <template v-if="props.tab !== 'all' && itemsOther.length">
              <p class="label">
                {{ t('search_results_other_cats') }}
              </p>

              <GameCard v-for="item of itemsOther" :key="item.id" :item="item" @update="fetchResults" />
            </template>
          </div>

          <div v-else-if="loading && !itemsAll.length" class="dd-search__inner">
            <GameCardPlaceholder v-for="i of 20" :key="i" light />
          </div>

          <div v-else-if="!loading && !itemsAll.length" class="slots-section_empty flex justify-center items-center flex-col py-10">
            <Logo name="logo" class="w-[72px] h-[72px] mb-5" />

            <p>{{ t('empty.nothing') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.slots--search {
  width: 100%;

  &-input {
    z-index: 1000;
  }

  @media screen and (min-width: 769px) {
    input {
      height: 32px;
      border-color: transparent;
      padding-left: 36px;
      padding-right: 36px;
    }

    .icon {
      width: 32px;
      height: 32px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .input_btn {
      width: 32px;
      min-width: 32px;
      height: 32px;
    }
  }
}

.dd-search {
  margin-top: 20px;

  @include _768 {
    margin-top: 8px;
  }

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--gray);
  border-radius: $radius6;
  z-index: 1000;
  padding: 20px 6px;
  box-shadow: 0px 4px 4px rgba(12, 14, 16, 0.3);

  &_wrap {
    overflow-y: auto;
    min-height: 190px;
    max-height: 380px;
    padding: 0 10px;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;

    @include _760 {
      gap: 8px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .label {
      grid-column: 1 / -1;
      color: var(--white);
      font-size: 16px;
      padding-bottom: 8px;
      font-weight: 700;
    }
  }
}
</style>
