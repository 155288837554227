export const route = [{
  path: 'cashback',
  name: 'cashback',
  props: true,
  component: () => import('./views/Cashback.vue'),
  meta: {
    auth: true,
  },
},
{
  path: 'rakeback',
  name: 'rakeback',
  props: true,
  component: () => import('./views/Rakeback.vue'),
  children: [
    {
      path: 'my',
      name: 'rakeback-my',
      component: () => import('./views/RakebackMy.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'about',
      name: 'rakeback-about',
      component: () => import('./views/RakebackAbout.vue'),
    },

  ],
},
{
  path: 'club',
  name: 'club',
  props: true,
  component: () => import('./views/Club.vue'),
},
]
