import type { ToastInterface } from 'vue-toastification'
import { useToast } from 'vue-toastification'
import { Toast } from '@components/Interface'
import { useBus } from '@composable/useBus'
import { t } from '@/plugins/i18n'

export const useError: ToastInterface['error'] = (message: string, options?) => {
  const toast: ToastInterface = useToast()

  return toast.error({
    component: Toast,
    props: {
      type: 'error',
      title: message,
      content: message,
    },
  }, { id: message, hideProgressBar: true, ...options })
}

export const useSuccess: ToastInterface['success'] = (message: string, options?) => {
  const toast: ToastInterface = useToast()

  return toast.success({
    component: Toast,
    props: {
      type: 'success',
      title: message,
      content: message,
    },
  }, { id: message, hideProgressBar: true, ...options })
}

export const useInfo: ToastInterface['info'] = (message: string, options?) => {
  const toast: ToastInterface = useToast()

  return toast.info({
    component: Toast,
    props: {
      type: 'info',
      title: message,
      content: message,
    },
  }, { id: message, hideProgressBar: true, ...options })
}

export const useWarning: ToastInterface['warning'] = (message: string, options?) => {
  const toast: ToastInterface = useToast()

  return toast.warning({
    component: Toast,
    props: {
      type: 'warning',
      title: message,
      content: message,
    },
  }, { id: message, hideProgressBar: true, ...options })
}

export function useUpdatePwaToast() {
  const toast: ToastInterface = useToast()

  const bus = useBus()

  return toast.info({
    component: Toast,
    props: {
      type: 'info',
      title: t('updatePwa.title'),
      content: t('updatePwa.description'),
      buttons: [
        {
          buttonName: 'update',
          text: t('updatePwa.button'),
        },
      ],
    },
    listeners: {
      button: (buttonName: string) => {
        if (buttonName === 'update')
          bus.emit('update:confirm')
      },
    },
  }, { id: 'update-pwa-toast', hideProgressBar: true, timeout: false, closeOnClick: false, draggable: false })
}
