<script setup lang="ts">
import {
  ref,
  useError,
  useKeydown,
  useLocalePath,
  useRoute,
  useRouter,
  useState,
  useUserStore,
} from '@modules/auth/auth.dependencies'
import { useAuthStore } from '@modules/auth'
import { watch } from 'vue'
import Close from '@/assets/icon/close.svg'
import { t } from '@/plugins/i18n'

interface Props {
  payload: {
    type: 'signIn' | 'reset-password'
    onContinue: () => Promise<void> | void
  }
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])
const authStore = useAuthStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const code = ref<string>('')
const error = ref<boolean>(false)
const [loading, setLoading] = useState<boolean>(false)

useKeydown('Enter', onSignIn)
watch(() => code.value, (value: string) => {
  if (value.length > 5)
    onSignIn()
})

function onClose() {
  emit('close')
}
const onClearError = () => error.value = false
async function onSignIn() {
  if (!code.value.length) {
    useError(t('notify.tfa_required'))

    return
  }

  setLoading(true)

  const service = props.payload.type === 'signIn' ? authStore.signInTfa : userStore.resetPasswordTfa

  const isSuccess = await service({ authenticatorCode: code.value })

  if (isSuccess) {
    const lastPath = localStorage.getItem('lastPath')

    if (!!lastPath && userStore.isLogged && localePath(route?.path) !== lastPath) {
      await router.push(lastPath)
      localStorage.removeItem('lastPath')
    }

    if (props.payload.onContinue)
      props.payload.onContinue()

    onClose()
  }
  else {
    error.value = true
    code.value = ''
  }

  setLoading(false)
}
</script>

<template>
  <div class="modal_content" @click.stop>
    <header class="tfa-header">
      <h3 class="tfa__title">
        {{ t("auth.tfa.title") }}
      </h3>

      <button class="btn_close" @click="onClose">
        <Close class="w-5 h-5" />
      </button>
    </header>

    <p class="tfa__subtitle">
      {{ t('auth.tfa.subtitle') }}
    </p>

    <input
      v-model="code" class="form_input" :class="{ 'invalid-tfa': error }" type="text"
      :placeholder="t('auth.tfa.placeholder')" @blur="onClearError"
    >

    <button :disabled="loading" class="form__btn btn-primary" @click="onSignIn">
      <span>{{ t("auth.signin") }}</span>
    </button>
  </div>
</template>

<style lang="scss">
.modal_content {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tfa {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    color: white;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: white
  }
}

.invalid-tfa {
  border: 1px solid var(--red) !important;
}
</style>
