import { httpClient } from './support.dependencies'
import type { ServiceResponse, SupportCloseResponseDto, SupportCreateResponseDto, SupportListRequestDto, SupportListResponseDto, SupportMessagesRequestDto, SupportSendMessageResponseDto } from './support.dto'
import type { TicketMessage } from './support.model'

export function getTicketsServise(params: SupportListRequestDto): Promise<ServiceResponse<SupportListResponseDto>> {
  return httpClient.get('support/tickets/list', {
    params,
  })
}

export function getTicketService(id: number): Promise<ServiceResponse<SupportCreateResponseDto>> {
  return httpClient.get(`support/tickets/${id}`)
}

export function createTicketService(body: FormData): Promise<ServiceResponse<SupportCreateResponseDto>> {
  return httpClient.postForm('support/tickets', body)
}

export function sendMessageService(body: FormData): Promise<ServiceResponse<SupportSendMessageResponseDto>> {
  return httpClient.postForm('support/tickets/messages/create', body)
}

export function getTicketMessagesServise(params: SupportMessagesRequestDto): Promise<ServiceResponse<TicketMessage[]>> {
  return httpClient.get('support/tickets/messages/list', { params })
}

export function closeTicketService(id: number): Promise<ServiceResponse<SupportCloseResponseDto>> {
  return httpClient.patch(`support/tickets/close/${id}`)
}
