<script lang="ts" setup>
import { ref } from 'vue'
import { useImageLoading } from '@/composable/useElementVisibleOnce'
import { useState } from '@/composable/useState'

interface Props {
  name?: string
  src: string
  small?: string
  width?: number
  height?: number
  loading?: 'lazy' | 'eager'
  isAvatar?: boolean
  transparent?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  transparent: false,
})
const emit = defineEmits(['load'])
const imageRef = ref<HTMLElement | null>(null)
const { loading: LocalLoading } = useImageLoading(imageRef)
const [notLoaded, setNotLoaded] = useState<boolean>(false)
const [loadedSmall, setLoadedSmall] = useState<boolean>(false)
const [loaded, setLoaded] = useState<boolean>(false)
const [isErrorLoadAvatar, setIsErrorLoadAvatar] = useState(false)
function onImageLoadingError() {
  setNotLoaded(true)
  if (props.isAvatar)
    setIsErrorLoadAvatar(true)
}
function withDefaultAvatar(path: string) {
  return isErrorLoadAvatar?.value ? path.replace('default-avatar', 'avatar').replace('avatar', 'default-avatar') : path
}
function onLoadImgSmall() {
  emit('load')
  setLoadedSmall(true)
}
function onLoadImg() {
  setLoaded(true)
}
</script>

<template>
  <div ref="imageRef" class="thumb_img" :class="{ with_bg: !props.transparent }">
    <template v-if="props.src && !notLoaded">
      <transition v-if="props.small" name="fadeIn">
        <img
          v-show="loadedSmall" :src="props.small" :alt="props.name" class="thumb_img__sm"
          @load="onLoadImgSmall()"
        >
      </transition>

      <transition name="fadeIn">
        <img
          v-show="loaded" :src="props.isAvatar ? withDefaultAvatar(props.src) : props.src" :alt="props.name" :loading="loading || LocalLoading" class="thumb_img__main"
          @error="onImageLoadingError" @load="onLoadImg()"
        >
      </transition>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.thumb_img {
  width: 100%;
  height: 100%;
  &.with_bg{
    background: linear-gradient(180deg, #29323A -8.33%, #1D2226 106.67%);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__sm {
    filter: blur(10px);
    position: absolute !important;
  }
}
</style>
