<script setup lang="ts">
import SecurityLock from '@/assets/icon/security-lock.svg'
import Close from '@/assets/icon/close.svg'
import Check from '@/assets/icon/check.svg'
import { t } from '@/plugins/i18n'

const emit = defineEmits(['close'])

function onClose() {
  emit('close')
}
</script>

<template>
  <div class="modal_content restore-password-successful" @click.stop>
    <header class="header-popup">
      <SecurityLock class="header-popup__svg w-[22px] h-[22px] yellow" />

      <h3 class="header-popup__title">
        {{ t("auth.password_repairing") }}
      </h3>

      <button class="btn_close" @click="onClose">
        <Close class="w-5 h-5" />
      </button>
    </header>

    <div class="modal_body">
      <div class="form">
        <div class="form__content">
          <Check class="green restore-password-successful__icon" />

          <p class="restore-password-successful__description">
            {{ t('auth.password_changed_success') }}
          </p>

          <button class="form__btn btn-primary" @click="onClose">
            <span>{{ t("close") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.restore-password-successful {
  & .form {
    padding: 0;
  }

  &__icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }

  &__description {
    color: var(--white);
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
}
</style>
