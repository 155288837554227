import { useUserStore } from '@modules/user'
import { useModalLayer } from '@composable/useModal'

export function withAuth(callback: () => void, redirectPath?: string) {
  const userStore = useUserStore()

  if (!userStore.isLogged) {
    const modalLayer = useModalLayer()

    modalLayer.show('auth', { type: 'signIn', path: redirectPath })
  }
  else {
    callback()
  }
}
