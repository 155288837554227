import type { UserModule } from '@/types'
import { useLayoutStore } from '@/store/Layout'

export const install: UserModule = ({ isClient, router }) => {
  if (isClient) {
    const layoutStore = useLayoutStore()

    router.beforeEach((to, from) => {
      if (to.path !== from.path)
        layoutStore.progressStart()
    })
    router.afterEach(() => {
      layoutStore.progressDone()
    })
  }
}
