import type { RouteRecordRaw } from 'vue-router'
import NotFound from './404.vue'
import generatedRoutes from '~pages'

const formatted = generatedRoutes.map((route) => {
  return {
    ...route,
    path: route.path.startsWith('/') ? route.path.slice(1) : route.path,
  }
})
formatted.push({
  path: ':pathMatch(.*)*',
  name: 'not-found',
  component: NotFound,
})

export default [
  {
    path: '/:locale?',
    name: 'root',
    props: true,
    children: formatted,
  },
] as RouteRecordRaw[]
