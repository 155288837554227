<script setup lang="ts">
import { useToggle, vOnClickOutside } from '@modules/auth/auth.dependencies'
import UserAvatar from '@modules/user/components/UserAvatar.vue'
import ProfileButtons from './ProfileButtons.vue'
import Arrow2 from '@/assets/icon/arrow-2.svg'
import { ProfileElements } from '@/cypress.types'

const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false)

function close() {
  setIsOpen(false)
}
</script>

<template>
  <div v-on-click-outside="close" class="relative">
    <div class="cursor-pointer rounded-normal bg-black p-2 flex gap-4 items-center " :data-cy="ProfileElements.PROFILE_MENU_DROPDOWN" @click="toggleIsOpen">
      <UserAvatar type="small" />

      <Arrow2 name="arrow-2" class="w-2 h-2 yellow transition hidden min-[359px]:block" :class="[isOpen && 'rotate-180']" />
    </div>

    <transition name="fade">
      <div v-if="isOpen" :class="$style.dropdown">
        <ProfileButtons @close="() => setIsOpen(false)" />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" module>
.dropdown {
  @apply absolute right-0 bg-[#2A3339] rounded-big w-[232px] overflow-hidden;

  top: calc(100% + 12px);
}
</style>
