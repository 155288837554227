<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

interface Props {
  prefix?: string
  name: string
}

const props = defineProps<Props>()

const icon = defineAsyncComponent(() => import((`../../assets/icon/${props.prefix ? `${props.prefix}/` : ''}${props.name}.svg`)))
</script>

<template>
  <component :is="icon" />
</template>

<style lang="scss">
</style>
