<script setup lang="ts">
import { useState } from '@composable/useState'
import ModalTitle from '@components/Interface/ModalTitle.vue'
import { computed } from 'vue'
import AdBlockIcon from '@/assets/icon/blockers/adblock.svg'
import AdblockplusIcon from '@/assets/icon/blockers/adblockplus.svg'
import UblockIcon from '@/assets/icon/blockers/ublock.svg'
import UblockoriginIcon from '@/assets/icon/blockers/ublockorigin.svg'
import { t } from '@/plugins/i18n'

interface Blocker {
  id: 'adblock' | 'adblockplus' | 'ublock' | 'ublockorigin'
  steps: string[]
  icon: any
}

interface Props {
  payload?: {
    blocker: Blocker['id']
    step?: 0 | 1
  }
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])
const [id, setId] = useState<Blocker['id']>(props.payload?.blocker || 'adblock')
const [step, setStep] = useState<0 | 1>(props.payload?.step || 0)

const blockers = computed<Blocker[]>(() => ([
  {
    id: 'adblockplus',
    steps: [
      t('blockers.adblockplus.step1'),
      t('blockers.adblockplus.step2'),
      t('blockers.adblockplus.step3'),
    ],
    icon: AdblockplusIcon,
  },
  {
    id: 'adblock',
    steps: [
      t('blockers.adblock.step1'),
      t('blockers.adblock.step2'),
    ],
    icon: AdBlockIcon,
  },
  {
    id: 'ublock',
    steps: [
      t('blockers.ublock.step1'),
      t('blockers.ublock.step2'),
      t('blockers.ublock.step3'),
    ],
    icon: UblockIcon,
  },
  {
    id: 'ublockorigin',
    steps: [
      t('blockers.ublockorigin.step1'),
      t('blockers.ublockorigin.step2'),
      t('blockers.ublockorigin.step3'),
    ],
    icon: UblockoriginIcon,
  },
]))
const currentBlocker = computed<Blocker | undefined>(() => blockers.value.find(item => item.id === id.value))

const close = () => emit('close')
</script>

<template>
  <div class="modal_content max-w-[635px] bg-white rounded-big flex flex-col items-center gap-5 p-[30px]">
    <template v-if="step === 0">
      <div class="flex flex-col gap-3 items-center">
        <AdBlockIcon class="w-[70px] h-[70px]" />

        <p class="text-18 font-700 leading-18 text-gray-3 text-center">
          {{ t('blockers.title') }}
        </p>

        <p class="text-14 leading-22 text-gray-3 whitespace-pre-line text-center spacing-[-0.14px]">
          {{ t('blockers.description') }}
        </p>
      </div>

      <button class="btn btn-primary w-full btn-size-big" @click="() => setStep(1)">
        <span class="text-14 font-700 leading-12 text-black-3">
          {{ t('blockers.turnOff') }}
        </span>
      </button>

      <p class="text-14 leading-12 text-center text-light hover:text-gray-2 transition cursor-pointer" @click="close">
        {{ t('blockers.continue') }}
      </p>
    </template>

    <template v-else>
      <ModalTitle :light="true" class="w-full" @close="close">
        <span class="text-gray-3">
          {{ t('blockers.title2') }}
        </span>
      </ModalTitle>

      <p class="text-14 leading-22 text-gray-3 whitespace-pre-line spacing-[-0.14px]">
        {{ t('blockers.description2') }}
      </p>

      <div class="flex items-center gap-3">
        <div v-for="item in blockers" :key="`${item.id}-icon`" :class="id === item.id && 'bg-yellow'" class="w-10 h-10 p-1 rounded-normal bg-white-2 flex items-center justify-center cursor-pointer hover:bg-yellow transition" @click="() => setId(item.id)">
          <component :is="item.icon" class="h-6 w-6" />
        </div>
      </div>

      <div class="flex flex-col gap-3 items-start w-full">
        <span class="text-16 leading-12 font-700 text-gray-3">
          {{ t(`blockers.${currentBlocker?.id || 'adblock'}.name`) }}
        </span>

        <ol class="pl-5">
          <li v-for="item in currentBlocker?.steps || []" :key="`step-${item}`" class="text-14 leading-22 text-gray-3 spacing-[-0.14px]">
            {{ item }}
          </li>
        </ol>
      </div>

      <p class="text-14 leading-12 text-center text-light cursor-pointer hover:text-light-2" @click="() => setStep(0)">
        {{ t('blockers.back') }}
      </p>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>
