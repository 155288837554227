import type {
  UserDtoChangeConvertCurrency,
  UserDtoChangeCurrencies,
  UserDtoDisableTfaRequest,
  UserDtoEnableTfaRequest,
  UserDtoGenerateQrResponse,
  UserDtoGetMeRequest,
  UserDtoMeRequest,
  UserDtoMeResponse,
  UserDtoResetRequest,
  UserDtoVerifyEmailRequest,
} from '@modules/user/user.dto'
import type {
  ServiceResponse,
} from '@modules/user/user.dependencies'
import { httpClient } from '@modules/user/user.dependencies'

export function userInfo(payload: UserDtoGetMeRequest): Promise<ServiceResponse<UserDtoMeResponse>> {
  return httpClient.get('users/me', payload)
}

export function putUserInfo(payload: Partial<UserDtoMeRequest>): Promise<ServiceResponse<UserDtoMeResponse>> {
  return httpClient.put('users/me', payload)
}

export function uploadUserAvatar(payload: FormData): Promise<ServiceResponse<any>> {
  return httpClient.put('users/me/avatar', payload)
}

export function resetPassword(payload: UserDtoResetRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/reset-password', payload)
}
export function resetPasswordTfa(payload: { authenticatorCode: string }): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/reset-password/tfa/google', payload)
}

export function generateTfaCode(): Promise<ServiceResponse<UserDtoGenerateQrResponse>> {
  return httpClient.get('auth/2fa/google/generate-qrcode')
}

export function addCurrencyToSettingsService(payload: UserDtoChangeCurrencies): Promise<ServiceResponse<string[]>> {
  return httpClient.post('users/me/currencies', payload)
}
export function deleteCurrencyFromSettingsService(payload: UserDtoChangeCurrencies): Promise<ServiceResponse<string[]>> {
  return httpClient.delete('users/me/currencies', { data: payload })
}

export function enableTfa(payload: UserDtoEnableTfaRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/2fa/google/enable', payload)
}

export function disableTfa(payload: UserDtoDisableTfaRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/2fa/google/disable', payload)
}

export function verifyEmailSend(): Promise<ServiceResponse<null>> {
  return httpClient.post('auth/email-verification/request')
}

export function verifyEmail(payload: UserDtoVerifyEmailRequest): Promise<ServiceResponse<UserDtoMeResponse>> {
  return httpClient.post('auth/email-verification', payload)
}

export function selectConvertCurrencyService(payload: UserDtoChangeConvertCurrency): Promise<ServiceResponse<any>> {
  return httpClient.put('users/me/convert-to-default-currency', payload)
}
