import { onBeforeUnmount, onMounted } from 'vue'
import { isSSR } from '@helpers/utils'

export function useKeydown(key: string, callback: () => void) {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === key)
      callback()
  }

  onMounted(() => {
    if (!isSSR())
      document.addEventListener('keydown', onKeyDown)
  })

  onBeforeUnmount(() => {
    if (!isSSR())
      document.removeEventListener('keydown', onKeyDown)
  })
}
