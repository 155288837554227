import { useState } from '@composable/useState'
import type { Ref } from 'vue'

type Services = 'chat' | 'vip' | 'payments' | 'bonus' | 'crash' | 'tower'

interface UseGracefulDegradation {
  offlineServices: Ref<Partial<Record<Services, Record<string, boolean>>>>
  setOfflineServices: (value: Partial<Record<Services, Record<string, boolean>>>) => void
  addServiceToOffline: (service: Services, code: string) => void
  removeServiceFromOffline: (service: Services, code: string) => void
  checkServiceForOffline: (service: Services) => boolean
}

const [offlineServices, setOfflineServices] = useState<Partial<Record<Services, Record<string, boolean>>>>({})

export function useGracefulDegradation(): UseGracefulDegradation {
  const addServiceToOffline = (service: Services, code: string) => {
    setOfflineServices({
      ...offlineServices.value,
      [service]: {
        ...offlineServices.value?.[service],
        [code]: true,
      },
    })
  }

  const removeServiceFromOffline = (service: Services, code: string) => {
    setOfflineServices({
      ...offlineServices.value,
      [service]: {
        ...offlineServices.value?.[service],
        [code]: false,
      },
    })
  }

  const checkServiceForOffline = (service: Services) => {
    return !!Object.values(offlineServices.value?.[service] || {}).find(Boolean)
  }

  return {
    removeServiceFromOffline,
    addServiceToOffline,
    offlineServices,
    setOfflineServices,
    checkServiceForOffline,
  }
}
