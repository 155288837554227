import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { HttpClient } from '@/config/env'

export interface ServiceResponse<Response> {
  success: boolean
  response: Response
}

export interface ServicePaginatedResponse<T> {
  data: Array<T>
  meta: {
    currentPage: number
    lastPage: number
    perPage: number
    total: number
  }
}

export interface ServiceValidationError {
  errors: Record<string, Array<string>>
}
export interface ServiceNotifyError {
  notify: {
    content: string
    title: string
    type: string
  }
}

export interface ServiceRedirect {
  redirect: string
}

export interface ServiceError {
  error: string
}

const instance = axios.create({
  baseURL: HttpClient.baseUrl || '/api',
  timeout: 30000,
  withCredentials: true,
})

export const httpClient: AxiosInstance = instance
