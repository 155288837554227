import { isSSR } from '@helpers/utils'

enum CurrencyIso {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
}

export function getCurrencySymbol(isoCode: string) {
  const symbols: Record<string, string> = {
    USD: '$',
    EUR: '€',
    USDT: '₮',
    RUB: '₽',
  }

  return symbols[isoCode] || ''
}

export function getCurrencyIsoById(id: string) {
  const symbols: Record<string, string> = {
    '840': 'USD',
    '978': 'EUR',
    '000001': 'USDT',
    '643': 'RUB',
  }

  return symbols[id] || ''
}

export function getIconClassName(currencyIsoCode: string) {
  const names: Record<string, string> = {
    BYN: 'c-byn',
    RUB: 'logo-rub-sm',
    USD: 'logo-usd-sm',
    EUR: 'logo-eur-sm',
    USDT: 'logo-usdt-sm',
    BCH: 'logo-bch-sm',
    BNB: 'logo-bnb-sm',
    BTC: 'logo-btc-sm',
    BUSD: 'logo-busd-sm',
    DAI: 'logo-dai-sm',
    DASH: 'logo-dash-sm',
    DOGE: 'logo-doge-sm',
    ETH: 'logo-eth-sm',
    LTC: 'logo-ltc-sm',
    MATIC: 'logo-matic-sm',
    TRX: 'logo-trx-sm',
    USDC: 'logo-usdc-sm',
    XMR: 'logo-xmr-sm',
  }

  return names[currencyIsoCode] || ''
}

export function getIconSymbolName(currencyIsoCode: string) {
  const names: Record<string, string> = {
    BYN: 'c-rub',
    RUB: 'c-rub',
    USD: 'c-usd',
    EUR: 'c-eur',
    USDT: 'c-usdt',
    BCH: 'c-bch',
    BNB: 'c-bnb',
    BTC: 'c-btc',
    BUSD: 'c-busd',
    DAI: 'c-dai',
    DASH: 'c-dash',
    DOGE: 'c-doge',
    ETH: 'c-eth',
    LTC: 'c-ltc',
    MATIC: 'c-matic',
    TRX: 'c-trx',
    USDC: 'c-usdc',
    XMR: 'c-xmr',
  }

  return names[currencyIsoCode] || ''
}

export function getBrowserCurrency() {
  if (isSSR())
    return CurrencyIso.RUB

  const rubConfig: string[] = ['ru']
  const eurConfig: string[] = ['fr', 'de', 'es', 'it', 'pt', 'nl', 'sv', 'da', 'fi', 'el', 'cs', 'hu', 'pl', 'sk', 'sl', 'lv', 'et', 'lt', 'bg', 'ro', 'hr', 'ga', 'sr',
  ]

  const browserLanguage = window.navigator.language

  const isRub = rubConfig.find(code => new RegExp(code).test(browserLanguage))
  if (isRub)
    return CurrencyIso.RUB

  const isEur = eurConfig.find(code => new RegExp(code).test(browserLanguage))
  if (isEur)
    return CurrencyIso.EUR

  // return CurrencyIso.USD
  return CurrencyIso.RUB
}
