<script lang="ts" setup>
import { computed } from 'vue'
import { useSuccess } from '@composable/useToast'

import { useCdn } from '@helpers/utils'
import { Badge } from '@components/Badge'
import { useUserStore } from '@modules/user'
import type { GameListEntity } from '@modules/casino/casino.model'
import { useMarkGameFavorite } from '@modules/casino/composables/api'
import PreloadedImage from '@components/Interface/PreloadedImage.vue'
import PicturedImage from '@components/Interface/PicturedImage.vue'
import { CentralSection } from '@/cypress.types'
import { t, useLocalePath } from '@/plugins/i18n'
import Play from '@/assets/icon/play.svg'
import Heart from '@/assets/icon/heart.svg'

interface Props {
  item: GameListEntity
  badge?: string
  link?: string
  plug?: boolean

  // TODO make it via scoped slots because it's too dirty now
  static?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  static: false,
})
const emit = defineEmits(['updateFavorite', 'update', 'unauthenticatedPlay'])
const { mutateAsync: markGameAsFavorite } = useMarkGameFavorite()
const userStore = useUserStore()
const localePath = useLocalePath()
const isFavorite = computed(() => props.item.favorite)

const linkToPlay = computed(() => localePath(props.link ? props.link : getGameLink()))
const linkToPlayDemo = computed(() => localePath(props.link ? props.link : getGameLink(true)))

const imagePath = computed(() => {
  if (!props.item.image)
    return ''
  if (props.static)
    return props.item.image

  return useCdn(String(props.item?.compressImages?.imageLargeJpeg || props.item.image || ''))
})

const badgeLabel = computed(() => {
  switch (props.badge) {
    case 'new':
      return t('games-grid.new')
    case 'popular':
      return t('games-grid.popular')
  }

  return ''
})

function getGameLink(isDemo = false) {
  // TODO to helpers or composable
  const name = props.item.name.replace(/[^a-z\d]+/gi, '-').toLocaleLowerCase()

  let link = `/all-games/${props.item.provider ?? ''}/${props.item.id}-${name}`
  if (isDemo)
    link += '?demo=true'

  return link
}

function onFavoriteClick() {
  const newIsFavorite = !isFavorite.value

  useSuccess(newIsFavorite ? t('games-grid.toasts.favorites.added') : t('games-grid.toasts.favorites.removed'))

  emit('updateFavorite', props.item.id, newIsFavorite)

  markGameAsFavorite(props.item)
}

function onUnauthenticatedPlayClick() {
  const path = props.link || getGameLink(false)

  emit('unauthenticatedPlay', path)
}
</script>

<template>
  <article class="game-card" :class="{ plug }" :data-cy="CentralSection.GAMES_BODY_DROPDOWN">
    <div class="game-card__thumb aspect-square">
      <PreloadedImage>
        <template #default="{ load }">
          <PicturedImage
            :sources="item?.compressImages?.imageLargeJpeg ? [
              {
                srcset: `${useCdn(item?.compressImages?.imageLargeJpeg || '')}`,
                media: '(min-width: 769px)',
              },
              {
                srcset: `${useCdn(item?.compressImages?.imageSmallJpeg || '')}`,
                media: '(max-width: 768px)',
              },

            ] : []"
            :src="String(imagePath || '')"
            :alt="props.item.name"
            class-name="w-full h-full"
            @load="load"
          />
        </template>

        <template #preload>
          <!--          <Blurhash v-if="item.imageBlurHash" :width="320" :height="320" :hash="item.imageBlurHash || ''" /> -->
        </template>
      </PreloadedImage>

      <div v-if="!plug" class="game-card__overlay">
        <RouterLink
          v-if="userStore.isLogged || props.link" :to="linkToPlay"
          :aria-label="t('play')"
          class="game-card__play-btn btn btn-primary uppercase"
        >
          <Play name="play" class="w-8 h-8" />

          <span>{{ t('play') }}</span>
        </RouterLink>

        <button
          v-else class="game-card__play-btn btn btn-primary uppercase"
          :aria-label="t('play')"
          @click="onUnauthenticatedPlayClick"
        >
          <Play name="play" class="w-8 h-8" />

          <span>{{ t('play') }}</span>
        </button>

        <!--    Hide demo play for fanta    -->
        <RouterLink v-if="!static && false" :to="linkToPlayDemo" class="game-card__demo-btn btn btn-simple p-0">
          <span class="whitespace-nowrap">{{ t('play_demo') }}</span>
        </RouterLink>

        <div v-if="props.item.rtp" class="game-card__rtp">
          RTP: {{ props.item.rtp }}%
        </div>
      </div>
    </div>

    <button
      v-if="userStore.isLogged && !props.static" class="game-card__favorite-btn btn-link-simple"
      :aria-label="t('favorite')"
      @click="onFavoriteClick"
    >
      <Heart
        name="heart"
        class="game-card__favorite-icon w-6 h-6 white"
        :class="{
          'game-card__favorite-icon--fav': isFavorite,
        }"
      />
    </button>

    <Badge v-if="props.badge" class="game-card__badge uppercase" :color="props.badge">
      {{ badgeLabel }}
    </Badge>

    <div class="game-card__info">
      <h2 class="game-card__title" :data-cy="CentralSection.GAMES_TITLE_DROPDOWN">
        {{ props.item.name }}
      </h2>

      <RouterLink
        v-if="props.item.provider && props.item.provider !== 'bandana'"
        :to="localePath(`/all-games/${props.item.provider}`)"
        class="game-card__provider capitalize"
      >
        {{ props.item.provider }}
      </RouterLink>
    </div>
  </article>
</template>

<style lang="scss">
.game-card{
  img{
    transition: .2s;
  }
  &:not(.plug) {
    .game-card__thumb:hover {
      border-radius: 14px;

      img {
        transform: scale(1.1);
        filter: blur(3px);
        border-radius: 40px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.placeholder {
  &__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }

  &__thumb {
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: none !important;
  }
}

.game-card {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  &_block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: var(--black-2);
      opacity: 0.8;
    }
    &__wrap {
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      color: var(--white);
      font-weight: 700;
      @apply flex justify-center items-center;
      flex-wrap: wrap;
    }
    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @apply flex justify-center items-center;
      align-content: center;
      flex-wrap: wrap;
      svg {
        margin-bottom: 16px;
        opacity: .7;
      }
    }
  }

  &__thumb {
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    border-radius: 14px;
    overflow: hidden;
    display: flex;

    // This line fixes the border artifacts (browser-level bug)
    -webkit-mask-image: radial-gradient(white, black);
    mask-image: radial-gradient(white, black);

    img {
      // background: var(--gray-4);
      // FIX black image on chrome when sidebar close on mobile
      //will-change: transform;
      transform-origin: center center;
      transition: 0.3s;
    }
    &_img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__placeholder {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
  }

  &__overlay {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s;
    background-color: rgb(12, 14, 16, .7);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .game-card__thumb:hover & {
      opacity: 100%;
      pointer-events: all;
    }

    .btn {
      width: 100px;
      font-size: 16px;
      position: relative;
      z-index: 1;
      border-radius: 6px;

      @include _480 {
        height: auto;
        width: auto;
        font-size: 13px;
      }
    }
  }

  &__info {
    text-align: center;
    padding-bottom: 10px;
  }

  &__title {
    color: var(--white);
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__provider {
    color: var(--gray-2);
    font-size: 14px;
  }

  &__play-btn {
    margin-bottom: 6px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);

    svg {
      display: none;
    }

    &::before {
      opacity: 0 !important;
    }

    @include _480 {
      border-radius: 40px !important;
      padding: 8px;
      margin-bottom: 12px;

      span {
        display: none;
      }

      svg {
        display: block;
      }
    }
  }

  &__demo-btn {
    height: 30px;

    @include _760 {
      padding-bottom: 10px;
    }

    @include _480 {
      padding: 0;
    }
  }

  &__rtp {
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    color: var(--light-2);

    @include _480 {
      padding: 8px;
    }
  }

  &__favorite-btn {
    position: absolute;
    z-index: 900;
    padding: 12px;
    top: 0;
    right: 0;
    opacity: 0.9;
    filter: drop-shadow(1px 3px 8px black);
  }

  &__favorite-icon {
    transition: transform 0.3s;
    // FIX black image on chrome when sidebar close on mobile
    //will-change: transform;

    &:hover {
      transform: scale(1.2);
    }

    &--fav {
      color: var(--red-4);
    }

    @include _480 {
      border-radius: 0;
    }
  }

  &__badge {
    pointer-events: none;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 999;
  }
}
</style>
