import { httpClient } from '@modules/games/airblast/airblast.dependencies'
import type { ServiceResponse } from '@modules/games/airblast/airblast.dependencies'
import type { AirblastDtoBetRequest, AirblastDtoBetResponse, AirblastDtoChangeClientSeedRequest, AirblastDtoChangeSeedResponse, AirblastDtoGameInitResponse, AirblastDtoHistory, AirblastDtoRoundHistoryResponse, AirblastDtoRoundInfoResponse } from '@modules/games/airblast/airblast.dto'
import type { ServicePaginatedResponse } from '@/api'

export function betService(body: AirblastDtoBetRequest): Promise<ServiceResponse<AirblastDtoBetResponse>> {
  return httpClient.post('crash/bet', body)
}

export function cashOutService(betId: string): Promise<ServiceResponse<{ id: string; amount: string }>> {
  return httpClient.post(`crash/cash-out/${betId}`)
}

export function initGameService(): Promise<ServiceResponse<AirblastDtoGameInitResponse>> {
  return httpClient.get('crash/init')
}

export function historyTopService(): Promise<ServiceResponse<AirblastDtoHistory[]>> {
  return httpClient.get('crash/bets/history/top')
}

export function historyMyService(config: { params: { currentPage: number } }): Promise<ServiceResponse<ServicePaginatedResponse<AirblastDtoHistory>>> {
  return httpClient.get('crash/bets/history/my', config)
}

export function roundInfoService(config: { gameId: string }): Promise<ServiceResponse<AirblastDtoRoundInfoResponse>> {
  return httpClient.get(`crash/games/history/${config.gameId}`)
}

export function roundHistoryService(config: { params: { currentPage: number; perPage: number } }): Promise<ServiceResponse<ServicePaginatedResponse<AirblastDtoRoundHistoryResponse>>> {
  return httpClient.get('crash/games/history', config)
}

export function changeClientSeed(data: AirblastDtoChangeClientSeedRequest): Promise<ServiceResponse<AirblastDtoChangeSeedResponse>> {
  return httpClient.post('fair/change-client-seed', data)
}
export function changeServerSeedService(): Promise<ServiceResponse<AirblastDtoChangeSeedResponse>> {
  return httpClient.post('fair/change-server-seed')
}
