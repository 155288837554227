<script setup lang="ts">
import type { AuthSocial } from '@modules/auth/auth.model'
import { getAuthSocialType } from '@modules/auth/auth.helpers'
import {
  Select,
  getIconClassName,
  useCurrencies,
  useError,
  useRoute,
  useRouter,
  useState,
  useUserStore,
} from '@modules/auth/auth.dependencies'
import { useAuthStore } from '@modules/auth'
import { getReferral } from '@helpers/utils'
import { locale, t } from '@/plugins/i18n'
import { useVisitorDataPlugin } from '@/plugins/fingerprint'

interface Props {
  payload: {
    social: AuthSocial
  }
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])
const currenciesQuery = useCurrencies()
const [currencyId, setCurrencyId] = useState<string>('')
const { getData } = useVisitorDataPlugin()

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const authStore = useAuthStore()

const close = () => emit('close')

async function onContinue() {
  if (!currencyId.value && !userStore.isLocalStorageHasName && getAuthSocialType() !== 'signIn') {
    useError(t('auth.select_currency_placeholder'))

    return
  }

  const code = props.payload.social !== 'steam' ? (route.query.code ? String(route.query.code) : '') : (window.location.search.replace('?', '') || '')
  const walletCurrencyId = currencyId.value

  const authSocialType = getAuthSocialType() || 'signUp'

  const fingerprint = await getData()
  const referralCode = getReferral()

  const payload = { code, walletCurrencyId, fingerprint, referralCode: referralCode?.code }

  if (userStore.isLocalStorageHasName)
    await authStore.linkSocialSend(payload, props.payload.social)
  else
    await authStore.authSocialSend(payload, authSocialType, props.payload.social)

  await router.push(`/${locale.value === 'en' ? 'en' : ''}`)

  close()
}

async function setup() {
  if (userStore.isLocalStorageHasName || getAuthSocialType() === 'signIn')
    await onContinue()
}

setup()
</script>

<template>
  <div class="modal_content" :class="[$style.root, userStore.isLocalStorageHasName && 'filled']">
    <template v-if="!userStore.isLocalStorageHasName && getAuthSocialType() !== 'signIn'">
      <p :class="$style.title">
        {{ t('auth.registration_final.title') }}
      </p>

      <p :class="$style.subtitle">
        {{ t('auth.registration_final.description') }}
      </p>

      <Select
        :placeholder="t('auth.select_currency_placeholder')"
        :current-id="currencyId"
        :items="(currenciesQuery?.balanceEnabled?.value || []).map(item => ({ ...item, name: item.name, icon: getIconClassName(item.name) }))"
        is-currencies
        @change="setCurrencyId"
      />

      <button class="btn btn-primary btn-size-big" @click="onContinue">
        <span>{{ t('button.accept') }}</span>
      </button>
    </template>

    <template v-else>
      <p :class="[$style.subtitle, $style.center]">
        {{ t('auth.registration_final.please_wait') }}
      </p>
    </template>
  </div>
</template>

<style module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: visible;
}

.center {
  display: flex;
  justify-content: center;
}

.title {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
}

.subtitle {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
}
</style>
