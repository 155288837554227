<script setup lang="ts">
import {
  AnimatedNumber,
  Icon,
  computed,
  getIconClassName,
  useModalLayer,
  // useOpenModals,
  useState,
  useToggle,
  useUserStore,
  vOnClickOutside,
} from '@modules/wallet/wallet.dependencies'
import { useCurrencies, useExchanger, useWallets } from '@modules/wallet'
import type { Currency, Wallet } from '@modules/wallet/wallet.model'
import CurrencyComponent from '@modules/wallet/component/Header/Currency.vue'
import SelectedCurrency from '@modules/wallet/component/Header/SelectedCurrency.vue'
import { useCreateWallet } from '@modules/wallet/composable/useCreateWallet'
import { useSelectWallet } from '@modules/wallet/composable/useSelectWallet'
import { useRoute } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { checkGameInfo } from '@modules/games/tower'
import MethodIcon from '@modules/wallet/component/MethodIcon.vue'
import { HeaderElement } from '@/cypress.types'
import { t } from '@/plugins/i18n'

interface Props {
  hideBalance?: boolean
}

defineProps<Props>()
const Exchanger = useExchanger()
// const openModals = useOpenModals()
const selectWalletMutation = useSelectWallet()
const createWalletMutation = useCreateWallet()
const userStore = useUserStore()
const route = useRoute()
const { fiat, crypto } = useCurrencies()
const wallets = useWallets()
const [loading, setLoading] = useState<boolean>(false)
const [hideNull] = useState<boolean>(false)
const [show, toggleShow, setShow] = useToggle(false)
const modalLayer = useModalLayer()
const queryClient = useQueryClient()

// function onOpenAddCurrency() {
//   modalLayer.show('wallet_add_currency')
// }
function withoutNullable(list: Currency[]) {
  return hideNull.value
    ? list.filter(currency =>
      wallets.data.value.find(
        wallet => wallet.currencyId === currency.id && Number(wallet.amount) > 0,
      ),
    )
    : list
}
function withoutDefault(list: Currency[]) {
  return list.filter(
    currency => currency.name !== wallets.selectedRealWallet.value?.currency?.name,
  )
}
function withoutBalanceDisabled(list: Currency[]) {
  return list.filter(currency => currency.isBalanceEnabled)
}
async function onSelectWallet(currencyId: string) {
  if (loading.value)
    return

  const tempGameInfo = checkGameInfo(queryClient)
  const gameInfo = Array.isArray(tempGameInfo) ? undefined : tempGameInfo

  if (route.meta?.casinoGame) {
    modalLayer.show('wallet.change_currency_slot_game', {
      onContinue: () => selectWallet(currencyId).then(null),
    })
  }
  else if (
    route.meta?.game
    && route.meta?.inHouseTowerGame
    && gameInfo
    && gameInfo?.currencyId !== currencyId
  ) {
    const getCurrencyString = (cId: string) =>
      `${Exchanger.getCurrencyIsoById(cId)} (${Exchanger.getCurrencySymbolById(cId)})`

    modalLayer.show('wallet.change_currency_tower_game', {
      onContinue: () => selectWallet(currencyId).then(null),
      current: getCurrencyString(
        gameInfo?.currencyId || wallets.selectedWalletCurrency.value,
      ),
      next: getCurrencyString(currencyId),
    })
  }
  else {
    selectWallet(currencyId).then(null)
  }
}
async function selectWallet(currencyId: string) {
  setLoading(true)

  const wallet = wallets.data.value?.find(
    wallet => wallet.currencyId === currencyId && wallet.typeName === 'REAL',
  )

  if (!wallet) {
    await createWalletMutation.mutateAsync({ currencyId })

    await selectWalletMutation.mutateAsync({ currencyId })
  }
  else {
    await selectWalletMutation.mutateAsync({ currencyId })
  }

  setLoading(false)
  setShow(false)
}
function getWalletAmountById(currencyId: string, type: Wallet['typeName']) {
  const wallet = wallets.data.value?.find(
    wallet => wallet.currencyId === currencyId && wallet.typeName === type,
  )

  return wallet ? Number(wallet.amount) / 100 : 0
}
function onClickOutside() {
  const currentModal = localStorage.getItem('modal')
  if (currentModal
    && ![
      'wallet_add_currency',
      'wallet_view_currency',
      'wallet.change_currency_tower_game',
    ].includes(currentModal)
  )
    setShow(false)
}
async function onChange(event: Event & { target: any }) {
  event.preventDefault()
  event.stopPropagation()

  if (userStore.user?.settings?.convertToDefaultCurrency)
    await userStore.changeConvertCurrency({ currencyId: null })
  else modalLayer.show('wallet_view_currency')

  event.target.checked = false
}

const currencies = computed<Currency[]>(() =>
  withoutBalanceDisabled(withoutNullable(withoutDefault(fiat.value))),
)
const cryptoCurrencies = computed<Currency[]>(() =>
  withoutBalanceDisabled(withoutNullable(withoutDefault(crypto.value))),
)
</script>

<template>
  <div
    v-on-click-outside="onClickOutside"
    class="wallet-currency-selector"
    :data-cy="HeaderElement.AMOUNT_SELECTOR"
  >
    <div class="wallet-currency-selector-header" @click="toggleShow">
      <div class="w-6 h-6">
        <MethodIcon
          :key="wallets.selectedRealWallet.value?.currency?.name?.toLocaleLowerCase()"
          :name="`logo-${wallets.selectedRealWallet.value?.currency?.name.toLocaleLowerCase() || 'rub'}-sm`"
          class="wallet-currency-selector-header__currency-icon"
        />
      </div>

      <AnimatedNumber
        v-if="!hideBalance"
        :to="
          Exchanger.exchangeToViewCurrency(
            wallets.summaryBalance.value / 100,
            wallets.selectedRealWallet.value?.currencyId || '643',
          )
        "
        :class="getIconClassName(Exchanger.getViewOrUserCurrencyIso())"
      />

      <div v-else class="flex items-center gap-1">
        <span class="text-20 leading-14 text-white">
          {{ " ***.**" }}
        </span>

        <span :class="getIconClassName(Exchanger.getViewOrUserCurrencyIso())" />
      </div>

      <Icon
        name="arrow-2"
        class="yellow w-2 h-2 ml-auto transition-all"
        :class="[show && 'rotate-180']"
      />
    </div>

    <transition name="fade">
      <div v-if="show" class="wallet-currency-selector-body">
        <div class="wallet-currency-selector-currencies">
          <div class="wallet-currency-selector-currencies-wrapper">
            <SelectedCurrency />

            <template v-if="currencies.length">
              <CurrencyComponent
                v-for="item of currencies"
                :key="item.id"
                :currency="item"
                :amount="getWalletAmountById(item.id, 'REAL')"
                :bonus-amount="getWalletAmountById(item.id, 'BONUS')"
                @click="() => onSelectWallet(item.id)"
              />
            </template>

            <template v-if="cryptoCurrencies.length">
              <CurrencyComponent
                v-for="item of cryptoCurrencies"
                :key="item.id"
                :currency="item"
                :amount="getWalletAmountById(item.id, 'REAL')"
                :bonus-amount="getWalletAmountById(item.id, 'BONUS')"
                @click="() => onSelectWallet(item.id)"
              />
            </template>
          </div>
        </div>

        <div v-if="false" class="wallet-currency-selector-options">
          <div class="wallet-currency-selector-options__item">
            {{ t("wallet.dropdown.view_currency") }}

            <input
              id="wallet-view-currency"
              :checked="!!userStore.user?.settings?.convertToDefaultCurrency"
              class="switcher dark"
              type="checkbox"
              @change="onChange"
            >

            <label for="wallet-view-currency" />
          </div>

          <div
            v-if="fiat.length + crypto.length > 1"
            class="wallet-currency-selector-options__item"
          >
            {{ t("wallet.dropdown.hide_nullable") }}

            <input
              id="wallet-hide"
              v-model="hideNull"
              class="switcher dark"
              type="checkbox"
            >

            <label for="wallet-hide" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.wallet-currency-selector {
  position: relative;

  &-currencies {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    background-color: var(--dark);
    border-radius: $radiusXl;
    overflow: hidden;
    max-height: min(400px, calc(100vh - 180px));

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow-y: auto;
      margin-right: -5px;
      padding-right: 5px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;

      color: var(--gray-2);
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;

      &__icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &-options {
    display: flex;
    gap: 4px;
    flex-direction: column;
    border-top: 1px solid #181c1f;
    background: var(--gray-5);
    padding: 8px 20px;
    border-radius: 0 0 $radiusXl $radiusXl;

    &__item {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--white);
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }
  }

  &-body {
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    width: 100%;
    min-width: 321.5px;
    height: auto;
    max-height: calc(100% - 68px);
    border-radius: $radiusXl;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);

    @include _767 {
      position: fixed;
      top: 60px;
      max-width: 350px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-header {
    cursor: pointer;
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--black);
    border-radius: $radius;
    min-width: 170px;

    color: var(--white);
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;

    &__currency-icon {
      color: var(--gray-2);
      width: 24px;
      height: 24px;
    }

    @include _767 {
      min-width: 160px;
    }
  }
}
</style>
