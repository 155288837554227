import { createWalletService } from '@modules/wallet/wallet.service'
import type { WalletDtoCreate } from '@modules/wallet/wallet.dto'
import { WalletCacheKey } from '@modules/wallet/wallet.types'
import { useMutation, useQueryClient } from '../wallet.dependencies'

interface UseCreateWalletArguments {
  updateWallets?: boolean
}

export function useCreateWallet(args?: UseCreateWalletArguments) {
  const queryClient = useQueryClient()

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (payload: WalletDtoCreate) => createWalletService(payload),
    onSuccess: async () => {
      if (args?.updateWallets) {
        await queryClient.invalidateQueries({
          queryKey: [WalletCacheKey.value],
        })
      }
    },
  })

  return { mutateAsync, isLoading }
}
