import type { Ref } from '@modules/chat/chat.dependencies'
import { defineStore, useState } from '@modules/chat/chat.dependencies'
import type { Message, Room } from '@modules/chat/chat.model'
import {
  banMessage,
  deleteMessageService,
  deleteMessagesService,
  getChats,
  getMessagesService,
  sendMessageService,
} from '@modules/chat/chat.service'
import type { ChatDtoSendMessageRequest } from '@modules/chat/chat.dto'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import { locale } from '@/plugins/i18n'

interface IChatStore {
  rooms: Ref<Room[]>
  current: Ref<Room | null>
  loading: Ref<boolean>

  // actions
  blockMessage: (userId: string, messageId: string, reason: string, duration: number) => Promise<boolean>
  updateRooms: () => Promise<void>
  getMessages: (chatId: string, direction: 'UP' | 'DOWN', cursorTime?: string) => Promise<Message[]>
  sendMessage: (message: string, parentId?: string, attachments?: ChatDtoSendMessageRequest['attachments']) => Promise<boolean | undefined>
  deleteMessage: (chatId: string, messageId: string) => Promise<boolean | undefined>
  deleteMessages: (chatId: string, userId: string) => Promise<boolean | undefined>

  // mutations
  setCurrent: (payload: Room | null) => void
}

export const useChatStore = defineStore<string, IChatStore>('chatStore', () => {
  const [rooms, setRooms] = useState<Room[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [current, setCurrent] = useState<Room | null>(null)
  const gracefulDegradation = useGracefulDegradation()
  // actions
  async function updateRooms(): Promise<void> {
    try {
      setLoading(true)

      const serviceResponse = await getChats()

      if (!serviceResponse.success) {
        gracefulDegradation.addServiceToOffline('chat', 'roomsUpdate')
        return
      }
      else {
        gracefulDegradation.removeServiceFromOffline('chat', 'roomsUpdate')
      }

      const list = [...serviceResponse.response]
      list.sort(a => a?.languageIsoCode?.toLowerCase() === locale.value?.toLowerCase() ? -1 : 1)

      setRooms(list)

      setCurrent(list.at(0) || null)
    }
    catch {
      gracefulDegradation.addServiceToOffline('chat', 'roomsUpdate')
    }
    finally {
      setLoading(false)
    }
  }

  async function getMessages(chatId: string, direction: 'UP' | 'DOWN', cursorTime?: string): Promise<Message[]> {
    try {
      setLoading(true)

      const serviceResponse = await getMessagesService(chatId, direction, cursorTime)

      if (serviceResponse.success)
        gracefulDegradation.removeServiceFromOffline('chat', 'getMessages')
      else
        gracefulDegradation.addServiceToOffline('chat', 'getMessages')

      return serviceResponse.response || []
    }
    catch {
      gracefulDegradation.addServiceToOffline('chat', 'getMessages')

      return []
    }
    finally {
      setLoading(false)
    }
  }

  async function sendMessage(text: string, parentId?: string, attachments?: ChatDtoSendMessageRequest['attachments']): Promise<boolean | undefined> {
    try {
      setLoading(true)

      if (!current.value?.id)
        await updateRooms()

      const result = await sendMessageService(current.value?.id || '', {
        text,
        parentId,
        attachments,
      })

      return result.success
    }
    finally {
      setLoading(false)
    }
  }

  async function deleteMessage(chatId: string, messageId: string) {
    try {
      setLoading(true)

      const serviceResponse = await deleteMessageService(chatId, messageId)

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }

  async function deleteMessages(chatId: string, userId: string) {
    try {
      setLoading(true)

      const serviceResponse = await deleteMessagesService(chatId, { userId })

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }

  async function blockMessage(userId: string, messageId: string, reason: string, duration: number) {
    try {
      setLoading(true)

      const serviceResponse = await banMessage('CHAT.SEND_MESSAGE', userId, messageId, reason, duration)

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }

  return { blockMessage, rooms, updateRooms, loading, current, setCurrent, sendMessage, deleteMessage, deleteMessages, getMessages }
})
