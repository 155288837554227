export const HttpClient: { baseUrl?: string } = {
  baseUrl: import.meta.env.VITE_DEV_BASE_URL || '',
}

export const FingerprintKeys = {
  apiKey: import.meta.env.VITE_FINGERPRINT_PUBLIC_API_KEY || '',
  endpoint: import.meta.env.VITE_FINGERPRINT_ENDPOINT || '',
}

export const CdnConfig: { url: string } = {
  url: import.meta.env.VITE_CDN_URL || '',
}

export const Centrifugo: { ws: string } = {
  ws: import.meta.env.VITE_DEV_CENTRIFUGO_URL || (typeof window !== 'undefined' ? `wss://${window.location.host}/cfg` : 'wss://'),
}

export const Socials: { vk: string, tg: string } = {
  vk: import.meta.env.VITE_SOCIAL_VK_GROUP_URL || '',
  tg: import.meta.env.VITE_SOCIAL_TG_CHANNEL_URL || '',
}

export const isBeta: boolean = (import.meta.env.VITE_BETA_TEST === 'true') || false

export const isProduction = import.meta.env.VITE_NODE_ENV === 'production'
