<script setup lang="ts">
import { watch } from 'vue'
import { useKeydown } from '@composable/useKeydown'

interface Props {
  open?: boolean
  closable?: boolean
}

const props = withDefaults(defineProps<Props>(), { closable: true })
const emit = defineEmits(['close'])
const onClose = () => props.closable && emit('close')

useKeydown('Escape', onClose)

watch(() => props.open, (value: boolean) => {
  if (value)
    window.document.body.classList.add('overflow-hidden')
  else
    window.document.body.classList.remove('overflow-hidden')
})
</script>

<template>
  <Teleport to="body">
    <div class="modal" :class="{ open }">
      <div class="modal__bg" @click="onClose" />

      <Transition name="slideModal">
        <slot />
      </Transition>
    </div>
  </Teleport>
</template>

<style lang="scss">
.modal-body {

}

// Animation show modal
.slideModal-enter-active,
.slideModal-leave-active {
  transition: 200ms ease-out;
  transform: unset;
  z-index: 220 !important;
}
.slideModal-enter,
.slideModal-leave-to {
  transform: translateY(3rem) scale(0.95);
  z-index: 220 !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  padding: 12px;
  visibility: hidden;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;

  &_content {
    position: relative;
    z-index: 220;
    max-width: 100%;
    border-radius: $radius;
    background: var(--gray-5);
    margin: auto;
    width: 100%;
    // max-height: 90vh;
    overflow: hidden;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-4);
    color: var(--white);
  }
  .btn_close {
    margin-left: auto;
    color: var(--gray-2);
    &:hover {
      color: var(--yellow);
    }
  }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    background: rgba(16, 19, 21, 0.9);
  }
  &.open {
    opacity: 1;
    visibility: visible;
  }
}
</style>
