<script setup lang="ts">
import {
  AnimatedNumber,
  getIconSymbolName,
} from '@modules/wallet/wallet.dependencies'
import { useExchanger, useWallets } from '@modules/wallet'
import MethodIcon from '@modules/wallet/component/MethodIcon.vue'
import { FinanceSelectorElements } from '@/cypress.types'

import { t } from '@/plugins/i18n'

const Exchanger = useExchanger()

const wallets = useWallets()
</script>

<template>
  <div class="b-wallet-selected-currency">
    <div class="b-wallet-selected-currency-item">
      <MethodIcon
        :name="`logo-${wallets.selectedRealWallet.value?.currency?.name.toLocaleLowerCase()}-sm`"
        class="b-wallet-selected-currency__icon"
      />

      <div class="b-wallet-selected-currency-text">
        <span
          :data-cy="
            FinanceSelectorElements.FINANCE_CURRENT_FIAT_CURRENCY
              + wallets.selectedRealWallet.value?.currency?.name
          "
        >{{ wallets.selectedRealWallet.value?.currency?.name }}</span>

        <AnimatedNumber
          :class="getIconSymbolName(Exchanger.getViewOrUserCurrencyIso())"
          :to="
            Exchanger.exchangeToViewCurrency(
              Number(wallets.selectedRealWallet.value?.amount || 0) / 100,
              wallets.selectedRealWallet.value?.currencyId || '643',
            )
          "
        />
      </div>
    </div>

    <div class="b-wallet-selected-currency-item">
      <div class="b-wallet-selected-currency-text gray-2">
        <span :data-cy="FinanceSelectorElements.FINANCE_CURRENT_BONUS_CURRENCY">{{
          t("wallet.dropdown.bonus_wallet")
        }}</span>

        <AnimatedNumber
          :class="getIconSymbolName(Exchanger.getViewOrUserCurrencyIso())"
          :to="
            Exchanger.exchangeToViewCurrency(
              Number(wallets.selectedBonusWallet.value?.amount || 0) / 100,
              wallets.selectedBonusWallet.value?.currencyId || '643',
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.b-wallet-selected-currency {
  font-size: 14px;
  line-height: 14px;
  color: var(--white);
  font-weight: 700;
  margin-bottom: 8px;

  &__icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    color: var(--gray-2);
  }

  &-item {
    padding: 8px 12px;
    display: flex;
    gap: 8px;
    background: var(--gray-5);
    height: 40px;

    &:first-child {
      border-radius: $radius $radius 0 0;
    }
    &:last-child {
      border-top: 1.5px solid var(--dark);
      border-radius: 0 0 $radius $radius;
    }
  }

  &-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;

    &.gray-2 {
      color: var(--gray-2);
    }
  }
}
</style>
