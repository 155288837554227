<script setup lang="ts">
import { getFormatBalance } from '@helpers/utils'
import type { GiveawayToat } from '@/modules/bonus/bonus.model'
import { useModalLayer } from '@/composable/useModal'
import { getCurrencyIsoById, getCurrencySymbol } from '@/helpers/currency'
import Money from '@/assets/icon/money.svg'
import Close from '@/assets/icon/close.svg'
import { t } from '@/plugins/i18n'

interface Props {
  giveaway: GiveawayToat
}

defineProps<Props>()
const emit = defineEmits(['closeToast', 'button'])
const modalLayer = useModalLayer()

const onClose = () => emit('closeToast')
</script>

<template>
  <div :class="[$style.root, $style.warning]">
    <div :class="$style.border" />

    <Money />

    <div :class="$style.wrap">
      <span :class="$style.title">
        {{ t('giveaway.notify_title') }}
      </span>

      <p :class="$style.contentWrapped">
        {{ t('giveaway.notify_desc', {
          amount: `${getFormatBalance(Number(giveaway?.amount))} ${getCurrencySymbol(getCurrencyIsoById(giveaway.currencyId || '643'))}`,
        }) }}
      </p>

      <button
        class="btn btn-dark4" :class="$style.button"
        @click="() => modalLayer.show('bonus_giveaway', { code: giveaway.code })"
      >
        <span>
          {{ t('giveaway.go') }}
        </span>
      </button>
    </div>

    <Close :class="$style.closeIcon" @click="onClose" />
  </div>
</template>

<style module lang="scss">
.button {
  @apply font-700 p-1;
  padding: 1px !important;
  height: 32px !important;
  border-radius: 4px !important;
}

.root {
  @apply overflow-hidden p-3 pl-4 rounded-[6px] flex gap-2 relative;
  @apply bg-[#272F36] min-w-[270px];
}

.title {
  @apply text-14 font-700 uppercase;
  line-height: 14px;
}

.content {
  @apply text-12 font-400 text-white whitespace-pre-line;
  line-height: 12px;
}

.contentWrapped {
  @apply text-12 font-400 text-white md:max-w-[230px];
  line-height: 1.5;
}

.border {
  @apply absolute left-0 top-0 bottom-0 w-1;
}

.wrap {
  @apply w-full flex flex-col gap-2;
}

.closeIcon {
  @apply text-[#576774] hover:text-[#F6E444] cursor-pointer;
}
.warning {
  @apply text-[#F6E444];

  & .border {
    @apply bg-[#F6E444];
  }
}
</style>
