import { ViteSSG } from 'vite-ssg'
import App from './App.vue'
import '@/assets/scss/main.scss'

import routes from '@/routes'
import { installByType } from '@/helpers/main'
import { install as installApi } from '@/api'

export const createApp = ViteSSG(
  App,
  {
    routes,
    base: import.meta.env.BASE_URL,
    scrollBehavior(to, _from, savedPosition) {
      if (savedPosition)
        return savedPosition
      if (to.hash)
        return { el: to.hash, behavior: 'smooth' }
      else
        return { top: 0 }
    },
  },
  (ctx) => {
    installByType('plugins', ctx)
    installByType('modules', ctx)
    installApi(ctx)

    ctx.app.config.performance = true
  },
)
