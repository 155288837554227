<script setup lang="ts">
import { useState } from '@composable/useState'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import type { UseSwipeDirection } from '@vueuse/core'
import { useSwipe, useWindowSize } from '@vueuse/core'
import Blurhash from '@components/Interface/Blurhash.vue'
import PicturedImage from '@components/Interface/PicturedImage.vue'
import PreloadedImage from '@components/Interface/PreloadedImage.vue'
import { getWebp } from '@helpers/webp'
import Arrow from '@/assets/icon/arrow.svg'
import { CentralSection } from '@/cypress.types'
import { t, useLocalePath } from '@/plugins/i18n'

interface ISlide {
  url: string
  id: number
  img: string
  blurhash: string
  title: string
  description: string
}

let interval: any
const localePath = useLocalePath()
const windowSize = useWindowSize()
const router = useRouter()
const bannerRef = ref<HTMLElement | null>(null)
const [index, setIndex] = useState<number>(0)
const [height, setHeight] = useState<number>(0)
const [fontSize, setFontSize] = useState<number>(40)
const [resizeObserver, setResizeObserver] = useState<any>(null)

const slides = computed<ISlide[]>(() => [
  {
    url: '/bonus',
    id: 5,
    img: '/img/mainslider/first',
    blurhash: 'UQEU$b0TM{~3sDOEf4WCE4xsr=NLxuV@R-oy',
    title: t('banner.bonus.title'),
    description: t('banner.bonus.description'),
  },
  {
    url: '/all-games?type=slots',
    id: 25,
    img: '/img/mainslider/second',
    blurhash: 'UMD9iO00I;~XICxnoLNGIYxVn~NLx[M|bEs;',
    title: t('banner.games.title'),
    description: t('banner.games.description'),
  },
  // {
  //   url: '/referral',
  //   id: 26,
  //   img: '/img/mainslider/third',
  //   blurhash: 'UID*DV9Tw9~Bk9J4W-bEIVxsk9RRW.jboISO',
  //   title: t('banner.referral.title'),
  //   description: t('banner.referral.description'),
  // },
  {
    url: '/all-games?type=live',
    id: 31,
    img: '/img/mainslider/fourth',
    blurhash: 'UVDTlF24IK[jS*oHkRbcWak8obWsXAoHWCbc',
    title: t('banner.live.title'),
    description: t('banner.live.description'),
  },
])

const banner = computed<{ sources: { srcset: string; media: string }[]; alt: string; src: string }>(() => {
  return {
    sources: [
      {
        srcset: `${slides.value.at(index.value)?.img}@xs.${getWebp('jpeg')}`,
        media: '(max-width: 500px)',
      },
      {
        srcset: `${slides.value.at(index.value)?.img}@s.${getWebp('jpeg')}`,
        media: '(max-width: 768px)',
      },
      {
        srcset: `${slides.value.at(index.value)?.img}@m.${getWebp('jpeg')}`,
        media: '(max-width: 1024px)',
      },
    ],
    src: `${slides.value.at(index.value)?.img}@l.${getWebp('jpeg')}`,
    alt: 'banner',
  }
})

function updateAutoSwitch() {
  try {
    clearInterval(interval)

    interval = setInterval(nextSlide, 50000)
  }
  catch (ex) {
  }
}
function nextSlide() {
  const nextIndex = index.value === slides.value.length - 1 ? 0 : index.value + 1

  setIndex(nextIndex)
}
function prevSlide() {
  const prevIndex = index.value === 0 ? slides.value.length - 1 : index.value - 1

  setIndex(prevIndex)
}

function onNextSlide() {
  nextSlide()

  updateAutoSwitch()
}
function onPrevSlide() {
  prevSlide()

  updateAutoSwitch()
}
function onSelectSlide(idx: number) {
  setIndex(idx)

  updateAutoSwitch()
}
function onResize() {
  setHeight(bannerRef.value?.offsetHeight || 0)
}
function onBannerClick() {
  const bannerUrl = slides.value.at(index.value)?.url || ''

  router.push(localePath(bannerUrl))
}
function onSwipeEnd(e: TouchEvent, direction: UseSwipeDirection) {
  if (direction === 'right')
    onPrevSlide()
  else if (direction === 'left')
    onNextSlide()
}

onMounted(() => {
  updateAutoSwitch()
  setResizeObserver(new ResizeObserver(onResize))
  resizeObserver.value.observe(bannerRef.value)

  useSwipe(bannerRef.value, {
    onSwipeEnd,
  })
})

watch(() => height.value, (val) => {
  const v = windowSize.width.value > 1600 ? 8 : 6.5
  setFontSize(val / v)
})
</script>

<template>
  <div ref="bannerRef" class="root">
    <div class="root__prev root__button" :data-cy="CentralSection.BACK_BANNER_ARROW" @click="onPrevSlide">
      <Arrow name="arrow" />
    </div>

    <div class="root__next root__button" :data-cy="CentralSection.FORWARD_BANNER_ARROW" @click="onNextSlide">
      <Arrow name="arrow" />
    </div>

    <div class="root__dots">
      <div
        v-for="idx of slides.length" :key="`banner-dot-${idx}`" class="root__dot"
        :class="{ 'root__dot-active': index === idx - 1 }" @click.stop="() => onSelectSlide(idx - 1)"
      />
    </div>

    <transition name="banner" mode="out-in" appear>
      <div :key="index" class="slide" :style="{ height: `${height}px` }" @click="onBannerClick">
        <PreloadedImage class="!absolute top-0 left-0 right-0 bottom-0">
          <template #default="{ load }">
            <PicturedImage class-name="object-cover w-full h-full" class="slide__img" :sources="banner.sources" :src="banner.src" :alt="banner.alt" @load="load" />
          </template>

          <template #preload>
            <Blurhash :width="1320" :height="365" :hash="slides.at(index)?.blurhash || ''" class="slide__img w-full h-full" />
          </template>
        </PreloadedImage>

        <div class="slide__title" :style="{ fontSize: `${fontSize}px` }">
          <p>{{ slides.at(index)?.title }}</p>

          <span>{{ slides.at(index)?.description }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.root {
  border-radius: $radiusXl;
  overflow: hidden;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 27%;
  cursor: pointer;
  background: linear-gradient(180deg, #29323A -8.33%, #1D2226 106.67%);

  @include _989 {
    padding-bottom: 40%;
  }

  @include _600 {
    padding-bottom: 40%;
  }

  @media screen and (min-width: 1920px) {
    padding-bottom: 0;
    height: 365px;
  }

  &__dots {
    position: absolute;
    z-index: 10;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__dot {
    cursor: pointer;
    border-radius: 10rem;
    background: var(--white);
    opacity: .3;
    width: 6px;
    height: 4px;
    transition: all 0.5s;

    &-active {
      opacity: 1;
      width: 16px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__button {
    display: inline-flex;
    padding: 12px 4px 12px 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: rgba(29, 34, 38, 0.30);
    cursor: pointer;
    transition: all .2s;

    &:hover {
      color: var(--yellow);
      background: var(--dark)
    }
  }

  &__prev {
    position: absolute;
    z-index: 10;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__next {
    position: absolute;
    z-index: 10;
    right: 12px;
    top: 50%;
    transform: translateY(-50%) rotateZ(180deg);
  }
}

.slide {
  height: 100%;
  min-height: 100px;
  width: 100%;
  //position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  &__title {
    position: relative;
    z-index: 5;
    color: var(--white);
    font-size: 40px;
    font-weight: 700;
    padding-left: 64px;
    width: 100%;

    p {
      max-width: 45%;
    }

    span {
      display: block;
      font-size: 0.5em;
      font-weight: 400;
      padding-top: 1em;
      transform: none;
      max-width: 30%;

      @include _1279 {
        display: block;
      }

      @include _600 {
        font-size: 0.7em;
        padding-top: 0.5em;
      }
    }

    @include _989 {
      font-size: 4vw;
      padding-left: 20px;

      p {
        max-width: 55%;
      }

      span {
        max-width: 55%;
      }
    }

    @include _600 {
      margin: 0 12px;
      padding: 0 0 0 28px;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: none;
    transform: scale(1);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;

    @include _600 {
      background-position: right center;
    }

  }
}

.banner-enter-from {
  opacity: 0;
  transition: opacity 0.7s, bottom 0.7s;

  & .slide__img {
    transition: transform 0.7s;
    transform: scale(1.1);
  }

  & .slide__title {
    opacity: 0;
    transition: opacity 0.5s;

    span {
      transform: translateY(10px);
      transition: transform 0.7s;
    }
  }
}

.banner-enter-to {
  opacity: 1;
  transition: opacity 0.7s, bottom 0.7s;

  & .slide__img {
    transition: transform 0.7s;
    transform: scale(1);
  }

  & .slide__title {
    opacity: 1;
    transition: opacity 0.5s;

    span {
      transform: unset;
      transition: transform 0.7s;
    }
  }
}
</style>
