<script setup lang="ts">
import { t } from '@/plugins/i18n'

interface Props {
  path: string
  tag?: string
}
const props = defineProps<Props>()

function getTranslated() {
  return t(props.path)
}

function getTranslateMap() {
  return getTranslated().replaceAll('{', '{slot:').replaceAll('}', '{').split('{')
}

getTranslateMap()
</script>

<template>
  <component :is="tag || 'p'">
    <template v-for="item in getTranslateMap()">
      <slot v-if="item.startsWith('slot:')" :key="item" :name="item.replace('slot:', '')" />

      <template v-else>
        {{ item }}
      </template>
    </template>
  </component>
</template>
