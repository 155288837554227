import { getExchangeRates } from '@modules/wallet/wallet.service'
import type { ExchangeRates } from '@modules/wallet/wallet.model'
import { useQuery } from '../wallet.dependencies'
import type { Ref } from '../wallet.dependencies'

interface UseExchangeRates {
  data: Ref<ExchangeRates | undefined>
  dataUpdatedAt: Ref<number>
}

export function useExchangeRates(): UseExchangeRates {
  const query = useQuery(['exchange-rates'], async () => {
    const serviceResponse = await getExchangeRates()

    return serviceResponse.response
  }, { cacheTime: 60 * 60 * 1000, staleTime: 60 * 60 * 1000 })

  return {
    data: query.data,
    dataUpdatedAt: query.dataUpdatedAt,
  }
}
