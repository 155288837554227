import type { CommonValidatorFunction } from '@helpers/validation'
import { getValidHelper, setInputValidity } from '@helpers/validation'
import type { Ref } from '@modules/chat/chat.dependencies'
import { maxLength, ref, required, useVuelidate, watch } from '@modules/chat/chat.dependencies'

export const messageHasUrl = (value: string) => /[a-zA-Z]\.(com|ru|io|net|org|casino|club|win|pro|bet|duck)/.test(value)

export const useMessageValidation: CommonValidatorFunction = (element?: Ref<HTMLInputElement>) => {
  const message = ref<string>('')
  const rules = {
    message: {
      required,
      maxLength: maxLength(256),
    },
  }
  const messages: Record<string, string> = {
    required: 'notify.required_message',
    maxLength: 'notify.length_message',
  }
  const $v = useVuelidate<{ message: Ref<string> }>(rules, { message })

  const getIsValid = getValidHelper($v, messages)
  const reset = () => message.value = ''
  const getIsError = () => $v.value.$invalid

  if (element)
    watch(() => $v.value.$invalid, (invalid: boolean) => setInputValidity(element, invalid))

  return { value: message, getIsValid, reset, getIsError }
}
