import type { UserVipStatus } from '@modules/user/user.model'

export interface Bonus {
  id: string
  userId: string
  status: BonusStatus
  createdAt: string
  updatedAt: string
  activationCause: BonusActivationCause
  payload: {
    wager: string
    amount: string
    currencyId: string
    walletType: BonusWalletType
    bonusWalletId: string
    wageringWallets: BonusWalletType[]
    daysToComplete?: number
    amountMultiplier?: string
    maxWin?: string
    wageredAmount?: string
  }
  bonusType: BonusType
}

type BonusActivationCause = 'CASHBACK' | 'DEPOSIT' | 'JOIN_TG_CHANNEL' | 'JOIN_VK_GROUP' | 'PROMO_CODE' | 'RAFFLE' | 'RAKEBACK'

export interface RewardedActionCommon {
  id: string
  action: BonusType
  activationsCount: number
  activeUntil: string | null
  deferredActivation: boolean
  bonusParameters: Bonus[]
  canBeActivated: boolean
  pendingActivation: null | {
    id: string
    mustBeCommitedUntil: string
    status: 'PENDING'
    userId: string
  }
}

export interface RewardedActionTelegram extends RewardedActionCommon {
  action: BonusType.JOIN_TG_CHANNEL
  parameters: {
    channelId: string
    groupUrl: string
  }
}

export interface RewardedActionDeposit extends RewardedActionCommon {
  action: BonusType.DEPOSIT
  parameters: {
    deferredActivationTimeLimitExceededIn: number
    minimalDepositAmounts: Record<string, string>
    maximumDepositAmounts: Record<string, string>
  }
}

export interface RewardedActionVk extends RewardedActionCommon {
  action: BonusType.JOIN_VK_GROUP
  parameters: {
    groupId: string
    groupUrl: string
  }
}

export type RewardedAction = RewardedActionTelegram | RewardedActionVk | RewardedActionDeposit

export enum BonusType {
  BALANCE = 'BALANCE',
  CASHBACK = 'CASHBACK',
  RAKEBACK = 'RAKEBACK',
  DEPOSIT = 'DEPOSIT',
  JOIN_TG_CHANNEL = 'JOIN_TG_CHANNEL',
  JOIN_VK_GROUP = 'JOIN_VK_GROUP',
}

export enum BonusWalletType {
  REAL = 'REAL',
  BONUS = 'BONUS',
}

export enum BonusStatus {
  ACTIVATED = 'ACTIVATED',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export interface GiveawayUser {
  id: string
  avatar: string
  name: string
  login: string
  vipStatus: Pick<UserVipStatus, 'slug' | 'level'>
}
export interface GiveawayActionCommon {
  onlyForReferrals: boolean
  currencyId: string
  amount: string
  minDepositAmount: string
  winnerCount: number
  duration: number

}
export interface Giveaway extends GiveawayActionCommon {
  id: string
  status: GiveawayStatus
  winners: GiveawayUser[]
  participantCount: number
  createdAt: Date
  startsAt: Date
  userId: string
  isParticipant: boolean
  code: string
  finishesAt: Date
  referralCode: string
}
export interface GiveawayUsersList {
  total: number
  participants: GiveawayUser[]
}
export interface GiveawayUsersPaginationMeta {
  lastParticipantUserId?: string
  limit: number
}
export interface GiveawaysPaginationMeta {
  currentPage: number
  perPage: number
  status: GiveawayStatus
}
export enum GiveawayStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export interface GiveawayToat {
  status: GiveawayStatus
  code: string
  id?: string
  amount?: string
  currencyId?: string
}
