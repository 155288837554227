<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { vOnClickOutside } from '@vueuse/components'

import Search from '@modules/casino/components/Search.vue'
import ProvidersDropdown from '@modules/casino/components/ProvidersDropdown.vue'

const props = defineProps({
  currTab: {
    type: String,
    default: null,
  },
  providers: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['searchToggle'])

const route = useRoute()

const provider = computed(() => {
  return route.params.provider
})

function onSearchSlots(val) {
  if (typeof val === 'boolean')
    emit('searchToggle', val)
}
</script>

<template>
  <section class="games-grid-filter">
    <div v-on-click-outside="() => emit('searchToggle', false)" class="games-grid-filter__top">
      <Search
        id="search_slots"
        name="search-slots"
        :tab="props.currTab"
        :provider="provider"
        @search-slots="onSearchSlots"
      />
    </div>

    <div class="games-grid-filter__bottom">
      <ProvidersDropdown :providers="props.providers" />
    </div>
  </section>
</template>

<style lang="scss">
.games-grid-filter {
  // TODO move into search! component should NOT change styles of another component
  input {
    height: 48px !important;
    padding-left: 46px !important;
    border-color: var(--gray) !important;
  }

  .icon {
    width: 46px;
    height: 46px;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  .input_btn {
    width: 46px;
    min-width: 46px;
    height: 46px;
  }
}
</style>

<style lang="scss" scoped>
.games-grid-filter {
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 12px;

  &__top {
    width: 100%;

    &.alone {
      padding-right: 0;
    }
  }

  &__bottom {
    flex-grow: 2;

    @include _480 {
      flex-grow: 1;
    }
  }

  @include _600 {
    margin-top: 12px;
  }
}
</style>
