import type { Component } from 'vue'
import type { TabsIds } from '@modules/fair/fair.types'

interface FairRouteChild {
  name: TabsIds
  path: TabsIds
  component: Component
  props?: boolean
}

type FairRoutes = {
  name: string
  path: string
  component: Component
  props: boolean
  children: FairRouteChild[]
}[]

export const route: FairRoutes = [
  {
    name: 'fair',
    path: 'fair',
    component: () => import('./views/Index.vue'),
    props: true,
    children: [
      {
        name: 'info',
        path: 'info',
        component: () => import('./sections/Index/Info.vue'),
      },
      {
        name: 'realization',
        path: 'realization',
        component: () => import('./sections/Index/Realization.vue'),
      },
      {
        name: 'transformation',
        path: 'transformation',
        component: () => import('./sections/Index/Transformation.vue'),
      },
      {
        name: 'events',
        path: 'events',
        component: () => import('./sections/Index/Events.vue'),
      },
      {
        name: 'calculating',
        path: 'calculating',
        component: () => import('./sections/Index/Calculating.vue'),
        props: true,
      },
    ],
  },
]
