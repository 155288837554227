<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Arrow from '@/assets/icon/arrow.svg'
import Burger from '@/assets/icon/burger.svg'
import CasinoGameSlots from '@/assets/icon/casino-game-slots.svg'
import Gift from '@/assets/icon/gift.svg'
import Chat from '@/assets/icon/chat.svg'
import { useLayoutStore } from '@/store/Layout'
import { t, useLocalePath } from '@/plugins/i18n'

const localePath = useLocalePath()
const layoutStore = useLayoutStore()

const closeAll = () => null
function toggleMenu() {
  layoutStore.toggleOneCloseAnother('left')
}
function closeMenu() {
  layoutStore.setLeftSide('closed')
}
function closeChat() {
  layoutStore.setRightSide('closed')
}
const toggleChat = () => layoutStore.toggleOneCloseAnother('right')

const route = useRoute()
const isGamePage = computed(() => (!!route.meta?.game || !!route.meta?.casinoGame) && (layoutStore.windowSize.width <= 767 || (layoutStore.windowSize.width < 1024 && layoutStore.windowSize.height < 600)))

const onCloseMobileNav = () => layoutStore.setIsOpenMobileNav(false)
</script>

<template>
  <footer class="footer-m" :class="{ gamemode: isGamePage, open: layoutStore.isOpenMobileNav }">
    <div v-if="layoutStore.isHideHeaderOn && layoutStore.isOpenMobileNav && !layoutStore.rightOpen && !layoutStore.leftOpen" class="fixed top-0 left-0 right-0 bottom-0 z-[500]" @click="onCloseMobileNav" />

    <div class="footer-m_wrap">
      <div class="footer-m_toggle">
        <button class="footer-m_toggle__btn" :aria-label="t('open_footer')" @click="layoutStore.setIsOpenMobileNav(!layoutStore.isOpenMobileNav)">
          <Arrow name="arrow" />
        </button>
      </div>

      <div class="footer-m_main" :class="{ 'footer-m_main--active': layoutStore.isOpenMobileNav }">
        <ul class="list footer-m_main--list">
          <li @click="closeAll">
            <button v-if="!layoutStore.leftOpen" class="btn" @click.stop="toggleMenu">
              <Burger name="burger" />

              <span>{{ t('menu') }}</span>
            </button>

            <button v-else class="btn" @click.stop="closeMenu">
              <Burger name="burger" />

              <span>{{ t('menu') }}</span>
            </button>
          </li>

          <li @click="closeAll">
            <RouterLink :to="localePath('/all-games')" class="btn">
              <CasinoGameSlots name="casino-game-slots" />

              <span>{{ t('casino') }}</span>
            </RouterLink>
          </li>

          <!--          <li v-if="nextUpdate" @click="closeAll()"> -->
          <!--            <RouterLink to="/club" class="btn"> -->
          <!--              <Crown name="crown" /> -->
          <!--              <span>VIP</span> -->
          <!--            </RouterLink> -->
          <!--          </li> -->

          <li @click="closeAll">
            <RouterLink :to="localePath('/bonus')" class="btn">
              <Gift name="gift" />

              <span>{{ t('bonus') }}</span>
            </RouterLink>
          </li>

          <li @click="closeAll">
            <button v-if="!layoutStore.rightOpen" class="btn" @click.stop="toggleChat">
              <Chat name="chat" />

              <span>{{ t('chat.title') }}</span>
            </button>

            <button v-else class="btn" @click.stop="closeChat">
              <Chat name="chat" />

              <span>{{ t('chat.title') }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer-m {
  display: none;

  @include _1170 {
    display: block;
  }

  position: fixed;
  z-index: 4000;
  bottom: 0;
  left: 0;
  right: 0;
  transition: bottom .3s;

  &_toggle {
    position: absolute;
    top: -10px;
    z-index: 2005;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    align-items: center;
    justify-content: center;

    &__btn {
      background: var(--gray);
      border-top: 1px solid #2c3238;
      width: 50px;
      height: 40px;
      border-radius: 10px;
      padding-bottom: 16px;

      svg {
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
        transition: transform .2s;
      }
    }
  }

  &_wrap {
    height: 64px;
    position: relative;
    transition: transform .3s;
  }

  &_main {
    width: 100%;
    height: 64px;
    background: var(--gray);
    border-top: 1px solid #2c3238;
    padding: 10px 0;
    position: relative;
    z-index: 1000;

    &--active {
      z-index: 1002;
    }

    &--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;
      transform: translateY(0);

      li {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          border-right: none;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 auto 4px;
      }

      span {
        width: 100%;
        color: var(--white);
      }

      .btn {
        color: var(--gray-2);
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 12px;

        &.active {
          color: var(--yellow);
        }
      }
    }
  }

  &_dd {
    position: fixed;
    z-index: 90;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--gray-4);
    box-shadow: 0 1px 4px rgb(0 0 0 / 55%);
    border-radius: $radius;
    padding-bottom: 64px;
    margin: 0 auto;
    transition: 0.3s;
    transform: translateY(100%);

    &--nav {
      padding: 12px;

      &_item {
        position: relative;

        &.inactive {
          filter: grayscale(1);
        }

        .btn {
          justify-content: flex-start;
          padding: 0 16px;
          height: 50px;
          width: 100%;
          font-weight: 700;
        }

        .lside {
          width: 100%;

          span {
            display: block;
            width: 100%;

            &:last-child {
              color: var(--gray-2) !important;
              font-size: 12px;
            }
          }
        }

        .nuxt-link-exact-active {
          .lside {
            span {
              color: var(--yellow);
            }
          }

          &.btn_more {
            color: var(--yellow);

            i {
              color: inherit;
            }
          }
        }
      }
    }

    &.open {
      transform: unset;
    }
  }

  .ha {
    height: auto;
  }

  &.gamemode {
    bottom: -51px !important;

    .footer-m_toggle {
      display: flex;
    }

    //&_wrap {
    //  transform: translateY(80%);
    //}

    &.open {
      bottom: 0 !important;

      .footer-m_toggle__btn svg {
        transform: rotate(-90deg);
      }

      .footer-m_wrap {
        transform: unset;
      }
    }
  }
}
</style>
