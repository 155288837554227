import type { RouteLocationNormalized } from 'vue-router'
import { useCookies } from '@vueuse/integrations/useCookies'
import { nextTick } from 'vue'
import { availableLocales } from './i18n'
import { type UserModule } from '@/types'

// https://github.com/antfu/vite-plugin-pwa#automatic-reload-when-new-content-available
export const install: UserModule = ({ router }) => {
  router.beforeEach(async (to, from, next) => {
    try {
      // not found page
      const currentLocale = to.params.locale?.toString()
      if (currentLocale && !availableLocales.includes(currentLocale))
        to.meta.showNotFound = true

      // utm params
      let shouldRemoveUtmParams = false
      const cookies = useCookies()
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      utmParams.forEach((param) => {
        if (to.query[param]) {
          const expires = new Date()
          expires.setTime(expires.getTime() + (1 * 60 * 60 * 1000))
          cookies.set(param, to.query[param], { expires })
          shouldRemoveUtmParams = true
        }
      })
      if (shouldRemoveUtmParams) {
        nextTick(() => {
          const query = { ...to.query }
          utmParams.forEach(param => delete query[param])
          router.replace({ ...to, query }).catch((err) => {
            if (err.name !== 'NavigationDuplicated')
              throw err
          })
        })
      }
    }
    catch (e) { }
    next()
  })
  router.onError((error: Error) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      const nextRoute: RouteLocationNormalized = router.currentRoute.value as RouteLocationNormalized
      window.location.href = nextRoute?.fullPath || window.location.href
    }
  })
}
