<script setup lang="ts">
import type { UserRank } from '@modules/user/user.model'

interface Props {
  rank: UserRank
}

const props = defineProps<Props>()

const getImageSrc = () => `/img/user/icon-${props.rank}.png`
</script>

<template>
  <img :key="rank" alt="user_rank" :src="getImageSrc()">
</template>

<style scoped lang="scss">

</style>
