<script setup lang="ts">
interface Source {
  srcset: string
  media: string
  type?: string
}

interface Props {
  sources?: Source[]
  src: string
  alt: string
  className?: string
}

defineProps<Props>()
const emit = defineEmits(['load'])

const onLoad = () => emit('load')
</script>

<template>
  <picture>
    <source v-for="source of (sources || [])" :key="source.srcset" :class="className" :type="source?.type" :srcset="source.srcset" :media="source.media">

    <img :class="className" :src="src" :alt="alt" @load="onLoad">
  </picture>
</template>

<style scoped lang="scss">

</style>
