<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

interface Props {
  name: string
}
const props = defineProps<Props>()
const icon = defineAsyncComponent(() => import(`../../../assets/icon/payments/${props.name}.svg`))
</script>

<template>
  <component :is="icon" />
</template>

<style scoped lang="scss">

</style>
