import type { Ref } from '@modules/auth/auth.dependencies'
import { useError } from '@modules/auth/auth.dependencies'
import type { Validation } from '@vuelidate/core'
import { t } from '@/plugins/i18n'

export interface CommonValidatorFunction {
  (): CommonValidator
  (element: Ref<HTMLInputElement>): CommonValidator
  (element?: Ref<HTMLInputElement>, initialData?: string): CommonValidator
}
export interface CommonValidator {
  value: Ref<string>
  getIsValid: () => Promise<boolean>
  reset: () => void
  getIsError: () => boolean
}

export function setInputValidity(element: Ref<HTMLInputElement>, invalid: boolean) {
  if (invalid)
    element.value.setCustomValidity('error')
  else
    element.value.setCustomValidity('')
}
export function getValidHelper($v: Ref<Validation>, messages: Record<string, string>, withToast = true) {
  return async () => {
    const isValid = await $v.value.$validate()

    if (!isValid) {
      const validator = $v.value.$errors.at(0)?.$validator
      const message = messages[validator || ''] || ''

      if (withToast)
        useError(t(message))
    }

    return isValid
  }
}
export function getValidationMessageHelper($v: Ref<Validation>, messages: Record<string, string>) {
  return async () => {
    const isValid = await $v.value.$validate()

    if (!isValid) {
      const validator = $v.value.$errors.at(0)?.$validator
      const message = validator ? t(messages[validator]) : ''

      return message
    }
  }
}
