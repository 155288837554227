<script setup lang="ts">
import { useState } from '@composable/useState'
import { vOnClickOutside } from '@vueuse/components'
import { computed } from 'vue'
import Arrow2 from '@/assets/icon/arrow-2.svg'
import { DefaultElements } from '@/cypress.types'

interface Item {
  id: string
  [key: string]: any
}

interface Props {
  items: Item[]
  placeholder?: string
  currentId: string
  bgColor?: string
}

const props = defineProps<Props>()
const emit = defineEmits(['change'])
const [show, setShow] = useState<boolean>(false)

const current = computed<Item | undefined>(() => props.items.find(item => item.id === props.currentId))

const onHeadClick = () => setShow(!show.value)
const close = () => setShow(false)
function onItemClick(id: string) {
  emit('change', id)
  close()
}
</script>

<template>
  <div v-on-click-outside="close" class="cursor-pointer relative">
    <!--  HEAD  -->
    <div
      :data-cy="DefaultElements.BASE_SELECT_DROPDOWN + currentId"
      class="py-2 px-3 h-[50px] flex items-center justify-between"
      :class="[!show ? `rounded-normal ${$style.borderTransparent}` : `rounded-t-normal ${$style.borderColored} ${$style.borderBottomHide}`, bgColor || 'bg-black-2']"
      @click="onHeadClick"
    >
      <span v-if="current" class="text-14 font-700 leading-14 text-white">
        <slot :current="current" :selected="true" />
      </span>

      <span v-else>
        {{ placeholder }}
      </span>

      <Arrow2 name="arrow-2" class="w-2 h-2 min-w-[8px] text-yellow" />
    </div>

    <!--  BODY  -->
    <div v-if="show" class="absolute rounded-b-normal z-10 top-[50px] w-full " :class="[$style.borderColored, bgColor || 'bg-black-2']">
      <div class="max-h-[245px] overflow-y-auto">
        <div v-for="item in items.filter(item => item.id !== currentId)" :key="`select-${item.id}`" :data-cy="DefaultElements.BASE_SELECT_OPTION + item.id" :class="[$style.item]" class="py-2 px-3 h-[50px] flex items-center justify-between transition hover:text-yellow" @click="() => onItemClick(item.id)">
          <slot :current="item" :selected="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.borderTransparent {
  border: 1px solid transparent;
}

.borderColored {
  border: 1px solid var(--gray-4);

  &:not(:first-child) {
    border-top: none;
  }
}

.borderBottomHide {
  border-bottom: none;
}

.item {
  &:last-child {
    @apply rounded-b-normal;
  }

  border-top: 1px solid var(--gray-4);
}
</style>
