<script lang="ts" setup>
import {
  Block,
  Select,
  computed,
  getIconClassName,
  ref,
  useCookies,
  useCurrencies,
  useError,
  useLocalePath,
  useModalLayer,
  useRoute,
  useRouter,
  useState,
  useToggle, useUserStore,
} from '@modules/auth/auth.dependencies'
import { useEmailValidation, usePasswordValidation } from '@modules/auth/auth.validator'
import { useAuthStore } from '@modules/auth'
import { onBeforeUnmount, onMounted } from 'vue'
import type { AuthSocialType } from '@modules/auth/auth.model'
import { clearAuthSocialType } from '@modules/auth/auth.helpers'
import PreloadedImage from '@components/Interface/PreloadedImage.vue'
import Blurhash from '@components/Interface/Blurhash.vue'
import Translate from '@components/Translate.vue'
import { LazyImage } from '@components/Interface'
import { AuthElement } from '@/cypress.types'
import GoogleIcon from '@/assets/icon/google.svg'
import { getReferral, removeReferral } from '@/helpers/utils'
import Show from '@/assets/icon/show.svg'
import Email from '@/assets/icon/email.svg'
import Close from '@/assets/icon/close.svg'
import Hide from '@/assets/icon/hide.svg'
import Password from '@/assets/icon/password.svg'
import Steam from '@/assets/icon/steam.svg'
import Vk from '@/assets/icon/vk.svg'
import SecurityLock from '@/assets/icon/security-lock.svg'
import { t } from '@/plugins/i18n'
import { isBeta } from '@/config/env'
import { useVisitorDataPlugin } from '@/plugins/fingerprint'

interface Props {
  payload: { type: AuthSocialType; path?: string }
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])
const authStore = useAuthStore()
const userStore = useUserStore()
const modalLayer = useModalLayer()
const cookie = useCookies()
const [loading, setLoading] = useState<boolean>(false)
const { getData } = useVisitorDataPlugin()

const emailInputRef = ref<HTMLInputElement>(document.createElement('input'))
const passwordInputRef = ref<HTMLInputElement>(document.createElement('input'))
const [showPassword, toggleShowPassword] = useToggle(false)
const [currencyId, setCurrencyId] = useState<string>(cookie.get('country') === 'RU' ? '643' : '643')
const [isSignIn, toggleIsSignIn] = useToggle(props.payload.type === 'signIn')
const { value: email, getIsValid: getIsEmailValid, reset: resetEmail } = useEmailValidation(emailInputRef)
const { value: password, getIsValid: getIsPasswordValid, reset: resetPassword } = usePasswordValidation(passwordInputRef)

const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const { balanceEnabled: currenciesRaw } = useCurrencies()
const currencies = computed(() => {
  if (!currenciesRaw || !currenciesRaw.value)
    return []

  return currenciesRaw?.value?.map((currency) => {
    return {
      ...currency,
      name: currency.name,
      icon: getIconClassName(currency.name),
      alias: currency.name.toLowerCase(),
    }
  })
})
const referralCode = computed(() => {
  const referral = getReferral()

  if (referral && referral.code)
    return referral.code

  return undefined
})

const onClose = () => emit('close')
function forgotPassword() {
  modalLayer.show('forgot')
}
async function getIsCurrencyValid() {
  const isValid = currencyId.value !== ''

  if (!isValid)
    useError(t('auth.select_currency_placeholder'))

  return isValid
}
async function onAuth() {
  try {
    setLoading(true)

    const promises: any[] = [getIsEmailValid()]

    if (!isSignIn) {
      promises.push(getIsPasswordValid())
      promises.push(getIsCurrencyValid())
    }
    else {
      promises.push((() => {
        const passwordInvalid = password.value?.length === 0

        if (passwordInvalid)
          useError(t('notify.required_password'))

        return !passwordInvalid
      })())
    }

    const [isEmailValid, isPasswordValid, isCurrencyValid] = await Promise.all(promises)
    if (!(isEmailValid && isPasswordValid && (isCurrencyValid || isSignIn))) {
      setLoading(false)
      return
    }

    if (!currencyId.value && !isSignIn.value) {
      useError(t('auth.select_currency_placeholder'))
      setLoading(false)
      return
    }

    const fingerprint = await getData()

    const isSuccess = isSignIn.value
      ? (await authStore.signIn({
          email: email.value,
          password: password.value,
          fingerprint,
        }))
      : (await authStore.signUp({
          email: email.value,
          password: password.value,
          repeatPassword: password.value,
          walletCurrencyId: currencyId.value,
          referralCode: referralCode.value,
          fingerprint,
        }))

    if (isSuccess) {
      const lastPath = localStorage.getItem('lastPath')
      if (!!lastPath && userStore.isLogged && localePath(route?.path) !== lastPath) {
        localStorage.removeItem('lastPath')

        await router.push(lastPath)
      }
      removeReferral()
      onClose()
    }
  }
  catch {
    setLoading(false)
  }
  finally {
    setLoading(false)
  }
}
onMounted(() => {
  clearAuthSocialType()

  if (props.payload.path)
    localStorage.setItem('lastPath', props.payload.path)
  else
    localStorage.removeItem('lastPath')
})
onBeforeUnmount(() => {
  localStorage.removeItem('lastPath')
})

function onPasswordFocus(e: Event & { target: HTMLInputElement | null | any }) {
  e.target?.setCustomValidity('error')
}
</script>

<template>
  <div class="modal_content auth" @click.stop>
    <div class="auth_wrap" :data-cy="AuthElement.MODAL_WINDOW">
      <div class="auth__bg">
        <div class="auth__bg-overlay">
          <PreloadedImage class="absolute top-0 left-0 right-0 bottom-0">
            <template #default="{ load }">
              <LazyImage
                name="banner"
                src="img/auth/bg@l.webp"
                small="img/auth/preloader.png"
                @load="load"
              />
            </template>

            <template #preload>
              <Blurhash :width="360" hash="UCGQ:y^h0#tQ1t};}?V@WES^t2WE^#o#0*bF" :height="510" />
            </template>
          </PreloadedImage>
        </div>

        <div class="auth__bg_wrap">
          <p>{{ t('join_club') }}</p>

          <b>Bandana. casino</b>
        </div>
      </div>

      <div class="auth__inner">
        <div class="auth-header">
          <SecurityLock class="w-[22px] h-[22px] yellow" />

          <h3 class="header-popup__title uppercase" :data-cy="AuthElement.MODAL_WINDOW_NAME">
            {{ isSignIn ? t('auth.login') : t('auth.registration') }}
          </h3>

          <button class="btn_close" :disabled="authStore.loading" :data-cy="AuthElement.CLOSE_MODAL_BUTTON" @click="onClose">
            <Close class="w-5 h-5" />
          </button>
        </div>

        <div class="auth-form">
          <form class="auth-form" @submit.prevent>
            <div class="form_field input-with-btn">
              <input
                id="login" ref="emailInputRef" v-model="email"
                class="form_input with-icon" type="text" :placeholder="t('auth.email')" minlength="4"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$" :data-cy="AuthElement.EMAIL_INPUT"
              >

              <label for="login" class="!m-0">
                <i class="icon w-[50px] h-[50px]">
                  <Email class="w-5 h-5" />
                </i>
              </label>

              <div class="input_btn h-[50px] flex justify-between items-center pr-2.5 font-700 gap4 top-0 bottom-auto">
                <button v-if="email.length" type="button" class="btn-link-simple w-8 h-8" :data-cy="AuthElement.CLEAR_EMAIL_BUTTON" @click="resetEmail">
                  <Close class="w-5 h-5" />
                </button>
              </div>
            </div>

            <div class="form-group">
              <div class="form_field input-with-btn">
                <input
                  id="pass" ref="passwordInputRef" v-model="password" autocomplete="off" name="password"
                  class="form_input with-icon" :type="showPassword ? 'text' : 'password'" :placeholder="t('auth.password')"
                  :data-cy="AuthElement.SET_PASSWORD_INPUT" @keypress.enter="onAuth" @focus.once="onPasswordFocus"
                >

                <label for="pass" class="!m-0">
                  <i class="icon w-[50px] h-[50px]">
                    <Password class="w-5 h-5" />
                  </i>
                </label>

                <div class="input_btn h-[50px] flex justify-between items-center pr-2.5 font-700 gap4">
                  <button v-if="password.length" type="button" class="btn-link-simple w-8 h-8" :data-cy="AuthElement.CLEAR_PASSWORD_BUTTON" @click="resetPassword">
                    <Close class="w-5 h-5" />
                  </button>

                  <button class="btn-dark3 w-8 h-8" type="button" :data-cy="AuthElement.SHOW_PASSWORD_BUTTON" @click="toggleShowPassword">
                    <Hide v-if="showPassword" />

                    <Show v-else />
                  </button>
                </div>
              </div>

              <button v-if="isSignIn" type="button" class="btn btn-default mt-3" :data-cy="AuthElement.FORGET_PASSWORD_BUTTON" @click="forgotPassword">
                {{ t('auth.password_forgot') }}
              </button>
            </div>
          </form>

          <Select
            v-if="!isSignIn" class="auth__select" :placeholder="t('auth.select_currency_placeholder')"
            :current-id="currencyId"
            :items="currencies"
            :data-cy="AuthElement.CURRENCY_SELECT"
            is-currencies
            @change="setCurrencyId"
          />

          <button
            class="btn-primary w-full h-[50px] uppercase" :disabled="loading" :data-cy="isSignIn ? AuthElement.LOGIN_BUTTON : AuthElement.REGISTER_BUTTON"
            @click="onAuth"
          >
            <span>
              {{ isSignIn ? t('auth.login') : t('auth.registration') }}
            </span>
          </button>

          <div class="form__confirmation">
            <Translate path="auth.agree">
              <template #link>
                <RouterLink :to="localePath('/terms-and-conditions')" target="_blank">
                  {{
                    t('auth.agree_link')
                  }}
                </RouterLink>
              </template>
            </Translate>
          </div>

          <Block v-if="!isSignIn" :text="t('auth.block_one_more')" />
        </div>

        <div class="auth-socials">
          <div class="auth-socials_or">
            <span class="uppercase">{{ t('or') }}</span>
          </div>

          <div class="auth-socials_wrap disabled">
            <button :disabled="isBeta" class="auth-socials_item steam" :data-cy="AuthElement.STEAM_BUTTON" @click="() => authStore.authSteamGetLink(payload.type)">
              <Steam class="w-6 h-6" />
            </button>

            <button :disabled="isBeta" class="auth-socials_item vk" :data-cy="AuthElement.VK_BUTTON" @click="() => authStore.authVkGetLink(payload.type)">
              <Vk class="w-[26px] h-[26px]" />
            </button>

            <button :disabled="isBeta" class="auth-socials_item google" :data-cy="AuthElement.GOOGLE_BUTTON" @click="() => authStore.authGoogleGetLink(payload.type)">
              <GoogleIcon class="w-6 h-6" />
            </button>
          </div>
        </div>

        <div class="auth-footer">
          <div v-if="isSignIn" class="auth-footer_wrap">
            {{ t('auth.havent_acc') }}
            <button class="btn btn-link" :data-cy="AuthElement.REGISTER_FOOTER_BUTTON" @click="toggleIsSignIn">
              {{ t('auth.registration') }}
            </button>
          </div>

          <div v-else class="auth-footer_wrap">
            {{ t('auth.have_acc') }}
            <button class="btn btn-link" :data-cy="AuthElement.LOGIN_FOOTER_BUTTON" @click="toggleIsSignIn">
              {{ t('auth.login') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  &_content {
    width: 720px;
    max-width: 100%;
    background: var(--dark-1);
  }
}

.auth {
  padding: 0;

  &__select {
    height: 50px;
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 0 20px 0;
    color: var(--white);
    text-transform: uppercase;
  }

  &_wrap {
    display: flex;
    overflow: hidden;
  }

  &__bg {
    width: 360px;
    min-width: 360px;
    // background: url('/img/auth0.png') center center no-repeat;
    background-size: cover;
    border-radius: $radius 0 0 $radius;
    position: relative;
    overflow: hidden;
    @include _760 {
      display: none;
    }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &_wrap {
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
      font-family: 'Montserrat';
      padding: 12% 20px;
      color: var(--white);
      text-transform: uppercase;
      text-align: center;
      position: relative;

      p {
        padding-right: 8px;
        padding-bottom: 4px;
      }

      b {
        font-size: 40px;
        color: var(--yellow);
        text-shadow: 0px 2.95px 0px #3d1600;
      }
    }
  }

  &__inner {
    overflow-y: auto;
    padding: 20px;
    width: 100%;
  }

  &-form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--padding);
    font-family: "PT Sans", sans-serif;

    input {
      font-family: "PT Sans", sans-serif;
      &:valid:not(:placeholder-shown) {
        border: 1px solid var(--green-3);
      }

      &:invalid:not(:placeholder-shown) {
        border: 1px solid var(--red-7);
      }

      &:focus {
        &+label {
          color: var(--white);
        }
      }
    }

    label {
      transition: 0.3s;
    }
  }

  &-socials {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #293036;

    &_or {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 20px 0;
      font-weight: 700;
      font-size: 12px;
      color: var(--gray-2);

      &:before,
      &:after {
        content: '';
        height: 1px;
        flex-grow: 1;
        border-top: 1px solid #293036;
      }
    }

    &_wrap {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      &:hover:not(.disabled) {
        .auth-socials_item {
          opacity: 0.5;
        }
      }
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: $radius;
      transition: all 0.3s ease;
      background: var(--white);
      color: var(--black-2);

      &.vk {
        background: #4872a3;
        color: var(--white);
      }

      &:hover:not(:disabled) {
        opacity: 1 !important;
      }
    }
  }

  &-footer {
    text-align: center;
  }
}

.form {
  &__confirmation {
    margin: 0 auto;

    p {
      max-width: 295px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: var(--white);
      text-align: center;
    }

    a {
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
      color: var(--yellow);
      text-decoration: underline;
    }
  }
}
</style>
