import type { LiveWinsPeriod } from '@modules/live/live.model'
import type { Tab } from '@/types/interface'

export const tabsConfig: Tab<LiveWinsPeriod, LiveWinsPeriod>[] = [
  {
    name: 'liveWins.period.live',
    type: 'live',
    id: 'live',
    url: '',
  },
  {
    name: 'liveWins.period.month',
    type: 'month',
    id: 'month',
    url: '',
  },
  {
    name: 'liveWins.period.week',
    type: 'week',
    id: 'week',
    url: '',
  },
  {
    name: 'liveWins.period.day',
    type: 'day',
    id: 'day',
    url: '',
  },
]
