<script setup lang="ts">
import { useModalLayer } from '@modules/wallet/wallet.dependencies'
import CurrencySelector from '@modules/wallet/component/Header/CurrencySelector.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { CasinoRoutes } from '@modules/casino'
import Wallet from '@/assets/icon/wallet.svg'
import { HeaderElement } from '@/cypress.types'
import { t } from '@/plugins/i18n'

const modalLayer = useModalLayer()
const route = useRoute()

const onTopUp = () => modalLayer.show('beta.topUp')

const hideBalance = computed<boolean>(() => route.name === CasinoRoutes.GAME)
</script>

<template>
  <div class="wallet-header-center relative">
    <CurrencySelector :hide-balance="hideBalance" />

    <button
      class="wallet-header__replenish uppercase"
      :data-cy="HeaderElement.DEPOSIT_BUTTON"
      @click="onTopUp"
    >
      <span>{{ t('payment_up') }}</span>

      <Wallet name="wallet" />
    </button>
  </div>
</template>

<style lang="scss">
.wallet-header {
  &-center {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__replenish {
    padding: 0 32px;
    height: 40px;
    border-radius: $radius;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--white);
    background: linear-gradient(265.29deg, #74d628 5.77%, #209540 100%);
    svg {
      display: none;
    }
    @include _760 {
      padding: 0 12px;
    }
    @media screen and (max-width: 400px) {
      svg {
        display: block;
      }
      span {
        display: none;
      }
    }

    &:hover {
      filter: brightness(1.2);
    }
  }
}
</style>
