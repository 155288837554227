<script setup lang="ts">
import { useState } from '@composable/useState'

const [loaded, setLoaded] = useState<boolean>(false)
const [preloadedShow, setPreloaderShow] = useState<boolean>(true)

function onLoad() {
  setLoaded(true)
  setTimeout(() => setPreloaderShow(false), 300)
}
</script>

<template>
  <div class="relative w-full h-full" :class="$style.bg">
    <div v-if="preloadedShow" :class="$style.parent">
      <slot name="preload" />
    </div>

    <transition name="fadeIn">
      <div v-show="loaded" :class="$style.parent">
        <slot name="default" :load="onLoad" />
      </div>
    </transition>
  </div>
</template>

<style module lang="scss">
.bg {
  background: linear-gradient(180deg, #29323A -8.33%, #1D2226 106.67%);;
}

.parent > * {
  @apply absolute left-0 top-0 bottom-0 right-0 w-full h-full;
}
</style>
