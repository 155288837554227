import { authMiddleware } from '@modules/auth/auth.middleware'

import type { AuthSocial, AuthSocialType, User } from '@modules/auth/auth.model'
import type { UserModule } from '@modules/auth/auth.dependencies'
import { useEmailValidation, usePasswordValidation } from '@modules/auth/auth.validator'

// validator
import ForgotModal from './components/Modal/Forgot.vue'
import RestoreModal from './components/Modal/Restore.vue'
import RestoreSuccessfulModal from './components/Modal/RestoreSuccessful.vue'
import AuthModal from './components/Modal/Authorization.vue'
import TfaModal from './components/Modal/Tfa.vue'
import ContinueSocialRegister from './components/Modal/ContinueSocialRegister.vue'

import { useAuthStore } from './auth.store'

const install: UserModule = async ({ router }) => {
  router.beforeEach(authMiddleware)
}

export {
  AuthSocialType, AuthSocial, ContinueSocialRegister,
  install, ForgotModal, useAuthStore, RestoreModal, User, AuthModal, TfaModal, usePasswordValidation, useEmailValidation,
  RestoreSuccessfulModal,
}
