<script setup lang="ts">
import { useLocalePath, useRoute } from '@modules/user/user.dependencies'
import Settings from '@/assets/icon/settings.svg'
import Security from '@/assets/icon/security.svg'
import History from '@/assets/icon/history.svg'
import { ProfileElements } from '@/cypress.types'
import { t } from '@/plugins/i18n'

const emit = defineEmits(['toggle'])
const route = useRoute()
const localePath = useLocalePath()
const toggleLink = () => emit('toggle')

const getIsActiveLink = (name: string) => String(route.name).startsWith(name)
</script>

<template>
  <nav class="nav">
    <RouterLink
      class="nav__link"
      :data-cy="ProfileElements.PROFILE_LEFT_MENU_MAIN"
      :class="{ active: getIsActiveLink('profile-settings') }"
      :to="localePath('/profile')"
      @click="toggleLink"
    >
      <Settings name="settings" class="w-[18px] h-[18px]" />

      {{ t('profile.settings') }}
    </RouterLink>

    <RouterLink
      class="nav__link"
      :data-cy="ProfileElements.PROFILE_LEFT_MENU_SECURITY"
      :class="{ active: getIsActiveLink('profile-security') }"
      :to="localePath('/profile/security')"
      @click="toggleLink"
    >
      <Security name="security" class="w-[18px] h-[18px]" />

      {{ t('profile.security') }}
    </RouterLink>

    <RouterLink
      class="nav__link"
      :data-cy="ProfileElements.PROFILE_LEFT_MENU_HISTORY"
      :class="{ active: getIsActiveLink('profile-history') }"
      :to="localePath('/profile/history/deposit')"
      @click="toggleLink"
    >
      <History name="history" class="w-[18px] h-[18px]" />

      {{ t('profile.history') }}
    </RouterLink>
  </nav>
</template>

<style lang="scss" scoped>
.nav {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  height: 100%;
  border-radius: $radius;
  background: var(--gray);

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    padding: 15px;
    width: 240px;
    height: 48px;
    border-radius: $radius;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--white);
    background: var(--gray-9);
    transition: all 0.3s ease;

    svg {
      display: flex;
      justify-content: center;
      color: var(--gray-2);
    }

    &:hover {
      color: var(--yellow);
      background: var(--gray-4);

      svg {
        color: var(--yellow);
      }
    }

    &.active {
      color: var(--yellow) !important;
      background: var(--gray-4) !important;

      svg {
        color: var(--yellow);
      }
    }
  }
}

@include _767 {
  .nav {
    width: 100%;
    padding: 12px;
    &__link {
      width: 100%;
    }
  }
}
</style>
