<script setup lang="ts">
import { tween } from 'shifty'
import { computed, ref, watch } from 'vue'
import { round } from '@helpers/utils'

const props = defineProps<{
  to: number
  duration?: number
  easing?: string
  full?: boolean
  minLength?: number
}>()

const tweened = ref<number>(0)

const counter = computed<string>(() => {
  const rounded = round(tweened.value, 2).toFixed(props.full ? 0 : 2)

  if (props.minLength && rounded.toString().length < props.minLength) {
    const diff = props.minLength - rounded.toString().length

    return `${'0'.repeat(diff)}${rounded}`
  }

  return rounded
})

watch(() => props.to, (newVal, oldVal) => {
  tween({
    from: { x: oldVal || 0 },
    to: { x: newVal || 0 },
    duration: props.duration === undefined ? 500 : props.duration,
    easing: 'easeOutQuint',
    render: ({ x }: { x: number }) => tweened.value = x,
  })
}, {
  immediate: true,
})
</script>

<template>
  <span>{{ counter }}</span>
</template>

<style scoped>

</style>
