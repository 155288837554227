<script setup lang="ts">
import { ref, useError, useKeydown, useState, useSuccess } from '@modules/user/user.dependencies'
import { generateTfaCode } from '@modules/user/user.service'
import { useUserStore } from '@modules/user'
import { watch } from 'vue'
import Translate from '@components/Translate.vue'
import Close from '@/assets/icon/close.svg'
import Copy from '@/assets/icon/copy.svg'
import { ProfileSecurity } from '@/cypress.types'
import { t } from '@/plugins/i18n'

const emit = defineEmits(['close'])
const userStore = useUserStore()
const [qr, setQr] = useState<string>('')
const [secret, setSecret] = useState<string>('')
const [state, setState] = useState<'' | 'success' | 'error'>('')
const code = ref<string>('')

useKeydown('Enter', onConnect)
const onClose = () => emit('close')
async function onCopy() {
  await navigator.clipboard.writeText(secret.value)

  useSuccess(t('notify.copied'))
}

async function onConnect() {
  if (code.value.length < 6) {
    useError(t('notify.code_min_length'))
    setState('error')
    return
  }

  const isSuccess = await userStore.connectTfa({
    authenticatorCode: code.value,
    secret: secret.value,
  })

  if (isSuccess) {
    setState('success')
    onClose()
  }
  else {
    setState('error')
  }
}
async function setup() {
  try {
    const serviceResponse = await generateTfaCode()

    setQr(serviceResponse.response.qrCode)
    setSecret(serviceResponse.response.secret)
  }
  catch (ex) {
    // ~
  }
}

setup()

watch(() => code.value, () => setState(''))
</script>

<template>
  <div class="modal_content" @click.stop>
    <header class="flex justify-between items-center">
      <h3 class="tfa__title">
        {{ t("auth.tfa.title") }}
      </h3>

      <button class="btn_close" @click="onClose">
        <Close name="close" class="w-5 h-5" />
      </button>
    </header>

    <div class="flex justify-between items-start tfa-gap">
      <div v-if="!qr" class="tfa__qr filled" />

      <img v-else :data-cy="ProfileSecurity.TFA_QR_IMAGE" class="tfa__qr bg-white" :src="qr" alt="qr">

      <div class="flex flex-col justify-between gap-5">
        <Translate path="profile.2fa.download" class="tfa__text" tag="p">
          <template #method>
            <span class="yellow">
              {{ "Google 2FA" }}
            </span>
          </template>
        </Translate>

        <Translate path="profile.2fa.scan" class="tfa__text" tag="p">
          <template #action>
            <span class="yellow">
              {{ t('profile.2fa.scan_action') }}
            </span>
          </template>
        </Translate>
      </div>
    </div>

    <div class="tfa-keys">
      <h3 class="tfa-keys__title">
        {{ t('profile.2fa.key') }}
      </h3>

      <div class="copy-input">
        <input class="form__input" :value="secret">

        <Copy :data-cy="ProfileSecurity.TFA_SECRET_KEY_INPUT" name="copy" class="copy-input__icon" @click="onCopy" />
      </div>

      <input
        v-model="code"
        class="form__input"
        :data-cy="ProfileSecurity.TFA_CODE_INPUT"
        :class="{
          'connect_tfa__input': state === '',
          'connect_tfa__input--success': state === 'success',
          'connect_tfa__input--error': state === 'error',

        }"
        :placeholder="t('profile.2fa.enter_code')"
      >
    </div>

    <button
      :data-cy="ProfileSecurity.TFA_CONNECT_BUTTON"
      class="btn-primary uppercase"
      :disabled="userStore.loading"
      @click="onConnect"
    >
      <span>{{ t('button.connect') }}</span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.connect_tfa__input {
  &:valid:not(:placeholder-shown) {
    border: 1px solid var(--gray-4);
  }

  &--success {
    border: 1px solid var(--green-3) !important;
  }

  &--error {
    border: 1px solid var(--red) !important;
  }

}

.copy-input {
  position: relative;

  & .form__input {
    pointer-events: none;
    border: 1px solid var(--gray-4) !important;
    color: white;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
  }

  &__icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: var(--gray-5);
    color: var(--gray-2);

    &:hover {
      color: var(--yellow);
    }
  }
}

.tfa {
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    color: white
  }

  &-keys {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: white
    }
  }

  &-gap {
    gap: 20px;
  }

  &__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }

  &__qr {
    border-radius: $radius;
    width: 150px;
    min-width: 150px;
    height: 150px;
  }
}
</style>
