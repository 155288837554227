<script setup lang="ts">
import Close from '@/assets/icon/close.svg'
import { NotificationsElements } from '@/cypress.types'
import { t } from '@/plugins/i18n'

import Success from '@/assets/icon/toast_success.svg'
import Error from '@/assets/icon/toast_error.svg'
import Warning from '@/assets/icon/toast_warning.svg'
import Info from '@/assets/icon/toast_info.svg'

interface ToastButton {
  buttonName: string
  text: string
}
type ToastType = 'success' | 'error' | 'warning' | 'info'
interface Props {
  type: ToastType
  title: string
  content: string
  buttons?: ToastButton[]
}

defineProps<Props>()
const emit = defineEmits(['closeToast', 'button'])

function getIconByStatus(status: ToastType) {
  const icons: Record<ToastType, any> = {
    success: Success,
    error: Error,
    warning: Warning,
    info: Info,
  }

  return icons[status]
}
const onClose = () => emit('closeToast')
const onButtonClick = (buttonName: ToastButton['buttonName']) => emit('button', buttonName)
</script>

<template>
  <div :class="[$style.root, $style[type]]">
    <div :class="$style.border" />

    <component :is="getIconByStatus(type)" />

    <div :class="$style.wrap">
      <span :class="$style.title" :data-cy="NotificationsElements.TOAST_TITLE + type">
        {{ t(`toast.${type}`) }}
      </span>

      <p :class="$style.content" :data-cy="NotificationsElements.TOAST_CONTENT">
        {{ content }}
      </p>

      <button v-for="button in buttons" :key="button.text" class="btn btn-dark4" :class="$style.button" @click="() => onButtonClick(button.buttonName)">
        <span>
          {{ button.text }}
        </span>
      </button>
    </div>

    <Close name="close" :class="$style.closeIcon" @click="onClose" />
  </div>
</template>

<style module lang="scss">
.button {
  padding: 8px !important;
  height: 32px !important;
  border-radius: 4px !important;
}

.root {
  @apply overflow-hidden p-3 pl-4 rounded-[6px] flex gap-2 relative;
  @apply bg-[#272F36] min-w-[270px];
}

.title {
  @apply text-14 font-700 uppercase;
  line-height: 14px;
}
.content {
  @apply text-12 font-400 text-white whitespace-pre-line;
  line-height: 12px;
}

.border {
  @apply absolute left-0 top-0 bottom-0 w-1;
}

.wrap {
  @apply w-full flex flex-col gap-2;
}

.closeIcon {
  @apply text-[#576774] hover:text-[#F6E444] cursor-pointer;
}

.error {
  @apply text-[#FF5454];
  & .border {
    @apply bg-[#FF5454];
  }
}
.success {
  @apply text-[#11D23B];
  & .border {
    @apply bg-[#11D23B];
  }
}
.warning {
  @apply text-[#F6E444];
  & .border {
    @apply bg-[#F6E444];
  }
}
.info {
  @apply text-[#6DB0FF];
  & .border {
    @apply bg-[#6DB0FF];
  }
}
</style>
