<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useModalLayer } from '@composable/useModal'
import { useUserStore } from '@modules/user'
import { isSSR } from '@helpers/utils'
import { Language, Socials } from './index'
import Support from '@/assets/icon/support.svg'
import { t, useLocalePath } from '@/plugins/i18n'
import { getOurPublishGames } from '@/config/games'
import { useLayoutStore } from '@/store/Layout'

const store = useUserStore()
const router = useRouter()
const modalLayer = useModalLayer()
const layoutStore = useLayoutStore()
const localePath = useLocalePath()

const onSignIn = () => modalLayer.show('auth', { type: 'signIn', path: localePath('/support') })

const nextUpdate = ref(false)
const carousel = ref([
  'bitcoin',
  'fk',
  'mc',
  'piastrix',
  'steam',
  'visa',
  'bitcoin',
  'fk',
  'mc',
  'piastrix',
  'steam',
  'visa',
])

const isCasino = computed<boolean>(() => {
  if (isSSR())
    return false

  return window.location.host === 'bandana.casino'
})

function Marquee(selector: string, speed: number, reverse?: boolean) {
  const parentSelector = !isSSR() && document.querySelector(selector)

  if (parentSelector) {
    const clone = parentSelector.innerHTML
    const firstElement = parentSelector.children[0] as HTMLElement
    let i = 0
    parentSelector?.insertAdjacentHTML('beforeend', clone)
    setInterval(() => {
      if (reverse)
        firstElement.style.marginRight = `-${i}px`
      else firstElement.style.marginLeft = `-${i}px`
      if (i > firstElement?.clientWidth)
        i = 0

      i = i + speed
    }, 0)
  }
}

function onRakeback() {
  router.push(localePath(`/rakeback${store.isLogged ? '/my' : '/about'}`))
}

function onCashback() {
  router.push(localePath('/cashback'))
}

setTimeout(() => {
  Marquee('.marquee', 0.2)
  Marquee('.marquee-reverse', 0.2, true)
  Marquee('.marquee-mob', 0.2)
}, 2000)
</script>

<template>
  <footer
    class="footer"
    :class="{
      'footer-left-open': layoutStore.leftOpen,
      'footer-right-open': layoutStore.rightOpen,
    }"
  >
    <div class="footer_container">
      <nav class="footer-nav">
        <div class="footer-nav__wrap">
          <ul class="list footer-nav_list">
            <li>
              <p class="footer-nav_title">
                {{ t('games') }}
              </p>
            </li>

            <li v-for="ourGame in getOurPublishGames()" :key="`footer-our-games-${ourGame.id}`">
              <RouterLink class="footer-nav__link" :to="localePath(`/${ourGame.path}`)">
                {{ ourGame.name }}
              </RouterLink>
            </li>
          </ul>

          <ul class="list footer-nav_list">
            <li>
              <p class="footer-nav_title">
                {{ t('casino') }}
              </p>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/all-games')">
                {{ t('all_games') }}
              </RouterLink>
            </li>

            <!--            <li> -->
            <!--              <RouterLink :to="localePath('/all-games?type=hot')" class="footer-nav__link"> -->
            <!--                {{ t('hot') }} -->
            <!--              </RouterLink> -->
            <!--            </li> -->

            <li>
              <RouterLink :to="localePath('/all-games?type=top')" class="footer-nav__link">
                {{ t('top') }}
              </RouterLink>
            </li>

            <!--            <li> -->
            <!--              <RouterLink :to="localePath('/all-games?type=new')" class="footer-nav__link"> -->
            <!--                {{ t('new') }} -->
            <!--              </RouterLink> -->
            <!--            </li> -->

            <li>
              <RouterLink :to="localePath('/all-games?type=slots')" class="footer-nav__link">
                {{ t('slots') }}
              </RouterLink>
            </li>

            <!--            <li> -->
            <!--              <RouterLink :to="localePath('/all-games?type=live')" class="footer-nav__link"> -->
            <!--                {{ t('live') }} -->
            <!--              </RouterLink> -->
            <!--            </li> -->

            <li>
              <RouterLink :to="localePath('/all-games?type=bonus-buy')" class="footer-nav__link">
                {{ t('bonus-buy') }}
              </RouterLink>
            </li>

            <li>
              <RouterLink :to="localePath('/all-games?type=roulette')" class="footer-nav__link">
                {{ t('roulette') }}
              </RouterLink>
            </li>
          </ul>
        </div>

        <div class="footer-nav__wrap">
          <ul class="list footer-nav_list">
            <li>
              <p class="footer-nav_title">
                Bandana Club
              </p>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/club')">
                Bandana Club
              </RouterLink>
            </li>

            <li>
              <button class="footer-nav__link" @click="onRakeback">
                Rakeback
              </button>
            </li>

            <li>
              <button class="footer-nav__link" @click="onCashback">
                Cashback
              </button>
            </li>

            <!--            <li> -->
            <!--              <RouterLink class="footer-nav__link" :to="localePath('/referral')"> -->
            <!--                {{ -->
            <!--                  t('affiliate_program') -->
            <!--                }} -->
            <!--              </RouterLink> -->
            <!--            </li> -->
          </ul>

          <ul class="list footer-nav_list">
            <li>
              <p class="footer-nav_title">
                {{ t('bonuses.title') }}
              </p>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/bonus')">
                {{ t('bonus_cabinet') }}
              </RouterLink>
            </li>

            <li v-if="nextUpdate">
              <RouterLink class="footer-nav__link" :to="localePath('/bonus-rangs')">
                Спиннер
              </RouterLink>
            </li>
          </ul>

          <ul class="list footer-nav_list">
            <li>
              <p class="footer-nav_title">
                {{ t('other') }}
              </p>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/aml-policy')">
                {{ t('aml_policy') }}
              </RouterLink>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/terms-and-conditions')">
                {{ t('terms_conditions') }}
              </RouterLink>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/responsible-gambling')">
                {{ t('responsible_gaming') }}
              </RouterLink>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/privacy')">
                {{ t('privacy_policy') }}
              </RouterLink>
            </li>

            <li>
              <RouterLink class="footer-nav__link" :to="localePath('/fair/info')">
                {{ t('fair.title') }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </nav>

      <div class="footer-bar flex justify-between items-center">
        <div class="footer-socials">
          <Socials />
        </div>

        <div class="rside flex justify-between items-center gap12">
          <button v-if="!store.isLogged" class="support gap8" @click="onSignIn">
            <Support name="support" class="w-[18px] h-[18px]" />
            {{ t('support.title') }}
          </button>

          <RouterLink v-else :to="localePath('/support')" class="support gap8">
            <Support name="support" class="w-[18px] h-[18px]" />
            {{ t('support.title') }}
          </RouterLink>

          <Language />
        </div>
      </div>

      <div class="footer-carousel">
        <div class="footer-carousel_wrap hidden md:block">
          <div class="marquee flex justify-start items-center overflow-hidden">
            <div class="marquee_item flex justify-between items-center whitespace-nowrap" :style="{ minWidth: `${125 * carousel.length}px` }">
              <img v-for="(item, i) of carousel" :key="i" :src="`/img/logo/${item}.svg`" alt="">
            </div>
          </div>
        </div>

        <div class="footer-carousel_wrap hidden md:block">
          <div class="marquee marquee-reverse flex items-center justify-start overflow-hidden">
            <div class="marquee_item flex justify-between items-center whitespace-nowrap" :style="{ minWidth: `${125 * carousel.length}px` }">
              <img v-for="(item, i) of carousel" :key="i" :src="`/img/logo/${item}.svg`" alt="">
            </div>
          </div>
        </div>

        <div class="footer-carousel_wrap block md:hidden">
          <div class="marquee marquee-mob flex justify-start items-center overflow-hidden">
            <div class="marquee_item flex justify-between items-center whitespace-nowrap" :style="{ minWidth: `${44 * carousel.length}px` }">
              <div v-for="(item, i) of carousel" :key="i" class="px-[5px]">
                <img :src="`/img/logo/${item}_mob.svg`" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-logos">
        <RouterLink
          class="logo" :to="localePath('/')"
          :aria-label="t('homepage')"
        >
          <img src="/logo-full.png" height="40" alt="Bandana logo">
        </RouterLink>

        <iframe
          v-if="isCasino"
          class="footer__curacao iframe"
          src="https://licensing.gaming-curacao.com/validator/?lh=686a854e1cc3f716fae2e711015199d5&template=tseal"
          width="150"
          height="50"
          STYLE="border:none;"
        />

        <a
          v-else
          class="footer__curacao link"
          href="https://licensing.gaming-curacao.com/validator/?lh=686a854e1cc3f716fae2e711015199d5"
          target="_blank"
        >
          <img
            src="https://licensing.gaming-curacao.com/validator/images/Gaming-Curacao-ClickToVerify.png"
            width="100%"
            height="100%"
            ALT="Gaming Curacao: Click to Validate"
          >
        </a>
      </div>

      <div class="footer-copyright">
        <p>
          This website is owned and operated by Novaspin N.V., a company
          registered and established under the laws of Curaçao, with
          registration number 163071, with registered address Zuikertuintjeweg
          Z/N (Zuikertuin Tower), Curaçao.
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--gray);
  color: var(--light);
  margin-top: auto;
  transition: 0.3s;

  &-left-open {
    padding-left: 300px;

    @include _1279 {
      padding-left: 0;
    }
  }
  &-right-open {
    padding-right: 300px;

    @include _1279 {
      padding-right: 0;
    }
  }

  &__curacao {
    width: 150px;
    height: 50px;

    & img {
      width: inherit;
      height: inherit;
    }
  }

  .marquee-reverse {
    flex-direction: row-reverse;
  }

  &_container {
    max-width: 1320px;
    padding: 30px;
    width: 100%;
    margin: 0 auto;
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-4);
    &__wrap {
      display: contents;
    }
    &_title {
      font-size: 14px;
      font-weight: 600;
      color: var(--white);
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &_list.list {
      margin-bottom: 20px;
    }

    &__link {
      color: var(--light);
      padding: 10px 0;
      display: flex;

      &:hover {
        color: var(--yellow);
      }
    }
  }

  &-bar {
    padding: 20px 0;
  }

  &-socials {
    a {
      background: var(--gray-3);
      color: var(--white);
      width: 32px;
      height: 32px;
      border-radius: $radius;
      @apply flex justify-center items-center;

      svg path {
        fill: currentColor;
      }
    }
  }

  &-carousel {
    border-top: 1px solid var(--gray-4);
    padding: 20px 0;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 20px 0;
    border-top: 1px solid var(--gray-4);
  }

  &-copyright {
    text-align: center;
    font-size: 12px;
    border-top: 1px solid var(--gray-4);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .logo {
    svg {
      height: 24px;
      width: 170px;
      color: #fff;
    }
  }

  .support {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 12px;
    /* border: 1px solid var(--gray-3); */
    border-radius: $radius;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--light);
    background: var(--black);
    transition: all 0.3s ease;

    &__svg {
      margin: 0 12px 0 0;

      path {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      /* border: 1px solid var(--yellow); */
      color: var(--yellow);

      svg {
        path {
          fill: var(--yellow);
        }
      }
    }
  }

  .scroll-up {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border: 1px solid var(--gray-3);
    border-radius: $radius;
    background: var(--black);
    transition: all 0.3s ease;

    svg {
      path {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      border: 1px solid var(--yellow);

      svg {
        path {
          fill: var(--yellow);
        }
      }
    }
  }
}

@include _600 {
  .footer {
    &_container {
      padding: 30px 12px 80px;
    }

    &-nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &__wrap {
        display: block;
      }
    }

    &-bar {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      .rside {
        width: 100%;
        justify-content: center;
      }
    }

    &-socials {
      width: 100%;
      @apply flex justify-center items-center;
      order: 1;
    }
  }
}
</style>
