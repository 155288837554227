<script setup lang="ts">
import {
  computed,
  ref,
  useError,
  useKeydown,
  useModalLayer,
  useState,
  useToggle,
  useUserStore, vOnClickOutside, watch,
} from '@modules/chat/chat.dependencies'
import { messageHasUrl, useMessageValidation } from '@modules/chat/chat.validation'
import { useChatStore } from '@modules/chat'
import type { Message } from '@modules/chat/chat.model'
import { defineAsyncComponent } from 'vue'
import Send from '@/assets/icon/send.svg'
import Emoji from '@/assets/icon/emoji.svg'
import Reply from '@/assets/icon/reply.svg'
import Close from '@/assets/icon/close.svg'
import { t } from '@/plugins/i18n'

const props = defineProps<Props>()

const emit = defineEmits(['reply'])

interface Props {
  reply: Message | null
}

const Picker = defineAsyncComponent(async () => (await import('emoji-mart-vue-fast/src')).Picker)

const [emojiIndex, setEmojiIndex] = useState<any>(null)
const [messageSended, setMessageSended] = useState<boolean>(false)
const chatStore = useChatStore()
const userStore = useUserStore()
const inputElementRef = ref<HTMLInputElement>(document.createElement('input'))
const { value: message, getIsValid, reset: resetMessage } = useMessageValidation()
const [open, toggleOpen, setOpen] = useToggle(false)
const modalLayer = useModalLayer()

const i18nSmiles = computed(() => ({
  search: t('search'),
  notfound: t('empty.nothing'),
  categories: {
    search: t('search_results'),
    recent: t('recent'),
    smileys: t('smiles_all'),
    custom: t('stickers'),
  },
}))

const placeholder = computed<string>(() => {
  if (userStore.activeChatBan)
    return t('chat.is_blocked')

  return t('message')
})
const banBefore = computed<string>(() => {
  if (userStore.activeChatBan?.durationInSeconds === undefined)
    return ''

  if (userStore.activeChatBan?.durationInSeconds === null)
    return t('chat.is_blocked_forever')

  return t('chat.is_blocked_before') + new Date(new Date(userStore.activeChatBan?.createdAt).getTime() + (userStore.activeChatBan?.durationInSeconds * 1000)).toLocaleString()
})

useKeydown('Escape', () => setOpen(false))

const closeEmoji = () => setOpen(false)
function onEmojiSelect(emoji: any) {
  if (!emoji.custom)
    message.value += emoji.native
}

async function onSendMessage() {
  if (chatStore.loading)
    return
  if (!userStore.isLogged) {
    modalLayer.show('auth', { type: 'signIn' })

    return
  }

  const isValid = await getIsValid()
  const isMessageHasUrl = messageHasUrl(message.value)

  if (isMessageHasUrl && !userStore.isAdmin) {
    useError(t('notify.dont_use_url_in_message'))
    return
  }
  if (message.value && messageSended.value) {
    useError(t('notify.chat_message_per_time'))
    return
  }

  if (isValid) {
    const isSuccess = await chatStore.sendMessage(message.value, props.reply?.id)

    if (isSuccess) {
      closeReply()
      resetMessage()
      setMessageSended(true)
      setTimeout(() => setMessageSended(false), 2000)
    }
  }
}

function closeReply() {
  emit('reply', null)
}

watch(() => props.reply?.userId, () => inputElementRef.value?.focus())
watch(() => open.value, (newValue: boolean) => {
  if (newValue)
    setupEmoji()
}, { once: true })

async function setupEmoji() {
  const [data, Index] = await Promise.all([
    import('@modules/chat/smile.json'),
    import('emoji-mart-vue-fast/src'),
    import('emoji-mart-vue-fast/css/emoji-mart.css'),
  ])

  const ClassConstructor = Index.EmojiIndex

  setEmojiIndex(
    new ClassConstructor(data.default, {
      include: ['recent', 'smileys'],
    }),
  )
}
</script>

<template>
  <div class="chat__footer">
    <!--      v-if="((!userRankEntity && userStore.isLogged) || !userStore.isIdentificationEnd) && !gracefulDegradation.checkServiceForOffline('vip')" -->
    <!--    <div -->
    <!--      v-if="!gracefulDegradation.checkServiceForOffline('vip')" -->
    <!--      class="rounded-normal h-[50px] px-3 py-2 flex items-center gap-2 relative z-10 filled filled-black" -->
    <!--    /> -->

    <div class="chat__footer-input flex justify-between items-center">
      <div class="emoji" :class="{ 'emoji--active': open }">
        <Picker
          v-if="emojiIndex && open"
          v-on-click-outside="closeEmoji"
          :per-line="5"
          :show-preview="false"
          set="apple"
          emoji="smiley"
          :i18n="i18nSmiles"
          :data="emojiIndex"
          :native="true"
          @select="onEmojiSelect"
        />
      </div>

      <div v-if="reply" class="chat__footer-reply">
        <div class="chat__footer-reply_wrap">
          <Reply name="reply" class="i-reply" />

          <p>{{ reply?.user?.name }}</p>

          <button class="btn-default" @click="closeReply">
            <Close name="close" />
          </button>
        </div>
      </div>

      <div class="ttip ttip_top ttip_center">
        <div class="form_field input-with-btn">
          <input
            ref="inputElementRef" v-model="message" type="text" :placeholder="placeholder"
            :disabled="!!userStore.activeChatBan" maxlength="254" autocomplete="off" :class="{ bt0: open }"
            @keypress.enter="onSendMessage"
          >

          <div class="chat__footer-input_toolbar">
            <button
              :disabled="!!userStore.activeChatBan"
              class="btn-secondary"
              aria-label="Emojy"
              @click="toggleOpen"
            >
              <Emoji name="emoji" />
            </button>

            <button :disabled="chatStore.loading || !!userStore.activeChatBan" type="submit" class="btn-secondary" aria-label="Send" @click="onSendMessage">
              <Send name="send" />
            </button>
          </div>
        </div>

        <div v-if="!!userStore.activeChatBan" class="ttip_wrap">
          <span>
            {{ banBefore }}
          </span>
        </div>
      </div>
    </div>

    <!--    <div -->
    <!--      class="bg-black rounded-normal h-[50px] px-3 py-2 flex items-center gap-2 relative z-10 cursor-not-allowed" -->
    <!--    > -->
    <!--      <Lock2 name="lock2" /> -->

    <!--      <div class="flex items-center gap-1"> -->
    <!--        <p class="text-12 font-700 leading-18 text-gray-2"> -->
    <!--          <span> -->
    <!--            {{ t('chat.access_rank') }} -->
    <!--          </span> -->

    <!--          <span class="leading-12"> -->
    <!--            {{ 'Newcomer' }} -->
    <!--          </span> -->
    <!--        </p> -->

    <!--        <div class="flex items-center"> -->
    <!--          <Star name="star" class="w-3 h-3 min-w-[12px]" /> -->

    <!--          <Star name="star" class="w-3 h-3 min-w-[12px]" /> -->
    <!--        </div> -->
    <!--      </div> -->
    <!--    </div> -->

    <!--    <div -->
    <!--      class="bg-black rounded-normal h-[50px] px-3 py-2 flex items-center justify-center gap-2 relative z-10 cursor-not-allowed" -->
    <!--    > -->
    <!--      <Lock2 name="lock2" /> -->

    <!--      <p class="text-12 font-700 leading-18 text-gray-2"> -->
    <!--        {{ t('chat.unavailable') }} -->
    <!--      </p> -->
    <!--    </div> -->
  </div>
</template>

<style lang="scss">
.chat__footer {
  position: relative;
  padding: 0 var(--padding) var(--padding);

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(0deg, var(--dark) 60%, transparent 100%);
  }

  & .ttip {
    width: 100%;
  }

  &-reply {
    position: absolute;
    top: -14px;
    left: 12px;
    z-index: 11;
    right: 12px;
    display: flex;

    &_wrap {
      display: flex;
      gap: 4px;
      padding: 4px 8px;
      background: var(--gray-5);
      border-radius: $radius;
      max-width: 100%;
      overflow: hidden;

      .i-reply {
        transform: scale(-1, 1);
      }

      p {
        @apply overflow-ellipsis overflow-hidden whitespace-nowrap;
        color: var(--white);
        max-width: 100%;
      }

      button {
        padding: 0;

        &:hover {
          color: var(--yellow);
        }
      }
    }
  }

  &-input {
    position: relative;
    z-index: 1;

    &_toolbar {
      display: flex;
      align-items: center;
      height: 50px;
      position: absolute;
      top: 0;
      right: 8px;
      gap: 8px;

      button {
        height: 32px;
        width: 32px;
        padding: 0px;
      }
    }
  }

  .form_field {
    width: 100%;
    margin: 0;

    input {
      height: 50px;
      border-radius: $radius;
      color: var(--white);
      width: 100%;
      border-color: transparent;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      background: var(--black-2);
      transition: 0.3s;
      padding: 12px 86px 12px 12px;

      &:hover,
      &:focus {
        background: var(--black);
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: var(--gray-2);
      }

      &.bt0 {
        border-top-left-radius: 0;
      }
    }
  }

  .emoji {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    max-width: 217px;
    max-height: 246px;

    &--active {
      display: block;
    }

    @include _480 {
      max-width: 100%;
    }
  }

  .emoji-mart-category-label {
    position: sticky !important;
    top: -2px !important;
  }

  .emoji-mart.emoji-mart-static {
    width: 100% !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: var(--black-2);
    border: none;
    max-height: 246px;
  }

  .emoji-mart-category-label h3 {
    background-color: var(--black-2);
    color: #ffff;
    font-family: 'DIN Pro', -apple-system, BlinkMacSystemFont, 'Roboto',
      sans-serif;
  }

  .emoji-mart-bar {
    border: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    overflow: hidden;
  }

  .emoji-mart-anchors {
    padding: 0;
  }

  .emoji-mart-anchor-bar {
    display: none;
  }

  .emoji-mart-anchor {
    height: 40px;
    background: #15161f;
    border-radius: 0;

    @include _480 {
      background: darken(#15161f, 2%);
    }

    &-selected {
      background: #21222f;
      color: #1991eb !important;
    }
  }

  .emoji-mart-search {
    padding: 0 14px;
    margin: 14px 0;
  }

  .emoji-mart-search input {
    background: #15161f;
    border-radius: 3px;
    height: 30px;
    min-height: 30px;
    border: 1px solid #353748;
    font-size: 12px;

    &::placeholder {
      font-size: 12px;
    }
  }

  .emoji-mart-bar {
    display: none;
  }

  .emoji-mart-scroll {
    overflow: auto;
  }

  .emoji-mart-emoji {
    display: flex;
  }

  .emoji-mart-category {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .emoji-mart-category-label,
    .emoji-mart-category-label+div {
      grid-column: 1 / -1;
      color: var(--gray-2);
      font-size: 12px;
      padding-bottom: 8px;
    }
  }

  .emoji-mart-no-results-label {
    padding: 5px 6px;
    color: var(--gray-2);
  }

  .emoji-mart-category .emoji-mart-emoji:hover:before,
  .emoji-mart-emoji-selected:before {
    height: 0;
    padding-bottom: 100%;
    top: 2px;
  }
}
</style>
