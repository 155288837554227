import type { GameRoute } from '@modules/games/games.types'

export const route: GameRoute = {
  name: 'tower',
  path: 'tower',
  children: [
    {
      path: '',
      name: 'tower-index',
      component: () => import('./views/index.vue'),
      meta: {
        game: true,
        inHouseTowerGame: true,
      },
    },
    {
      path: 'history',
      name: 'tower-history',
      component: () => import('./views/history.vue'),
      meta: {
        auth: true,
      },
    },
  ],
}
