<script setup lang="ts">
import * as Layout from '@components/Layout'
import { Combiner as ModalCombiner } from '@components/Modal'

import { useQueryClient } from '@tanstack/vue-query'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import {
  isLanguageDetected,
  isSNGCountry,
  isSSR, setLanguageDetected,
} from '@helpers/utils'
import { useAuthStore } from '@modules/auth'
import { VerifyEmailRow, useUserStore } from '@modules/user'
import { useBus } from '@composable/useBus'
import UpdatePwaRow from '@components/Interface/UpdatePwaRow.vue'
import { useState } from '@composable/useState'
import { setSupportWebp } from '@helpers/webp'
import { useModalLayer } from '@composable/useModal'
import useResizeHandler from '@helpers/resizeRemoveAnimations'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useChatStore } from '@modules/chat'
import NotFound from '@/404.vue'
import { useLayoutStore } from '@/store/Layout'
import { useQueryStore } from '@/store/Query'
import { setLanguage, useLocalePath } from '@/plugins/i18n'
import { saveReferralForUnauthorized } from '@/helpers/utils'
import { isBeta } from '@/config/env'

const bus = useBus()
const queryClient = useQueryClient()
const authStore = useAuthStore()
const cookie = useCookies()
const userStore = useUserStore()
const queryStore = useQueryStore()
const layoutStore = useLayoutStore()
const chatStore = useChatStore()
const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const [needUpdate, setNeedUpdate] = useState<boolean>(false)
const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(true)

const routeClass = computed<string>(() => `p-${route.name?.toString()}`)

const showEmailNotVerify = computed<boolean>(() => {
  const isGame = !!(route.meta?.game || route.meta?.casinoGame)
  const moreThanFiveMinutes = Date.now() - new Date(userStore.user?.createdAt || '').getTime() > 1000 * 60

  return userStore.isLogged && !userStore.user?.emailVerifiedAt && showVerifyEmail.value && !isGame && moreThanFiveMinutes
})

const layoutHasTopRow = computed<boolean>(() => needUpdate.value || showEmailNotVerify.value)

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'BANDANA.CASINO',
  htmlAttrs: {
    lang: 'ru',
  },
  meta: [
    // { hid: 'description', name: 'description', content: 'Play BANDANA CASINO! Big wins! Fast payouts!' },
    { name: 'format-detection', content: 'telephone=no' },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: '/fav.png' }],
})

async function setup() {
  if (isSSR())
    return

  if (!isSNGCountry(cookie.get('country')) && !isLanguageDetected()) {
    await router.replace(localePath(route.fullPath, 'en'))
    setLanguageDetected()
    setLanguage('en')
    chatStore.setCurrent(chatStore.rooms.find(item => item.languageIsoCode === 'EN') || chatStore.current || null)
  }

  import('supports-webp').then((x: any) => x?.default?.then(setSupportWebp))

  bus.on('update:notification', () => {
    setNeedUpdate(true)
  })

  queryStore.setQueryClient(queryClient)
  await authStore.loadAppAuthPipeline()

  await router.replace(localePath(route.fullPath.replace('checkUser=true', '')))
}

watch(() => userStore.isIdentificationEnd, (val?: boolean) => {
  if (isSSR())
    return
  if (!val)
    return

  const params = new URLSearchParams(window.location.search)
  const code = params.get('ref')

  if (code) {
    const refer = document.referrer || 'unknown'

    saveReferralForUnauthorized(code, refer)

    router.replace(localePath('/'))
  }
}, { immediate: true })
watch(() => route.path + JSON.stringify(route?.query || {}), layoutStore.closeAllIfMobile)
setup()

onMounted(layoutStore.mountPipeline)
onMounted(() => {
  if (isBeta) {
    const isShown = localStorage.getItem('beta.onboarding')

    if (!isShown) {
      setTimeout(() => {
        useModalLayer().show('beta.onboarding')
        localStorage.setItem('beta.onboarding', 'true')
      }, 1500)
    }
  }
})
// Turn back if add block
// onMounted(() => setTimeout(async () => {
//   const module = await import('@/helpers/adblock')
//   const TIMEOUT = 10000
//   const SEND_REQUEST_TS = Date.now()
//
//   module.checkIsAdBlockIsOnService()
//     .then((result) => {
//       if (result) {
//         if ((Date.now() - SEND_REQUEST_TS) < TIMEOUT) {
//           const openModals = useOpenModals()
//
//           if (!openModals.active.value)
//             useModalLayer().show('blockers_detect')
//         }
//       }
//     })
// }, 500))
useResizeHandler(200)
</script>

<template>
  <div class="app">
    <VerifyEmailRow v-if="showEmailNotVerify" @close="() => setShowVerifyEmail(false)" />

    <UpdatePwaRow v-if="needUpdate" @close="() => setNeedUpdate(false)" />

    <Layout.Header :need-update="layoutHasTopRow" />

    <Layout.LeftSidebar :need-update="layoutHasTopRow" />

    <Layout.RightSidebar :need-update="layoutHasTopRow" />

    <main
      id="app_main"
      class="app_main " :class="[
        route.meta?.game && 'app_main-game-mode',
        layoutStore.hideLeft && 'hideL',
        layoutStore.hideRight && 'hideR',
        layoutHasTopRow && '!pt-[108px] special-class-for-support-parent',
      ]"
    >
      <div class="app_main__wrap h-full" :class="routeClass">
        <RouterView v-if="!$route.meta.showNotFound" />

        <NotFound v-else />
      </div>

      <Layout.MobileNavigation />
    </main>

    <Layout.Footer />

    <ModalCombiner />
  </div>
</template>

<style lang="scss">
.app {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }

  &_content {
    flex-grow: 1;
  }

  &_main {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 70px 300px 0;
    overflow-x: hidden;

    &.hideR {
      padding-right: 0;

      @include _1399 {
        padding-right: 300px;
      }

      @include _1279 {
        padding-right: 0;

      }
    }

    &-game-mode {
      @media screen and (max-width: 1599px) {
        padding-right: 0;

        &.hideR {
          padding-right: 300px;
        }
      }

      @include _1279 {
        &.hideR {
          padding-right: 0;
        }
      }
    }

    @include _1399 {
      padding-right: 0;
    }

    @media screen and (min-width: 1400px) and (max-width: 1919px) {
      &.hideL {
        padding-left: 0;
      }
    }

    @media screen and (min-width: 1280px) and (max-width: 1399px) {
      &.hideL {
        padding-left: 0;
      }
    }

    @include _1279 {
      padding-left: 0;
      padding-right: 0;
    }

    @include _989 {
      min-height: initial;
    }

    &__wrap {
      width: 100%;
      flex-grow: 1;

      .container {
        max-width: 1320px;
        padding: 30px 30px 60px;
        width: 100%;
        margin: 0 auto;

        @include _989 {
          padding: 12px 12px 60px;
        }
      }
    }
  }

  &-header {
    color: var(--white);
  }
}
</style>
