export type UserRole = 'USER' | 'MODERATOR' | 'ADMINISTRATOR' | 'YOUTUBER'

export interface Ban {
  banName: string
  createdAt: string
  durationInSeconds: number
  id: string
  message?: {
    attachments: []
    id: string
    text: string
    userId: string
  }
  attachments?: []
  text?: string
  reason: string
  userId?: string
  userIdPerformedAction?: string
}

export enum UserRank {
  NEWCOMER = 'newcomer',
  PLAYER = 'player',
  GAMBLER = 'gambler',
  HIGH_ROLLER = 'high_roller',
}

export type UserVipStatusRankSlug = 'newcomer1' |
'newcomer2' |
'newcomer3' |
'player1' |
'player2' |
'player3' |
'gambler1' |
'gambler2' |
'gambler3' |
'high_roller1' |
'high_roller2' |
'high_roller3'

export interface UserVipStatusPublic extends Omit<UserVipStatus, 'userId'> {}
export interface UserVipStatus {
  score: string
  label: string
  level: 1 | 2 | 3
  slug: UserVipStatusRankSlug
  userId: string
}

export interface User {
  bans: Ban[]
  code: string
  accountStatus: string
  avatar: string
  avatarWebp: string
  centrifugoToken: string
  createdAt: string
  permissions: string[]
  userSeed: {
    clientSeed: string
    nonce: string
    id: string
    requesterType: string
    requesterId: string
    serverSeed: string
    active: boolean
  }
  email: string
  emailVerifiedAt: string
  hasPassword: boolean
  id: string
  login: string
  name: string
  role: UserRole
  profile: {
    address: string | null
    birthDate: string | null
    city: string | null
    country: string | null
    firstName: string | null
    lastName: string | null
    phone: string | null
    state: string | null
    postalCode: string | null
  }
  settings: {
    hideBets: boolean
    streamer: boolean
    convertToDefaultCurrency?: string
    tfa: {
      type: string
    }
    currencies: string[]
  }
  updatedAt: string
  userSocials: UserSocial[]
  vipStatus: UserVipStatus
}

export interface UserSocial {
  meta: string
  name: string
  primary: boolean
  profileLink: string
  provider: 'VK'
  providerId: string
}
