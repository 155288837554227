export const route = {
  path: 'profile',
  name: 'profile',
  component: () => import('./views/Profile.vue'),
  props: true,
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'profile-settings',
      component: () => import('./components/Profile/Sections/Settings.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'security',
      name: 'profile-security',
      component: () => import('./components/Profile/Sections/Security.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'history',
      name: 'profile-history',
      component: () => import('./components/Profile/Sections/Payments.vue'),
      children: [
        {
          path: 'deposit',
          name: 'profile-history-deposit',
          component: () => import('./components/Profile/Table/Deposit.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: 'withdraw',
          name: 'profile-history-withdraw',
          component: () => import('./components/Profile/Table/Withdraws.vue'),
          meta: {
            auth: true,
          },
        },
      ],
    },
  ],

}
