import type { UserModule } from '@/types'

export const install: UserModule = async ({ isClient, app, router }) => {
  if (!isClient)
    return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const VueMatomo = (await import('vue-matomo')).default

  app.use(VueMatomo, {
    host: import.meta.env.VITE_MATOMO_HOST,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
    router,
  })

  window && (window as any)?._paq?.push(['trackPageView'])
}
