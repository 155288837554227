<script setup lang="ts">
import { HeaderCenter } from '@modules/wallet'
import { ProfileMenu, useUserStore } from '@modules/user'
import { useModalLayer } from '@composable/useModal'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import Chat from '@/assets/icon/chat.svg'
import { HeaderElement, LeftMenu } from '@/cypress.types'
import { t, useLocalePath } from '@/plugins/i18n'

import { useLayoutStore } from '@/store/Layout'

interface Props {
  needUpdate: boolean
}

defineProps<Props>()
const route = useRoute()
const localePath = useLocalePath()
const modalLayer = useModalLayer()
const layoutStore = useLayoutStore()
const store = useUserStore()
function clearFix() {
  window.document.body.classList.remove('overflow-hidden')
}
const onOpenLeft = () => layoutStore.setLeftSide('open')
const onCloseLeft = () => layoutStore.setLeftSide('closed')
function onCloseRight() {
  layoutStore.setRightSide('closed')
}
function onOpenRight() {
  layoutStore.setRightSide('open')
}

const onSignIn = () => modalLayer.show('auth', { type: 'signIn' })
const onSignUp = () => modalLayer.show('auth', { type: 'signUp' })

const isCasinoGamePage = computed<boolean>(() => !!route.meta?.casinoGame)
</script>

<template>
  <header id="header" :class="[needUpdate && $style.needUpdate, !layoutStore.isOpenMobileNav && layoutStore.isHideHeaderOn && isCasinoGamePage && $style.isOpenMobileForCasinoGame]" class="header">
    <div
      v-if="!needUpdate"
      class="fixed top-0 left-0 right-0 h-[5px] bg-yellow"
      :style="`width: ${layoutStore.progress?.toFixed(2)}%;`"
      :class="layoutStore.progress === 0 ? '' : $style.transitionBar"
    />

    <div class="header__flex">
      <div class="header__left">
        <button
          v-if="layoutStore.leftOpen" class="header__burger"
          :aria-label="t('main_menu')" :data-cy="LeftMenu.MENU_LEFT_BURGER" @click="onCloseLeft"
        >
          <svg
            class="header__burger__icon w-[22px] h-[22px]"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2H20M2 11H20M2 20H20"
              stroke="#576774"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <button v-else class="header__burger" @click="onOpenLeft">
          <svg
            class="header__burger__icon w-[22px] h-[22px]"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2H20M2 11H20M2 20H20"
              stroke="#576774"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <RouterLink class="header__logo" :aria-label="t('homepage')" :to="localePath('/')" @click="clearFix()">
          <img
            src="/logo-full.png"
            height="40"
            alt=""
            class="header__logo-d"
          >

          <img src="/logo.png" height="40" alt="" class="header__logo-m">
        </RouterLink>
      </div>

      <div v-if="store.isLogged" class="header__center">
        <HeaderCenter />
      </div>

      <!--    Authed    -->
      <div v-if="store.isLogged" class="header__right">
        <ProfileMenu />

        <button
          v-if="layoutStore.rightOpen" class="header__chat"
          :aria-label="t('chat_btn')"
          :data-cy="HeaderElement.CHAT_BUTTON" @click="onCloseRight"
        >
          <Chat name="chat" />
        </button>

        <button
          v-else class="header__chat"
          :aria-label="t('chat_btn')"
          :data-cy="HeaderElement.CHAT_BUTTON" @click="onOpenRight"
        >
          <Chat name="chat" />
        </button>
      </div>

      <!--    Not authed    -->
      <div v-else-if="store.isIdentificationEnd" class="header__right">
        <button
          class="header__enter uppercase"
          :data-cy="HeaderElement.LOGIN_BUTTON"
          @click="onSignIn"
        >
          <span>{{ t('auth.signin') }}</span>
        </button>

        <button
          class="btn-primary uppercase"
          :data-cy="HeaderElement.REGISTER_BUTTON"
          @click="onSignUp"
        >
          <span> {{ t('auth.registration') }}</span>
        </button>

        <button
          v-if="layoutStore.rightOpen"
          :aria-label="t('chat_btn')" class="header__chat" @click="onCloseRight"
        >
          <Chat name="chat" />
        </button>

        <button
          v-else class="header__chat"
          :aria-label="t('chat_btn')" @click="onOpenRight"
        >
          <Chat name="chat" />
        </button>
      </div>
    </div>
  </header>
</template>

<style module lang="scss">
.transitionBar {
  transition: .2s ease-in-out;
}

.needUpdate {
  transform: translateY(38px) !important;
}

.isOpenMobileForCasinoGame {
  @include _767 {
    transform: translateY(-100%) !important;
  }
  @media screen and (max-width: 989px) and (max-height: 600px){
    transform: translateY(-100%) !important;
  }
}
</style>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding: 5px 20px 0 20px;
  height: 70px;
  background: var(--gray);
  box-shadow: 0 4px 4px rgba(12, 14, 16, 0.3);
  position: fixed;
  z-index: 4200;
  left: 0;
  right: 0;
  transform: translateY(0);
  @apply transition-all;

  & .btn-primary {
    font-size: 12px;
    line-height: 12px;
    padding: 14px 38px;

    @include _480 {
      padding: 14px 22px;
    }
  }

  @include _1170 {
    padding: 0 12px;

    .profile {
      &__main-content {
        display: none;
      }
    }
  }

  &__logo {
    & .on-desk {
      display: none;
    }
    & .on-mob {
      display: block;
    }

    &-d {
      @include _600 {
        display: none;
      }
    }
    &-m {
      display: none;

      @include _600 {
        display: block;
      }
    }
  }

  &__burger {
    display: flex;
    align-items: center;

    &__icon {
      path {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--yellow);
        }
      }
    }

    @include _1170 {
      display: none;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    z-index: 2;
  }

  &__center {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    @include _1170 {
      margin-left: auto;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    z-index: 2;
  }

  &__enter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 36px;
    height: 40px;
    border: 1px solid var(--gray-3);
    border-radius: $radius;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: var(--white);
    transition: all 0.3s ease;

    &:hover {
      border: 1px solid var(--yellow);
      color: var(--yellow);
    }
    @include _480 {
      padding: 14px 22px;
    }

    @include _1170 {
      padding: 13px 24px;
    }
  }

  &__register {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 38px;
    height: 40px;
    font-size: 12px;

    @include _1170 {
      padding: 13px 24px;
    }
  }

  &__chat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    /* border: 1px solid var(--gray-3); */
    border-radius: $radius;
    background-color: var(--black);
    transition: all 0.3s ease;
    color: var(--gray-2);

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover {
      /* border: 1px solid var(--yellow); */
      color: var(--yellow);
    }

    @include _1170 {
      display: none;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 400px) {
    padding: 0 12px;
    span {
      font-size: 12px;
    }
  }
}
</style>
