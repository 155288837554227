import type { UserModule } from '@/types'

import 'vue-toastification/dist/index.css'

export const install: UserModule = ({ app, isClient }) => {
  if (isClient) {
    import('vue-toastification').then(module => app.use(module.default, {
      icon: false,
      closeButton: false,
      position: module.POSITION.TOP_RIGHT,
      shareAppContext: true,
    }))
  }
}
