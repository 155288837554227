<script setup lang="ts">
import {
  ref,
  useKeydown,
  useModalLayer,
  useToggle,
} from '@modules/auth/auth.dependencies'
import { useAuthStore } from '@modules/auth'
import {
  useEmailValidation,
} from '@modules/auth/auth.validator'
import { ForgotPasswordScreen } from '@/cypress.types'
import { t } from '@/plugins/i18n'

import SecurityLock from '@/assets/icon/security-lock.svg'
import Close from '@/assets/icon/close.svg'

interface Props {
  // initial state of isSuccess
  payload: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])
const modalLayer = useModalLayer()
const authStore = useAuthStore()
const emailHtmlElement = ref<HTMLInputElement>(document.createElement('input'))

const [isSuccess, _, setIsSuccess] = useToggle(props.payload)
const { value: email, getIsValid } = useEmailValidation(emailHtmlElement)

useKeydown('Enter', onRestoreLinkSend)

const onClose = () => emit('close')
function toggleIsForgot() {
  modalLayer.show('auth', { type: 'signIn' })
}

async function onRestoreLinkSend() {
  const isValid = await getIsValid()

  if (isValid) {
    const success = await authStore.restorePasswordSend({ email: email.value })

    setIsSuccess(!!success)
  }
}
</script>

<template>
  <div class="modal_content" :data-cy="ForgotPasswordScreen.FORGOT_PASSWORD_WINDOW" @click.stop>
    <header class="header-popup">
      <SecurityLock class="header-popup__svg w-[22px] h-[22px] yellow" />

      <h3 class="header-popup__title">
        {{ t("auth.password_repairing") }}
      </h3>

      <button class="btn_close" @click="onClose">
        <Close class="w-5 h-5" />
      </button>
    </header>

    <div class="modal_body" :data-cy="ForgotPasswordScreen.SUCCESS_WINDOW">
      <template v-if="isSuccess === true">
        <div class="text white">
          {{ t("auth.password_forgot_sended_desc") }}
        </div>

        <div class="form">
          <div class="form__content">
            <button class="form__btn btn-primary" :data-cy="ForgotPasswordScreen.SUCCESS_CLOSE_BUTTON" @click="onClose">
              <span>{{ t("close") }}</span>
            </button>
          </div>
        </div>
      </template>

      <!--    Forgot password    -->
      <template v-else>
        <div class="text white">
          <p>
            {{ t("auth.password_forgot_mail") }}
          </p>
        </div>

        <div class="form">
          <div class="form__content">
            <label class="form__label" for="login">
              <input
                id="login"
                ref="emailHtmlElement"
                v-model="email"
                :data-cy="ForgotPasswordScreen.FORGOT_EMAIL_INPUT"
                name="login"
                class="form__input"
                type="email"
                :placeholder="t('auth.email')"
                minlength="4"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
              >
            </label>

            <button
              :data-cy="ForgotPasswordScreen.FORGOT_SEND_REQUEST_BUTTON"
              class="form__btn btn-primary"
              :disabled="authStore.loading"
              @click="onRestoreLinkSend"
            >
              <span>{{ t("auth.password_repair") }}</span>
            </button>

            <div class="text light text-12">
              <p>{{ t("auth.password_forgot_desc") }}</p>
            </div>

            <span class="form__line" />

            <button class="form__forgot" @click="toggleIsForgot">
              {{ t("back_to_prev") }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.modal {
  &_content {
    padding: 20px;
    max-width: 360px;
    @include _360 {
      max-width: 100%;
    }
  }
  &__flex {
    display: flex;
  }
}

.light {
  color: var(--light);
}

.header-popup {
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid var(--gray-4);

  &__svg {
    margin: 0 12px 0 0;
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--white);
    text-transform: uppercase;
  }
}

.close {
  margin-left: auto;

  &:hover {
    color: var(--yellow);
  }
}

.login-via {
  padding: 20px 30px 26px 30px;
  @include _360 {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__auth {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: $radius;
    background: var(--gray-4);
    transition: all 0.3s ease;
    color: var(--white);

    &:hover {
      background: var(--yellow);
      color: var(--black-2);
    }
  }
}

.or {
  position: relative;

  &__line {
    height: 1px;
    width: 100%;
    border-top: 1px solid #293036;
  }

  &__content {
    position: absolute;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__text {
    padding: 0 13px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: var(--gray-4);
    background: var(--gray-5);
  }
}

.form {
  padding: 24px 0 0;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--gray-2);
  }

  &__input {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 50px;
    border: 1px solid var(--gray-4);
    outline: none;
    border-radius: $radius;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--white);
    background-color: var(--black-2);
    transition: all 0.3s ease;

    &:hover {
      border: 1px solid var(--gray-2);
    }

    &:valid:not(:placeholder-shown) {
      border: 1px solid var(--green-3);
    }

    &:invalid:not(:placeholder-shown) {
      border: 1px solid var(--red-7);
    }

    &::placeholder {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: var(--gray-2);
    }
  }

  &__btn {
    height: 50px;
    width: 100%;
    text-transform: uppercase;
  }

  &__confirmation {
    margin: 0 auto;

    p {
      max-width: 295px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: var(--white);
      text-align: center;
    }

    a {
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
      color: var(--yellow);
      text-decoration: underline;
    }
  }

  &__line {
    width: 100%;
    border-top: 1px solid var(--gray-4);
  }

  &__forgot {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: var(--gray-2);
    text-align: center;
    transition: all 0.3s ease;

    &:hover {
      color: var(--yellow);
    }
  }
}
</style>
