import { httpClient } from '@modules/auth/auth.dependencies'
import type { ServiceResponse } from '@modules/auth/auth.dependencies'
import type { AuthDtoRestoreRequest, AuthDtoRestoreSendRequest, AuthDtoSignInRequest, AuthDtoSignInResponse, AuthDtoSignInTfaGoogleRequest, AuthDtoSignInVkRequest, AuthDtoSignUpRequest, AuthDtoTfaResponse } from './auth.dto'

export function signInService(body: AuthDtoSignInRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/sign-in', body)
}

export function refreshSession(): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/refresh-session')
}

export function signUpService(body: AuthDtoSignUpRequest): Promise<ServiceResponse<AuthDtoSignInResponse>> {
  return httpClient.post('auth/sign-up', body)
}

export function authVkGetLinkService(): Promise<ServiceResponse<any>> {
  return httpClient.get('auth/social/vk')
}

export function authSteamGetLinkService(): Promise<ServiceResponse<any>> {
  return httpClient.get('auth/social/steam')
}

export function authVkSendLoginService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/vk/login', payload)
}
export function authVkSendRegisterService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/vk/register', payload)
}

export function authGoogleLoginService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/google/login', payload)
}

export function authGoogleRegisterService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/google/register', payload)
}

export function linkGoogleService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/google/link', payload)
}

export function linkVkService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/vk/link', payload)
}

export function linkSteamService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/steam/link', payload)
}

export function authSteamRegisterService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/steam/register', payload)
}

export function authSteamLoginService(payload: AuthDtoSignInVkRequest): Promise<ServiceResponse<AuthDtoSignInResponse & AuthDtoTfaResponse>> {
  return httpClient.post('auth/social/steam/login', payload)
}

export function authGoogleGetLinkService(): Promise<ServiceResponse<any>> {
  return httpClient.get('auth/social/google')
}

export function signOutService(): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/sign-out')
}

export function restorePasswordRequest(payload: AuthDtoRestoreSendRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/restore-password/request', payload)
}

export function restorePassword(payload: AuthDtoRestoreRequest): Promise<ServiceResponse<any>> {
  return httpClient.post('auth/restore-password', payload)
}

export function tfaSignInGoogle(payload: AuthDtoSignInTfaGoogleRequest): Promise<ServiceResponse<AuthDtoSignInResponse>> {
  return httpClient.post('auth/2fa/google/sign-in', payload)
}
