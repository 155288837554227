<script setup lang="ts">
import { isSSR } from '@helpers/utils'
import { useUserStore } from '@modules/user'
import { useRouter } from 'vue-router'
import { useSuccess } from '@composable/useToast'
import { useState } from '@composable/useState'
import { onBeforeUnmount } from 'vue'
import Close from '@/assets/icon/close.svg'
import { NotificationsElements } from '@/cypress.types'
import { t, useLocalePath } from '@/plugins/i18n'

const emit = defineEmits(['close'])
const [loading, setLoading] = useState<boolean>(false)
const localePath = useLocalePath()
const router = useRouter()
const userStore = useUserStore()
function close() {
  emit('close')
}

async function onVerify() {
  setLoading(true)

  if (userStore.user?.email) {
    const isSuccess = await userStore.verifyEmailSend()

    if (isSuccess)
      useSuccess(t('notify.email_send'))
  }
  else {
    await router.push(localePath('profile/security'))
  }

  setLoading(false)
  close()
}

function setup() {
  if (isSSR())
    return

  document.body.classList.add('app-update-need')
}

setup()

onBeforeUnmount(() => document.body.classList.remove('app-update-need'))
</script>

<template>
  <div class="fixed top-0 left-0 right-0 z-[4200] py-1 px-3 bg-[#E5A72E] flex items-center justify-left screen-375:justify-center gap-3 h-[38px]" :data-cy="NotificationsElements.VERIFY_EMAIL_ROW">
    <Close name="close" :data-cy="NotificationsElements.TOP_EMAIL_ROW_CLOSE_BUTTON" class="transition-all cursor-pointer text-[#ffffff] opacity-50 hover:opacity-100 absolute right-5 top-1/2 -translate-y-1/2" @click="close" />

    <span class="text-14 font-700 text-white">
      {{ t('userModule.verifyEmailRow.title') }}
    </span>

    <button :disabled="loading" :data-cy="NotificationsElements.TOP_EMAIL_ROW_BUTTON" class="w-[90px] screen-375:w-[114px] rounded-[4px] p-2 flex items-center justify-center bg-[rgba(0,0,0,0.30)] text-white hover:text-yellow" @click="onVerify">
      <span class="text-12 font-700">
        {{ t('userModule.verifyEmailRow.verify') }}
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>
