<script setup lang="ts">
import { useLocalePath, useRouter, useUserStore } from '@modules/auth/auth.dependencies'
import { useAuthStore } from '@modules/auth'
import { UserAvatar } from '@modules/user'
import UserRankIcon from '@modules/user/components/UserRankIcon.vue'
import { getUserRank } from '@modules/user/user.helpers'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Username from '@components/Interface/Username.vue'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import { useUserRank } from '@modules/club/composables/useUserRank'
import Logout from '@/assets/icon/logout.svg'
import Gift from '@/assets/icon/gift.svg'
import History from '@/assets/icon/history.svg'
import Profile from '@/assets/icon/profile.svg'
import { ProfileElements } from '@/cypress.types'
import { t } from '@/plugins/i18n'

type RankComponent = 'rank-star' | 'rank-star-2' | 'rank-star-3'
interface RankBar<T> { icon: T; class: string; isRank?: boolean }

const emit = defineEmits(['close'])
const gracefulDegradation = useGracefulDegradation()
// const modalLayer = useModalLayer()
const router = useRouter()
const route = useRoute()
const localePath = useLocalePath()
const store = useAuthStore()
const userStore = useUserStore()
const userRankQuery = useUserRank()
const rankProgress = computed<number>(() => {
  const userHasScore = Number(userRankQuery.data.value?.score || 0)
  const userNeedScore = Number(userRankQuery.data.value?.status?.features?.find(item => item.code === 'score')?.meta?.fields?.find(field => field.field === 'score')?.value || 0)

  return userHasScore / userNeedScore * 100
})
const currentRank = computed<RankBar<RankComponent>>(() => {
  return getCurrentRank(userRankQuery.data.value?.status?.level || 1)
})

const nextRank = computed<RankBar<any>>(() => {
  if (userRankQuery.data.value?.status?.slug === 'high_roller3')
    return { icon: 'empty', class: '' }

  if (userRankQuery.data.value?.status?.level === 3) {
    const ranks = ['newcomer', 'player', 'gambler', 'high_roller']

    const currentRankIndex = ranks.findIndex(item => item === getUserRank(userRankQuery.data.value?.status?.slug || 'newcomer1'))
    const nextRankIndex = currentRankIndex + 1

    return { icon: ranks[Math.min(nextRankIndex, 3)], class: '', isRank: true }
  }

  else { return getCurrentRank(Number(userRankQuery.data.value?.status?.level || 1) + 1) }
})

function getCurrentRank(level: number): RankBar<RankComponent> {
  const levels: Record<number, RankComponent> = {
    1: 'rank-star',
    2: 'rank-star-2',
    3: 'rank-star-3',
  }
  const classes: Record<number, string> = {
    1: 'min-w-[14px] w-[14px]',
    2: 'min-w-[23px] w-[23px]',
    3: 'min-w-[30px] w-[30px]',
  }

  return { icon: levels[level], class: classes[level] }
}

const onClose = () => emit('close')

async function onLogOut() {
  onClose()

  await store.signOut()

  const isAuthPage = route.meta?.auth

  if (isAuthPage)
    await router.push('/')
}

// function onWithdraw() {
//   onClose()
//   return modalLayer.show('payments', {
//     type: 'payout',
//   })
// }
</script>

<template>
  <nav :class="$style.nav">
    <div :class="$style.rank">
      <div class="flex items-center gap-2">
        <UserAvatar type="normal" />

        <div class="flex flex-col gap-1 overflow-hidden">
          <Username :name="userStore.user?.name || ''" />

          <div v-if="!gracefulDegradation.checkServiceForOffline('vip')" class="flex items-center gap-1">
            <UserRankIcon :rank="getUserRank(userRankQuery.data.value?.status?.slug || 'newcomer1')" />

            <span class="text-14 leading-12 text-gray-2">
              {{ userRankQuery.data.value?.status?.name }}
            </span>
          </div>
        </div>
      </div>

      <template v-if="!gracefulDegradation.checkServiceForOffline('vip')">
        <div v-if="!userRankQuery.data.value?.status" class="h-4 w-full filled" />

        <div v-else class="flex items-center gap-1 justify-between w-full">
          <div :class="currentRank.class" class="flex items-center">
            <img :src="`/img/${currentRank.icon}.svg`" alt="123" class="text-yellow h-[14px]" :class="currentRank.class">
          </div>

          <div class="w-full h-2 rounded-[25px] bg-[rgba(16,19,21,0.50)] overflow-hidden">
            <div class="h-2 rounded-[25px] bg-yellow max-w-full transition" :style="`width: ${rankProgress}%`" />
          </div>

          <div :class="nextRank.class" class="flex items-center">
            <img v-if="nextRank.icon !== 'empty' && !nextRank.isRank" :src="`/img/${nextRank.icon}.svg`" alt="123" class="text-yellow h-[14px]" :class="nextRank.class">

            <UserRankIcon v-else-if="nextRank.isRank" :rank="nextRank.icon" />
          </div>
        </div>
      </template>
    </div>

    <RouterLink :class="$style.item" :to="localePath('/profile')" :data-cy="ProfileElements.PROFILE_MENU_ELEMENT_MAIN" @click="onClose">
      <Profile name="profile" />

      <span :class="$style.text">{{ t('profile.title') }}</span>
    </RouterLink>

    <!--    <button -->
    <!--      :class="$style.item" -->
    <!--      :data-cy="ProfileElements.PROFILE_MENU_ELEMENT_WITHDRAW" -->
    <!--      @click="onWithdraw" -->
    <!--    > -->
    <!--      <Money name="money" /> -->

    <!--      <span :class="$style.text">{{ t('withdraw_funds') }}</span> -->
    <!--    </button> -->

    <RouterLink :class="$style.item" :to="localePath('/profile/history/deposit')" :data-cy="ProfileElements.PROFILE_MENU_ELEMENT_HISTORY" @click="onClose">
      <History name="history" />

      <span :class="$style.text">{{ t('profile.history') }}</span>
    </RouterLink>

    <RouterLink :class="$style.item" :to="localePath('/bonus')" :data-cy="ProfileElements.PROFILE_MENU_ELEMENT_BONUS" @click="onClose">
      <Gift name="gift" />

      <span :class="$style.text">{{ t('bonuses.title') }}</span>
    </RouterLink>

    <button :class="$style.item" :data-cy="ProfileElements.PROFILE_MENU_ELEMENT_LOGOUT" @click="onLogOut">
      <Logout name="logout" />

      <span :class="$style.text">{{ t('signout') }}</span>
    </button>
  </nav>
</template>

<style module lang="scss">
.nav {
  & > *:not(:last-child) {
    border-bottom: 1px solid var(--dark);
  }
}

.rank {
  @apply p-3 flex flex-col gap-3;
}

.item {
  @apply w-full justify-start flex items-center gap-3 h-[46px] px-3 text-gray-2;

  &:hover {
    @apply text-yellow bg-gray;

    & .text {
      @apply text-yellow;
    }

  }
}

.text {
  @apply text-14 font-700 text-white;
}
</style>
