import { useMutation, useQueryClient } from '@tanstack/vue-query'

import { markGameFavorite } from '@modules/casino/casino.service'
import type { GameEntity, GameListEntity } from '@modules/casino/casino.model'
import { QUERY_RESOURSES } from '@modules/casino/casino.constants'

export function useMarkGameFavorite() {
  const queryClient = useQueryClient()

  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: (game: GameEntity | GameListEntity) => markGameFavorite(game.id),
    onSuccess: async (data, game) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERY_RESOURSES.GET_GAME_RESOURSE, game.id],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_RESOURSES.GET_GAMES_RESOURSE, {
            type: game.type,
          }],
        }),
        queryClient.refetchQueries({
          queryKey: [QUERY_RESOURSES.GET_GAMES_RESOURSE],
          type: 'all',
        }),
      ])
    },
  })

  return {
    isLoading,
    isSuccess,
    mutateAsync,
  }
}
