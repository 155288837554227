export enum MethodsCacheKey {
  value = 'methods-cache-key',
}

export enum WalletCacheKey {
  value = 'wallet-cache-key',
}
export enum HistoryDepositCacheKey {
  value = 'history-deposit-cache-key',
}
export enum HistoryWithdrawCacheKey {
  value = 'history-withdraw-cache-key',
}
export enum DepositTicketsCacheKey {
  value = 'deposit-tickets-cache-key',
}

export interface RubPayBank {
  id: number
  bank_name: string
}
