<script setup lang="ts">
import { computed, ref } from 'vue'

import { vOnClickOutside } from '@vueuse/components'

import type { GameProvider } from '@modules/casino/casino.model'
import Search1 from '@/assets/icon/search1.svg'
import ProvidersFilter from '@/assets/icon/providers-filter.svg'
import Chevron from '@/assets/icon/chevron.svg'
import { CentralSection } from '@/cypress.types'

import { t, useLocalePath } from '@/plugins/i18n'

const props = withDefaults(defineProps<{
  providers: GameProvider[]
}>(), {
  providers: () => [],
})
const localePath = useLocalePath()
const showProviders = ref(false)
const search = ref('')

const list = computed(() => {
  const letter = props.providers.map(x => x.label[0].toUpperCase()).sort()
  const letters = [...new Set(letter)]
  const arr: { letter: string; name: string }[] = []
  letters.map((l) => {
    return props.providers.filter(x => x.label.toUpperCase().startsWith(l)).map((p) => {
      return arr.push({
        letter: !arr.find(el => el.letter === l) ? l : '',
        name: p.label,
      })
    })
  })
  return arr.filter(el => el.name.toLocaleLowerCase().includes(search.value.toLocaleLowerCase()))
})

const maxHeight = computed(() => {
  return (Number(props.providers.length + 1) * 46) / 2
})
const provider = ref<HTMLElement>()
function toggle() {
  showProviders.value = !showProviders.value
  const e = document.getElementById('root')
  if (e) {
    const rect = provider.value!.getBoundingClientRect()
    const target = e.scrollTop + rect.top - 70
    if (window.innerWidth <= 980)
      e.scrollTo({ top: target, behavior: 'smooth' })
  }
}
</script>

<template>
  <div ref="provider" v-on-click-outside="() => (showProviders = false)" class="providers_dd">
    <button class="providers_dd__top" :aria-label="t('games-grid.providers.title')" :data-cy="CentralSection.PROVIDERS_CENTRAL_DROPDOWN" @click="toggle()">
      <ProvidersFilter name="providers-filter" size="18" />

      <span>{{ t("games-grid.providers.title") }}</span>

      <Chevron name="chevron" size="16" class="arrow" />
    </button>

    <div class="providers_dd__body" :class="{ open: showProviders }" :data-cy="CentralSection.PROVIDERS_BODY_DROPDOWN">
      <div class="providers_dd__body-header">
        <p>
          {{ t("games-grid.providers.title") }}
        </p>

        <button @click="showProviders = false">
          <svg viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 5L5.5 0.999999L1 5" stroke="currentColor" stroke-width="1.6" stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <div class="providers_dd__body-search">
        <label>
          <Search1 name="search1" size="20" />

          <input v-model="search" :placeholder="t('games-grid.providers.search')" :data-cy="CentralSection.PROVIDERS_SEARCH_INPUT">
        </label>
      </div>

      <div class="providers_dd__body-list">
        <div v-if="list.length" class="providers_dd__body-list_inner" :style="{ 'max-height': `${maxHeight}px` }">
          <div v-for="item in list" :key="`${item.name}`" class="providers_dd__body-list_item" :data-cy="CentralSection.PROVIDERS_NAME_BODY + item.name">
            <div class="providers_dd__body-list_item-letter">
              {{ item.letter }}
            </div>

            <RouterLink
              :key="`l-${item.letter}-${item.name}`" :to="localePath(`/all-games/${item.name}`)"
              class="providers_dd__body-list_item-name capitalize"
              :class="{
                active: item.name.toLocaleLowerCase() === $route.params?.name,
              }"
            >
              {{ item.name }}
            </RouterLink>
          </div>
        </div>

        <div v-else class="providers_dd__body-list_inner">
          <div class="providers_dd__body-empty">
            {{ t("games-grid.providers.empty") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@mixin mobile {
  @media screen and (max-width: 760px) {
    @content;
  }
}
.providers {
  &_dd {
    color: var(--gray-2);
    font-weight: 500;
    display: flex;
    z-index: 1000;
    position: relative;

    &__top {
      background: var(--gray);
      color: var(--gray-2);

      border-radius: 4px;
      height: 48px;
      display: flex;
      align-items: center;
      min-width: 180px;
      gap: 8px;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      transition: 0.3s;

      @include mobile {
        background: var(--gray);
      }

      span {
        flex-grow: 1;
        text-align: left;
      }
    }

    &__body {
      display: grid;
      color: var(--white);
      gap: 12px;
      background: var(--gray-5);
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 20px 0 0;
      width: 480px;
      position: absolute;
      top: 12px;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      z-index: 1050;

      &-header {
        margin-bottom: 6px;
      }

      &.open {
        top: 0;
        opacity: 1;
        visibility: visible;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        padding: 0 20px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--gray-5);
          border-radius: 4px;
          width: 24px;
          height: 24px;
          transition: 0.3s;
          color: var(--gray-2);

          svg {
            width: 12px;
            height: 8px;
          }
        }
      }

      &-search {
        padding: 0 20px;

        label {
          position: relative;
        }

        svg {
          color: var(--gray-2);
          margin: 0 12px;
          position: absolute;
          top: 0;
          left: 0;
        }

        input {
          background: var(--black);
          color: var(--white);
          border-radius: 6px;
          height: 48px;
          width: 100%;
          padding-left: 43px;
          font-weight: 700;
          font-size: 14px;
          border-width: 0 !important;

          &::placeholder {
            color: var(--gray-2);
          }
        }
      }

      &-empty {
        color: var(--gray-2);
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        padding: 28px 0;
      }

      &-list {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 20px 20px;

        &_inner {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 4px;
          column-gap: 8px;
        }

        &_item {
          display: flex;
          gap: 6px;
          // width: calc(50% - 4px);
          max-width: calc(50% - 6px);

          &-letter {
            display: flex;
            align-items: center;
            width: 20px;
            height: 42px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            background: var(--yellow);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          &-name {
            display: block;
            height: 42px;
            color: inherit;
            font-weight: inherit;
            padding: 12px;
            border-radius: 4px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
              background: var(--gray-4);
            }

            &.active {
              background: var(--gray-4);
              color: var(--yellow);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .providers {
    &_dd {
      &__body {
        &-search {
          input {
            font-size: 16px;

            &:placeholder-shown {
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .providers {
    &_dd {
      position: initial;

      &__top {
        width: 48px;
        min-width: 48px;
        height: 48px;
        padding: 11px;
        border-radius: 6px;
        background-color: var(--gray);

        span,
        .arrow {
          display: none;
        }
      }

      &__body {
        left: 0;
        right: 0;
        width: auto;
      }
    }
  }
}
</style>
