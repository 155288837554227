export const route = [
  {
    name: 'bonus',
    path: 'bonus',
    component: () => import('./views/Bonus.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    name: 'bonus-vk',
    path: 'bonus/vk',
    component: () => import('./views/Bonus.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    name: 'bonus-tg',
    path: 'bonus/tg',
    component: () => import('./views/Bonus.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  
]
