import { isSSR } from '@helpers/utils'
import { useState } from '@composable/useState'

const [isSupport, setIsSupport] = useState<boolean>(false)

export function setSupportWebp(value: boolean) {
  if (isSSR())
    return false

  document?.body?.setAttribute('data-webp', String(value))
  setIsSupport(value)
}

export function getSupportWebp() {
  if (isSSR())
    return false

  return document?.body?.getAttribute('data-webp') === 'true' || isSupport.value
}

export function getWebp(byDefault: string) {
  return getSupportWebp() ? 'webp' : byDefault
}
