import { UserVipStatus } from "@/modules/user/user.model"

export interface TowerGame {
  active: boolean
  amount: string
  coefficient: string
  createdAt: string
  currencyId: string
  history: []
  minesPositions?: Array<Array<number>>
  id: string
  name: string
  level: string
  profit: string
  rtp: number
  step: number
  total: string
}

export interface TowerGameHistory {
  amount: string
  coefficient: string
  createdAt: string
  currencyId: string
  id: string
  profit: string
  user?: {
    avatar: string
    id: string
    name: string
    vipStatus?: UserVipStatus
  }
}

export enum TowerGameLevels {
  EASY = 'EASY',
  HARD = 'HARD',
  MEDIUM = 'MEDIUM',
  NIGHTMARE = 'NIGHTMARE',
  NORMAL = 'NORMAL',
}
