import type { Currency } from '@modules/wallet/wallet.model'
import { computed, useQuery } from '../wallet.dependencies'
import type { Ref } from '../wallet.dependencies'
import { getCurrencies } from '../wallet.service'

interface UseCurrencies {
  data: Ref<Array<Currency> | undefined>
  balanceEnabled: Ref<Array<Currency>>
  fiat: Ref<Array<Currency>>
  crypto: Ref<Array<Currency>>
  enabled: Ref<Array<Currency>>
  isFetching: Ref<boolean>

  getCurrencyById: (id: string) => Currency | undefined
}

export function useCurrencies(): UseCurrencies {
  const query = useQuery<Array<Currency>>(['currencies-query-cache'], async () => {
    const serviceResponse = await getCurrencies()

    return serviceResponse.response
  }, { refetchOnWindowFocus: false, staleTime: 60000 * 20, cacheTime: 60000 * 20 })

  const data = computed<Array<Currency>>(() => {
    // ?.filter(currency => currency.visible)
    return query.data?.value || []
  })

  const fiat = computed<Currency[]>(() => {
    return data.value?.filter(currency => !currency.network)
  })

  const crypto = computed<Currency[]>(() => {
    return data.value?.filter(currency => !!currency.network)
  })

  const balanceEnabled = computed<Currency[]>(() => {
    return data.value?.filter(currency => currency.isBalanceEnabled)
  })

  const enabled = computed<Currency[]>(() => {
    return data.value?.filter(currency => currency.enabled)
  })

  const getCurrencyById = (id: string) => {
    return query.data.value?.find(currency => currency.id === id)
  }

  return { getCurrencyById, data, enabled, crypto, fiat, balanceEnabled, isFetching: query.isFetching }
}
