<script setup lang="ts">
import { getFormatBalance, getIconSymbolName } from '@modules/wallet/wallet.dependencies'
import type { Currency } from '@modules/wallet/wallet.model'
import { useExchanger } from '@modules/wallet'
import MethodIcon from '@modules/wallet/component/MethodIcon.vue'
import { FinanceSelectorElements } from '@/cypress.types'

interface Props {
  currency: Currency
  amount: number
  bonusAmount: number
}
defineProps<Props>()

const Exchanger = useExchanger()
</script>

<template>
  <div class="b-wallet-currency">
    <MethodIcon
      :name="`logo-${currency.name.toLocaleLowerCase()}-sm`"
      class="b-wallet-currency__icon"
    />

    <div class="b-wallet-currency-text">
      <span :data-cy="FinanceSelectorElements.FINANCE_CURRENCY_SELECT + currency.name">{{
        currency.name
      }}</span>

      <span
        :class="
          getIconSymbolName(
            Exchanger.getViewCurrencyEnabled()
              ? Exchanger.getViewOrUserCurrencyIso()
              : currency.name || 'RUB',
          )
        "
      >
        {{
          getFormatBalance(
            Exchanger.exchangeToViewCurrency(amount + bonusAmount, currency.id),
            false,
          )
        }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.b-wallet-currency {
  display: flex;
  padding: 6px 12px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;

  &:hover {
    border-radius: $radius;
    background-color: var(--gray-1);
  }

  &-text {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: var(--gray-2);
  }
}
</style>
