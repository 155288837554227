import { useInfiniteQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import { QUERY_RESOURSES } from '@modules/casino/casino.constants'
import { fetchGames } from '@modules/casino/casino.service'
import { isQueryEnabledByDefault } from '@modules/casino/casino.dependencies'

import type { GetGamesParams, GetGamesResponse } from '@modules/casino/casino.model'

export function useGetGames(queryParams: GetGamesParams, isDisabled = false) {
  const finalEnabled = computed(() => isQueryEnabledByDefault(!isDisabled))

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery(
    [QUERY_RESOURSES.GET_GAMES_RESOURSE, {
      currentPage: queryParams.currentPage ?? 1,
      perPage: queryParams.perPage,
      search: queryParams.search,
      provider: queryParams.provider,
      type: queryParams.type?.join(','),
      exclude: queryParams.exclude?.join(','),
    }],
    ({ pageParam = queryParams.currentPage }) => fetchGames(queryParams, pageParam),
    {
      getNextPageParam: ({ response }: GetGamesResponse) => {
        if (response.meta.currentPage < response.meta.lastPage)
          return response.meta.currentPage + 1
      },
      staleTime: Number.POSITIVE_INFINITY,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: finalEnabled,
    },
  )

  return {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  }
}
