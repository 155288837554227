import type { ViteSSGContext } from 'vite-ssg'
import type { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios'
import type { ServiceError, ServiceNotifyError, ServiceRedirect, ServiceResponse, ServiceValidationError } from '@api/plugins/httpClient'

import axios from 'axios'
import { httpClient } from '@api/plugins/httpClient'
import { isNeedToRefreshSession } from '@modules/auth/auth.helpers'
import { useAuthStore } from '@modules/auth'
import { addUtmTags, isSSR } from '@helpers/utils'
import { Events } from '@/config/events'
import { locale } from '@/plugins/i18n'
import { useBus } from '@/composable/useBus'

export { httpClient } from './plugins/httpClient'
export type { ServiceResponse, ServicePaginatedResponse } from './plugins/httpClient'

const refreshSessionPromise: { value: Promise<any> | null } = { value: null }

export function install(ctx: ViteSSGContext) {
  const bus = useBus()

  httpClient.interceptors.response.use((response: AxiosResponse<ServiceResponse<any> | any>) => {
    // if this is okay - extract response.data
    if (response?.data?.success)
      return Promise.resolve(response.data)

    return response
  }, (error: AxiosError<ServiceResponse<ServiceValidationError> | ServiceError | ServiceRedirect | ServiceResponse<ServiceNotifyError> | any>) => {
    if (ctx.isClient) {
      const statusCode = error.response?.status

      // not authed
      if (statusCode === 401) {
        bus.emit(Events.PERFORM_CLEAR_USER)

        return
      }

      // session expired
      if (error.response?.status === 440) {
        bus.emit(Events.REFRESH_USER_SESSION)

        return
      }

      // if this is redirect
      if (statusCode === 307) {
        window.location.href = error.response?.data?.redirect || ''

        return
      }

      if (error?.response?.status?.toString()?.startsWith('5'))
        return

      // if this is regular error
      if (axios.isAxiosError(error))
        return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  httpClient.interceptors.request.use(async (request) => {
    if (isSSR())
      return request

    request.headers.Lang = locale.value

    if (request.url === 'auth/refresh-session')
      return request

    const headers = addUtmTags()
    if (Object.keys(headers).length > 0)
      request.headers = { ...request.headers, ...headers } as AxiosRequestHeaders

    if (isNeedToRefreshSession() && !refreshSessionPromise.value) {
      const authStore = useAuthStore()

      refreshSessionPromise.value = authStore.refreshSession()
    }

    if (refreshSessionPromise.value) {
      await refreshSessionPromise.value

      refreshSessionPromise.value = null
    }

    return request
  })
}
