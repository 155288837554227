import { httpClient } from '@modules/casino/casino.dependencies'

import type {
  GameEntity,
  GameSessionParams,
  GameSessionResponse,
  GetGamesParams, GetGamesResponse,
} from '@modules/casino/casino.model'
import type { AxiosResponse } from 'axios'
import type { ServiceResponse } from '@/api'

export function fetchGames({ perPage, search, provider, type, exclude }: GetGamesParams, currentPage: number): Promise<GetGamesResponse> {
  return httpClient.get('/softswiss/games',
    {
      params: {
        currentPage,
        perPage,
        search,
        provider,
        type,
        exclude,
      },
    },
  )
}

export function fetchGameById(gameId: string): Promise<ServiceResponse<GameEntity>> {
  return httpClient.get(`/softswiss/games/${gameId}`)
}

export async function markGameFavorite(id: string): Promise<ServiceResponse<any>> {
  return await httpClient.patch(`/softswiss/games/${id}`)
}

export async function initGameSession({ id, isDemo = false }: GameSessionParams): Promise<AxiosResponse<GameSessionResponse>> {
  return httpClient.post(`/softswiss/games/${id}${isDemo ? '/demo' : ''}`)
}
