import { isBeta } from '@/config/env'

export const route = isBeta
  ? []
  : [{
      path: 'referral',
      name: 'referral',
      children: [
        {
          path: '',
          index: true,
          name: 'referral-main',
          component: () => import('./views/index.vue'),
          children: [
            {
              path: 'promo',
              name: 'referral-main-guests',
              component: () => import('./views/Guests.vue'),
            },
            {
              path: 'public',
              name: 'referral-main-public',
              component: () => import('./views/Public.vue'),
              meta: {
                type: 'public',
                auth: true,
              },
              children: [
                {
                  path: 'info',
                  name: 'referral-public-info',
                  component: () => import('./views/PublicInfo.vue'),
                  meta: {
                    auth: true,
                  },
                },
                {
                  path: 'campaigns',
                  name: 'referral-public-campaigns',
                  component: () => import('./views/PublicCampaigns.vue'),
                  meta: {
                    type: 'public',
                    auth: true,
                  },
                },
              ],
            },
            {
              path: 'revshare',
              name: 'referral-main-revshare',
              component: () => import('./views/ReferralMainRevShare/ReferralMainRevShare.vue'),
              meta: {
                type: 'revShare',
                auth: true,
              },
              children: [
                {
                  path: 'info',
                  name: 'referral-revshare-info',
                  component: () => import('./views/ReferralRevShareInfoView/ReferralRevShareInfoView.vue'),
                  meta: {
                    auth: true,
                  },
                },
                {
                  path: 'campaigns',
                  name: 'referral-revshare-campaigns',
                  component: () => import('./views/ReferralRevShareCampaignsView/ReferralRevShareCampaignsView.vue'),
                  meta: {
                    type: 'revShare',
                    auth: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
      // {
      //   name: 'bonus-rangs',
      //   path: 'bonus-rangs',
      //   props: true,
      //   component: () => import('./views/Spinner.vue'),
      // },
    ]
