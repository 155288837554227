import type { FpjsVueOptions } from '@fingerprintjs/fingerprintjs-pro-vue-v3'
import { useVisitorData as useVisitorDataFp } from '@fingerprintjs/fingerprintjs-pro-vue-v3'

import type { UserModule } from '@/types'
import { FingerprintKeys, isProduction } from '@/config/env'

export const install: UserModule = (ctx) => {
  if (ctx.isClient && isProduction) {
    import('@fingerprintjs/fingerprintjs-pro-vue-v3')
      .then((module) => {
        ctx.app.use(module.fpjsPlugin, {
          loadOptions: {
            apiKey: FingerprintKeys.apiKey,
            region: 'eu',
            endpoint: FingerprintKeys.endpoint,
            scriptUrlPattern: `https://fpsfps.bandana.casino/web/v3/${FingerprintKeys.apiKey}/loader_v3.9.0.js`,
          },
        } as FpjsVueOptions)
      })
  }
}

export function useVisitorDataPlugin() {
  return isProduction
    ? useVisitorDataFp(
      { extendedResult: true },
      { immediate: false },
    )
    : { getData: async () => undefined }
}
