<script setup lang="ts">
import { Tabs } from '@components/Interface'
import { useState } from '@composable/useState'
import { tabsConfig } from '@modules/live/live.config'
import { computed, ref } from 'vue'
import LiveHeader from '@modules/live/components/LiveHeader.vue'
import { subscribeLiveWins, useLiveWins } from '@modules/live/composable/useLiveWins'
import type { LiveWinModel, LiveWinsPeriod } from '@modules/live/live.model'
import { useExchanger } from '@modules/wallet'
import { getFormatBalance } from '@helpers/utils'
import { getFormattedName, getGameIconName, getGameUrl, isOurGame } from '@modules/live/live.helpers'
import { withAuth } from '@helpers/user'
import { useRouter } from 'vue-router'
import UserRankIcon from '@modules/user/components/UserRankIcon.vue'
import { getUserRank } from '@modules/user/user.helpers'
import type { Tab } from '@/types/interface'
import { t, useLocalePath } from '@/plugins/i18n'

const router = useRouter()
const localPath = useLocalePath()
const Exchanger = useExchanger()
const [tab, setTab] = useState<Tab<string, LiveWinsPeriod>>(tabsConfig[0])

const tabsComputedConfig = computed(() => tabsConfig)

const liveQuery = useLiveWins({
  period: computed(() => tab.value?.id || 'live'),
  count: ref(10),
})

subscribeLiveWins()

function onGameClick(winModel: LiveWinModel) {
  const path = localPath(getGameUrl(winModel))
  if(isOurGame(winModel)) 
    return router.push(path)
  withAuth(() => router.push(path), path)
}
</script>

<template>
  <section class="slots-table">
    <LiveHeader :title="t('liveWins.names.live_bets')" class="bg-gray rounded-t-normal p-2 pl-4 min-h-[64px] w-full">
      <Tabs
        class="w-auto hidden screen-480:flex"
        :items="tabsComputedConfig"
        :current="tab"
        @click="setTab"
      />
    </LiveHeader>

    <div class="slots-table_content">
      <div class="table">
        <div class="thead light-2">
          <div class="tr">
            <div class="col game">
              {{ t('liveWins.table.game') }}
            </div>

            <div class="col user w-[200px]">
              {{ t('liveWins.table.user') }}
            </div>

            <div class="col bet">
              {{ t('liveWins.table.amount_bet') }}
            </div>

            <div class="col coef">
              {{ t('liveWins.table.coeff') }}
            </div>

            <div class="col amount">
              {{ t('liveWins.table.cashout') }}
            </div>
          </div>
        </div>

        <div v-if="liveQuery.isFetching.value" class="tbody">
          <div
            v-for="item in 10"
            :key="item"
            class="tr"
          >
            <div class="col game">
              <div class="w-[100px] h-[18px] filled light" />
            </div>

            <div class="col user">
              <div class="w-[200px] h-[18px] filled light" />
            </div>

            <div class="col bet">
              <div class="w-[80px] h-[18px] filled light" />
            </div>

            <div class="col coef">
              <div class="w-[60px] h-[18px] filled light" />
            </div>

            <div class="col amount">
              <div class="w-[100px] h-[18px] filled light" />
            </div>
          </div>
        </div>

        <transition-group v-else name="slideGroupY" class="tbody max-h-[489px]" tag="div">
          <div
            v-for="(item) in (liveQuery.data.value || [])"
            :key="item.id"
            class="tr"
          >
            <div class="col game">
              <div
                class="flex justify-between items-center gap-3 cursor-pointer overflow-hidden btn-link-simple light-2"
                @click="() => onGameClick(item)"
              >
                <component :is="getGameIconName(item)" class="w-5 h-5" />

                <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{ item.bet.game.name }}</span>
              </div>
            </div>

            <div class="col user w-[200px] text-white flex gap-2 items-center">
              <UserRankIcon class="min-w-[18px] h-[18px]" :rank="getUserRank(item.bet.user.vipStatus.slug)" />

              <span class="text-ellipsis overflow-hidden">
                {{ getFormattedName(item.bet.user.name) }}
              </span>
            </div>

            <div class="col bet">
              <span :class="Exchanger.getIconSymbolNameById(item.bet.currencyId)">{{ getFormatBalance(Number(item.bet.betAmount || 0)) }}</span>
            </div>

            <div class="col coef">
              {{ item.multiplier }}x
            </div>

            <div class="col amount">
              <span :class="`${Exchanger.getIconSymbolNameById(item.bet.currencyId)} ${!item.bet.winAmount ? 'red' : 'green'}`">
                {{ getFormatBalance(Number((item.bet.winAmount || 0))) }}
              </span>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.slots-table {
  border-radius: $radius6;
  overflow: hidden;
  width: 100%;
  &_tabs {
    background: var(--gray);
    border-radius: $radius6 $radius6 0 0;
    padding: 8px;
    min-height: 64px;
    .tabs {
      width: auto !important;
    }
    &__period {
      flex-grow: unset;
    }
  }
  &_content {
    .table {
      border-radius: 0;
      .thead {
        border-radius: 0;
      }
    }
    .tbody {
      overflow: hidden;
      .tr {
        min-height: 48px;
        background: var(--black-4);

        &:nth-child(2n + 1) {
          background: var(--gray);
        }

      }
      .bet {
        color: var(--white);
      }
    }
    .game {
      width: 165px;
      gap: 12px;
    }

    .bet {
      width: 110px;
    }
    .coef {
      width: 70px;
    }
    .amount {
      width: 110px;
    }
  }
}
@include _760 {
  .slots-table {
    &_tabs {
      .tabs {
        width: 100%;
      }
      &__period {
        display: none !important;
      }
    }

    & .user {
      display: none;
    }
  }
}
@include _600 {
  .slots-table {
    &_content {
      .user,
      .bet {
        display: none;
      }
      .game {
        width: 140px;
      }
      .tbody {
        .coef {
          color: var(--white);
        }
      }
    }
  }
}
</style>
