export const route = {
  path: 'support',
  name: 'support',
  component: () => import('./views/LayoutNew.vue'),
  props: true,
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'support-index',
      component: () => import('./views/index.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'create',
      name: 'support-create',
      component: () => import('./views/create.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: ':id',
      name: 'support-details',
      component: () => import('./views/DetailsNew.vue'),
      meta: {
        auth: true,
      },
    },
  ],
}
