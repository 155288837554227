<script setup lang="ts">
import { decode } from 'blurhash'
import { onMounted, ref } from 'vue'

interface Props {
  hash: string
  width: number
  height: number
}
const props = defineProps<Props>()

const pixels = decode(props.hash, props.width, props.height)

const canvasRef = ref<HTMLCanvasElement | null>(null)

onMounted(() => {
  const context = canvasRef.value?.getContext('2d')
  const imageData = context?.createImageData(props.width, props.height) as ImageData
  imageData.data.set(pixels)
  context?.putImageData(imageData, 0, 0)
})
</script>

<template>
  <canvas ref="canvasRef" />
</template>

<style module lang="scss">

</style>
