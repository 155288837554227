export const RAKEBACK_TABS = [
  {
    name: 'rakeback.my_rakeback',
    id: 'rakeback-my',
    icon: 'person',
    disabled: false,
  },
  {
    name: 'rakeback.about_rakeback',
    id: 'rakeback-about',
    icon: 'limits',
    disabled: false,
  },
]
export enum QUERY_RESOURSES {
  GET_RANK_RESOURSE = 'get-rank',
  GET_RANKS_RESOURSE = 'get-ranks',
  GET_ACCRUALS_RESOURSE = 'get-accruals',
  GET_ACCRUALS_TOTAL_RESOURSE = 'get-accruals-total',
}

/**
 * highroller  rake  1 day - cash 1 day
 * gambler rake  3 days - cash 1 week
 * player rake  1 week- cash two weeks
 * newcomer rake two weeks - cash month
 *
 * burn date cash week - rake 2 months
 * */

export const withdraw_schedule: Record<'rakeback' | 'cashback', Record<string, string>> = {
  rakeback: {
    newcomer: '2week',
    player: '1week',
    gambler: '3days',
    high_roller: '1day',
  },
  cashback: {
    newcomer: 'month',
    player: '2week',
    gambler: '1week',
    high_roller: '1day',
  },
}
