<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { Modal } from '@components/Interface'
import { useOpenModals } from '@composable/useModal'

// async modal components
const ForgotModal = defineAsyncComponent(async () => (await import('@modules/auth')).ForgotModal)
const Restore = defineAsyncComponent(async () => (await import('@modules/auth')).RestoreModal)
const RestoreSuccessful = defineAsyncComponent(async () => (await import('@modules/auth')).RestoreSuccessfulModal)
const AuthModal = defineAsyncComponent(async () => (await import('@modules/auth')).AuthModal)
const TfaModal = defineAsyncComponent(async () => (await import('@modules/auth')).TfaModal)
const ContinueSocialRegister = defineAsyncComponent(async () => (await import('@modules/auth')).ContinueSocialRegister)

const BanModal = defineAsyncComponent(async () => (await import('@modules/chat')).BanModal)

const OnboardingModal = defineAsyncComponent(async () => (await import('@modules/beta')).OnboardingModal)
const TopUpModal = defineAsyncComponent(async () => (await import('@modules/beta')).TopUpModal)

const ConnectTfaModal = defineAsyncComponent(async () => (await import('@modules/user')).ConnectTfaModal)
const DisconnectTfaModal = defineAsyncComponent(async () => (await import('@modules/user')).DicsonnectTfaModal)

const PaymentsModal = defineAsyncComponent(async () => (await import('@modules/wallet')).PaymentsModal)
const DepositProblem = defineAsyncComponent(async () => (await import('@modules/wallet')).DepositProblem)
const DepositInfo = defineAsyncComponent(async () => (await import('@modules/wallet')).DepositInfo)
// crash
const CrashRoundHistory = defineAsyncComponent(async () => (await import('@modules/games/airblast')).RoundHistory)
const CrashRoundCheck = defineAsyncComponent(async () => (await import('@modules/games/airblast')).RoundCheck)
const SettingsFair = defineAsyncComponent(async () => (await import('@modules/games/airblast')).SettingsFair)
const FaqFair = defineAsyncComponent(async () => (await import('@modules/games/airblast')).FaqFair)
const Rules = defineAsyncComponent(async () => (await import('@modules/games/airblast')).Rules)
// tower
const TowerRulesModal = defineAsyncComponent(async () => (await import('@modules/games/tower')).TowerRulesModal)
const TowerCheckFairModal = defineAsyncComponent(async () => (await import('@modules/games/tower')).TowerCheckFairModal)

// referral
const GiveawayModal = defineAsyncComponent(async () => (await import('@modules/bonus')).GiveawayModal)
const GiveawayCreateModal = defineAsyncComponent(async () => (await import('@modules/bonus')).GiveawayCreateModal)
const EditCampaignModal = defineAsyncComponent(async () => (await import('@modules/referral')).EditCampaignModal)
const CreateCampaignModal = defineAsyncComponent(async () => (await import('@modules/referral')).CreateCampaignModal)
const DeleteCampaignModal = defineAsyncComponent(async () => (await import('@modules/referral')).DeleteCampaignModal)
const DeleteCampaignConfirmationModal = defineAsyncComponent(async () => (await import('@modules/referral')).DeleteCampaignConfirmationModal)
const RakebackModal = defineAsyncComponent(async () => (await import('@modules/club')).RakebackModal)

// wallet
const AddCurrencyModal = defineAsyncComponent(async () => (await import('@modules/wallet')).AddCurrencyModal)
const ViewInCurrency = defineAsyncComponent(async () => (await import('@modules/wallet')).ViewInCurrency)
const ChangeCurrencySlotGame = defineAsyncComponent(async () => (await import('@modules/wallet')).ChangeCurrencySlotGame)
const ChangeCurrencyTowerGame = defineAsyncComponent(async () => (await import('@modules/wallet')).ChangeCurrencyTowerGame)
const DepositInfoCrypto = defineAsyncComponent(async () => (await import('@modules/wallet')).DepositInfoCrypto)

// bonus
const ActiveBonusExistModal = defineAsyncComponent(async () => (await import('@modules/bonus')).ActiveBonusExistModal)
const DeleteBonusConfirmationModal = defineAsyncComponent(async () => (await import('@modules/bonus')).DeleteBonusConfirmationModal)
const BonusDetailsModal = defineAsyncComponent(async () => (await import('@modules/bonus')).BonusDetailsModal)

// single games
const SingleGameSeedManageModal = defineAsyncComponent(async () => (await import('@components/Games')).SingleGameSeedManageModal)
const FairFaqModal = defineAsyncComponent(async () => (await import('@components/Games')).FairFaqModal)

const BlockersDetectedModal = defineAsyncComponent(async () => (await import('@modules/user')).BlockersDetectedModal)

// bus
const { active, clear, payload, secondActive, secondPayload, clearSecond } = useOpenModals()
</script>

<template>
  <Modal :open="!!active" :closable="!['auth.continue_social_register', 'restore'].includes(active)" @close="clear">
    <BlockersDetectedModal v-if="active === 'blockers_detect'" :open="active === 'blockers_detect'" :payload="payload" @close="clear" />

    <TfaModal v-if="active === 'login_tfa'" :open="active === 'login_tfa'" :payload="payload" @close="clear" />

    <ContinueSocialRegister v-if="active === 'auth.continue_social_register'" :open="active === 'auth.continue_social_register'" :payload="payload" @close="clear" />

    <BanModal v-if="active === 'chat_ban'" :open="active === 'chat_ban'" :payload="payload" @close="clear" />

    <ForgotModal v-if="active === 'forgot'" :open="active === 'forgot'" :payload="payload" @close="clear" />

    <Restore v-if="active === 'restore'" :open="active === 'restore'" @close="clear" />

    <RestoreSuccessful v-if="active === 'restore_successful'" :open="active === 'restore_successful'" @close="clear" />

    <AuthModal v-if="active === 'auth'" :open="active === 'auth'" :payload="payload" @close="clear" />

    <ConnectTfaModal v-if="active === 'connect_tfa'" :open="active === 'connect_tfa'" @close="clear" />

    <DisconnectTfaModal v-if="active === 'disconnect_tfa'" :open="active === 'disconnect_tfa'" @close="clear" />

    <PaymentsModal v-if="active === 'payments'" :open="active === 'payments'" :payload="payload" @close="clear" />

    <DepositProblem v-if="active === 'deposit_problem'" :open="active === 'deposit_problem'" @close="clear" />

    <CrashRoundHistory v-if="active === 'crash_round_history'" :open="active === 'crash_round_history'" :payload="payload" @close="clear" />

    <CrashRoundCheck v-if="active === 'crash_round_check'" :open="active === 'crash_round_check'" :payload="payload" @close="clear" />

    <SettingsFair v-if="active === 'crash_settings_fair'" :open="active === 'crash_settings_fair'" :payload="payload" @close="clear" />

    <FaqFair v-if="active === 'crash_faq_fair'" :open="active === 'crash_faq_fair'" @close="clear" />

    <Rules v-if="active === 'crash_rules'" :open="active === 'crash_rules'" @close="clear" />

    <GiveawayModal v-if="active === 'bonus_giveaway'" :open="active === 'bonus_giveaway'" :payload="payload" @close="clear" />

    <GiveawayCreateModal v-if="active === 'bonus_giveaway_create'" :open="active === 'bonus_giveaway_create'" @close="clear" />

    <CreateCampaignModal v-if="active === 'ref_create_campaign'" :payload="payload" :open="active === 'ref_create_campaign'" @close="clear" />

    <EditCampaignModal v-if="active === 'ref_edit_campaign'" :payload="payload" :open="active === 'ref_edit_campaign'" @close="clear" />

    <DeleteCampaignModal v-if="active === 'ref_delete_campaign'" :payload="payload" :open="active === 'ref_delete_campaign'" @close="clear" />

    <DeleteCampaignConfirmationModal v-if="active === 'ref_delete_campaign_confirmation'" :payload="payload" :open="active === 'ref_delete_campaign_confirmation'" @close="clear" />

    <RakebackModal v-if="active === 'ref_rakeback'" :open="active === 'ref_rakeback'" @close="clear" />

    <TowerRulesModal v-if="active === 'games_tower_rules'" :open="active === 'games_tower_rules'" @close="clear" />

    <TowerCheckFairModal v-if="active === 'games_tower_check_fair'" :payload="payload" :open="active === 'games_tower_check_fair'" @close="clear" />

    <AddCurrencyModal v-if="active === 'wallet_add_currency'" :payload="payload" :open="active === 'wallet_add_currency'" @close="clear" />

    <ViewInCurrency v-if="active === 'wallet_view_currency'" :payload="payload" :open="active === 'wallet_view_currency'" @close="clear" />

    <ActiveBonusExistModal v-if="active === 'bonus.activeBonusExist'" :payload="payload" :open="active === 'bonus.activeBonusExist'" @close="clear" />

    <DeleteBonusConfirmationModal v-if="active === 'bonus.deleteBonusConfirmation'" :payload="payload" :open="active === 'bonus.deleteBonusConfirmation'" @close="clear" />

    <ChangeCurrencySlotGame v-if="active === 'wallet.change_currency_slot_game'" :payload="payload" :open="active === 'wallet.change_currency_slot_game'" @close="clear" />

    <SingleGameSeedManageModal v-if="active === 'single_game.seed_manage'" :payload="payload" :open="active === 'single_game.seed_manage'" @close="clear" />

    <BonusDetailsModal v-if="active === 'bonus.bonus_details'" :payload="payload" :open="active === 'bonus.bonus_details'" @close="clear" />

    <ChangeCurrencyTowerGame v-if="active === 'wallet.change_currency_tower_game'" :payload="payload" :open="active === 'wallet.change_currency_tower_game'" @close="clear" />

    <FairFaqModal v-if="active === 'single_game.faq_fair'" :payload="payload" :open="active === 'single_game.faq_fair'" @close="clear" />

    <OnboardingModal v-if="active === 'beta.onboarding'" :payload="payload" :open="active === 'beta.onboarding'" @close="clear" />

    <TopUpModal v-if="active === 'beta.topUp'" :payload="payload" :open="active === 'beta.topUp'" @close="clear" />
  </Modal>

  <Modal :open="!!secondActive" @close="clearSecond">
    <DeleteBonusConfirmationModal v-if="secondActive === 'bonus.deleteBonusConfirmation'" :payload="secondPayload" :open="secondActive === 'bonus.deleteBonusConfirmation'" @close="clearSecond" />

    <DepositInfo v-if="secondActive === 'deposit_info'" :open="secondActive === 'deposit_info'" :payload="secondPayload" @close="clearSecond" />

    <ActiveBonusExistModal v-if="secondActive === 'bonus.activeBonusExist'" :payload="secondPayload" :open="secondActive === 'bonus.activeBonusExist'" @close="clearSecond" />

    <DepositInfoCrypto v-if="secondActive === 'wallet.deposit_info_crypto'" :payload="secondPayload" :open="secondActive === 'wallet.deposit_info_crypto'" @close="clearSecond" />
  </Modal>
</template>

<style scoped lang="scss">

</style>
