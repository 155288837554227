import type { AuthSocialType } from '@modules/auth/auth.model'

const AUTH_SOCIAL_TYPE_KEY = 'bandana.auth-social.type'
const AUTH_SESSION_LIFETIME_KEY = 'bandana.auth.session-expire.ts'

export function getAuthSocialType(): AuthSocialType | null {
  return localStorage.getItem(AUTH_SOCIAL_TYPE_KEY) as AuthSocialType | null
}

export function setAuthSocialType(type: AuthSocialType) {
  localStorage.setItem(AUTH_SOCIAL_TYPE_KEY, type)
}

export function clearAuthSocialType() {
  localStorage.removeItem(AUTH_SOCIAL_TYPE_KEY)
}

export function updateSessionLifetime() {
  const newTime = (Date.now() + 5 * 60 * 1000).toString()

  localStorage.setItem(AUTH_SESSION_LIFETIME_KEY, newTime)
}

export function isNeedToRefreshSession(): boolean {
  const expireTime = localStorage.getItem(AUTH_SESSION_LIFETIME_KEY)

  return Date.now() >= (Number(expireTime) - 60 * 1000) && expireTime !== null
}

export function clearSessionExpireTime() {
  localStorage.removeItem(AUTH_SESSION_LIFETIME_KEY)
}
