<script lang="ts" setup generic="Type">
import { computed } from 'vue'
import { useUserStore } from '@modules/user'
import type { Tab } from '@/types/interface'
import { t } from '@/plugins/i18n'

interface Props {
  items: Tab<Type>[]
  current?: Tab<Type>
  name?: string
  count?: number
}

const props = defineProps<Props>()
const emit = defineEmits(['click'])
const userStore = useUserStore()

const currTab = computed(() => {
  return props.current || props.items[0]
})

function chooseTab(t: Tab<Type>) {
  emit('click', t)
}
</script>

<template>
  <div v-if="props.items.length" class="tabs">
    <button
      v-for="(item, i) in props.items"
      v-show="
        (item.type === 'favorites' && userStore.isLogged)
          || item.type !== 'favorites'
      "
      :key="i"
      class="tabs-item"
      :class="{ active: currTab ? item.name === currTab.name : false }"
      @click="chooseTab(item)"
    >
      <component :is="item.icon" v-if="item.icon" class="w-5 h-5" />

      <p>{{ t(item.name) }}</p>

      <span
        v-if="
          item.count !== null && item.count !== undefined && (currTab ? item.name === currTab.name : false)
        "
        class="count"
      >
        {{ item.count }}
      </span>
    </button>
  </div>
</template>

<style lang="scss">
.tabs {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 12px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 8px;
  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    padding: 3px 8px;
    height: 32px;
    border-radius: $radius;
    cursor: pointer;
    transition: 0.3s;
    .count {
      color: var(--white);
      background: var(--gray-1);
      border-radius: $radius;
      @apply flex justify-center items-center;
      height: 20px;
      min-width: 0px;
      max-width: 0;
      padding: 1px 4px;
      overflow: hidden;
      opacity: 0;
      // transition: .3s;
    }
    &:hover {
      color: var(--yellow);
    }
    &.active {
      color: var(--yellow);
      background: var(--gray-4);
      .count {
        opacity: 1;
        min-width: 20px;
        max-width: 50px;
        margin-left: 4px;
      }
    }
  }
}
</style>
