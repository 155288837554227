<script setup lang="ts">
import { computedAsync } from '@vueuse/core'
import { usePasswordValidation } from '@modules/auth/auth.validator'
import { restorePassword } from '@modules/auth/auth.service'
import { ref, useKeydown, useModalLayer, useRoute, useRouter } from '@modules/auth/auth.dependencies'
import { t, useLocalePath } from '@/plugins/i18n'
import SecurityLock from '@/assets/icon/security-lock.svg'
import Hide from '@/assets/icon/hide.svg'
import Show from '@/assets/icon/show.svg'

const route = useRoute()
const router = useRouter()
const modalLayer = useModalLayer()
const localePath = useLocalePath()

const passwordHtmlElement = ref<HTMLInputElement>(
  document.createElement('input'),
)
const passwordRepeatHtmlElement = ref<HTMLInputElement>(
  document.createElement('input'),
)

const isPasswordHidden = ref(true)
const isPasswordRepeatHidden = ref(true)

const isButtonClicked = ref(false)

const { value, repeatValue, getIsValid, getIsRepeatValid, getIsError, getIsRepeatError, getPasswordMessage } = usePasswordValidation(passwordHtmlElement, passwordRepeatHtmlElement, false)

useKeydown('Enter', onRestore)

const passwordError = computedAsync(async () => {
  if (!value.value && !isButtonClicked.value)
    return ''

  return await getPasswordMessage()
}, '')

const isPasswordRepeatErrorDisplay = computedAsync(() => {
  const passwordError = getIsError()

  if (value.value && repeatValue.value && value.value !== repeatValue.value && !passwordError)
    return true

  return false
})

async function onRestore() {
  const isValid = await getIsValid()
  const isRepeatValid = await getIsRepeatValid()

  if (isValid && isRepeatValid) {
    const token = String(route.query.token)
    const result = await restorePassword({ token, password: value.value })

    if (result.success) {
      modalLayer.hide('restore')
      router.replace(localePath('/'))

      setTimeout(() => {
        modalLayer.show('restore_successful')
      }, 200)
    }
  }
  else {
    isButtonClicked.value = true
  }
}

function showOriginalPassword() {
  isPasswordHidden.value = !isPasswordHidden.value
}

function showRepeatedPassword() {
  isPasswordRepeatHidden.value = !isPasswordRepeatHidden.value
}
</script>

<template>
  <div class="modal_content restore-password" @click.stop>
    <header class="header-popup">
      <SecurityLock class="header-popup__svg w-[22px] h-[22px] yellow" />

      <h3 class="header-popup__title">
        {{ t("auth.password_repairing") }}
      </h3>
    </header>

    <div class="modal_body">
      <div class="form">
        <div class="form__content">
          <label class="form__label" for="password">
            {{ t("auth.password") }}

            <div class="restore-input">
              <input
                id="password"
                ref="passwordHtmlElement"
                v-model="value"
                name="password_"
                class="form__input"
                :class="{
                  'invalid': getIsError(),
                  'valid': !getIsError(),
                  'invalid--forced': isButtonClicked,
                }"
                :type="isPasswordHidden ? 'password' : 'text'"
                :placeholder="t('auth.password')"
                minlength="8"
                pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
              >

              <button class="btn btn-secondary" @click="showOriginalPassword()">
                <Show v-if="isPasswordHidden" />

                <Hide v-else />
              </button>
            </div>

            <span v-if="getIsError()" class="restore-input-error">{{ passwordError }}</span>
          </label>

          <label class="form__label restore-field" for="password">
            {{ t("auth.password_repeat") }}

            <div class="restore-input">
              <input
                id="repeatPassword"
                ref="passwordRepeatHtmlElement"
                v-model="repeatValue"
                name="repeatPassword"
                class="form__input"
                :class="{
                  'invalid': getIsRepeatError(),
                  'valid': !getIsRepeatError(),
                  'invalid--forced': isButtonClicked,
                }"
                :type="isPasswordRepeatHidden ? 'password' : 'text'"
                :placeholder="t('auth.password_repeat')"
                minlength="8"
              >

              <button class="btn btn-secondary" @click="showRepeatedPassword()">
                <Show v-if="isPasswordRepeatHidden" />

                <Hide v-else />
              </button>
            </div>

            <span v-if="isPasswordRepeatErrorDisplay" class="restore-input-error">
              {{ t('notify.valid_password_repeat') }}
            </span>
          </label>

          <button class="form__btn btn-primary" @click="onRestore">
            <span>{{ t("auth.password_repair") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.restore-input {
  position: relative;

  &-error {
    display: inline-block;
    color: var(--red-7);
    margin-top: -8px;
  }

  input.invalid:not(:placeholder-shown),
  input:invalid:not(:placeholder-shown),
  input.invalid:valid:not(:placeholder-shown),
  input.invalid--forced {
    border-color: var(--red-7) !important;
  }

  input.valid:not(:placeholder-shown),
  input:valid:not(:placeholder-shown) {
    border-color: var(--green-3) !important;
  }

  .btn {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -16px;

    height: 32px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.restore-password {
  & .form {
    padding: 0;
  }

}
</style>
