import { computed, useQuery, useUserStore } from '@modules/wallet/wallet.dependencies'
import type { ComputedRef, Ref } from '@modules/wallet/wallet.dependencies'
import { WalletCacheKey } from '@modules/wallet/wallet.types'
import type { Wallet } from '@modules/wallet/wallet.model'
import { getWallets as getWalletsService } from '@modules/wallet/wallet.service'
import type { Subscription } from 'centrifuge'
import { isSSR } from '@helpers/utils'
import { useQueryStore } from '@/store/Query'
import { USER_RELATED_QUERY_MODIFIER } from '@/config/queries'

interface IUseWallet {
  data: Ref<Wallet []>
  isLoading: Ref<boolean>

  bonusWallet: ComputedRef<Wallet | undefined>
  realWallet: ComputedRef<Wallet | undefined>
  selectedRealWallet: ComputedRef<Wallet | undefined>
  selectedBonusWallet: ComputedRef<Wallet | undefined>
  selectedWalletCurrency: ComputedRef<string>

  summaryBalance: ComputedRef<number>

  getWallet: (filter: Partial<Wallet>) => Wallet | undefined
}
export function useWallets(): IUseWallet {
  const userStore = useUserStore()
  const enabled = computed<boolean>(() => !isSSR() && userStore.isLogged)

  const query = useQuery<unknown, unknown, Wallet[]>([WalletCacheKey.value, USER_RELATED_QUERY_MODIFIER], async () => {
    const response = await getWalletsService()

    return response.response
  }, { refetchOnWindowFocus: false, enabled, staleTime: 60 * 1000 })

  const data = computed<Wallet[]>(() => query.data.value || [])
  const bonusWallet = computed<Wallet | undefined>(() => data.value.find(wallet => wallet.typeName === 'BONUS'))
  const realWallet = computed<Wallet | undefined>(() => data.value.find(wallet => wallet.typeName === 'REAL'))
  const selectedRealWallet = computed<Wallet | undefined>(() => data.value.find(wallet => wallet.selected && wallet.typeName === 'REAL'))
  const selectedBonusWallet = computed<Wallet | undefined>(() => data.value.find(wallet => wallet.selected && wallet.typeName === 'BONUS'))
  const selectedWalletCurrency = computed<string>(() => selectedRealWallet.value?.currencyId || '')
  const summaryBalance = computed<number>(() => Number(selectedRealWallet.value?.amount || 0) + Number(selectedBonusWallet.value?.amount || 0))

  const isLoading = query.isLoading

  const getWallet = (filter: Partial<Wallet>) => {
    return query.data.value?.find((wallet) => {
      const keys = Object.keys(filter) as (keyof Partial<Wallet>)[]

      const matchList = keys.filter(key => wallet[key] === filter[key])

      return matchList.length === keys.length
    })
  }

  return { data, selectedWalletCurrency, selectedRealWallet, getWallet, selectedBonusWallet, bonusWallet, realWallet, isLoading, summaryBalance }
}

export function addSubscriptionListeners(subscription: Subscription) {
  subscription.on('publication', (ctx) => {
    if (ctx.data?.action === 'wallet:balance_updated') {
      const queryStore = useQueryStore()

      queryStore.queryClient?.setQueriesData<Wallet[]>(['wallet-cache-key'], (data) => {
        return data?.map((wallet) => {
          const updatedWallet = ctx.data.payload?.find((item: Wallet) => item.id === wallet.id)

          if (updatedWallet) {
            return {
              ...wallet,
              ...updatedWallet,
            }
          }
          else {
            return wallet
          }
        })
      })
    }
  })
}
