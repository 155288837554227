import { useIntersectionObserver } from '@vueuse/core'
import { computed, onBeforeUnmount, ref } from 'vue'
import type { Ref } from 'vue/dist/vue'

export function useImageLoading(target: Ref) {
  const isVisible = ref(false)
  const loading = computed<'lazy' | 'eager'>(() => isVisible.value ? 'eager' : 'lazy')

  const { stop, isSupported } = useIntersectionObserver(
    target,
    ([{ isIntersecting }]) => {
      if (isIntersecting) {
        isVisible.value = isIntersecting
        stop()
      }
    },
    {
      rootMargin: '100px',
    },
  )

  if (!isSupported.value)
    isVisible.value = true

  onBeforeUnmount(() => stop())

  return {
    loading,
  }
}
