<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components'
import { Chat } from '@modules/chat'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import ChatUnavailable from '@modules/chat/components/ChatUnavailable.vue'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import { useLayoutStore } from '@/store/Layout'

defineProps<Props>()

const gracefulDegradation = useGracefulDegradation()

interface Props {
  needUpdate: boolean
}

const layoutStore = useLayoutStore()
const route = useRoute()

const showChat = computed(() => layoutStore.isChatShowOnPageLoad || (!layoutStore.isChatShowOnPageLoad && layoutStore.hideRight))
</script>

<template>
  <aside
    :class="{
      [$style.hideDesktop]: layoutStore.hideRight,
      [$style.openTablet]: layoutStore.hideRight,
      [$style.openMobile]: layoutStore.hideRight,
      [$style.gameMode]: !!route.meta?.game,
      [$style.needUpdate]: needUpdate,
    }"
  >
    <div :class="$style.backdrop" />

    <div
      id="sidebar_right"
      v-on-click-outside="() => layoutStore.closeIfMobile('right')"
      :class="[$style.wrap]"
      @click.stop
    >
      <div :class="$style.content">
        <ChatUnavailable v-if="gracefulDegradation.checkServiceForOffline('chat')" />

        <keep-alive v-else>
          <Chat v-if="showChat" />
        </keep-alive>
      </div>
    </div>
  </aside>
</template>

<style module lang="scss">
.needUpdate {
  & .wrap {
    @apply transition-all pt-[38px];
  }
}

.wrap {
  @apply fixed z-[3030] top-[70px] right-0 bottom-0 flex flex-col justify-between;
  @apply translate-y-0;
  background: var(--black);

  @include _1399 {
    transform: translateX(100%);
  }

  @include _1170 {
    @apply pb-[64px];
  }

  @include _480 {
    @apply w-full;
  }
}

.content {
  @apply min-w-[300px] w-[300px] flex-grow overflow-hidden;
  transition: 0.3s;

  @include _480 {
    @apply w-full;
  }
}

.backdrop {
  @apply fixed left-0 right-0 top-[70px] bottom-0 z-[3000] hidden;
  background: rgba(0, 0, 0, 0.553);
  transition: 0.3s;

  @include _1170 {
    @apply bottom-[64px];
  }
}
// states
.hideDesktop {
  & .wrap {
    // closed
    transform: translateX(100%);
  }
}
.gameMode {
  @media screen and (max-width: 1599px) {
    & .wrap {
      transform: translateX(100%);
    }
  }

  &.openTablet {
    & .wrap {
      @media screen and (max-width: 1599px) {
        transform: unset;
      }

      @include _1279 {
        transform: translateX(100%);
      }
    }
  }

  &.openMobile {
    @include _1279 {
      // open
      & .wrap {
        @apply transform-none;
      }
      & .backdrop {
        @apply block;
      }
    }
  }
}

.openTablet {
  & .wrap {
    @include _1399 {
      transform: unset;
    }

    @include _1279 {
      transform: translateX(100%);
    }
  }

}
.openMobile {
  @include _1279 {
    // open
    & .wrap {
      @apply transform-none;
    }
    & .backdrop {
      @apply block;
    }
  }
}
</style>
