<script lang="ts" setup>
import { CentralSection } from '@/cypress.types'

import { t } from '@/plugins/i18n'

interface Tab {
  id: string
  name: string
  icon?: string
  count?: number
  disabled?: boolean
}
interface Props {
  tabs: Tab[]
  currentId: Tab['id']
  count?: number
}

defineProps<Props>()
const emit = defineEmits(['change'])

function onChangeTab(id: Tab['id']) {
  emit('change', id)
}
</script>

<template>
  <div class="tabs-corn">
    <div class="tabs-corn_wrap">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        class="tabs-corn--item"
        :class="{ active: tab.id === currentId }"
        :disabled="tab.disabled || false"
        :data-cy="CentralSection.CATEGORY_CENTRAL_BUTTON + tab.name"
        @click="() => onChangeTab(tab.id)"
      >
        <slot name="icon" :icon="tab.icon" />

        <p>{{ t(tab.name) }}</p>

        <span
          v-if="tab.count && tab.id === currentId"
          class="count"
        >
          {{ tab.count }}
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.tabs-corn {
  display: flex;
  margin: 10px 0 -10px;
  padding: 10px 0;
  overflow-y: hidden;
  overflow-x: auto;
  @include _989 {
    margin: 6px 0 -6px;
    padding: 6px 0;
  }
  &_wrap {
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 12px;
  }
  &--item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    padding: 3px 16px;
    height: 46px;
    color: var(--white);
    background: var(--gray-5);
    border-radius: $radius;
    position: relative;
    @include _600 {
      padding: 3px 10px;
      height: 44px;
    }
    .count {
      color: var(--yellow);
      background: var(--gray-1);
      border-radius: $radius;
      @apply flex justify-center items-center;
      height: 20px;
      min-width: 0px;
      max-width: 0;
      padding: 1px 3px;
      overflow: hidden;
      opacity: 0;
      font-size: 12px;
      margin: 0;
      // transition: .3s;
    }
    &:hover {
      color: var(--yellow);
    }
    &.active {
      color: var(--gray-1) !important;
      background: var(--yellow-gradient);
      .count {
        opacity: 1;
        min-width: 20px;
        max-width: 50px;
      }
    }
  }
}
</style>
