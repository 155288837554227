import { useBus } from '@composable/useBus'
import { type UserModule } from '@/types'

export const install: UserModule = async (ctx) => {
  if (!ctx.isClient)
    return

  await ctx.router.isReady()

  const registration = await navigator?.serviceWorker?.ready

  const updateInterval = 5 * 60 * 1000
  const bus = useBus()

  setInterval(async () => {
    if (('connection' in navigator) && !navigator.onLine)
      return

    const response = await (await fetch('/version.json', {
      cache: 'no-store',
      headers: {
        'cache': 'no-store',
        'cache-control': 'no-cache',
      },
    })).json()

    if (response?.value && response?.value !== localStorage.getItem('VERSION'))

      bus.emit('update:notification')
  }, updateInterval)

  bus.on('update:confirm', () => {
    registration?.active?.postMessage('UPDATE_WITH_RELOAD')
    window.location.reload()
  })
}
