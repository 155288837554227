import { selectWalletService } from '@modules/wallet/wallet.service'
import type { WalletDtoSelect } from '@modules/wallet/wallet.dto'
import { WalletCacheKey } from '@modules/wallet/wallet.types'
import { useMutation, useQueryClient } from '../wallet.dependencies'

export function useSelectWallet() {
  const queryClient = useQueryClient()

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (payload: WalletDtoSelect) => selectWalletService(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [WalletCacheKey.value],
      })
    },
  })

  return { mutateAsync, isLoading }
}
