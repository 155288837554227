<script setup lang="ts">

</script>

<template>
  <div class="h-[2px] bg-gray-8 w-full" />
</template>

<style scoped lang="scss">

</style>
