<script lang="ts" setup>
interface Props {
  color: string
}

const props = withDefaults(defineProps<Props>(), {
  color: '',
})
</script>

<template>
  <div
    class="badge"
    :class="{
      'badge--new': props.color === 'new',
      'badge--popular': props.color === 'popular',
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.badge {
  font-size: 12px;
  font-weight: 700;
  background-color: var(--white);
  color: var(--black);
  border-radius: 6px;
  padding: 6px 12px;
  line-height: 1;

  &--new {
    background-color: var(--green-7);
    color: var(--white);
  }

  &--popular {
    background-color: var(--pink);
    color: var(--white);
  }
}
</style>
