import type { CommonValidator, CommonValidatorFunction, Ref } from '@modules/auth/auth.dependencies'
import { email, getValidHelper, getValidationMessageHelper, minLength, ref, required, sameAs, setInputValidity, useVuelidate, watch } from '@modules/auth/auth.dependencies'

const repeatPasswordRequired = (value: string) => !!value.length

export const useEmailValidation: CommonValidatorFunction = (element?: Ref<HTMLInputElement>, initialData?: string) => {
  const emailValue = ref<string>(initialData || '')
  const rules = {
    email: {
      email,
      required,
    },
  }
  const messages: Record<string, string> = {
    required: 'notify.required_email',
    email: 'notify.valid_email',
  }
  const $v = useVuelidate<{ email: Ref<string> }>(rules, { email: emailValue })

  const getIsValid = getValidHelper($v, messages)
  const reset = () => emailValue.value = ''
  const getIsError = () => $v.value.$invalid

  if (element)
    watch(() => $v.value.$invalid, (invalid: boolean) => setInputValidity(element, invalid))

  return { value: emailValue, getIsValid, reset, getIsError }
}

export function usePasswordValidation(element?: Ref<HTMLInputElement>, repeatElement?: Ref<HTMLInputElement>, withToast = true): CommonValidator & {
  repeatValue: Ref<string>
  getIsRepeatValid: () => Promise<boolean>
  getIsRepeatError: () => boolean
  getPasswordMessage: () => Promise<string | undefined>
} {
  const passwordValue = ref<string>('')
  const repeatPasswordValue = ref<string>('')
  const rules = {
    password: {
      required,
      minlength: minLength(8),
      password: (value: string) =>
        /(?=.*[0-9])(?=.*[!"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z])/g.test(value),
    },
  }
  const repeatRules = {
    repeatPassword: {
      sameAs: sameAs(passwordValue),
      repeatPasswordRequired,
      password: (value: string) =>
        /(?=.*[0-9])(?=.*[!"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z])/g.test(value),
    },
  }
  const messages: Record<string, string> = {
    required: 'notify.required_password',
    repeatPasswordRequired: 'notify.required_password_repeat',
    minlength: 'notify.length_password',
    password: 'notify.valid_password',
    sameAs: 'notify.valid_password_repeat',
  }
  const $v = useVuelidate(rules, { password: passwordValue })
  const $vr = useVuelidate(repeatRules, { repeatPassword: repeatPasswordValue })

  const getIsValid = getValidHelper($v, messages, withToast)
  const getIsRepeatValid = getValidHelper($vr, messages, withToast)
  const getPasswordMessage = getValidationMessageHelper($v, messages)
  const reset = () => {
    passwordValue.value = ''
    repeatPasswordValue.value = ''
  }
  const getIsError = () => $v.value.$invalid
  const getIsRepeatError = () => $vr.value.$invalid

  if (element) {
    if (repeatElement) {
      watch(() => $v.value.$invalid, (invalid: boolean) => setInputValidity(element, invalid))
      watch(() => $vr.value.$invalid, (invalid: boolean) => setInputValidity(repeatElement, invalid))
    }
    else {
      watch(() => $v.value.$invalid, (invalid: boolean) => setInputValidity(element, invalid))
    }
  }

  return { value: passwordValue, repeatValue: repeatPasswordValue, getIsValid, getIsRepeatValid, reset, getIsError, getIsRepeatError, getPasswordMessage }
}
