import { httpClient } from '@modules/referral/referral.dependencies'

import type {
  AccrualTotalItem, RankEntity,
  UserAccrualEntity,
  UserAccrualPayload,
  UserAccrualType,
  UserRank,
} from '@modules/club/club.model'
import type { ServicePaginatedResponse, ServiceResponse } from '@/api'

export async function fetchAllRanks(): Promise<ServiceResponse<RankEntity[]>> {
  return await httpClient.get('/vip/status')
}

export async function fetchUserRank(): Promise<ServiceResponse<UserRank>> {
  return await httpClient.get('/vip/status/me')
}

export async function fetchUserAccruals({
  dateStart,
  dateEnd,
  updated_at,
  perPage = 10,
  currentPage = 1,
  status,
  type,
}: UserAccrualPayload): Promise<ServiceResponse<ServicePaginatedResponse<UserAccrualEntity>>> {
  return await httpClient.get(
    '/vip/accruals', {
      params: {
        dateStart,
        dateEnd,
        updated_at,
        perPage,
        currentPage,
        status,
        type,
      },
    },
  )
}

export async function fetchUserAccrualsTotal(params: { type: UserAccrualType }): Promise<ServiceResponse<AccrualTotalItem[]>> {
  return await httpClient.get('/vip/accruals/total', { params })
}

export async function postAccrue(id: string, type: 'rakeback' | 'cashback'): Promise<ServiceResponse<UserAccrualEntity>> {
  return await httpClient.post(
    `/vip/${type}/${id}/accrue`,
  )
}
