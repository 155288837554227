import type {
  AuthDtoRestoreSendRequest,
  AuthDtoSignInRequest, AuthDtoSignInTfaGoogleRequest,
  AuthDtoSignInVkRequest,
  AuthDtoSignUpRequest,
} from '@modules/auth/auth.dto'
import type { Ref, ServiceResponse } from '@modules/auth/auth.dependencies'
import {
  USER_RELATED_QUERY_MODIFIER,
  connectGuestSocket,
  connectUserSocket,
  defineStore, disconnectUserSocket,
  nextTick,
  useModalLayer,
  useQueryStore,
  useRoute,
  useRouter, useState, useSupportStore, useUserStore, watch,
} from '@modules/auth/auth.dependencies'
import {
  authGoogleGetLinkService,
  authGoogleLoginService,
  authGoogleRegisterService,
  authSteamGetLinkService,
  authSteamLoginService,
  authSteamRegisterService,
  authVkGetLinkService,
  authVkSendLoginService,
  authVkSendRegisterService, linkGoogleService, linkSteamService, linkVkService,
  refreshSession as refreshSessionService,
  restorePasswordRequest,
  signInService,
  signOutService,
  signUpService,
  tfaSignInGoogle,
} from '@modules/auth/auth.service'
import type { AuthSocial, AuthSocialType } from '@modules/auth/auth.model'
import { clearSessionExpireTime, setAuthSocialType, updateSessionLifetime } from '@modules/auth/auth.helpers'

interface IAuthStore {
  loading: Ref<boolean>

  refreshSession: () => Promise<boolean | undefined>
  loadAppAuthPipeline: () => Promise<void>
  signInTfa: (payload: AuthDtoSignInTfaGoogleRequest) => Promise<boolean | undefined>
  signIn: (payload: AuthDtoSignInRequest) => Promise<boolean | undefined>
  signUp: (payload: AuthDtoSignUpRequest) => Promise<boolean | undefined>
  signOut: () => Promise<void>
  authVkGetLink: (type: AuthSocialType) => Promise<ServiceResponse<any>>
  authSocialSend: (payload: AuthDtoSignInVkRequest, type: AuthSocialType, social: AuthSocial) => Promise<boolean | undefined>
  linkSocialSend: (payload: AuthDtoSignInVkRequest, social: AuthSocial) => Promise<boolean | undefined>
  authGoogleGetLink: (type: AuthSocialType) => Promise<ServiceResponse<any>>
  authSteamGetLink: (type: AuthSocialType) => Promise<ServiceResponse<any>>
  restorePasswordSend: (payload: AuthDtoRestoreSendRequest) => Promise<boolean | undefined>

  removeUserRelatedQueries: () => void
}

export const useAuthStore = defineStore<string, IAuthStore>('authModule', () => {
  const userStore = useUserStore()
  const supportStore = useSupportStore()
  const queryStore = useQueryStore()
  const [loading, setLoading] = useState<boolean>(false)

  // functions
  const removeUserRelatedQueries = () => {
    queryStore.queryClient?.removeQueries({
      predicate: query =>
        !!query.queryKey.slice(1)?.filter((queryKey) => {
          return queryKey === USER_RELATED_QUERY_MODIFIER
        })?.length,
    })
  }

  // actions
  const refreshSession = async () => {
    try {
      setLoading(true)

      const serviceResponse = await refreshSessionService()

      if (serviceResponse?.success)
        updateSessionLifetime()

      return !!serviceResponse?.success
    }
    catch (e) {
      removeUserRelatedQueries()
    }
    finally {
      setLoading(false)
    }
  }
  const loadAppAuthPipeline = async () => {
    connectGuestSocket()

    // NOT AUTHED BUT WAS. TRY GET INFO
    if (!userStore.isLogged && userStore.isWasAuthed) {
      try {
        await userStore.getUser()
      }
      catch (e) {
        // ~
      }
    }

    // END OF IDENTIFICATION
    userStore.setIsIdentificationEnd(true)

    // IF AUTHED - CONNECT TO USER SOCKET
    if (!userStore.isLogged) {
      const route = useRoute()
      const router = useRouter()

      if (route?.meta?.auth) {
        const modalLayer = useModalLayer()
        nextTick(() => modalLayer.show('auth', { type: 'signIn', path: route.path })).then(null)
        await router.push('/')
      }
    }
  }
  const loggedChangePipeline = async (isLogged: boolean): Promise<void> => {
    if (isLogged) {
      connectUserSocket()
    }
    else {
      disconnectUserSocket()
      connectGuestSocket()
    }
  }
  const authVkGetLink = async (type: AuthSocialType) => {
    try {
      setLoading(true)

      setAuthSocialType(type)

      return await authVkGetLinkService()
    }
    finally {
      setLoading(false)
    }
  }
  const authSteamGetLink = async (type: AuthSocialType) => {
    try {
      setLoading(true)

      setAuthSocialType(type)

      return await authSteamGetLinkService()
    }
    finally {
      setLoading(false)
    }
  }
  const authGoogleGetLink = async (type: AuthSocialType) => {
    try {
      setLoading(true)

      setAuthSocialType(type)

      return await authGoogleGetLinkService()
    }
    finally {
      setLoading(false)
    }
  }
  const linkSocialSend = async (payload: AuthDtoSignInVkRequest, social: AuthSocial) => {
    try {
      setLoading(true)

      const presets: Record<AuthSocial, (payload: any) => Promise<any>> = {
        vk: linkVkService,
        google: linkGoogleService,
        steam: linkSteamService,
      }

      const socialPromise = presets[social]

      const serviceResponse = await socialPromise(payload)

      if (serviceResponse?.response?.tfa?.type === 'GOOGLE') {
        const modalLayer = useModalLayer()
        modalLayer.show('login_tfa', { type: 'signIn' })
      }
      else if (serviceResponse?.response) {
        await userStore.getUser()
      }

      if (serviceResponse?.success)
        updateSessionLifetime()

      return !!serviceResponse?.success
    }
    catch (ex) {
      // ~
    }
    finally {
      setLoading(false)
    }
  }
  const authSocialSend = async (payload: AuthDtoSignInVkRequest, type: AuthSocialType, social: AuthSocial) => {
    try {
      setLoading(true)

      const presets: Record<AuthSocial, Record<AuthSocialType, any>> = {
        vk: {
          signIn: authVkSendLoginService,
          signUp: authVkSendRegisterService,
        },
        google: {
          signIn: authGoogleLoginService,
          signUp: authGoogleRegisterService,
        },
        steam: {
          signIn: authSteamLoginService,
          signUp: authSteamRegisterService,
        },
      }

      const socialPreset = presets[social]
      const socialService = socialPreset[type]

      const serviceResponse = await socialService(payload)

      if (serviceResponse?.response?.tfa?.type === 'GOOGLE') {
        const modalLayer = useModalLayer()
        modalLayer.show('login_tfa', { type: 'signIn' })
      }
      else if (serviceResponse?.response) {
        userStore.setUser(serviceResponse.response)
      }

      if (serviceResponse?.success)
        updateSessionLifetime()

      return serviceResponse?.success
    }
    catch (ex) {
      // ~
    }
    finally {
      setLoading(false)
    }
  }
  const signIn = async (payload: AuthDtoSignInRequest): Promise<boolean | undefined> => {
    try {
      setLoading(true)

      const serviceResponse = await signInService(payload)

      if (serviceResponse?.response?.tfa?.type === 'GOOGLE') {
        const modalLayer = useModalLayer()
        modalLayer.show('login_tfa', { type: 'signIn' })
      }
      else if (serviceResponse?.response) {
        userStore.setUser(serviceResponse.response)
      }

      if (serviceResponse?.success)
        updateSessionLifetime()

      return serviceResponse?.success
    }
    finally {
      setLoading(false)
    }
  }
  const signUp = async (payload: AuthDtoSignUpRequest): Promise<boolean | undefined> => {
    try {
      setLoading(true)

      const serviceResponse = await signUpService(payload)

      userStore.setUser(serviceResponse.response)

      if (serviceResponse?.success)
        updateSessionLifetime()

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }

  const signOut = async () => {
    try {
      setLoading(true)

      await signOutService()

      userStore.setUser(undefined)
      supportStore.clearStore()

      clearSessionExpireTime()
      removeUserRelatedQueries()
    }
    catch (e) {
      removeUserRelatedQueries()
    }
    finally {
      setLoading(false)
    }
  }
  const restorePasswordSend = async (payload: AuthDtoRestoreSendRequest) => {
    try {
      setLoading(true)

      const serviceResponse = await restorePasswordRequest(payload)

      return !!serviceResponse?.success
    }
    finally {
      setLoading(false)
    }
  }
  const signInTfa = async (payload: AuthDtoSignInTfaGoogleRequest) => {
    try {
      setLoading(true)

      const serviceResponse = await tfaSignInGoogle(payload)

      if (serviceResponse?.success)
        userStore.setUser(serviceResponse.response)

      if (serviceResponse?.success)
        updateSessionLifetime()

      return !!serviceResponse?.success
    }
    catch {
      // ~
    }
    finally {
      setLoading(false)
    }
  }

  // watch
  watch(() => userStore.isLogged, loggedChangePipeline)

  return {
    signInTfa,
    signIn,
    refreshSession,
    loading,
    authVkGetLink,
    authGoogleGetLink,
    authSteamGetLink,
    signUp,
    signOut,
    restorePasswordSend,
    authSocialSend,
    linkSocialSend,
    loadAppAuthPipeline,
    removeUserRelatedQueries,
  }
})
