<script setup lang="ts">
import {
  computed,
  isSSR,
  nextTick,
  ref,
  useCentrifuge,
  useState,
  useToggle,
  useUserStore, watch,
} from '@modules/chat/chat.dependencies'
import type {
  IMethods,
  IServerPublicationContext,
  SocketPublication,
} from '@modules/chat/types'
import type {
  Message as IMessage,
  Room as IRoom,
} from '@modules/chat/chat.model'
import { useChatStore } from '@modules/chat/chat.store'

import Send from './Send.vue'
import MessageCombiner from './MessageCombiner.vue'
import { Select } from '@/components/Interface'
import IconRU from '@/assets/icon/lang/ru.svg'
import IconEN from '@/assets/icon/lang/en.svg'
import Arrow from '@/assets/icon/arrow.svg'

import { t } from '@/plugins/i18n'

interface RoomOnline {
  channel: string
  numUsers: number
  numClients: number
}
const [, , setIsOpen] = useToggle(false)
const userStore = useUserStore()
const store = useChatStore()
const [list, setList] = useState<IMessage[]>([])
const [_, setScrollPosition] = useState<number>(0)
const [reply, setReply] = useState<IMessage | null>(null)
const [lastTime, setLastTime] = useState<string | null>(null)
const [savedDirection, setSavedDirection] = useState<string | null>(null)
const [loadStop, setLoadStop] = useState<boolean>(false)
const [stopLoading, setStopLoading] = useState<boolean>(false)
const [history, setHistory] = useState<boolean>(false)
const [showButtonScrollTop, setShowButtonScrollTop] = useState<boolean>(false)
const [online, setOnline] = useState<RoomOnline[]>([])

const messagesUlRef = ref<HTMLElement | null>(null)

const commonRoomOnline = computed<number>(() => online.value.reduce((accum, item) => accum + item.numUsers, 0))

function getOnlineByRoomId(roomId: string): RoomOnline | undefined {
  return online.value.find(room => room.channel === roomId)
}

function getRoomWithOnline(room: IRoom): IRoom {
  const onlineLocal = getOnlineByRoomId(room.id) || { numUsers: 0, numClients: 0 }

  return {
    ...room,
    onlineUsers: onlineLocal.numUsers,
    totalUsers: commonRoomOnline.value,
  }
}

function uniqueArray(ar: any[]) {
  const j: any = {}

  ar.forEach((v: any) => {
    j[`${v.id}::${typeof v}`] = v
  })

  return Object.keys(j).map((v: any) => {
    return j[v]
  })
}

// computed
const listSet = computed<IMessage[]>(() => uniqueArray(list.value))
const messages = computed<IMessage[]>(() => {
  if (!history.value || !lastTime.value || !savedDirection.value)
    return listSet.value.slice(0, 50)

  else return getMessagesByTime(lastTime.value)
})

const onCloseDropdown = () => setIsOpen(false)
function onClickChatRoom(roomId: string) {
  const room = store.rooms.find(room => room.id === roomId)

  if (!room)
    return

  store.setCurrent(room)

  subscribeChannel(roomId)

  onCloseDropdown()
}
function subscribeOnline() {
  const chat = 'live:chats_online'
  const centrifuge = useCentrifuge()
  const subscription = centrifuge.subscribe(chat)

  subscription?.subscribe()

  subscription?.on(
    'publication',
    (ctx: IServerPublicationContext<RoomOnline[]>) => {
      setOnline(ctx.data)
    },
  )
}
interface IMessageBan extends IMessage {
  reason?: string
  date?: string
  username?: string
}
function subscribeChannel(chatId?: string) {
  const correctChatId = `chat:${chatId || store.current?.id}`
  const centrifuge = useCentrifuge()
  const subscriptions = centrifuge.subscriptions()

  // unsubscribe
  const previousChatSubscriptionKeys = Object.keys(subscriptions).filter(
    string => string.includes('chat:'),
  )
  if (previousChatSubscriptionKeys)
    previousChatSubscriptionKeys.map(key => subscriptions[key].unsubscribe())

  const subscription = centrifuge.subscribe(correctChatId)

  subscription?.subscribe()

  const methods: IMethods = {
    new_message: (payload: IMessage) => {
      list.value.unshift(payload)

      if (list.value.length > 50 && !history.value)
        list.value.pop()

      if (userStore.isLogged && payload.userId === userStore.user?.id)
        scrollStart()
    },
    all_messages_deleted: (payload) => {
      const userId = payload.userId

      setList([
        ...list.value.filter(message => message.userId !== userId),
      ])
    },
    message_deleted: (payload) => {
      const messageId = payload?.messageId

      setList([
        ...list.value.filter(message => message.id !== messageId),
      ])
    },
    ban: (payload) => {
      const isCustomReason = !['insult_user', 'insult_admin', 'mat', 'spam', 'foreign_site'].includes(payload.reason)
      const message: IMessageBan = {
        type: 'ban',
        attachments: [],
        userId: '',
        createdAt: '',
        id: `ban-message-${Date.now()}`,
        text: 'ban',
        user: {
          avatarWebp: '',
          avatar: '',
          id: '',
          name: '',
          role: 'USER',
          vipStatus: {
            slug: 'newcomer1',
            level: 1,
          },
        },
        reason: isCustomReason ? payload.reason : t(`chat.ban_reason.${payload.reason}`),
        date: new Date(Date.now() + (payload.duration * 1000)).toLocaleString(),
        username: payload.username || '',
      }

      list.value.unshift(message)
    },
  }

  subscription?.on(
    'publication',
    (ctx: IServerPublicationContext<SocketPublication>) => {
      if (ctx.data.action) {
        if (['new_message', 'message_deleted', 'ban', 'all_messages_deleted'].includes(ctx.data.action))
          methods[ctx.data.action](ctx.data.payload)
      }
    },
  )
}

async function fetch(reset: boolean, direction: 'UP' | 'DOWN' = 'UP') {
  try {
    // если уже идет загрузка - выход
    if (loadStop.value)
      return

    // загрузка началась
    setLoadStop(true)

    // очищаем список если надо
    if (reset)
      setList([])

    // кол-во отображаемых сообщений
    const msgLength = messages.value.length

    // время верхнего сообщения
    const lastTimeLocal = msgLength
      ? direction === 'UP'
        ? messages.value[msgLength - 1].createdAt
        : messages.value[0].createdAt
      : null

    // сохраняем направление загрузки
    setSavedDirection(direction)

    // ???
    const result = getMessagesByTime(lastTimeLocal)

    if (
      (result.length && direction === 'DOWN')
      || (result.length >= 100 && direction === 'UP')
    ) {
      setLastTime(lastTimeLocal)

      await nextTick(() => {
        scrollToMessage(lastTimeLocal, false)
      })
    }
    else if (!lastTimeLocal || reset || direction === 'UP') {
      const resultList = await store.getMessages(
        store.current?.id || '',
        direction,
        lastTimeLocal || undefined,
      )

      if (resultList.length) {
        if (reset)
          setList(resultList)
        else
          setList([...list.value, ...resultList])

        if (direction === 'UP' && !reset)
          setHistory(true)

        setLastTime(lastTimeLocal)

        await nextTick(() => {
          // scrollToMessage(lastTimeLocal, direction === 'UP')
          nextTick(() => setLoadStop(false))
        })
      }
      else {
        setStopLoading(true)
        setLoadStop(false)

        if (direction === 'DOWN') {
          setStopLoading(false)
          setHistory(false)
        }
      }
    }
    else {
      if (lastTime.value) {
        await nextTick(() => {
          scrollToMessage(lastTime.value, false)
        })
      }
    }
  }
  catch (ex) {
    console.error(ex)
  }
  finally {
    setLoadStop(false)
  }
}
function scrollToMessage(lastTimeLocal: string | null, toTop = true) {
  const messageId = listSet.value.find(
    message => message.createdAt === lastTimeLocal,
  )?.id

  if (!messageId)
    return

  const container = document.getElementById('messages')
  const element = document.getElementById(`message_${messageId}`)
  const loader = document.getElementById('chatLoader')

  if (element && container) {
    let offset = 0

    const padding = Number.parseFloat(
      getComputedStyle(container).rowGap.replace(/[^+\d]/g, ''),
    )

    if (toTop) {
      offset
        = element.offsetTop
        - element.clientHeight
        + (loader?.clientHeight || 0)
        + padding
    }
    else {
      offset
        = element.offsetTop
        + element.clientHeight
        - container.clientHeight
        + (loader?.clientHeight || 0)
        - padding / 2
    }

    if (offset !== -9) {
      container.scrollTo({
        left: 0,
        top: offset,
        behavior: 'instant',
      })
    }
  }
}
function getMessagesByTime(time: string | null) {
  const count = 50

  if (!time)
    return listSet.value.slice(0, count)

  const index = listSet.value.findIndex(
    message => message.createdAt === time,
  )

  if (index < (count - 1))
    return listSet.value.slice(0, count)

  return listSet.value.slice(index - (count - 1), index + count + 1)
}
function loadMore() {
  if (!messagesUlRef.value)
    return

  // Показываем / скрываем кнопку
  if (Math.abs(messagesUlRef.value.scrollTop) > 200)
    setShowButtonScrollTop(true)
  else if (Math.abs(messagesUlRef.value.scrollTop) < 10)
    setShowButtonScrollTop(false)

  setScrollPosition(Math.abs(messagesUlRef.value.scrollTop))

  const scrolled = Math.abs(
    (100 * messagesUlRef.value.scrollTop)
      / (messagesUlRef.value.scrollHeight - messagesUlRef.value.clientHeight),
  )

  if (scrolled < 50)
    setStopLoading(false)

  if (!stopLoading.value) {
    // Дошли до верхней границы списка - грузим сверху
    if (scrolled >= 90)
      fetch(false, 'UP')

    // дошли до нижней - загружаем снизу
    else if (scrolled < 0.1 && history.value)
      fetch(false, 'DOWN')
  }
}
function scrollStart() {
  setLastTime(null)

  nextTick(() =>
    setTimeout(() => messagesUlRef.value?.scrollTo({ top: 0 }), 100),
  )
}

async function setup() {
  if (!isSSR()) {
    await store.updateRooms()

    subscribeChannel(store.current?.id)
    subscribeOnline()
  }
}
setup()

watch(() => store.current, async () => {
  await fetch(true, 'UP')

  // if (messages.value.length > 10)
  //   return

  await fetch(false, 'UP')
})

const selectOptions = computed(() => {
  if (!store.rooms)
    return []

  return store.rooms.map(getRoomWithOnline).map(room => ({
    id: room?.id,
    name: room.label || '',
    meta: {
      type: room.type,
      languageIsoCode: room.languageIsoCode,
      onlineUsers: room.onlineUsers,
      totalUsers: room.totalUsers,
    },
  }))
})

const activeOption = computed(() => {
  if (!store.current)
    return

  return getRoomWithOnline(store.current)
})
</script>

<template>
  <section class="chat">
    <div v-if="store.current" class="chat__title">
      <Select
        :items="selectOptions || []"
        :current-id="activeOption?.id as string"
        class="chat-room"
        item-classes="chat-room_item"
        @change="onClickChatRoom"
      >
        <template #placeholder>
          <IconRU v-if="activeOption?.languageIsoCode === 'RU'" />

          <IconEN v-else-if="activeOption?.languageIsoCode === 'EN'" />

          <b class="mr-auto">{{ activeOption?.label }}</b>

          <p>
            <span class="online" />
            {{ activeOption?.onlineUsers }} / {{ activeOption?.totalUsers }}
          </p>
        </template>

        <template #default="{ item }">
          <IconRU v-if="item?.meta?.languageIsoCode === 'RU'" />

          <IconEN v-else-if="item?.meta?.languageIsoCode === 'EN'" />

          <b>{{ item?.name }}</b>

          <p>
            <span class="online" />
            {{ item?.meta?.onlineUsers }} / {{ item?.meta?.totalUsers }}
          </p>
        </template>
      </Select>
    </div>

    <div v-else class="p-3 px-5 bg-gray shadow-[0px,4px,4px,rgba(12,14,16,0.3)]">
      <div class="filled filled-black w-full rounded-normal h-[38px]" />
    </div>

    <div class="chat_wrap">
      <div
        id="messages"
        ref="messagesUlRef"
        class="list chat__list"
        @scroll.passive="loadMore"
      >
        <MessageCombiner
          v-for="message in messages"
          :id="`message_${message.id}`"
          :key="message.id"
          :message="message"
          @reply="setReply"
        />

        <template v-if="!stopLoading || loadStop">
          <div v-if="messages.length" id="chatLoader" class="flex justify-center items-center">
            <i class="i-loading white" />
          </div>

          <template v-else>
            <div v-for="i in 10" :key="`chat-preloader-${i}`" class="bg-gray rounded-normal w-full min-h-[80px] p-3 flex flex-col justify-center gap-3">
              <div class="w-1/2 h-4 filled filled-gray rounded-small" />

              <div class="w-full h-4 filled filled-gray rounded-small" />
            </div>
          </template>
        </template>
      </div>

      <transition name="fade">
        <div v-if="showButtonScrollTop" class="to-start">
          <button class="btn-secondary to-start_btn " @click="scrollStart">
            <Arrow name="arrow" class="rotate-180" />
          </button>
        </div>
      </transition>

      <Send v-if="store.current" :reply="reply" @reply="setReply" />

      <div v-else class="p-5 pt-0 mt-auto">
        <div class="filled filled w-full rounded-normal h-[50px]" />
      </div>
    </div>
  </section>

<!--  <section v-else class="w-full h-full flex flex-col"> -->
<!--    <div class="p-3 bg-gray shadow-[0px,4px,4px,rgba(12,14,16,0.3)]"> -->
<!--      <div class="filled filled-black w-full rounded-normal h-[38px]" /> -->
<!--    </div> -->
<!--    -->
<!--    <div class="flex flex-col-reverse flex-grow px-5 gap-2 overflow-x-auto"> -->
<!--      <div v-for="i in 10" :key="`chat-preloader-${i}`" class="bg-gray rounded-normal w-full min-h-[80px] p-3 flex flex-col justify-center gap-3"> -->
<!--        <div class="w-1/2 h-4 filled filled-gray rounded-small" /> -->
<!--        -->
<!--        <div class="w-full h-4 filled filled-gray rounded-small" /> -->
<!--      </div> -->
<!--    </div> -->
<!--    -->
<!--    <div class="p-5 pt-6 mt-auto"> -->
<!--      <div class="filled filled w-full rounded-normal h-[50px]" /> -->
<!--    </div> -->
<!--  </section> -->
</template>

<style lang="scss">
.chat {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  &_wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--dark);
    height: calc(100% - 65px);

    //@include _1279 {
    //  height: calc(100% - 50px);
    //}
  }
  &-room {
    width: 100%;
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 8px;
      font-weight: 600;
      b {
        flex-grow: 1;
        text-align: left;
        font-weight: 600;
      }
      &.btn {
        /* padding-right: 28px !important; */
        gap: 8px !important;
      }
    }
  }
  &__list {
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column-reverse;
    padding: var(--padding) var(--padding) 0 !important;
    gap: 8px;
    margin: 0;
    flex-grow: 1;
    padding-bottom: 23px !important;
    position: relative;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px var(--padding);
    background: var(--gray);
    box-shadow: 0px 4px 4px rgba(12, 14, 16, 0.3);
    position: relative;
    z-index: 1;
    /* display: none;
    @include _1279 {
      display: flex;
    } */

    h2 {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: var(--white);
    }
  }

  &__background {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
  }

  .to-start {
    position: absolute;
    z-index: 1;
    bottom: 68px;
    left: 0;
    right: 0;
    padding: 12px var(--padding);
    display: flex;
    justify-content: flex-end;
    @include _989 {
      bottom: 61px;
    }
    @include _480 {
      bottom: 60px;
    }
    &_btn {
      width: 32px;
      height: 32px;
      background-color: var(--black);
      transform: rotate(90deg);
    }
  }
}
@include _480 {
  .chat {
    /* &_wrap {
      height: calc(100% - 50px);
    } */
    &__title {
      justify-content: flex-start;
    }
  }
}
</style>
