<script setup lang="ts">
import { useRouter } from 'vue-router'
import { t, useLocalePath } from '@/plugins/i18n'

import NotFoundImg from '@/assets/img/404.svg'
import { ErrorPage } from '@/cypress.types'

const router = useRouter()
const localePath = useLocalePath()
</script>

<template>
  <div class="container">
    <div :class="$style['not-found']">
      <NotFoundImg :class="$style['not-found-img']" />

      <h1 :class="$style['not-found-title']" :data-cy="ErrorPage.ERROR_PAGE_TITLE">
        {{ t("not_found.title") }}
      </h1>

      <p :class="$style['not-found-description']">
        {{ t("not_found.page_not_found") }}

        <br>

        {{ t("not_found.check_link") }}
      </p>

      <button
        class="btn btn-primary uppercase"
        @click="() => router.push(localePath('/'))"
      >
        <span>{{ t("not_found.go_back") }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" module>
.not-found {
  @apply flex flex-col items-center text-center pt-[50px] pb-[50px];

  @include _480 {
    @apply pt-0 pb-0;
  }
}

.not-found-title {
  @apply text-38 font-700 mb-[16px] text-white leading-none;

  @include _480 {
    @apply text-24;
  }
}

.not-found-description {
  @apply max-w-[440px] w-full text-16 text-white mb-[20px];

  @include _480 {
    @apply text-14;

    br {
      @apply hidden;
    }
  }
}

.not-found-img {
  @apply max-w-[440px] w-full h-auto mt-0 ml-auto mr-auto mb-[30px];
  height: 40vh;
}
</style>
