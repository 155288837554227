import { useCurrencies, useExchangeRates, useWallets } from '@modules/wallet'
import { getIconSymbolName, useUserStore } from '@modules/wallet/wallet.dependencies'
import { getBrowserCurrency } from '@helpers/currency'

export interface Exchanger {
  getRatesUpdatedAt: () => number

  exchangeById: (amount: number, fromId: string, toId: string) => number
  exchangeToViewOrUserCurrency: (amount: number, fromId: string) => number
  exchangeToUserCurrency: (amount: number, fromId: string) => number
  exchangeToViewCurrency: (amount: number, fromId: string) => number
  getViewCurrencyEnabled: () => boolean
  getUserCurrencyId: () => string | undefined
  getUserCurrencyIso: () => string
  getViewCurrencyId: () => string | undefined
  getViewCurrencyIso: () => string | undefined
  getViewOrUserCurrencyId: () => string | undefined
  getViewOrUserCurrencyIso: () => string

  // from helper
  getCurrencyIsoById: (currencyId: string) => string
  getCurrencyIdByIso: (currencyId: string) => string
  getIconSymbolNameById: (currencyId: string) => string
  getCurrencySymbolById: (currencyId: string) => string

  // with part
  withSymbol: (amount: string, currencyId: string) => string

  // nice round
  niceRound: (value: number, fromId: string, toId: string, options?: { up?: boolean }) => number
}
export function useExchanger(): Exchanger {
  const currencies = useCurrencies()
  const userStore = useUserStore()
  const rates = useExchangeRates()
  const wallet = useWallets()

  const getRatesUpdatedAt = () => rates.dataUpdatedAt.value

  const getCurrencyIsoById = (currencyId: string) => {
    return currencies.data.value?.find(currency => currency.id === currencyId)?.name || ''
  }
  const getCurrencyIdByIso = (currencyIso: string) => {
    return currencies.data.value?.find(currency => currency.name === currencyIso)?.id || ''
  }
  const getCurrencySymbolById = (currencyId: string) => {
    const symbols: Record<string, string> = {
      USD: '$',
      EUR: '€',
      USDT: '₮',
      RUB: '₽',
    }

    const isoCode = getCurrencyIsoById(currencyId)

    return symbols[isoCode] || ''
  }

  const exchangeById = (amount: number, fromId: string, toId: string): number => {
    if (fromId === toId)
      return amount

    const fromCurrency = currencies.data.value?.find(currency => currency.id === fromId)
    const toCurrency = currencies.data.value?.find(currency => currency.id === toId)

    if (!fromCurrency || !toCurrency)
      return 0

    const rate = rates.data.value?.find(eRate => eRate.currencyFrom === fromCurrency.name && eRate.currencyTo === toCurrency.name)?.rate

    if (!rate)
      return 0

    return amount * Number(rate)
  }

  const getUserCurrencyId = () => {
    return wallet.selectedRealWallet.value?.currencyId
  }
  const getUserCurrencyIso = () => {
    return currencies.data.value?.find(currency => currency.id === getUserCurrencyId())?.name || getBrowserCurrency() || 'RUB'
  }
  const getViewCurrencyId = () => {
    return userStore.user?.settings?.convertToDefaultCurrency
  }
  const getViewCurrencyIso = () => {
    return currencies.data.value?.find(currency => currency.id === getViewCurrencyId())?.name
  }
  const getViewOrUserCurrencyIso = () => {
    return currencies.data.value?.find(currency => currency.id === getViewCurrencyId())?.name || getUserCurrencyIso()
  }

  const getViewOrUserCurrencyId = () => {
    return getViewCurrencyId() || getUserCurrencyId()
  }
  const getViewCurrencyEnabled = () => !!userStore.user?.settings?.convertToDefaultCurrency

  const exchangeToUserCurrency = (amount: number, fromId: string): number => {
    const toId = getCurrencyIdByIso(getUserCurrencyIso()) || '643'

    return exchangeById(amount, fromId, toId)
  }

  const exchangeToViewCurrency = (amount: number, fromId: string): number => {
    const viewCurrency = getViewCurrencyId()

    if (!viewCurrency)
      return amount

    return exchangeById(amount, fromId, viewCurrency)
  }

  const exchangeToViewOrUserCurrency = (amount: number, fromId: string): number => {
    const viewCurrency = getViewCurrencyId()

    if (!viewCurrency)
      return exchangeToUserCurrency(amount, fromId)
    else
      return exchangeToViewCurrency(amount, fromId)
  }

  const getIconSymbolNameById = (currencyId: string) => {
    return getIconSymbolName(getCurrencyIsoById(currencyId))
  }

  const niceRound = (value: number, fromId: string, toId: string, options?: { up?: boolean }) => {
    const exchangedValue = Number(exchangeById(value, fromId, toId).toFixed())

    if (options?.up) {
      const residualNumber = !!(exchangedValue % 10)
      if (residualNumber) {
        const method = options?.up ? Math.ceil : Math.floor

        return method(exchangedValue / 10) * 10
      }

      return exchangedValue
    }
    else {
      return parseFloat(parseFloat(exchangedValue.toString()).toPrecision(2))
    }
  }

  const withSymbol = (amount: string, currencyId: string) => {
    return `${amount}${getCurrencySymbolById(currencyId)}`
  }

  return {
    withSymbol,
    niceRound,
    getIconSymbolNameById,
    getCurrencyIsoById,
    getCurrencyIdByIso,
    getRatesUpdatedAt,
    getUserCurrencyId,
    getViewOrUserCurrencyIso,
    getUserCurrencyIso,
    exchangeById,
    getViewOrUserCurrencyId,
    getViewCurrencyEnabled,
    exchangeToViewCurrency,
    exchangeToUserCurrency,
    exchangeToViewOrUserCurrency,
    getViewCurrencyId,
    getViewCurrencyIso,
    getCurrencySymbolById,
  }
}
