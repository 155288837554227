<script setup lang="ts">
import { Banners } from '@components/Layout/Sidebar/Left/index'
import { vOnClickOutside } from '@vueuse/components'
import { useModalLayer } from '@composable/useModal'
import { useToggle } from '@composable/useToggle'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import Bandana from '@/assets/icon/bandana.svg'
import Arrow from '@/assets/icon/arrow.svg'
import CasinoGameSlots from '@/assets/icon/casino-game-slots.svg'
import Crown from '@/assets/icon/crown.svg'
import Giveaway from '@/assets/icon/giveaway.svg'
import Support from '@/assets/icon/support.svg'
import Info from '@/assets/icon/info.svg'
import Referral from '@/assets/icon/referral.svg'

import Baccarat from '@/assets/icon/baccarat.svg'
import Money from '@/assets/icon/money.svg'
import Heart from '@/assets/icon/heart.svg'
import Games from '@/assets/icon/games.svg'
import Live from '@/assets/icon/live.svg'
import Star from '@/assets/icon/star.svg'
import Ranking from '@/assets/icon/ranking.svg'
import Fire from '@/assets/icon/fire.svg'

import { useLayoutStore } from '@/store/Layout'
import { t, useLocalePath } from '@/plugins/i18n'
import { useUserStore } from '@/modules/user'
import { getOurPublishGamesUnique } from '@/config/games'
import { LeftMenu } from '@/cypress.types'
import { isBeta } from '@/config/env'

interface Props {
  needUpdate: boolean
}

interface NavLink {
  dataCy: string
  vIf?: boolean
  to: string
  class: string
  icon: {
    name: string
    class: string
  }
  text: string
}

defineProps<Props>()
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const modalLayer = useModalLayer()
const [games, toggleGames] = useToggle(false)
const [casino, toggleCasino] = useToggle(false)
const localePath = useLocalePath()
const route = useRoute()

const onBoarding = () => modalLayer.show('beta.onboarding')

const navLinks = computed<NavLink[]>(() => {
  return ([
    {
      dataCy: LeftMenu.ALL_GAMES_LEFT_SECTION,
      to: localePath('/all-games'),
      class: route.fullPath === '/all-games' ? 'sidenav--link--active' : '',
      text: t('all_games'),
      icon: {
        name: Games,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.FAVOURITES_LEFT_SECTION,
      vIf: !!userStore.isLogged,
      to: localePath('/all-games?type=favorites'),
      class: route.fullPath === '/all-games?type=favorites' ? 'sidenav--link--active' : '',
      text: t('favorites'),
      icon: {
        name: Heart,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.HOT_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=hot'),
      class: route.fullPath === '/all-games?type=hot' ? 'sidenav--link--active' : '',
      text: t('hot'),
      vIf: false,
      icon: {
        name: Fire,
        class: 'w-5 h-5 text-[#ED7E11]',
      },
    },
    {
      dataCy: LeftMenu.TOP_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=top'),
      class: route.fullPath === '/all-games?type=top' ? 'sidenav--link--active' : '',
      text: t('top'),
      icon: {
        name: Ranking,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.NEW_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=new'),
      class: route.fullPath === '/all-games?type=new' ? 'sidenav--link--active' : '',
      text: t('new'),
      vIf: false,
      icon: {
        name: Star,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.SLOTS_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=slots'),
      class: route.fullPath === '/all-games?type=slots' ? 'sidenav--link--active' : '',
      text: t('slots'),
      icon: {
        name: CasinoGameSlots,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.LIVE_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=live'),
      class: route.fullPath === '/all-games?type=live' ? 'sidenav--link--active' : '',
      text: t('live'),
      vIf: false,
      icon: {
        name: Live,
        class: 'w-6 h-6',
      },
    },
    {
      dataCy: LeftMenu.BONUS_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=bonus-buy'),
      class: route.fullPath === '/all-games?type=bonus-buy' ? 'sidenav--link--active' : '',
      text: t('bonus-buy'),
      icon: {
        name: Money,
        class: 'w-5 h-5',
      },
    },
    {
      dataCy: LeftMenu.ROULETTE_GAMES_LEFT_SECTION,
      to: localePath('/all-games?type=roulette'),
      class: route.fullPath === '/all-games?type=roulette' ? 'sidenav--link--active' : '',
      text: t('roulette'),
      icon: {
        name: Baccarat,
        class: 'w-6 h-6',
      },
    },
  ] as NavLink[]).filter(item => (item?.vIf !== undefined && Boolean(item?.vIf)) || item?.vIf === undefined)
})

function close() {
  layoutStore.setLeftSide('closed')
}

function onCreateGiveaway() {
  layoutStore.closeIfMobile('left')
  modalLayer.show('bonus_giveaway_create')
}
</script>

<template>
  <aside
    class="sidenav" :class="[$style.root, {
      [$style.openMobile]: layoutStore.hideLeft,
      [$style.hideDesktop]: layoutStore.hideLeft,
      [$style.needUpdate]: needUpdate,
    }]" @click="close"
  >
    <div :class="$style.backdrop" />

    <div
      id="sidebar_left" v-on-click-outside="() => layoutStore.closeIfMobile('left')" :class="$style.wrap" class="sidenav_wrap" :data-cy="LeftMenu.LEFT_SIDEBAR" @click.stop
    >
      <Banners @close-left="() => layoutStore.closeIfMobile('left')" @click.stop />

      <nav class="sidenav-menu" @click.stop>
        <div class="sidenav-cat" :class="{ hide: games }">
          <button class="sidenav-cat_header btn-dark2" :data-cy="LeftMenu.BANDANA_GAMES_LEFT_DROPDOWN" @click="toggleGames">
            <Bandana name="bandana" class="w-6 h-6" />

            <p>Bandana {{ t('games').toLocaleLowerCase() }}</p>

            <Arrow name="arrow" class="arrow" />
          </button>

          <div class="sidenav-cat_body">
            <ul class="list sidenav-cat__list">
              <li :data-cy="LeftMenu.AIRBLAST_GAME_LEFT_SECTION">
                <!--                <WithPreloader > -->
                <RouterLink v-for="ourGame in getOurPublishGamesUnique()" :key="`our-game${ourGame.id}`" :data-cy="ourGame.name + LeftMenu.OUR_GAME_LEFT_SECTION" :to="localePath(`/${ourGame.path}`)" class="btn sidenav--link">
                  <i>
                    <component :is="ourGame.icon" class="w-6 h-6" />
                  </i>

                  <span class="whitespace-nowrap">{{ ourGame.name }}</span>
                </RouterLink>

                <!--                  <template #preloader> -->
                <!--                    <LazyNavItem /> -->
                <!--                  </template> -->
                <!--                </WithPreloader> -->
              </li>
            </ul>
          </div>
        </div>

        <div class="sidenav-cat last" :class="{ hide: casino }">
          <button class="sidenav-cat_header btn-dark2" :data-cy="LeftMenu.CASINO_GAMES_LEFT_DROPDOWN" @click="toggleCasino">
            <CasinoGameSlots name="casino-game-slots" class="w-6 h-6" />

            <p>{{ t('casino') }}</p>

            <Arrow name="arrow" class="arrow w-[19px] h-[19px]" />
          </button>

          <div class="sidenav-cat_body">
            <ul class="list sidenav-cat__list">
              <!--              <WithPreloader > -->
              <li v-for="item of navLinks" :key="item.text" :data-cy="item.dataCy">
                <RouterLink :to="item.to" class="btn sidenav--link" exact-active-class="" :class="item.class">
                  <i>
                    <component :is="item.icon.name" :class="item.icon.class" />
                  </i>

                  <span class="whitespace-nowrap">{{ item.text }}</span>
                </RouterLink>
              </li>

              <!--                <template #preloader> -->
              <!--                  <LazyNavItem /> -->
              <!--                </template> -->
              <!--              </WithPreloader> -->
            </ul>
          </div>
        </div>

        <div class="sidenav-more">
          <ul class="list sidenav-more__list">
            <!--            <WithPreloader> -->
            <li :data-cy="LeftMenu.BANDANA_CLUB_LEFT_SECTION">
              <RouterLink :to="localePath('/club')" class="btn sidenav--link">
                <i>
                  <Crown name="crown" class="w-6 h-6" />
                </i>

                <span>Bandana Club</span>
              </RouterLink>
            </li>

            <!--              <template #preloader> -->
            <!--                <LazyNavItem class="pl-9 pr-5" /> -->
            <!--              </template> -->
            <!--            </WithPreloader> -->

            <!--            <WithPreloader> -->
            <li v-if="!isBeta" :data-cy="LeftMenu.REFERRAL_LEFT_SECTION">
              <RouterLink :to="localePath('/referral')" class="btn sidenav--link">
                <i>
                  <Referral name="referral" class="w-[18px] h-[18px]" />
                </i>

                <span class="whitespace-nowrap">{{ t('affiliate_program') }}</span>
              </RouterLink>
            </li>

            <!--              <template #preloader> -->
            <!--                <LazyNavItem class="pl-9 pr-5" /> -->
            <!--              </template> -->
            <!--            </WithPreloader> -->

            <!--            <WithPreloader v-if="userStore.isLogged && userStore.getIsUserCanCreateRaffle()"> -->
            <li v-if="userStore.isLogged && userStore.getIsUserCanCreateRaffle()" :data-cy="LeftMenu.GIVEAWAY_LEFT_SECTION">
              <button
                class="btn sidenav--link"
                @click="onCreateGiveaway"
              >
                <i><Giveaway name="giveaway" class="w-5 h-5" /></i>

                <span class="whitespace-nowrap">{{ t('giveaway.create') }}</span>
              </button>
            </li>

            <!--              <template #preloader> -->
            <!--                <LazyNavItem class="pl-9 pr-5" /> -->
            <!--              </template> -->
            <!--            </WithPreloader> -->

            <!--            <WithPreloader> -->
            <li :data-cy="LeftMenu.SUPPORT_LEFT_SECTION">
              <RouterLink :to="localePath('/support')" class="btn sidenav--link">
                <i>
                  <Support name="support" class="w-5 h-5" />
                </i>

                <span class="whitespace-nowrap">{{ t('support.title') }}</span>
              </RouterLink>
            </li>

            <li v-if="isBeta" @click="onBoarding">
              <p class="btn sidenav--link">
                <i>
                  <Info name="support" class="w-6 h-6" />
                </i>

                <span class="whitespace-nowrap">{{ t('beta.onboarding.modalName') }}</span>
              </p>
            </li>

            <!--              <template #preloader> -->
            <!--                <LazyNavItem class="pl-9 pr-5" /> -->
            <!--              </template> -->
            <!--            </WithPreloader> -->
          </ul>
        </div>
      </nav>
    </div>
  </aside>
</template>

<style module lang="scss">
.needUpdate {
  & .wrap {
    @apply transition-all;
    padding-top: 38px;
  }
}

.root {

}

.backdrop {
  @apply fixed left-0 right-0 top-[70px] bottom-0 z-[3000] hidden;
  background: rgba(0, 0, 0, 0.553);
  transition: 0.3s;

  @include _1170 {
    @apply bottom-[64px];
  }
}
.wrap {
  @apply fixed w-[300px] z-[3030] top-[70px] left-0 bottom-0 flex flex-col;
  @apply overflow-y-auto overflow-x-hidden translate-y-0;
  background: var(--black);

  @include _1279 {
    transform: translateX(-100%);
  }

  @include _1170 {
    @apply bottom-[64px];
  }
}

.hideDesktop {
  @media screen and (min-width: 1280px) {
    & .wrap {
      transform: translateX(-100%);
    }
  }
}

.openMobile {
  @include _1279 {
    & .backdrop {
      @apply block;
    }
    & .wrap {
      transform: unset;
    }
  }
}
</style>

<style lang="scss" scoped>
.sidenav {
  &-cat {
    background: var(--gray-5);
    padding: 0 var(--padding) 12px;

    &.last {
      padding-bottom: 20px;
    }

    .sidenav--link {
      padding: 3px 8px 3px 16px;
      width: 100%;
    }

    &_header {
      background: var(--gray-4);
      height: 50px;
      min-height: 50px;
      width: 100%;
      padding: 3px 12px;
      font-size: 16px;
      font-weight: 700;
      color: var(--white);
      text-align: left;

      p {
        flex-grow: 1;
      }

      .arrow {
        transform: rotate(270deg);
        transition: transform 0.3s;
        color: var(--gray-2);
      }
    }

    &_body {
      opacity: 1;
      visibility: visible;
      overflow: hidden;
      transition: 0.4s;
      transform: unset;
      max-height: 440px;
    }

    &__list {
      padding: 4px 0;
    }

    &.hide {
      .arrow {
        transform: rotate(180deg);
      }

      .sidenav-cat {
        &_body {
          opacity: 0;
          padding-bottom: 0;
          max-height: 0;
          visibility: hidden;
          overflow: hidden;
          /* transform: translateX(-1rem); */
        }
      }
    }
  }

  &-more {
    &__list {
      padding: 4px 0;
    }
  }

  &--link {
    padding: 3px 36px;

    @include _989 {
      padding: 3px 26px;
    }

    height: 48px;
    min-height: 48px;
    gap: 16px;
    justify-content: flex-start;
    text-align: left;
    color: var(--white);
    font-weight: 700;
    font-size: 16px;
    width: 100%;

    i {
      display: flex;
      justify-content: center;
      width: 24px;
      min-width: 24px;
    }

    svg {
      color: var(--gray-2);
    }

    span {
      width: 100%;

      &:after {
        margin: 0;
      }
    }

    em {
      white-space: nowrap;
      color: var(--gray-2);
      font-size: 14px;
    }

    &:hover {
      color: var(--yellow);
    }

    //&.active,
    /* &.nuxt-link-active, */
    &.router-link-exact-active,
    &--active {
      color: var(--yellow);

      svg {
        color: inherit;
      }
    }
  }
}
</style>
