import { useState } from '@composable/useState'
import { onMounted, onUnmounted } from 'vue'

export default function useResizeHandler(delay: number) {
  const [isResized, setIsResized] = useState<boolean>(false)

  function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>
    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      if (!isResized.value) {
        setIsResized(true)
        document.body.setAttribute('transition', 'unset')
        const container = document.querySelector('.app_main__wrap .container') as HTMLElement | null
        if (container)
          container.style.width = `${container?.offsetWidth}px`
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this

      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func.apply(context, args)
      }, delay) as ReturnType<typeof setTimeout>
    }
  }

  const handleResize = debounce(() => {
    document.body.removeAttribute('transition')
    const container = document.querySelector('.app_main__wrap .container') as HTMLElement | null
    if (container)
      container.style.width = ''

    setIsResized(false)
  }, delay)

  onMounted(() => {
    window.addEventListener('resize', handleResize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
  })
}
