import mitt from 'mitt'
import type { Emitter, EventType } from 'mitt'

interface Events extends Record<EventType, unknown> {
  'modal:hide': { name: string }
  'modal:show': {
    name: string
    payload?: any
  }
  'modal:showSecond': {
    name: string
    payload?: any
  }
  'update:notification': undefined
  'update:confirm': undefined
  'giveaway:socket': {
    data: any
  }
  'giveaway:user': {
    data: any
  }
  'modal:update_deposit_status': {
    id: string
    status: string
  }
}

const bus: Emitter<Events> = mitt()

export function useBus() {
  return bus
}
