import type { Subscription } from 'centrifuge'
import { Centrifuge } from 'centrifuge'
import { useUserStore } from '@modules/user'
import { addSubscriptionListeners } from '@modules/wallet'
import { useSupportStore } from '@/modules/support'
import { isSSR } from '@/helpers/utils'
import { Centrifugo } from '@/config/env'

const guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwIn0.o1EQp9Qs6OFws5iPfvQItyC77wlDQ7tQWP_RagtCoHU'
class Manager extends Centrifuge {
  private readonly subs: { [key: string]: Subscription } = {}

  constructor(url: string, config?: object) {
    super(url, config)
    this.subs = {}
    this.on('connected', this.onConnected)
    this.on('disconnected', this.onDisconnect)
  }

  onConnected() {
    // subscribe
    Object.entries(this.subs).forEach(([channel, sub]) => {
      this.subs[channel] = sub
      sub.subscribe()
    })
  }

  middleware() {
    // middleware
  }

  onDisconnect() {
    // unsubscribe
    Object.entries(this.subs).forEach(([channel, sub]) => {
      this.subs[channel] = sub
      sub.unsubscribe()
    })
  }

  subscribe(channel: string) {
    const sub = this.getSubscription(channel)

    if (!sub) {
      const subscription = super.newSubscription(channel)
      subscription.subscribe()
      this.subs[channel] = subscription

      return subscription
    }

    return this.getSubscription(channel)
  }

  unsubscribe(channel: string) {
    if (this.getSubscription(channel)) {
      this.subs[channel]?.unsubscribe()
      this.subs[channel]?.removeAllListeners()
      delete this.subs[channel]
    }
  }

  getSub(channel: string) {
    const sub = this.subs[channel]
    if (!sub)
      return null

    return sub
  }

  removeUserSubscriptions() {
    const channels = Object.keys(this.subs).filter(x => x.includes('#'))

    channels.forEach((channel) => {
      this.unsubscribe(channel)
    })
  }

  reconnect() {
    this.disconnect()
    this.connect()
  }
}

function createCentrifuge() {
  return new Manager(Centrifugo.ws, {
    getToken: async () => {
      if (isSSR())
        return
      const store = useUserStore()

      return store.user?.centrifugoToken || guestToken
    },
  })
}

let centrifuge = createCentrifuge()
function reCreateCentrifuge() {
  centrifuge = createCentrifuge()
}

export function connectUserSocket() {
  if (isSSR())
    return
  reCreateCentrifuge()
  const userStore = useUserStore()
  const supportStore = useSupportStore()

  if (userStore.isLogged && userStore.user?.centrifugoToken) {
    const subscription = userStore.subscribeUserChannel()

    if (subscription) {
      addSubscriptionListeners(subscription)
      userStore.addSubscriptionListeners(subscription)
      supportStore.addSubscriptionListeners(subscription)
    }
  }

  centrifuge.reconnect()
}

export function connectGuestSocket() {
  reCreateCentrifuge()
  centrifuge.reconnect()
}

export function disconnectUserSocket() {
  centrifuge.removeUserSubscriptions()
}

export function useCentrifuge() {
  return centrifuge
}
