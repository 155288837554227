import type { ComputedRef, Ref } from '@modules/user/user.dependencies'
import {
  computed,
  defineStore,
  isSSR,
  useCentrifuge, useError,
  useModalLayer, useState, useSuccess,
  watch,
} from '@modules/user/user.dependencies'
import {
  addCurrencyToSettingsService,
  deleteCurrencyFromSettingsService,
  disableTfa,
  enableTfa,
  putUserInfo,
  resetPassword as resetPasswordService,
  resetPasswordTfa as resetPasswordTfaService,
  selectConvertCurrencyService,
  userInfo,
  verifyEmailSend as verifyEmailSendService,
  verifyEmail as verifyEmailService,
} from '@modules/user/user.service'
import type { Ban, User, UserVipStatus } from '@modules/user/user.model'
import type {
  UserDtoChangeConvertCurrency,
  UserDtoDisableTfaRequest,
  UserDtoEnableTfaRequest,
  UserDtoGetMeRequest,
  UserDtoMeRequest,
  UserDtoResetRequest,
  UserDtoVerifyEmailRequest,
} from '@modules/user/user.dto'
import type { Subscription } from 'centrifuge'
import { QUERY_RESOURSES } from '@modules/club/club.constants'
import { useGiveawayToast } from '@modules/bonus/composable/useGiveawayToast'
import { mutateHistoryDepositData } from '@modules/wallet/composable/useHistory'
import { changeClientSeed, changeServerSeedService } from '@modules/games/airblast/airblast.service'
import { getBonusTitle } from '@modules/bonus/bonus.helpers'
import { getSupportWebp } from '@helpers/webp'
import type { Bonus } from '../bonus/bonus.model'
import { GiveawayStatus } from '../bonus/bonus.model'
import { useBus } from '@/composable/useBus'
import { useQueryStore } from '@/store/Query'
import { t } from '@/plugins/i18n'

interface IUserStore {
  user: Ref<User | undefined>
  loading: Ref<boolean>
  isIdentificationEnd: Ref<boolean>

  isLogged: ComputedRef<boolean>
  isWasAuthed: ComputedRef<boolean>
  isLocalStorageHasName: ComputedRef<boolean>
  isNeedLoadProfileInfo: ComputedRef<boolean>
  isGoogleTfaEnabled: ComputedRef<boolean>
  isAdmin: ComputedRef<boolean>
  isModerator: ComputedRef<boolean>
  activeChatBan: ComputedRef<Ban | undefined>
  activeSupportBan: ComputedRef<Ban | undefined>
  activeDepositBan: ComputedRef<Ban | undefined>
  activeWithdrawBan: ComputedRef<Ban | undefined>
  userVip: Ref<UserVipStatus | undefined>

  subscribeUserChannel: () => Subscription | null
  addSubscriptionListeners: (subscription: Subscription) => void

  updateUser: (payload: any) => void
  setUser: (payload: IUserStore['user']['value']) => void
  getUserAvatar: (hash?: string) => ({ url?: string; color?: string; text?: string })
  getIsAdmin: (role: string) => boolean
  getIsUserCanCreateRaffle: () => boolean
  getIsModerator: (role: string) => boolean
  clearUser: () => void
  setIsIdentificationEnd: (payload: boolean) => void
  getUserChannel: () => string

  changeSeed: (clientSeed: string) => Promise<boolean | undefined>
  changeServerSeed: () => Promise<boolean | undefined>
  changeConvertCurrency: (payload: UserDtoChangeConvertCurrency) => Promise<boolean | undefined>
  connectTfa: (payload: UserDtoEnableTfaRequest) => Promise<boolean | undefined>
  disconnectTfa: (payload: UserDtoDisableTfaRequest) => Promise<boolean | undefined>
  getUser: (options?: UserDtoGetMeRequest) => Promise<boolean | undefined>
  editUser: (payload: Partial<UserDtoMeRequest>) => Promise<boolean | undefined>
  resetPassword: (payload: UserDtoResetRequest, t: (value: string) => string) => Promise<boolean | undefined>
  resetPasswordTfa: (payload: { authenticatorCode: string }) => Promise<boolean | undefined>
  verifyEmail: (payload: UserDtoVerifyEmailRequest) => Promise<boolean>
  verifyEmailSend: () => Promise<boolean>

  changeCurrencies: (ids: string[], action: 'push' | 'remove') => Promise<boolean>
}

export const useUserStore = defineStore<string, IUserStore>('userStore', () => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [isIdentificationEnd, setIsIdentificationEnd] = useState<boolean>(false)

  // helpers
  const getIsUserCanCreateRaffle = () => !!user.value?.permissions?.includes('BONUS.CREATE_RAFFLE')
  const getIsAdmin = (role: string) => role === 'ADMINISTRATOR'
  const getIsModerator = (role: string) => role === 'MODERATOR'
  const getUserAvatar = (str?: string) => {
    const localHash = str || user.value?.avatar || ''
    const [color, text] = localHash.split(':')

    if (user.value?.settings?.streamer) {
      if ((str || user.value?.avatar || '').includes('/')) {
        const url = str || user.value?.avatar || ''
        const localColor = url.split('/')?.at(1)?.substring(0, 6) || ''

        const r = (Number.parseInt(localColor.replace('#', '').substring(0, 2), 16) + 128) % 255
        const g = (Number.parseInt(localColor.replace('#', '').substring(0, 2), 16) + 128) % 255
        const b = (Number.parseInt(localColor.replace('#', '').substring(0, 2), 16) + 128) % 255

        const toHex = (value: number) => value.toString(16).padStart(2, '0')

        const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`

        return {
          color: hex, text: '',
        }
      }
      else {
        const r = (Number.parseInt(color.replace('#', '').substring(0, 2), 16) + 128) % 255
        const g = (Number.parseInt(color.replace('#', '').substring(0, 2), 16) + 128) % 255
        const b = (Number.parseInt(color.replace('#', '').substring(0, 2), 16) + 128) % 255

        const toHex = (value: number) => value.toString(16).padStart(2, '0')

        const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`

        return {
          color: hex, text: '',
        }
      }
    }
    else {
      if ((str || user.value?.avatar || '').includes('/')) {
        return {
          url: str || (getSupportWebp() ? user.value?.avatarWebp : user.value?.avatar),
        }
      }
      else {
        return {
          color, text,
        }
      }
    }
  }
  const getUserChannel = () => `user:#${user.value?.id}`

  // computed
  const isLogged = computed<boolean>(() => !!(user.value?.name && user.value?.id && user.value?.centrifugoToken))
  const isWasAuthed = computed<boolean>(() => !!(user.value?.name && !user.value?.id && !user.value?.centrifugoToken))
  const isLocalStorageHasName = computed<boolean>(() => !!user.value?.name)
  const isNeedLoadProfileInfo = computed<boolean>(() => !user.value?.profile)
  const isGoogleTfaEnabled = computed<boolean>(() => user.value?.settings?.tfa?.type === 'GOOGLE')
  const isAdmin = computed<boolean>(() => getIsAdmin(user.value?.role || ''))
  const isModerator = computed<boolean>(() => getIsModerator(user.value?.role || ''))

  const activeSupportBan = computed<Ban | undefined>(() => user.value?.bans?.find(ban => ban.banName === 'SUPPORT.CREATE_TICKET' && ((new Date(ban.createdAt).getTime() + (ban.durationInSeconds * 1000) >= Date.now()) || ban.durationInSeconds === null)))
  const activeDepositBan = computed<Ban | undefined>(() => user.value?.bans?.find(ban => ban.banName === 'PAYMENTS.DEPOSIT_CREATE' && ((new Date(ban.createdAt).getTime() + (ban.durationInSeconds * 1000) >= Date.now()) || ban.durationInSeconds === null)))
  const activeWithdrawBan = computed<Ban | undefined>(() => user.value?.bans?.find(ban => ban.banName === 'PAYMENTS.WITHDRAW_CREATE' && ((new Date(ban.createdAt).getTime() + (ban.durationInSeconds * 1000) >= Date.now()) || ban.durationInSeconds === null)))
  const activeChatBan = computed<Ban | undefined>(() => user.value?.bans?.find(ban => ban.banName === 'CHAT.SEND_MESSAGE' && ((new Date(ban.createdAt).getTime() + (ban.durationInSeconds * 1000) >= Date.now()) || ban.durationInSeconds === null)))
  const userVip = computed<UserVipStatus | undefined>(() => user.value?.vipStatus)

  // mutations
  const clearUser = () => setUser(undefined)
  const updateUser = (payload: any) => setUser({
    ...user.value,
    ...payload,
  })
  const removeBan = (banName: string) => {
    updateUser({
      bans: user.value?.bans?.filter(item => item.banName !== banName) || [],
    })
  }

  // actions
  const subscribeUserChannel = (): Subscription | null => {
    const centrifuge = useCentrifuge()

    const userChannel = getUserChannel()

    const sub = centrifuge.subscribe(userChannel)

    sub?.subscribe()

    return sub
  }
  const addSubscriptionListeners = async (subscription: Subscription) => {
    const bus = useBus()
    const queryStore = useQueryStore()

    subscription.on(
      'publication', (ctx) => {
        // if user update
        if (ctx.data?.action === 'update') {
          updateUser({
            emailVerifiedAt: ctx?.data?.payload?.emailVerifiedAt || '',
          })
        }

        // vip user rank reward
        if (['vip_user_rank_reward'].includes(ctx.data?.action)) {
          // const payload = ctx.data?.payload as { slug: UserVipStatusRankSlug; transaction: any } | undefined

          useSuccess(t('notify.vip_user_rank_reward'))
        }

        // user bonus complete
        if (['bonus_complete'].includes(ctx.data?.action)) {
          const payload = ctx.data?.payload as Bonus | undefined

          if (!payload)
            return

          const bonusTitle = getBonusTitle(payload)

          useSuccess(`${t('bonuses.complete.success')} ${t(bonusTitle.default, { yellow: bonusTitle.yellow ? t(bonusTitle.yellow) : '' })}`)
        }

        //

        if (['withdraw_status_changed', 'deposit_status_changed'].includes(ctx.data?.action)) {
          const payload = ctx.data?.payload as { id: string; status: string } | undefined
          const isDeposit = ctx.data?.action === 'deposit_status_changed'
          if (isDeposit)
            bus.emit('modal:update_deposit_status', payload || { id: '', status: '' })

          mutateHistoryDepositData({
            queryClient: queryStore.queryClient,
            isDeposit,
            updater: (data) => {
              return data
                ? {
                    ...data,
                    data: data?.data?.map(item => ({
                      ...item,
                      status: item.id === payload?.id ? (payload?.status || item?.status) : item?.status,
                    })) || [],
                  }
                : undefined
            },
          })
        }

        if (ctx.data?.action === 'update_vip_user_score') {
          queryStore.queryClient?.setQueriesData([QUERY_RESOURSES.GET_RANK_RESOURSE], (data: any) => {
            if (ctx.data?.payload?.statusId !== data?.statusId)
              useSuccess(t('club.level_up'))

            return {
              ...(data || {}),
              ...(ctx.data?.payload || {}),
            }
          })
        }

        // if user is banned
        if (ctx.data?.action === 'ban') {
          const bans = user.value?.bans || []

          bans.push({
            id: Date.now().toString(),
            createdAt: new Date().toString(),
            banName: ctx.data?.payload?.banName || '',
            durationInSeconds: ctx.data?.payload?.duration,
            reason: ctx.data?.payload?.reason,
          })

          updateUser({
            bans,
          })
        }

        // if user was unbanned
        if (ctx.data?.action === 'unban')
          removeBan(ctx.data?.payload?.banName)

        // Giveaway
        if (ctx.data?.action.includes('raffle_')) {
          bus.emit('giveaway:user', ctx.data)
          const { showToast, closeToast } = useGiveawayToast(ctx.data.payload)
          if (ctx.data.payload.status === GiveawayStatus.ACTIVE)
            showToast()
          else closeToast()
        }
      })
  }

  const changeServerSeed = async () => {
    try {
      setLoading(true)

      const serviceResponse = await changeServerSeedService()

      if (serviceResponse?.success) {
        updateUser({
          ...user.value,
          userSeed: serviceResponse.response,
        })
      }

      return !!serviceResponse?.success
    }
    catch {
      // ~
    }
    finally {
      setLoading(false)
    }
  }

  const changeSeed = async (clientSeed: string) => {
    if (loading.value)
      return
    try {
      setLoading(true)
      const serviceResponse = await changeClientSeed({ clientSeed })

      if (serviceResponse?.success) {
        updateUser({
          ...user.value,
          clientSeed,
        })
      }

      return !!serviceResponse?.success
    }
    catch {
      // ~
      return false
    }
    finally {
      setLoading(false)
    }
  }

  const getUser = async (options: UserDtoGetMeRequest = { params: {} }) => {
    try {
      setLoading(true)

      const serviceResponse = await userInfo(options)

      if (serviceResponse?.success) {
        setUser({
          ...(user.value || {}),
          ...serviceResponse.response,
        })
      }

      return serviceResponse?.success
    }
    finally {
      setLoading(false)
    }
  }
  const editUser = async (payload: Partial<UserDtoMeRequest>) => {
    if (loading.value)
      return
    try {
      setLoading(true)

      const serviceResponse = await putUserInfo(payload)

      if (!serviceResponse)
        return false

      updateUser(serviceResponse.response)

      return !!serviceResponse?.success
    }
    finally {
      setLoading(false)
    }
  }
  const resetPassword = async (payload: UserDtoResetRequest, t: (value: string) => string) => {
    if (loading.value)
      return
    try {
      setLoading(true)

      const serviceResponse = await resetPasswordService(payload)

      if (serviceResponse?.response?.tfa?.type === 'GOOGLE') {
        const modalLayer = useModalLayer()

        modalLayer.show('login_tfa', {
          type: 'reset-password',
          onContinue: () => {
            useSuccess(t('notify.password_changed'))
          },
        })
      }

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }
  const resetPasswordTfa = async (payload: { authenticatorCode: string }) => {
    if (loading.value)
      return
    try {
      setLoading(true)

      const serviceResponse = await resetPasswordTfaService(payload)

      return serviceResponse.success
    }
    finally {
      setLoading(false)
    }
  }

  const connectTfa = async (payload: UserDtoEnableTfaRequest) => {
    if (loading.value)
      return

    try {
      setLoading(true)

      const serviceResponse = await enableTfa(payload)

      if (serviceResponse.success)
        updateUser(serviceResponse.response)

      return true
    }
    catch {
      // ~
    }
    finally {
      setLoading(false)
    }
  }

  const disconnectTfa = async (payload: UserDtoDisableTfaRequest) => {
    if (loading.value)
      return
    try {
      setLoading(true)
      const serviceResponse = await disableTfa(payload)

      if (serviceResponse.success) {
        updateUser({
          ...user.value,
          settings: {
            ...user.value?.settings,
            tfa: undefined,
          },
        })
      }

      return true
    }
    catch {
      // ~
    }
    finally {
      setLoading(false)
    }
  }

  const verifyEmail = async (payload: UserDtoVerifyEmailRequest) => {
    try {
      setLoading(true)

      const serviceResponse = await verifyEmailService(payload)
      if (serviceResponse?.success)
        updateUser(serviceResponse.response)

      return !!serviceResponse?.success
    }
    catch (e: any) {
      const msg = e.response?.data?.response?.notify?.content || e?.response?.data?.response?.error
      if (msg === 'Invalid code')
        useError(t('notify.email_verify_code_not_found'))
      else if (msg === 'Active email verification session not found')
        useError(t('notify.email_verify_code_session_expire'))
      else useError(msg)
      return false
    }
    finally {
      setLoading(false)
    }
  }

  const verifyEmailSend = async () => {
    try {
      setLoading(true)

      const serviceResponse = await verifyEmailSendService()

      return !!serviceResponse?.success
    }
    finally {
      setLoading(false)
    }
  }

  const changeCurrencies = async (currencyIds: string[], action: 'push' | 'remove'): Promise<boolean> => {
    try {
      setLoading(true)

      const service = action === 'push' ? addCurrencyToSettingsService : deleteCurrencyFromSettingsService

      const serviceResponse = await service({ currencyIds })

      if (serviceResponse.success) {
        updateUser({
          settings: {
            ...(user.value?.settings || {}),
            currencies: serviceResponse.response,
          },
        })
      }

      return !!serviceResponse?.success
    }
    catch {
      return false
    }
    finally {
      setLoading(false)
    }
  }

  const setWatchForBan = (newValue: Ban | undefined, banName: string) => {
    if (!newValue)
      return

    if (newValue.durationInSeconds === null)
      return

    const expireBanTime = new Date(new Date(newValue?.createdAt || 0).getTime() + ((Number(newValue.durationInSeconds || 1) + 5) * 1000))
    const difference = expireBanTime.getTime() - Date.now()

    setTimeout(() => removeBan(banName), difference)
  }

  const changeConvertCurrency = async (payload: UserDtoChangeConvertCurrency) => {
    if (loading.value)
      return
    try {
      setLoading(true)

      const serviceResponse = await selectConvertCurrencyService(payload)

      if (serviceResponse.success)
        await getUser()

      return !!serviceResponse?.success
    }
    catch {
      return false
    }
    finally {
      setLoading(false)
    }
  }

  watch(() => activeChatBan.value, (newValue: Ban | undefined) => setWatchForBan(newValue, 'CHAT.SEND_MESSAGE'))
  watch(() => activeSupportBan.value, (newValue: Ban | undefined) => setWatchForBan(newValue, 'SUPPORT.CREATE_TICKET'))
  watch(() => activeDepositBan.value, (newValue: Ban | undefined) => setWatchForBan(newValue, 'PAYMENTS.DEPOSIT_CREATE'))
  watch(() => activeWithdrawBan.value, (newValue: Ban | undefined) => setWatchForBan(newValue, 'PAYMENTS.WITHDRAW_CREATE'))

  return { changeSeed, changeServerSeed, getIsUserCanCreateRaffle, userVip, resetPasswordTfa, changeConvertCurrency, changeCurrencies, activeDepositBan, activeWithdrawBan, isLocalStorageHasName, subscribeUserChannel, activeSupportBan, activeChatBan, addSubscriptionListeners, updateUser, verifyEmailSend, verifyEmail, isIdentificationEnd, setIsIdentificationEnd, getIsAdmin, getIsModerator, isAdmin, isModerator, disconnectTfa, isGoogleTfaEnabled, connectTfa, editUser, isWasAuthed, isLogged, user, setUser, getUserAvatar, clearUser, getUserChannel, loading, getUser, resetPassword, isNeedLoadProfileInfo }
}, {
  persist: isSSR()
    ? false
    : { storage: localStorage, paths: ['user.name'] },
})
