import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { LiveWinsQueryKey } from '@modules/live/live.types'
import { getLiveWins } from '@modules/live/live.service'
import type { LiveWinModel, LiveWinsPeriod } from '@modules/live/live.model'
import type { Ref } from 'vue'
import { useCentrifuge } from '@composable/useCentrifuge'
import type { Subscription } from 'centrifuge'

interface UseLiveWinsParams {
  period: Ref<LiveWinsPeriod>
  count: Ref<number>
}

interface UseLiveWins {
  data: Ref<LiveWinModel[] | undefined>
  isFetching: Ref<boolean>
}

export function useLiveWins(params: UseLiveWinsParams): UseLiveWins {
  const query = useQuery<LiveWinModel[]>([LiveWinsQueryKey.value, { count: params.count, period: params.period }], async () => {
    const response = await getLiveWins({
      historyPeriod: params.period.value,
      itemsCount: params.count.value,
    })

    return response.response
  }, { refetchOnWindowFocus: false })

  return {
    data: query.data,
    isFetching: query.isFetching,
  }
}

export function subscribeLiveWins() {
  const centrifuge = useCentrifuge()

  const liveWinsChannel = 'live:wins'

  const sub = centrifuge.getSub(liveWinsChannel)

  if (!sub) {
    const newSub = centrifuge.subscribe(liveWinsChannel)

    if (newSub) {
      newSub.subscribe()
      addSubscriptionListeners(newSub)
    }

    return newSub
  }

  return sub
}

export function addSubscriptionListeners(subscription: Subscription) {
  const queryClient = useQueryClient()

  subscription.on('publication', (ctx: { data: { liveWin: LiveWinModel } }) => {
    queryClient.setQueriesData([LiveWinsQueryKey.value, { period: 'live' }], (data: any) => {
      return [ctx.data.liveWin, ...data.slice(0, -1)]
    })
  })
}
