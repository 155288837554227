<script setup lang="ts">
import {
  computed,
  getFormatBalance,
} from '@modules/games/tower/tower.dependencies'
import type { MessageModel } from '@modules/games/tower/tower.dependencies'
import { getUserRank } from '@modules/user/user.helpers'
import { UserAvatar } from '@modules/user'
import { getIconSymbolName } from '@helpers/currency'
import { useExchanger } from '@modules/wallet'
import { getSupportWebp } from '@helpers/webp'
import { getOurGameById } from '@/config/games'
import { t } from '@/plugins/i18n'

interface Props {
  message: MessageModel
}
const props = defineProps<Props>()
const Exchanger = useExchanger()
const Game = getOurGameById('TOWER')

const info = computed(() => props.message.attachments?.at(0))
function getAmountCurrency(currencyId: string) {
  const viewCurrencyId = Exchanger.getViewCurrencyId()

  return getIconSymbolName(Exchanger.getCurrencyIsoById(viewCurrencyId || currencyId))
}
</script>

<template>
  <div class="gt-chat-message">
    <div class="grid gap-2" :class="info?.bet?.username ? 'grid-cols-2 ' : 'grid-cols-1'">
      <div class="gt-chat-message-user">
        <UserAvatar
          type="chat"
          :rank="getUserRank(message.user.vipStatus.slug)"
          class="crash-message__avatar"
          :src="getSupportWebp() ? (message?.user?.avatarWebp || message?.user?.avatar) : message?.user?.avatar"
        />

        <span class="gt-chat-message__name text-ellipsis overflow-hidden whitespace-nowrap" :class="{ special: info?.bet?.username }">{{ message?.user?.name }}</span>
      </div>

      <div v-if="info?.bet?.username" class="flex flex-row-reverse items-center justify-between">
        <UserAvatar
          type="chat"
          :rank="getUserRank(message.user.vipStatus.slug)"
          class="crash-message__avatar"
          :src="getSupportWebp() ? (info?.bet?.avatar?.webpKey || info?.bet?.avatar?.key) : info?.bet?.avatar?.key"
        />

        <span class="gt-chat-message__name text-ellipsis overflow-hidden whitespace-nowrap" :class="{ special: info?.bet?.username }">{{ info?.bet?.username }}</span>
      </div>
    </div>

    <div class="gt-chat-message__row">
      <p class="gt-chat-message-game">
        <component :is="Game?.icon || ''" class="w-5 h-5" />

        Tower
      </p>

      <p class="gt-chat-message__shared">
        {{ t('tower.message.shared') }}
      </p>
    </div>

    <div class="gt-chat-message-blocks">
      <div class="gt-chat-message__block">
        <h3>{{ t('tower.message.bet') }}</h3>

        <p :class="getAmountCurrency(info?.bet?.currencyId || '643')">
          {{ getFormatBalance(Number(info?.bet?.amount || 0)) }}
        </p>
      </div>

      <div class="gt-chat-message__block">
        <h3>{{ t('tower.message.out') }}</h3>

        <p>x{{ getFormatBalance(Number(info?.bet?.finalCoefficient || 0), false) }}</p>
      </div>

      <div class="gt-chat-message__block">
        <h3>{{ t('tower.message.win') }}</h3>

        <p :class="getAmountCurrency(info?.bet?.currencyId || '643')">
          {{ getFormatBalance(Math.max(Number(info?.bet?.winAmount || 0), 0)) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gt-chat-message {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  background: var(--gray-5);

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__block {
    border-radius: 4px;
    background: var(--dark);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;

    color: var(--yellow);

    &:first-child {
      color: #F6E444;
      width: calc(50% - 4px);
    }
    &:last-child {
      & > * {
        text-align: center;
      }
      width: 100%;
      color: var(--green-6);
    }
    &:nth-child(2) {
      width: calc(50% - 4px);
      color: var(--blue-4);
    }

    & h3 {
      color: inherit;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;

    }

    & p {
      color: var(--white);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
    }
  }

  &__shared {
    color: var(--light);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:nth-child(2) {
      flex-direction: row-reverse;
    }
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: $radius;
  }

  &-user {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-game {
    display: flex;
    align-items: center;
    gap: 6px;

    color: var(--white);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &__name {
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;

    &.special.ell {
      width: 80px;
    }
  }

}
</style>
