import { CasinoRoutes } from '@modules/casino/casino.types'

export const route = {
  path: 'all-games/:provider',
  name: 'casino',
  children: [
    {
      path: '',
      name: CasinoRoutes.PROVIDER,
      component: () => import('./views/Provider.vue'),
    },
    {
      path: ':gameId-:gameName',
      name: CasinoRoutes.GAME,
      component: () => import('./views/Game.vue'),
      meta: {
        casinoGame: true,
      },
    },
  ],
}
