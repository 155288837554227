import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { isSSR, removeDoubleSlash } from '@helpers/utils'
import { ref } from 'vue'
import type { UserModule } from '@/types'

export const fallbackLanguage = 'ru'

export const locale = ref()
const messages = ref<any>({})

export type Locale = string

export interface Lang {
  code: 'ru' | 'en'
  label: 'Русский' | 'English'
}

export function languages(): Lang[] {
  return [
    {
      code: 'ru',
      label: 'Русский',
    },
    {
      code: 'en',
      label: 'English',
    },
  ]
}

const localesMap = Object.fromEntries(
  Object.entries(import.meta.glob('../../locales/*.json')).map(
    ([path, loadLocale]) => [path.match(/([\w-]*)\.json$/)?.[1], loadLocale],
  ),
) as Record<Locale, () => Promise<{ default: Record<string, string> }>>

export const availableLocales = Object.keys(localesMap)
// 000
export function useLocalePath() {
  return function (path: string, lang?: string) {
    const finalLang = lang || locale.value

    const prefix = finalLang === fallbackLanguage
      ? ''
      : path.startsWith(`/${finalLang}`) ? '' : finalLang

    return removeDoubleSlash(`/${prefix}/${path}`)
  }
}
export function pathWithLocale(path: string, lang?: string) {
  const finalLang = lang || locale.value

  const prefix = finalLang === fallbackLanguage
    ? ''
    : path.startsWith(`/${finalLang}`) ? '' : finalLang

  return removeDoubleSlash(`/${prefix}/${path}`)
}

export const loadedLanguages: string[] = []

// 000
export function setLanguage(lang: Locale) {
  locale.value = lang as any
  if (typeof document !== 'undefined') {
    document.querySelector('html')?.setAttribute('lang', lang)
    localStorage.setItem('lang', lang)
  }

  return lang
}

// 000
export async function loadLanguageAsync(lang: string): Promise<Locale> {
  let localLang = lang

  if (!availableLocales.includes(lang))
    localLang = fallbackLanguage

  // If the same language
  if (locale.value === localLang)
    return setLanguage(localLang)

  // If the language was already loaded
  if (loadedLanguages.includes(localLang))
    return setLanguage(localLang)

  // If the language hasn't been loaded yet
  const localMessages = await localesMap[localLang]()

  messages.value[localLang] = localMessages.default
  loadedLanguages.push(localLang)

  return setLanguage(localLang)
}

export function t(msg: string, param: any = null) {
  let val = msg.split('.').reduce((value: any, part: string) => {
    return value?.[part] || msg
  }, messages.value[locale.value])

  if (param)
    Object.keys(param).map(key => val = val.replace(new RegExp(`\\{${key}\\}`, 'g'), param[key]))

  return val
}
export function te(msg: string) {
  return msg
    .split('.')
    .reduce((value: any, part: string) => {
      return value?.[part] || msg
    }, messages.value[locale.value]) !== msg
}

function detectUsedLocale(route: RouteLocationNormalizedLoaded) {
  const lang = localStorage.getItem('lang')
  const target = route.params.locale?.toString()
  if (target?.length && target !== lang)
    localStorage.setItem('lang', target)
  else if (!target)
    localStorage.setItem('lang', fallbackLanguage)
}

function getUserLocale(route: RouteLocationNormalizedLoaded) {
  if (isSSR())
    return route.params.locale?.toString() || fallbackLanguage

  detectUsedLocale(route)

  const target
    = route.params.locale?.toString()
    || localStorage.getItem('lang')
    || fallbackLanguage

  return availableLocales.includes(target) ? target : fallbackLanguage
}

export const install: UserModule = ({ router }) => {
  router.beforeEach(async (to) => {
    // detect user locale
    const targetLang = getUserLocale(to)
    // load lang
    if (!loadedLanguages.includes(targetLang))
      await loadLanguageAsync(targetLang)
  })
}
