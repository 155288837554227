import type { UserModule } from '@modules/user/user.dependencies'
import type { User, UserVipStatusPublic } from '@modules/user/user.model'

import { getUserMiddleware } from '@modules/user/user.middleware'
import { useUserStore } from '@modules/user/user.store'

import UserAvatar from '@modules/user/components/UserAvatar.vue'
import Profile from '@modules/user/views/Profile.vue'
import ConnectTfaModal from '@modules/user/components/Profile/Modal/ConnectTfa.vue'
import DicsonnectTfaModal from '@modules/user/components/Profile/Modal/DicsonnectTfa.vue'
import ProfileMenu from './components/Profile/ProfileMenu.vue'

export { default as VerifyEmailRow } from './components/VerifyEmailRow.vue'
export { default as BlockersDetectedModal } from './modals/BlockersDetectedModal.vue'

const install: UserModule = async ({ router }) => {
  // middleware here, because i need SSG_Ctx
  router.beforeEach(getUserMiddleware())
}

export {
  UserVipStatusPublic, UserAvatar,
  install, User, useUserStore, ProfileMenu, Profile, ConnectTfaModal, DicsonnectTfaModal,
}
