import { TowerGameLevels } from '@modules/games/tower/tower.model'

export const LevelConfig = [
  {
    id: TowerGameLevels.EASY,
    src: '/img/tower/green.svg',
    title: 'tower.complexitySelector.easy',
    monsterAnimationName: 'purple',
    min: 3,
    max: 4,
  },
  {
    id: TowerGameLevels.NORMAL,
    src: '/img/tower/orange.svg',
    title: 'tower.complexitySelector.normal',
    monsterAnimationName: 'purple',
    min: 2,
    max: 3,
  },
  {
    id: TowerGameLevels.MEDIUM,
    src: '/img/tower/pink.svg',
    title: 'tower.complexitySelector.medium',
    monsterAnimationName: 'purple',
    min: 1,
    max: 2,
  },
  {
    id: TowerGameLevels.HARD,
    src: '/img/tower/purple.svg',
    title: 'tower.complexitySelector.hard',
    monsterAnimationName: 'purple',
    min: 1,
    max: 3,
  },
  {
    id: TowerGameLevels.NIGHTMARE,
    src: '/img/tower/blue.svg',
    title: 'tower.complexitySelector.hardcore',
    monsterAnimationName: 'purple',
    min: 1,
    max: 4,
  },
]
