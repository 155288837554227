import type { Ref } from 'vue'
import { onBeforeUnmount, onMounted } from 'vue'
import seedrandom from 'seedrandom'
import { useCookies } from '@vueuse/integrations/useCookies'
import { CdnConfig } from '@/config/env'
import type { User } from '@/modules/auth'
import { useUserStore } from '@/modules/user'

export function isSSR() {
  return typeof window === 'undefined'
}
export function isSNGCountry(countryCode: string) {
  return ['RU', 'BY', 'KZ', 'UA'].includes(countryCode)
}
export function isLanguageDetected() {
  return !!localStorage.getItem('changed-language-by-user')
}
export function setLanguageDetected() {
  localStorage.setItem('changed-language-by-user', 'true')
}
export function removeDoubleSlash(str: string) {
  return str.replace(/(^|[^:])\/+/gm, '$1/')
}

export function round(num: number, decimals = 2) {
  const regExp = new RegExp(`^-?\\d+(?:\.\\d{0,${decimals || -1}})?`)

  return Number.parseFloat(num.toString().match(regExp)?.[0] || '0')
}

function getCurrencyDecimals(currencyId: string) {
  const fiatCurrencyIds = ['643', '840', '978']

  return fiatCurrencyIds.includes(currencyId) ? 2 : 8
}
export function prepareFormatBalance(value: number, currencyId: string) {
  return formatNumber(prepareBalance(value, currencyId), getCurrencyDecimals(currencyId))
}

export function prepareBalance(value: number, currencyId: string) {
  const currencyDecimals = getCurrencyDecimals(currencyId)
  const readyValue = value / (currencyDecimals === 2 ? 100 : 100000000)

  return round(readyValue, getCurrencyDecimals(currencyId))
}

export function formatNumber(value: number, decimals = 2): string {
  const localeString = value < 1 ? value.toString().replace('.', ',') : value.toLocaleString('ru')
  const [first, second] = localeString.split(',')

  return `${first},${(second || '').padEnd(decimals === 2 ? decimals : decimals - 2, '0')}`.replace(',', '.')
}

export function getFormatBalance(value: number, cents = true, decimals = 2): string {
  let initialValue = value
  if (cents)
    initialValue = value / 100

  const rounded = round(initialValue, decimals)

  const localeString = rounded.toLocaleString('ru')
  const [first, second] = localeString.split(',')

  return `${first},${(second || '').padEnd(2, '0')}`.replace(',', '.')
}

export function getSlicedNumberAsString(value: number): string {
  const [first, second] = value.toString().split('.')

  if (!second)
    return first

  return `${first}.${second.slice(0, 2).padEnd(2, '0')}`
}

export function getSlicedNumberBeforeDot(value: number): number {
  const [first] = value.toString().split('.')

  return Number(first)
}

export function getCompactlyFormattedBalance(value: number, cents = true): string {
  const digits = 4
  let initialValue = value
  if (cents)
    initialValue = value / 100

  const absValue = Math.abs(initialValue)

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e6, symbol: 'M' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup.slice().reverse().find((item) => {
    return absValue >= item.value
  })

  // Big number, need to compact
  if (item && item.symbol) {
    const formattedValue = `${(absValue / item.value).toFixed(digits).replace(rx, '$1')} ${item.symbol}`
    return initialValue < 0 ? `-${formattedValue}` : formattedValue
  }

  // Always not cents, because if cents, we performed divide above
  return getFormatBalance(initialValue, false)
}

export function formatDate(passedDate: string | Date, noHours = false): string {
  if (!passedDate)
    return ''

  let date: Date
  if (typeof passedDate === 'string')
    date = new Date(passedDate)
  else
    date = passedDate

  // XXX: months starts from 0
  let month = String(date.getMonth() + 1)
  let day = String(date.getDate())
  let hours = String(date.getHours())
  let minutes = String(date.getMinutes())
  if (Number.parseInt(month, 10) < 10)
    month = `0${month}`
  if (Number.parseInt(day, 10) < 10)
    day = `0${day}`
  if (Number.parseInt(hours, 10) < 10)
    hours = `0${hours}`

  if (Number.parseInt(minutes, 10) < 10)
    minutes = `0${minutes}`

  if (noHours)
    return `${day}.${month}.${date.getFullYear()}`

  return `${day}.${month}.${date.getFullYear()} ${hours}:${minutes}`
}

export function useClick({
  el,
  toggle,
}: {
  el: Ref<HTMLElement | undefined>
  toggle: Ref<boolean>
}) {
  const documentClick = (event: MouseEvent) => {
    const target = event.target as Element
    if (el && el.value && el.value !== target && !el.value.contains(target))
      toggle.value = false
  }

  onMounted(() => {
    document.addEventListener('click', documentClick)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', documentClick)
  })
}

export function useCdn(path: string) {
  return `${CdnConfig.url}/${path}`
}

export function getAvatar(data: User) {
  const store = useUserStore()
  const url = data.avatar
  if (store.user?.settings)
    return `${process.env.CDN_URL}/avatars/streamer/${data.id}.svg`

  else if (url.includes('https://'))
    return url
  else return useCdn(url)
}

export function isQueryEnabledByDefault(enabled: Ref<boolean> | boolean) {
  if (isSSR())
    return false

  if (typeof enabled === 'boolean')
    return enabled

  return enabled.value
}

export function saveReferralForUnauthorized(code: string, refer: string) {
  localStorage.setItem('referral', JSON.stringify({
    code,
    refer,
  }))
}

export function getReferral(): { code: string; refer: string } | null {
  try {
    if (!localStorage.getItem('referral'))
      return null

    return JSON.parse(localStorage.getItem('referral') || '')
  }
  catch (e) {
    //
  }

  return null
}

export function removeReferral() {
  localStorage.removeItem('referral')
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getRandomNumberWithSeed(min: number, max: number, seed: string): number {
  const rng = seedrandom(seed)
  return Math.floor(min + rng() * (max - min + 1))
}
export function expandArrayWithSeed<T>(arr: T[], count: number, seed: string): T[] {
  if (arr.length >= count) {
    return arr
  }
  else {
    const expandedArr: T[] = []
    const rng = seedrandom(seed)
    while (expandedArr.length < count) {
      const randomIndex = Math.floor(rng() * arr.length)
      expandedArr.push(arr[randomIndex])
    }
    return expandedArr
  }
}
export function shuffleArrayWithSeed<T>(arr: T[], seed: string): T[] {
  const rng = seedrandom(seed)
  const shuffledArr = [...arr]
  for (let i = shuffledArr.length - 1; i > 0; i--) {
    const j = Math.floor(rng() * (i + 1));
    [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]]
  }
  return shuffledArr
}

export function addUtmTags() {
  const cookies = useCookies()
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const headers: Record<string, string> = {}
  utmParams.forEach((param) => {
    const utmValue = cookies.get(param)
    const paramSecond = param.split('_')[1]
    const paramKey = paramSecond.charAt(0).toUpperCase() + paramSecond.slice(1)
    if (utmValue)
      headers[`X-Utm-${paramKey}`] = utmValue
  })
  return headers
}
