<script setup lang="ts">
import { isSSR } from '@helpers/utils'
import { onBeforeUnmount } from 'vue'
import { useBus } from '@composable/useBus'
import Close from '@/assets/icon/close.svg'
import { t } from '@/plugins/i18n'

const emit = defineEmits(['close'])
const bus = useBus()
function update() {
  bus.emit('update:confirm')
  close()
}

function close() {
  emit('close')
}

function setup() {
  if (isSSR())
    return

  document.body.classList.add('app-update-need')
}

setup()

onBeforeUnmount(() => document.body.classList.remove('app-update-need'))
</script>

<template>
  <div class="fixed top-0 left-0 right-0 z-[4200] py-2 px-3 bg-[#2D9F3D] flex items-center justify-left screen-375:justify-center gap-3 h-[38px]">
    <Close name="close" class="transition-all cursor-pointer text-[#ffffff] opacity-50 hover:opacity-100 absolute right-5 top-1/2 -translate-y-1/2" @click="close" />

    <span class="text-14 font-700 text-white">
      {{ t('updatePwa.title') }}
    </span>

    <button class="w-[114px] rounded-[4px] p-2 flex items-center justify-center bg-[rgba(0,0,0,0.30)] text-white hover:text-[#6bff53]" @click="update">
      <span class="text-12 font-700">
        {{ t('updatePwa.button') }}
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>
