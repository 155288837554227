import { useBus } from '@composable/useBus'
import { useState } from '@composable/useState'
import type { CurrencyEntity } from '@modules/referral'
import type { AuthSocial, AuthSocialType } from '@modules/auth'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DepositCredentials } from '@modules/wallet'
import type { DepositCredentialsCrypto } from '@modules/wallet/wallet.model'
import type { Bonus, RewardedActionDeposit } from '@modules/bonus/bonus.model'
import type { CheckFairSingleGame } from '@/types/singleGames'

interface PaymentModalPayload {
  type: 'payout' | 'payment'

  // Referral
  referral?: boolean
  campaignId?: string
  amount?: number
  walletId?: string
  currencyId?: string
}

type onContinue = () => void | Promise<void>

type ActiveBonusModalType = 'rakeback' | 'cashback' | 'bonus' | 'withdraw' | 'limit'

type TUseModalLayerResponse = () => {
  show: IShowFunction
  showSecond: ISecondShowFunction
  hide: (name: string) => void
}
interface ISecondShowFunction {
  (name: 'wallet.deposit_info_crypto', payload: { id: string; cryptoCredentials: DepositCredentialsCrypto; night: boolean }): void
  (name: 'deposit_info', payload: { id: string; credentials: DepositCredentials; night: boolean }): void
  (name: 'bonus.activeBonusExist', payload: { type: ActiveBonusModalType; onContinue?: onContinue }): void
  (name: 'bonus.deleteBonusConfirmation', payload: { onContinue: onContinue }): void
}
interface IShowFunction {
  (name: 'forgot', payload?: boolean): void
  (name: 'blockers_detect'): void
  (name: 'restore'): void
  (name: 'restore_successful'): void
  (name: 'connect_tfa'): void
  (name: 'login_tfa', payload: { type: 'signIn' | 'reset-password'; onContinue?: onContinue }): void
  (name: 'auth.continue_social_register', payload: { social: AuthSocial }): void
  (name: 'bonus_giveaway', payload: { code: string }): void
  (name: 'ref_rakeback'): void
  (name: 'bonus_giveaway_create'): void
  (name: 'ref_create_campaign', payload: { type: 'public' | 'revShare' }): void
  (name: 'ref_edit_campaign', payload: { campaignId: string; linkId: string; name: string }): void
  (name: 'ref_delete_campaign', payload: { campaignId: string; linkId: string; name: string }): void
  (name: 'ref_delete_campaign_confirmation', payload: { campaignId: string; linkId: string; name: string; amount: string; currency: string }): void
  (name: 'ref_withdraw_profit', payload: { amount: string; currency: CurrencyEntity }): void
  (name: 'payments', payload?: PaymentModalPayload): void
  (name: 'deposit_problem'): void
  (name: 'crash_round_history', payload: { gameId: string }): void
  (name: 'crash_round_check', payload: { gameId: string }): void
  (name: 'crash_settings_fair', payload: { serverSeed: string; nextSeed: string }): void
  (name: 'crash_faq_fair'): void
  (name: 'crash_rules'): void
  (name: 'chat_ban', payload: { userId: string; messageId: string }): void
  (name: 'disconnect_tfa'): void
  (name: 'wallet_select_currency'): void
  (name: 'wallet_add_currency'): void
  (name: 'wallet_view_currency'): void
  (name: 'wallet.change_currency_slot_game', payload: { onContinue: onContinue }): void
  (name: 'wallet.change_currency_tower_game', payload: { onContinue: onContinue; current: string; next: string }): void
  (name: 'disconnect_tfa'): void
  (name: 'bonus.activeBonusExist', payload: { type: ActiveBonusModalType }): void
  (name: 'bonus.bonus_details', payload: { bonus?: Bonus; action?: RewardedActionDeposit; hasActiveBonus?: boolean }): void
  (name: 'bonus.deleteBonusConfirmation', payload: { onContinue: onContinue }): void
  (name: 'auth', payload: { type: AuthSocialType; path?: string }): void

  // tower
  (name: 'games_tower_rules'): void
  (name: 'games_tower_check_fair', payload: CheckFairSingleGame): void

  // single games
  (name: 'single_game.seed_manage'): void
  (name: 'single_game.faq_fair'): void

  // beta
  (name: 'beta.onboarding'): void
  (name: 'beta.topUp'): void
}

// scoped
const bus = useBus()
class SingletonStorage {
  public static value: Ref<string> = ref<string>('')
}

export const useModalLayer: TUseModalLayerResponse = () => {
  return {
    show: (name, payload?) => {
      bus.emit('modal:show', { name, payload })
    },
    showSecond: (name, payload?) => {
      bus.emit('modal:showSecond', { name, payload })
    },
    hide: name => bus.emit('modal:hide', { name }),
  }
}

/** Note: that not singleton, use only once for modal combiner */
export function useOpenModals() {
  const [secondActive, setSecondActive] = useState<string>('')
  const [payload, setPayload] = useState<any | null>(null)
  const [secondPayload, setSecondPayload] = useState<any | null>(null)

  const setActive = (value: string) => SingletonStorage.value.value = value

  const clear = () => {
    setActive('')
    localStorage.removeItem('modal')
  }

  const clearSecond = () => setSecondActive('')

  bus.on('modal:show', (ctx) => {
    const delay = SingletonStorage.value.value ? 350 : 100
    if (delay)
      clear()
    setTimeout(() => {
      setActive(ctx.name)
      setPayload(ctx.payload)
      localStorage.setItem('modal', SingletonStorage.value.value)
    }, delay)
  })
  bus.on('modal:showSecond', (ctx) => {
    setSecondActive(ctx.name)
    setSecondPayload(ctx.payload)
  })

  bus.on('modal:hide', clear)

  return { active: SingletonStorage.value, secondActive, clear, payload, clearSecond, secondPayload }
}
