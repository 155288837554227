<script setup lang="ts">
import type { UserRank } from '@modules/user/user.model'
import { defineAsyncComponent, shallowRef, watch } from 'vue'

interface Props {
  rank: UserRank
}

const props = defineProps<Props>()
const icon = shallowRef<any>(null)

const setIcon = (value: any) => icon.value = value

const getIconComponent = () => defineAsyncComponent(() => import(`@modules/user/assets/icon-${props.rank}.svg`))

setIcon(getIconComponent())

watch(() => props.rank, () => {
  setIcon(getIconComponent())
})
</script>

<template>
  <component :is="icon" />
</template>

<style scoped lang="scss">

</style>
