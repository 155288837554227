import type { Ref } from 'vue'
import { ref } from 'vue'

export function useToggle(initialValue: boolean): [Readonly<Ref<boolean>>, () => void, (payload: boolean) => void] {
  const refValue = ref<boolean>(initialValue)
  const toggleFunction = () => refValue.value = !refValue.value
  const setFunction = (payload: boolean) => refValue.value = payload

  return [refValue, toggleFunction, setFunction]
}
