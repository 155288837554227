import type { Ref, ServicePaginatedResponse } from '@modules/wallet/wallet.dependencies'
import { ref, useQuery, useState } from '@modules/wallet/wallet.dependencies'
import { HistoryDepositCacheKey, HistoryWithdrawCacheKey } from '@modules/wallet/wallet.types'
import { getHistoryDeposit, getHistoryWithdraw } from '@modules/wallet/wallet.service'
import type { HistoryDeposit } from '@modules/wallet/wallet.model'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import type { MutateDataFunction } from '@/types/query'

interface UseHistory {
  data: Ref<ServicePaginatedResponse<HistoryDeposit>>
  isFetching: Ref<boolean>
  page: Ref<number>
  dataUpdatedAt: Ref<number>
  setPage: (value: number) => void
}

interface UseHistoryArg {
  isWithdraw?: boolean
}

export function useHistory(arg?: UseHistoryArg): UseHistory {
  const gracefulDegradation = useGracefulDegradation()
  const placeholderData = ref({
    data: [],
    meta: {
      currentPage: 1,
      perPage: 10,
      lastPage: 1,
      total: 0,
    },
  })
  const [page, setPage] = useState<number>(1)
  const query = useQuery<ServicePaginatedResponse<HistoryDeposit> | undefined, unknown, ServicePaginatedResponse<HistoryDeposit>>(
    [arg?.isWithdraw ? HistoryWithdrawCacheKey.value : HistoryDepositCacheKey.value, page],
    async () => {
      try {
        const serviceFunc = arg?.isWithdraw ? getHistoryWithdraw : getHistoryDeposit
        const result = await serviceFunc({ currentPage: page.value, perPage: 25, createdAt: 'DESC' })

        gracefulDegradation.removeServiceFromOffline('payments', 'paymentsHistory')

        return result.response
      }
      catch {
        gracefulDegradation.addServiceToOffline('payments', 'paymentsHistory')
      }
    }, {
      refetchOnWindowFocus: false,
      placeholderData,
    })

  return {
    data: query.data.value ? query.data : placeholderData,
    isFetching: query.isFetching,
    page,
    setPage,
    dataUpdatedAt: query.dataUpdatedAt,
  }
}

interface MutateDataArgs extends MutateDataFunction<ServicePaginatedResponse<HistoryDeposit>> {
  isDeposit: boolean
}

export function mutateHistoryDepositData({ queryClient, isDeposit, updater }: MutateDataArgs) {
  queryClient?.setQueriesData({
    queryKey: [!isDeposit ? HistoryWithdrawCacheKey.value : HistoryDepositCacheKey.value],
    exact: false,
  }, updater)
}
