<script setup lang="ts">
import BaseSelect from '@components/Interface/BaseSelect.vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { t, useLocalePath } from '@/plugins/i18n'
import Settings from '@/assets/icon/settings.svg'
import Security from '@/assets/icon/security.svg'
import History from '@/assets/icon/history.svg'

const localePath = useLocalePath()
const router = useRouter()
const route = useRoute()
const selectedRouteId = computed<string>(() => {
  return route.name?.toString()?.replace('profile-', '')?.replace('-deposit', '')?.replace('-withdraw', '') || 'settings'
})
const routes = computed<{ name: string; id: string; iconComponent: any }[]>(() => [
  {
    name: t('profile.settings'),
    id: 'settings',
    iconComponent: Settings,
  },
  {
    name: t('profile.security'),
    id: 'security',
    iconComponent: Security,
  },
  {
    name: t('profile.history'),
    id: 'history',
    iconComponent: History,
  },
])

function onChange(value: string) {
  if (value === 'security')
    router.replace(localePath('/profile/security'))
  else if (value === 'settings')
    router.replace(localePath('/profile/'))
  else if (value === 'history')
    router.replace(localePath('/profile/history'))
}
</script>

<template>
  <BaseSelect :current-id="selectedRouteId" :items="routes" bg-color="bg-black" @change="onChange">
    <template #default="{ current }">
      <div class="flex items-center gap-2">
        <component :is="current.iconComponent" class="text-gray-2" />

        <span>
          {{ current.name }}
        </span>
      </div>
    </template>
  </BaseSelect>
</template>

<style scoped lang="scss">

</style>
