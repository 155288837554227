<script lang="ts" setup>
import ProfileNavigation from '@modules/user/components/Profile/Sections/Navigation.vue'
import { isSSR } from '@modules/user/user.dependencies'

import { useUserStore } from '@modules/user'
import SectionsSelector from '@modules/user/components/Profile/SectionsSelector.vue'
import Divider from '@modules/fair/components/Divider.vue'

const userStore = useUserStore()

if (userStore.isNeedLoadProfileInfo && !isSSR())
  userStore.getUser({ params: { extend: 1 } })
</script>

<template>
  <div class="container container-profile">
    <div v-if="!userStore.loading && userStore.user?.profile || (userStore.loading && userStore.user?.profile)" class="app_content profile">
      <div class="profile_wrap">
        <ProfileNavigation class="!hidden screen-1024:!flex" />

        <div class="profile__main p-3 screen-1024:pt-5 w-full bg-gray rounded-normal">
          <SectionsSelector class="block screen-1024:hidden" />

          <Divider class="!w-[calc(100%+24px)] -ml-3 mt-3 mb-5 flex screen-1024:hidden" />

          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container-profile {
  min-height: 750px;
}

.profile {
  &_wrap {
    display: flex;
    gap: 20px;
    @include _767 {
      flex-wrap: wrap;
    }
  }
  &__main {
    width: 100%;
    .btn_backto {
      color: var(--white);
      gap: 8px;
      padding: 12px 0;
      margin-bottom: 8px;
      display: none;
      @include _767 {
        display: flex;
      }
    }
  }
}
</style>
