<script setup lang="ts">
import { ref, useError, useKeydown, useState } from '@modules/user/user.dependencies'
import { useUserStore } from '@modules/user'
import { watch } from 'vue'
import Close from '@/assets/icon/close.svg'
import { t } from '@/plugins/i18n'

const emit = defineEmits(['close'])
const userStore = useUserStore()
const code = ref<string>('')

const [state, setState] = useState<'' | 'success' | 'error'>('')

useKeydown('Enter', onDisconnect)

const onClose = () => emit('close')
async function onDisconnect() {
  if (code.value.length < 6) {
    useError(t('notify.code_min_length'))
    setState('error')
    return
  }

  const isSuccess = await userStore.disconnectTfa({
    authenticatorCode: code.value,
  })

  if (isSuccess) {
    setState('success')
    onClose()
  }
  else {
    setState('error')
  }
}

watch(() => code.value, () => setState(''))
</script>

<template>
  <div class="modal_content" @click.stop>
    <header class="flex justify-between items-center">
      <h3 class="tfa__title">
        {{ t("auth.tfa.title") }}
      </h3>

      <button class="btn_close" @click="onClose">
        <Close name="close" class="w-5 h-5" />
      </button>
    </header>

    <p class="tfa__text">
      {{ t('profile.2fa.enter_code_for_off') }}
    </p>

    <input
      v-model="code" class="form_input" :class="{
        'connect_tfa__input': state === '',
        'connect_tfa__input--success': state === 'success',
        'connect_tfa__input--error': state === 'error',

      }" :placeholder="t('profile.2fa.enter_code')"
    >

    <button
      class="btn-primary uppercase"
      :disabled="userStore.loading"
      @click="onDisconnect"
    >
      <span>{{ t('button.disconnect') }}</span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.connect_tfa__input {
  &:valid:not(:placeholder-shown) {
    border: 1px solid var(--gray-4);
  }

  &--success {
    border: 1px solid var(--green-3) !important;
  }

  &--error {
    border: 1px solid var(--red) !important;
  }

}

.tfa {
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    color: white
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }
}
</style>
