<script setup lang="ts">
import { useUserStore } from '@modules/user'
import { UserRank } from '@modules/user/user.model'
import type { SharedUserAvatarProps } from '@modules/user/user.types'

import UserRankIconPng from '@modules/user/components/UserRankIconPng.vue'
import { LazyImage } from '@components/Interface'
import { useCdn } from '@helpers/utils'
import UserAvatarSvg from '@modules/user/components/UserAvatarSvg.vue'
import AvatarHighRoller from '../assets/avatar-high_roller-border.svg'
import AvatarGambler from '../assets/avatar-gambler-border.svg'

import AvatarGamblerBandana from '../assets/avatar-gambler-bandana.svg'
import AvatarHighRollerBandana from '../assets/avatar-high_roller-bandana.svg'

interface Props extends SharedUserAvatarProps {}

defineProps<Props>()
const userStore = useUserStore()
</script>

<template>
  <div :class="[$style[`type-${type}`], $style[`rank-${rank}`]]" class="relative will-change-transform">
    <template v-if="rank === UserRank.GAMBLER">
      <AvatarGambler :class="$style.border" class="will-change-transform" />

      <AvatarGamblerBandana v-if="type !== 'chat'" :class="[$style.bandana, type === 'profile' && 'z-20']" class="will-change-transform" />
    </template>

    <template v-else-if="rank === UserRank.HIGH_ROLLER">
      <AvatarHighRoller v-if="type === 'profile'" :class="$style.border" class="will-change-transform" />

      <div v-if="type === 'chat'" :class="[$style.border, $style.borderChat]" class="will-change-transform" />

      <AvatarHighRollerBandana v-if="type !== 'chat'" :class="[$style.bandana, type === 'profile' && 'z-20', $style.bandanaHighRoller]" class="will-change-transform" />
    </template>

    <div v-if="type === 'chat' && rank && rank !== 'none'" class="rounded-full bg-gray-5 w-4 h-4 flex items-center justify-center absolute top-[-4px] right-[-4px] z-20">
      <UserRankIconPng :rank="rank" class="min-w-[14px] w-[14px] h-[14px] will-change-transform" />
    </div>

    <UserAvatarSvg
      v-if="!userStore.getUserAvatar(src)?.url"
      :key="userStore.getUserAvatar(src)?.color"
      class="w-full h-full rounded-normal"
      :color="userStore.getUserAvatar(src)?.color!"
      :text="userStore.getUserAvatar(src)?.text!"
    />

    <LazyImage
      v-else
      :class="$style.img"
      name="user-avatar"
      transparent
      :src="useCdn(src || userStore.getUserAvatar(src)?.url || '')"
      is-avatar
    />
  </div>
</template>

<style module lang="scss">
.img {
  @apply relative;

  img {
    @apply object-cover rounded-normal w-full h-full;
  }
}
.border {
  @apply absolute top-0 left-0 w-full h-full z-10;
}
.borderChat {
  @apply rounded-small;
  border: 2px solid var(--yellow);
}
.bandana {
  @apply w-full absolute left-[-31%] top-[-28%] h-full;
}
.bandanaHighRoller {
  @apply left-[-34%];
}

.type-small,
.type-chat {
  @apply min-w-[24px] min-h-[24px] w-6 h-6 rounded-normal;

  & .img {
    @apply rounded-small;
  }

  &.rank-gambler,
  &.rank-high_roller {
    @apply p-[1px];
  }
}

.type-normal {
  @apply min-w-[32px] min-h-[32px] w-8 h-8 rounded-normal;
}

.type-profile {
  @apply w-[80px] h-[80px] rounded-medium p-1;
  border: 1px solid var(--white);

  &.rank-gambler,
  &.rank-high_roller{
    border: none;
  }
}
</style>
