<script setup lang="ts">
import type { Message } from '@modules/chat/chat.model'

import { CrashChatMessage } from '@modules/chat/chat.dependencies'
import { TowerChatMessage } from '@modules/games/tower'
import RegularMessage from './Message.vue'

interface Props {
  message: Message
}

defineProps<Props>()
const emit = defineEmits(['reply'])

function onReply(data: Message) {
  emit('reply', data)
}
</script>

<template>
  <RegularMessage v-if="!message?.attachments?.length" :message="message" @reply="onReply" />

  <CrashChatMessage v-else-if="message.attachments?.at(0)?.type === 'CRASH_BET'" :message="message" />

  <TowerChatMessage v-else-if="message.attachments?.at(0)?.type === 'GAMES_BET'" :message="message" />
</template>

<style scoped lang="scss">

</style>
