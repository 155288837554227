import type { Subscription } from 'centrifuge'
import { defineStore, useState } from './support.dependencies'
import type { Pagination, Ref } from './support.dependencies'
import type { Ticket, TicketMessage } from './support.model'
import { closeTicketService, createTicketService, getTicketMessagesServise, getTicketService, getTicketsServise, sendMessageService } from './support.service'
import type { SupportMessagesRequestDto } from './support.dto'

interface ISupportStore {
  ticketsMeta: Ref<Pagination>
  tickets: Ref<Ticket[]>
  ticketsFetched: Ref<boolean>
  current: Ref<Ticket | null>
  loadingTickets: Ref<boolean>
  loadingTicketsStop: Ref<boolean>
  loadingMessages: Ref<boolean>

  // socket
  addSubscriptionListeners: (subscription: Subscription) => void

  // actions
  getTickets: () => Promise<boolean | void>
  getTicket: (id: number) => Promise<boolean | void>
  createTicket: (body: FormData) => Promise<string | number | null | undefined>
  sendMessage: (body: FormData) => Promise<boolean | undefined>
  fetchMessages: (params: SupportMessagesRequestDto) => Promise<any>
  closeTicket: () => Promise<boolean | void>
  clearStore: () => void

  // mutations
  updateTicket: (ticket: Ticket, message?: TicketMessage) => void
}

export const useSupportStore = defineStore<string, ISupportStore>('supportStore', () => {
  const [ticketsMeta, setTicketsMeta] = useState<Pagination>({
    currentPage: 1,
    perPage: 20,
    total: 0,
    lastPage: 0,
  })
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [ticketsFetched, setTicketsFetched] = useState<boolean>(false)
  const [loadingTickets, setLoadingTickets] = useState<boolean>(false)
  const [loadingTicketsStop, setLoadingTicketsStop] = useState<boolean>(false)
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false)
  const [current, setCurrent] = useState<Ticket | null>(null)

  // socket
  function addSubscriptionListeners(subscription: Subscription) {
    subscription.on('publication', (ctx) => {
      if (ctx.data.action === 'support')
        updateTicket(ctx.data.payload.ticket, ctx.data.payload.message)
    })
  }

  function updateTicket(ticket: Ticket, message?: TicketMessage) {
    const ticketId = ticket?.id || message?.ticketId
    const currentTicket = tickets.value.find((el: Ticket) => el.id === ticketId)
    if (ticket) {
      if (currentTicket)
        Object.assign(currentTicket, ticket)
      else
        tickets.value.unshift(ticket)
    }

    if (message && currentTicket) {
      if (message.ticketId === currentTicket.id) {
        const exist = currentTicket.messages?.find(x => x.id === message.id)
        if (currentTicket.messages) {
          if (exist)
            Object.assign(exist, message)
          else currentTicket.messages.unshift(message)
        }
        else {
          currentTicket.messages = []
          currentTicket.messages.unshift(message)
        }
      }
    }
  }

  function clearStore() {
    setTickets([])
    setTicketsFetched(false)
    setLoadingTickets(false)
    setLoadingTicketsStop(false)
    setLoadingMessages(false)
    setCurrent(null)
    setTicketsMeta({
      currentPage: 1,
      perPage: 20,
      total: 0,
      lastPage: 0,
    })
  }

  // actions

  async function getTickets() {
    if (loadingTickets.value)
      return
    setLoadingTickets(true)
    try {
      const { response, success } = await getTicketsServise({ currentPage: ticketsMeta.value.currentPage, perPage: ticketsMeta.value.perPage })

      if (success) {
        setTicketsMeta(response.meta)
        if (response.data.length)
          tickets.value.push(...response.data)
        else
          setLoadingTicketsStop(true)
      }

      return success
    }
    finally {
      setLoadingTickets(false)
      setTicketsFetched(true)
    }
  }

  async function getTicket(id: number) {
    const choosen = tickets.value.find((el: Ticket) => el.id === id)
    if (choosen) {
      setCurrent(choosen)
    }
    else {
      try {
        const { response, success } = await getTicketService(id)

        if (success) {
          setCurrent(response)
          const index = tickets.value.findIndex(item => item.id === response.id)
          tickets.value.splice(index, 1, response)
        }
      }
      catch (ex) {
        console.log(ex)
      }
    }

    return true
  }

  async function createTicket(body: FormData) {
    try {
      const { response, success } = await createTicketService(body)
      if (success) {
        setCurrent(null)
        return response.id
      }
    }
    catch (e) { }
  }

  async function sendMessage(body: FormData) {
    try {
      const { success } = await sendMessageService(body)

      return success
    }
    catch (e) { }
  }

  async function fetchMessages(params: SupportMessagesRequestDto) {
    if (loadingMessages.value)
      return
    setLoadingMessages(true)
    try {
      const { response, success } = await getTicketMessagesServise(params)

      if (success) {
        if (response.length) {
          if (!current.value!.messages)
            current.value!.messages = response
          else
            current.value!.messages.push(...response)
        }
        else { current.value!.loadStop = true }
      }

      return success
    }
    finally {
      setLoadingMessages(false)
    }
  }

  async function closeTicket() {
    if (!current.value)
      return

    try {
      const { success, response } = await closeTicketService(current.value?.id)

      if (success)
        Object.assign(current.value, response)
      // tickets.value.some((el) => {
      //   if (el.id === response.id)
      //     el.status = response.status

      //   return el
      // })

      return success
    }
    catch (e) { }
  }

  return { addSubscriptionListeners, tickets, ticketsFetched, ticketsMeta, current, loadingTickets, loadingTicketsStop, loadingMessages, getTickets, createTicket, getTicket, fetchMessages, sendMessage, closeTicket, updateTicket, clearStore }
})
