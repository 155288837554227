<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import type { Lang } from '@/plugins/i18n'
import { fallbackLanguage, languages, loadLanguageAsync, locale } from '@/plugins/i18n'
import IconRU from '@/assets/icon/lang/ru.svg?url'
import IconEN from '@/assets/icon/lang/en.svg?url'
import { Select } from '@/components/Interface'

const icon: { [key: string]: string } = {
  ru: IconRU,
  en: IconEN,
}

const availableLocales = computed(() => languages())
const currentLang = computed(() => availableLocales.value.find((el: Lang) => el.code === locale.value))
const router = useRouter()

const localeItems = computed(() => {
  if (!availableLocales.value)
    return []

  return availableLocales.value.map(item => ({
    id: item.code,
    name: item.label,
  })).filter(item => item.id !== currentLang.value?.code)
})

function selectLanguage(language: string) {
  loadLanguageAsync(language).then(() => {
    const targetPrefix = language === fallbackLanguage ? '' : language
    router.replace({
      params: {
        locale: targetPrefix,
      },
    })
  })
}
</script>

<template>
  <Select
    :items="localeItems || []"
    :current-id="currentLang?.code as string"
    class="lang font-400 text-left min-w-[125px] text-14/[14px]"
    on-top
    item-classes="!font-400"
    @change="selectLanguage"
  >
    <template #placeholder>
      <img v-if="currentLang" :src="icon[currentLang.code]" :alt="`${currentLang} locale`" class="w-5 h-5">

      <span class="flex-grow">{{ currentLang?.label }}</span>
    </template>

    <template #default="{ item }">
      <div class="flex items-center w-full gap-[8px]" :class="`lang--${locale.value}`">
        <img :src="icon[item.id]" :alt="`${locale.value} locale`" class="w-5 h-5">

        <span>{{ item.name }}</span>
      </div>
    </template>
  </Select>
</template>
