import type { ToastInterface } from 'vue-toastification'
import { useToast } from 'vue-toastification'
import { GiveawayToast } from '@/modules/bonus/components'
import type { GiveawayToat } from '@/modules/bonus/bonus.model'

export function useGiveawayToast(giveaway: GiveawayToat) {
  const toast: ToastInterface = useToast()

  const showToast = () => {
    toast.info({
      component: GiveawayToast,
      props: {
        giveaway,
        buttons: [
          {
            buttonName: 'update',
          },
        ],
      },
    }, { id: 'giveaway-toast', hideProgressBar: true, timeout: false, draggable: false })
  }

  const closeToast = () => {
    toast.dismiss('giveaway-toast')
  }

  return { showToast, closeToast }
}
