<script setup lang="ts">
import { GameCard, GamesGrid, GamesGridRows } from '@modules/casino'
import { TabsMain } from '@components/Interface'
import { Banner } from '@components/Index'

import { useState } from '@composable/useState'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@modules/user'
import type { GameType } from '@modules/casino'
import { LiveWinsTable } from '@modules/live'
import { useHead } from '@unhead/vue'
import { getOurPublishGames, tabsPair } from '@/config/games'
import { useModalLayer } from '@/composable/useModal'
import { t } from '@/plugins/i18n'

interface Tab {
  name: string
  id: string
  type: GameType
  icon: string
  count?: number
  url: string
}

const modalLayer = useModalLayer()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const [currentTabId, setCurrentTabId] = useState<string>('all')
const [searchEnable, setSearchEnable] = useState<boolean>(false)

const tabs = computed<Tab[]>(() => {
  const commonTabs = [
    tabsPair.all,
    // tabsPair.hot,
    tabsPair.top,
    // tabsPair.new,
    tabsPair.bandana,
    tabsPair.slots,
    // tabsPair.live,
    tabsPair['bonus-buy'],
    tabsPair.roulette,
  ]

  if (userStore.isLogged || userStore.isWasAuthed)
    commonTabs.splice(1, 0, tabsPair.favorites)

  return commonTabs
})
const currentTab = computed<Tab | undefined>(() => tabs.value.find(tab => tab.id === currentTabId.value) || tabs.value[0])

function setTab(id: string) {
  setCurrentTabId(id)

  if (id !== 'all')
    router.push({ query: { type: id } })
  else
    router.push('/')
}

function setCurrentTab() {
  setCurrentTabId(route.query?.type ? String(route.query.type) : 'all')
}

onMounted(() => {
  setCurrentTab()
  if (route.query.g)
    modalLayer.show('bonus_giveaway', { code: route.query.g?.toString() })
})

watch(route, () => {
  setCurrentTab()
})

useHead({
  meta: [
    { name: 'description', content: t('seo.index.description') },
    { name: 'title', content: t('seo.index.title') },
  ],
})
</script>

<template>
  <div class="slots index">
    <div class="container">
      <div class="app_content">
        <div class="slots_wrap">
          <Banner />

          <TabsMain
            :tabs="tabs"
            :current-id="currentTabId"
            @change="setTab"
          >
            <template #icon="{ icon }">
              <component :is="icon || ''" class="w-5 h-5" />
            </template>
          </TabsMain>

          <div :class="{ unfocus: searchEnable }">
            <div v-if="currentTab" class="slots_inner">
              <div v-if="currentTabId === 'all'" class="slots__content">
                <GamesGridRows
                  :tabs="[
                    tabsPair.bandana,
                    tabsPair.top,
                    tabsPair.slots,
                    // tabsPair.hot,
                    // tabsPair.new,
                    // tabsPair.live,
                  ]"
                  :per-page="12"
                  @search-toggle="setSearchEnable"
                >
                  <template #default="{ tab }">
                    <GamesGrid v-if="tab === 'bandana'" :tab="tabsPair.bandana" no-search static>
                      <GameCard
                        v-for="ourGame in getOurPublishGames()" :key="`our-game-publish${ourGame.id}`"
                        :item="ourGame.entity" :link="ourGame.path" static
                      />
                    </GamesGrid>
                  </template>
                </GamesGridRows>
              </div>

              <div v-else-if="currentTabId !== 'all'" class="slots__content">
                <template v-if="currentTab?.type">
                  <GamesGridRows
                    v-if="currentTab.type === 'bandana'" :tabs="[tabsPair.bandana]"
                    @search-toggle="setSearchEnable"
                  >
                    <template #default>
                      <GamesGrid :tab="currentTab" no-search static>
                        <GameCard
                          v-for="ourGame in getOurPublishGames()" :key="`our-game-publish${ourGame.id}`"
                          :item="ourGame.entity" :link="ourGame.path" static
                        />
                      </GamesGrid>
                    </template>
                  </GamesGridRows>

                  <GamesGrid
                    v-else :key="currentTab.type" :per-page="40" :tab="currentTab"
                    @search-toggle="setSearchEnable"
                  />
                </template>
              </div>
            </div>
          </div>

          <LiveWinsTable />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.index {
  .container {
    z-index: 10;
    position: relative;
  }
}

.slots {
  &_inner {
    margin-bottom: 60px;
  }
}
</style>
