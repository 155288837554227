import type { GameType } from '@modules/casino'
import type { GameListEntity } from '@modules/casino/casino.model'

import CasinoGameTower from '@/assets/icon/casino-game-tower.svg'
import CasinoGameMines from '@/assets/icon/casino-game-mines.svg'
import CasinoGameAirblast from '@/assets/icon/casino-game-airblast.svg'
import CasinoGameSlots from '@/assets/icon/casino-game-slots.svg'
import Pacman from '@/assets/icon/pacman.svg'
import Fire from '@/assets/icon/fire.svg'
import Ranking from '@/assets/icon/ranking.svg'
import Star from '@/assets/icon/star.svg'
import Bandana from '@/assets/icon/bandana.svg'
import Live from '@/assets/icon/live.svg'
import Money from '@/assets/icon/money.svg'
import Baccarat from '@/assets/icon/baccarat.svg'
import Heart from '@/assets/icon/heart.svg'

export interface OurGame {
  id: string
  path: string
  name: string
  icon: any
  entity: GameListEntity
  playBonusAvailable?: boolean
  publish?: boolean
}

export enum OurGamesEnum {
  CRASH = 'CRASH',
  TOWER = 'TOWER',
  MINES = 'MINES',
}

export const crashGame: GameListEntity = {
  id: 'airblast',
  name: 'Airblast',
  image: '/img/bandana-games/airblast-v3.svg',
  imageBlurHash: 'UQCIUqa69jWCP;VyoykV76aK%GbuFzbvs*ad',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
  rtp: 97,
}
export const minesGame: GameListEntity = {
  id: 'mines',
  name: 'Mines',
  image: '/img/bandana-games/mines-v2.svg',
  imageBlurHash: 'UQCIUqa69jWCP;VyoykV76aK%GbuFzbvs*ad',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const plinkoGame: GameListEntity = {
  id: 'plinko',
  name: 'Plinko',
  image: '/img/bandana-games/plinko-v3.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const diceGame: GameListEntity = {
  id: 'dice',
  name: 'Dice',
  image: '/img/bandana-games/dice.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const x100Game: GameListEntity = {
  id: 'x100',
  name: 'X100',
  image: '/img/bandana-games/x100-v2.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const towerGame: GameListEntity = {
  id: 'tower',
  name: 'Tower',
  image: '/img/bandana-games/tower-v2.svg',
  imageBlurHash: 'UDD]ca%M00IB?2tQD%Mx5HRkwZs-00RP.Ttl',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
  rtp: 96,
}
export const kenoGame: GameListEntity = {
  id: 'Keno',
  name: 'Keno',
  image: '/img/bandana-games/keno-v2.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const coinflipGame: GameListEntity = {
  id: 'coinflip',
  name: 'Coinflip',
  image: '/img/bandana-games/coinflip-v1.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}

export const jokerGame: GameListEntity = {
  id: 'joker',
  name: 'Joker',
  image: '/img/bandana-games/joker-v3.svg',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}

export const rouletteGame: GameListEntity = {
  id: 'roulette',
  name: 'Roulette',
  image: '/img/bandana-games/roulette.png',
  devices: ['desktop'],
  favorite: false,
  createdAt: '',
  type: 'bandana',
  provider: 'bandana',
  order: 0,
}
export const tabsPair: Record<GameType, {
  name: string
  id: string
  type: GameType
  icon: string
  count?: number
  url: string
}> = {
  'all': {
    name: 'lobbi',
    id: 'all',
    type: 'all',
    icon: Pacman,
    url: '/all-games',
  },
  'hot': {
    name: 'hot',
    id: 'hot',
    type: 'hot',
    icon: Fire,
    url: '/all-games?type=hot',
  },
  'top': {
    name: 'top',
    id: 'top',
    type: 'top',
    icon: Ranking,
    url: '/all-games?type=top',
  },
  'new': {
    name: 'new',
    id: 'new',
    type: 'new',
    icon: Star,
    url: '/all-games?type=new',
  },
  'bandana': {
    name: 'bandana',
    id: 'bandana',
    type: 'bandana',
    icon: Bandana,
    url: '/all-games?type=bandana',
  },
  'slots': {
    name: 'slots',
    id: 'slots',
    type: 'slots',
    icon: CasinoGameSlots,
    url: '/all-games?type=slots',
  },
  'live': {
    name: 'live',
    id: 'live',
    type: 'live',
    icon: Live,
    url: '/all-games?type=live',
  },
  'bonus-buy': {
    name: 'bonus-buy',
    id: 'bonus-buy',
    type: 'bonus-buy',
    icon: Money,
    url: '/all-games?type=bonus-buy',
  },
  'roulette': {
    name: 'roulette',
    id: 'roulette',
    type: 'roulette',
    icon: Baccarat,
    url: '/all-games?type=roulette',
  },
  'favorites': {
    name: 'favorites',
    id: 'favorites',
    type: 'favorites',
    icon: Heart,
    url: '/all-games?type=favorites',
  },
}

export const ourGames: OurGame[] = [
  {
    id: OurGamesEnum.CRASH,
    entity: crashGame,
    name: 'Airblast',
    path: 'airblast',
    icon: CasinoGameAirblast,
    publish: true,
    playBonusAvailable: true,
  },
  {
    id: OurGamesEnum.TOWER,
    entity: towerGame,
    name: 'Tower',
    path: 'tower',
    icon: CasinoGameTower,
    publish: true,
    playBonusAvailable: true,
  },
  {
    id: OurGamesEnum.MINES,
    entity: minesGame,
    name: 'Mines',
    path: 'mines',
    icon: CasinoGameMines,
    publish: false,
  },
  // {
  //   id: 'plinko',
  //   entity: plinkoGame,
  //   name: 'Plinko',
  //   path: 'plinko',
  //   icon: 'casino-game-plinko',
  //   publish: false,
  // },
  // {
  //   id: 'keno',
  //   entity: kenoGame,
  //   name: 'Keno',
  //   path: 'keno',
  //   icon: 'casino-game-keno',
  //   publish: false,
  // },
  // {
  //   id: 'dice',
  //   entity: diceGame,
  //   name: 'Dice',
  //   path: 'dice',
  //   icon: 'casino-game-dice',
  //   publish: false,
  // },
  // {
  //   id: 'x100',
  //   entity: x100Game,
  //   name: 'X100',
  //   path: 'x100',
  //   icon: 'casino-game-x100',
  //   publish: false,
  // },
  // {
  //   id: 'coinflip',
  //   entity: coinflipGame,
  //   name: 'Coinflip',
  //   path: 'coinflip',
  //   icon: 'casino-game-coinflip',
  //   publish: false,
  // },
  // {
  //   id: 'joker',
  //   entity: jokerGame,
  //   name: 'Joker',
  //   path: 'joker',
  //   icon: 'casino-game-joker',
  //   publish: false,
  // },
]

export const getOurPublishGames = () => ourGames.filter(game => game.publish)

export const getOurGames = () => ourGames
export function getOurPublishGamesUnique() {
  const publishGames = getOurPublishGames()
  const setGamesId = new Set()

  return publishGames.filter((game) => {
    const isAllow = !setGamesId.has(game.id)

    setGamesId.add(game.id)

    return isAllow
  })
}

export const getGameIsPublish = (game?: OurGame) => !!game?.publish
export const getPublishGameByPath = (path: string) => getOurPublishGames().find(game => game.path === path)
export const getOurGameById = (id: OurGamesEnum | string) => ourGames.find(game => game.id === id)
