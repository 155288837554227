const __pages_import_0__ = () => import("/src/pages/all-games/index.vue");
const __pages_import_1__ = () => import("/src/pages/terms-and-conditions.vue");
const __pages_import_2__ = () => import("/src/pages/responsible-gambling.vue");
const __pages_import_3__ = () => import("/src/pages/privacy.vue");
import __pages_import_4__ from "/src/pages/index.vue";
const __pages_import_5__ = () => import("/src/pages/aml-policy.vue");
const __pages_import_6__ = () => import("/src/pages/jackpot/[...type].vue");

const routes = [{"name":"all-games","path":"/all-games","component":__pages_import_0__,"props":true},{"name":"terms-and-conditions","path":"/terms-and-conditions","component":__pages_import_1__,"props":true},{"name":"responsible-gambling","path":"/responsible-gambling","component":__pages_import_2__,"props":true},{"name":"privacy","path":"/privacy","component":__pages_import_3__,"props":true},{"name":"index","path":"/","component":__pages_import_4__,"props":true},{"name":"aml-policy","path":"/aml-policy","component":__pages_import_5__,"props":true},{"name":"jackpot-type","path":"/jackpot/:type(.*)","component":__pages_import_6__,"props":true}];

export default routes;