import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { getGameInfo } from '@modules/games/tower/tower.service'
import type { TowerGame } from '@modules/games/tower/tower.model'
import { useGracefulDegradation } from '@composable/useGracefulDegradation'
import type { MutateDataFunction } from '@/types/query'

export enum TowerQueryEnum {
  game = 'tower-game',
}

interface GameInfoArgs {
  enabled: Ref<boolean>
}

interface GameInfo {
  data: Ref<TowerGame | null | undefined>
  isFetching: Ref<boolean>
  dataUpdatedAt: Ref<number>
  refetch: any
}

export function useGameInfo(args: GameInfoArgs): GameInfo {
  const gd = useGracefulDegradation()
  const query = useQuery<TowerGame | null>([TowerQueryEnum.game], async () => {
    try {
      const serviceResponse = await getGameInfo()

      if (serviceResponse.success)
        gd.removeServiceFromOffline('tower', 'gameInfo')
      else
        gd.addServiceToOffline('tower', 'gameInfo')

      return serviceResponse.response || null
    }
    catch {
      gd.addServiceToOffline('tower', 'gameInfo')

      return null
    }
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: args.enabled,
  })

  return {
    data: query.data,
    isFetching: query.isFetching,
    refetch: query.refetch,
    dataUpdatedAt: query.dataUpdatedAt,
  }
}

interface MutateGame<T = TowerGame> extends MutateDataFunction<T> {

}

export function mutateGameInfo<T>({ queryClient, updater }: MutateGame<T>) {
  queryClient?.setQueriesData([TowerQueryEnum.game], updater)
}

export function checkGameInfo(queryClient: any) {
  return queryClient.getQueryData([TowerQueryEnum.game])
}
