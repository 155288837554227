<script lang="ts" setup>
const props = withDefaults(defineProps<{ light?: boolean }>(), {
  light: false,
})
</script>

<template>
  <div class="game-card-placeholder">
    <div class="game-card-placeholder__wrap" :class="{ light: props.light }">
      <div class="game-card-placeholder__thumb filled" />

      <div class="game-card-placeholder__info">
        <div class="h20 filled mb-2" />

        <div class="h14 filled w-[70%] ml-auto mr-auto" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game-card-placeholder {
  &__wrap {
    border-radius: 12px;
    overflow: hidden;
    font-weight: 700;
    position: relative;
    color: var(--white);
  }

  &__thumb {
    border-radius: 12px;
    position: relative;
    padding-bottom: 100%;
    display: flex;
    align-items: center;
  }

  &__info {
    padding: 16px;
  }
}
</style>
