<script setup lang="ts">
import Close from '@/assets/icon/close.svg'

interface Props {
  light?: boolean
}

defineProps<Props>()
const emit = defineEmits(['close'])

const onClose = () => emit('close')
</script>

<template>
  <div class="flex items-center justify-between gap-2">
    <span class="text-16 font-700 leading-12 text-white">
      <slot />
    </span>

    <Close name="close" class="w-5 h-5 cursor-pointer hover:text-yellow" :class="light && 'hover:text-gray-1'" @click="onClose" />
  </div>
</template>

<style scoped lang="scss">

</style>
