<script lang="ts" setup>
import { useToggle } from '@composable/useToggle'
import { vOnClickOutside } from '@vueuse/components'
import { computed } from 'vue'
import { Icon } from '@components/Icon'
import MethodIcon from '@modules/wallet/component/MethodIcon.vue'
import { UISelect } from '@/cypress.types'

import { t } from '@/plugins/i18n'

interface Item {
  id: string | number
  name: string | number
  icon?: string
  meta?: Record<string, any>
}

interface Props {
  placeholder?: string
  emptyPlaceholder?: string
  items: Item[]
  currentId: string | number
  onTop?: boolean
  itemClasses?: string
  currencyClass?: string
  isCurrencies?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['change'])
const [open, toggleOpen, setOpen] = useToggle(false)

const selected = computed<Item | undefined>(() => props.items.find(item => item.id === props.currentId))

const onClose = () => setOpen(false)

function onSelect(item: Item) {
  onClose()
  emit('change', item.id)
}
</script>

<template>
  <div
    :class="$style.select"
    :data-open="open"
    :data-ontop="props.onTop"
  >
    <button
      v-on-click-outside="onClose"
      :data-cy="UISelect.DROPDOWN_BUTTON + placeholder"
      :class="$style.select_top"
      @click="toggleOpen"
    >
      <div :class="$style.placeholder">
        <slot name="placeholder" :item="selected" :placeholder="placeholder">
          <MethodIcon
            v-if="isCurrencies && selected?.icon"
            :name="selected?.icon"
            class="w-6 h-6"
          />

          <Icon v-else-if="selected?.icon" :name="selected?.icon" />

          <span v-if="selected?.name" class="white">
            <span v-if="currencyClass" :class="currencyClass" class="mr-1" />
            {{ selected?.name }}
          </span>

          <span v-else>
            {{ placeholder }}
          </span>
        </slot>
      </div>

      <i :class="$style.arrow" />
    </button>

    <div :class="$style.select_dropdown">
      <ul :class="$style.select_list">
        <li
          v-if="!props.items?.length"
          :class="{
            [$style.item]: true,
            [$style.item_last]: true,
            [$style.item_empty]: true,
          }"
        >
          {{ props.emptyPlaceholder || t('empty.just_empty') }}
        </li>

        <li
          v-for="(item, i) in props.items"
          v-else
          :key="`select-${i}`"
          :class="{
            [$style.item]: true,
            [$style.item_last]: props.items.length === i + 1,
          }"
          :data-cy="UISelect.DROPDOWN_SELECT + item?.name"
          @click="() => onSelect(item)"
        >
          <button
            :class="{
              [props.itemClasses as string]: !!props.itemClasses,
              [$style.item_active]: item.id === currentId,
              [$style.item_button]: true,
            }"
          >
            <slot :item="item">
              <MethodIcon
                v-if="isCurrencies && item?.icon"
                :name="item?.icon"
                class="w-6 h-6"
              />

              <Icon v-else-if="item?.icon" :name="item?.icon" class="w-5 h-5" />

              <span>
                <span v-if="currencyClass" :class="currencyClass" class="mr-1" />
                {{ item?.name }}
              </span>
            </slot>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" module>
.select {
  @apply relative font-400 max-w-lg h-[38px];
}

.select_list {
  @apply border border-solid border-t-0 p-0 overflow-y-auto max-h-[175px] rounded-normal;

  background-color: var(--black);
  border-color: var(--gray-3);

  [data-open='true'] & {
    @apply rounded-t-none;
  }

  [data-ontop='true'] & {
    @apply border-t border-b-0;
  }

  [data-ontop='true'][data-open='true'] & {
    @apply rounded-t-normal rounded-b-none;
  }
}

.select_dropdown {
  @apply absolute z-50 pl-2.5 pr-2.5 top-full -left-[10px] -right-[10px] min-w-full opacity-0 invisible;

  [data-open='true'] & {
    @apply top-full opacity-100 visible;
  }

  [data-ontop='true'] & {
    @apply bottom-full top-auto;
  }
}

.select_top {
  @apply rounded-normal flex items-center justify-start text-left pl-[12px] pr-[12px] h-inherit border border-solid border-transparent rounded-normal font-400 w-full overflow-hidden text-ellipsis whitespace-nowrap gap-[12px] transition-none;

  color: var(--gray-2);
  background: var(--black);

  [data-open='true'] & {
    @apply border-solid border rounded-b-none;

    border-color: var(--gray-3);
  }

  [data-ontop='true'][data-open='true'] & {
    @apply rounded-t-none rounded-b-normal;
  }
}

.placeholder {
  @apply flex items-center w-full gap-[8px];
}

.arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M6.1237 0C6.92396 0 7.40003 0.89325 6.95383 1.55757L4.80637 4.75478C4.4085 5.34715 3.53603 5.34413 3.14227 4.74902L1.02678 1.55181C0.58692 0.887032 1.06363 0 1.86075 0H6.1237Z' fill='%23F6E444' /%3E%3C/svg%3E");

  @apply bg-contain bg-center bg-no-repeat w-[8px] h-[8px] transition-all;

  [data-open='true'] & {
    transform: rotate(180deg);
  }
}

.item {
  @apply border-0 border-b border-solid max-h-[44px] flex items-center;

  border-color: var(--gray-3);
}
.item_last {
  border-bottom-width: 0 !important;
}
.item_empty {
  @apply h-[38px] flex items-center justify-center;
}

.item_button {
  @apply overflow-hidden text-ellipsis whitespace-nowrap w-full gap-[12px] rounded-none font-400 flex items-center justify-start pt-2.5 pb-2.5 pr-[12px] pl-[12px] transition-all border-0;

  color: var(--gray-2);

  &:hover{
    color: var(--yellow);
  }
}
  .item_active {
    color: var(--yellow);
  }
</style>
