<script lang="ts" setup>
import { useModalLayer } from '@composable/useModal'
import { useUserStore } from '@modules/user'
import { useRouter } from 'vue-router'
import { t, useLocalePath } from '@/plugins/i18n'
import { LeftMenu } from '@/cypress.types'

const emit = defineEmits(['closeLeft'])
const modalLayer = useModalLayer()
const userStore = useUserStore()
const router = useRouter()
const localePath = useLocalePath()

function onRakeback() {
  emit('closeLeft')
  if(userStore.isLogged) {
    modalLayer.show('ref_rakeback')
    return
  } 
  return router.push(localePath('/rakeback/about'))
}

function onCashback() {
  emit('closeLeft')

  if (!userStore.isLogged) {
    modalLayer.show('auth', { type: 'signIn', path: localePath('/cashback') })
    return
  }

  router.push(localePath('/cashback'))
}
</script>

<template>
  <div class="lsbanners">
    <div class="lsbanners_wrap">
      <div class="lsbanners-item lsbanners_bonus" :data-cy="LeftMenu.BONUS_CABINET_LEFT_BANNER">
        <div class="overlay" />

        <RouterLink :to="localePath('/bonus')" class="lsbanners-item--link" @click="emit('closeLeft')">
          <div class="lsbanners-item_text">
            <b>BONUS</b>

            <p>{{ t('bonuses.room') }}</p>
          </div>

          <div class="lsbanners-item_img">
            <img class="lsbanners__b" src="/img/banners/b1-min.png" alt="">

            <img
              class="lsbanners__c1"
              src="/img/banners/coin01-min.png"
              alt=""
            >

            <img
              class="lsbanners__c2"
              src="/img/banners/coin02-min.png"
              alt=""
            >

            <img
              class="lsbanners__c3"
              src="/img/banners/coin03-min.png"
              alt=""
            >

            <img
              class="lsbanners__c4"
              src="/img/banners/coin04-min.png"
              alt=""
            >
          </div>
        </RouterLink>

        <!--        <template #preloader> -->
        <!--          <LazyBanner /> -->
        <!--        </template> -->
      </div>

      <div class="lsbanners-item lsbanners_rakeback" :data-cy="LeftMenu.RAKEBACK_LEFT_BANNER">
        <button
          class="lsbanners-item--link"
          @click="onRakeback"
        >
          <div class="lsbanners-item_text">
            <b>Rake</b>

            <p>Back</p>
          </div>

          <div class="lsbanners-item_img">
            <img class="lsbanners__rb1" src="/img/banners/rb1-min.png" alt="">

            <img class="lsbanners__rb2" src="/img/banners/rb2-min.png" alt="">
          </div>
        </button>

        <!--        <template #preloader> -->
        <!--          <LazyBanner class="!w-auto !flex-grow" /> -->
        <!--        </template> -->
      </div>

      <div class="lsbanners-item lsbanners_cashback" :data-cy="LeftMenu.CASHBACK_LEFT_BANNER">
        <button
          class="lsbanners-item--link"
          @click="onCashback"
        >
          <div class="lsbanners-item_text">
            <b>Cash</b>

            <p>Back</p>
          </div>

          <div class="lsbanners-item_img">
            <img class="lsbanners__cb1" src="/img/banners/cb1-min.png" alt="">

            <img class="lsbanners__cb3" src="/img/banners/cb3-min.png" alt="">

            <img class="lsbanners__cb2" src="/img/banners/cb2-min.png" alt="">
          </div>
        </button>

        <!--        <template #preloader> -->
        <!--          <LazyBanner class="!w-auto !flex-grow" /> -->
        <!--        </template> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.lsbanners {
  padding: var(--padding);
  background: var(--gray-5);
  &_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  img {
    pointer-events: none;
    user-select: none;
    max-height: initial;
  }
  &_bonus {
    width: 100%;
  }
  &_cashback {
    flex-grow: 1;
  }
  &_rakeback {
    flex-grow: 1;
  }
  &-item {
    background: var(--gray-4);
    border-radius: $radius;
    height: 48px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background: var(--gray-3);
    }
    &--link {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      height: inherit;
      line-height: inherit;
    }
    &_text {
      color: var(--white);
      text-transform: uppercase;
      padding: 0 14px;
      font-size: 14px;
      p {
        font-size: 10px;
      }
    }
    &_img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &_bonus {
    &:before {
      content: '';
      width: 230px;
      height: 120px;
      background: #0066ff;
      opacity: 0.4;
      filter: blur(18px);
      position: absolute;
      border-radius: 100%;
      right: -100px;
      bottom: -70px;
    }
    .overlay {
      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 440px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &:after {
        background: url('/img/banners/serp-back-min.png') top left repeat-y;
        animation: animatedBackground 8s linear infinite;
      }
      &:before {
        background: url('/img/banners/serp-front-min.png') top left repeat-y;
        animation: animatedBackground 15s linear infinite;
      }
    }
  }
  &_cashback {
    &:before {
      content: '';
      width: 120px;
      height: 120px;
      background: #ff0000;
      opacity: 0.5;
      filter: blur(18px);
      position: absolute;
      border-radius: 100%;
      right: -60px;
      bottom: -70px;
    }
  }
  &_rakeback {
    &:before {
      content: '';
      width: 120px;
      height: 120px;
      background: #00ed26;
      opacity: 0.4;
      filter: blur(18px);
      position: absolute;
      border-radius: 100%;
      right: -60px;
      bottom: -70px;
    }
  }
  &__b {
    position: absolute;
    right: 6px;
    top: 10px;
    animation: bonus 4s infinite ease-out;
  }
  &__c1 {
    width: 22px;
    position: absolute;
    right: 6px;
    top: -9px;
    animation: rakeback4 5s 2s ease-in-out infinite;
  }
  &__c2 {
    width: 23px;
    position: absolute;
    right: 77px;
    bottom: -9px;
    animation: rakeback4 5s 4s ease-in-out infinite;
  }
  &__c3 {
    width: 23px;
    position: absolute;
    right: 115px;
    top: 0;
    animation: rakeback4 5s 2.5s ease-in-out infinite;
  }
  &__c4 {
    width: 23px;
    position: absolute;
    right: -14px;
    bottom: 3px;
    animation: rakeback4 5s 1s ease-in-out infinite;
  }
  &__rb1 {
    position: absolute;
    right: 5px;
    bottom: -7px;
  }
  &__rb2 {
    position: absolute;
    right: 5px;
    bottom: -7px;
    animation: rotate 7s ease-in-out infinite;
  }
  &__cb1 {
    position: absolute;
    right: 0px;
    bottom: -20px;
    animation: cahbackWallet 4s 3s ease-in-out infinite;
  }
  &__cb2 {
    position: absolute;
    right: 0px;
    bottom: -20px;
    animation: cahbackWallet 4s 3s ease-in-out infinite;
  }
  &__cb3 {
    position: absolute;
    right: 0px;
    bottom: -20px;
    animation: cahbackMoney 4s 3s ease-in-out infinite;
  }
  .banners {
  }
}
-webkit-keyframes animatedBackground {
  to {
    transform: translateY(50%);
  }
}
@keyframes animatedBackground {
  to {
    transform: translateY(50%);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  70% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(180deg);
  }
  85% {
    transform: rotate(180deg);
  }
  95% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes cahbackMoney {
  from {
    transform: rotate(0);
  }
  10% {
    transform: rotate(20deg) translateY(-5px);
  }
  15% {
    transform: rotate(20deg) translateY(-5px);
  }
  25% {
    transform: unset;
  }
  35% {
    transform: rotate(20deg) translateY(-5px);
  }
  40% {
    transform: rotate(20deg) translateY(-5px);
  }
  50% {
    transform: unset;
  }
  to {
    transform: unset;
  }
}
@keyframes cahbackWallet {
  from {
    transform: translateY(0);
  }
  4% {
    transform: translateY(0);
  }
  12% {
    transform: rotate(-5deg) translateY(3px);
  }
  17% {
    transform: rotate(-5deg) translateY(3px);
  }
  25% {
    transform: unset;
  }
  33% {
    transform: rotate(-5deg) translateY(3px);
  }
  38% {
    transform: rotate(-5deg) translateY(3px);
  }
  46% {
    transform: unset;
  }
  to {
    transform: unset;
  }
}
@keyframes rakeback4 {
  from {
    transform: translateY(0);
  }
  5% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  45% {
    transform: translateY(0) rotate(-10deg);
  }
  65% {
    transform: translateY(3px) rotate(-10deg);
  }
  85% {
    transform: translateY(0);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bonus {
  0% {
    transform: scaleX(1);
  }
  8% {
    transform: scaleX(1.12) scaleY(0.75);
  }
  11% {
    transform: scaleX(0.85) scaleY(1);
  }
  13% {
    transform: scaleX(1.09) scaleY(0.85);
  }
  15% {
    transform: scaleX(0.9) scaleY(1);
  }
  18% {
    transform: scaleX(1.05) scaleY(0.95);
  }
  20% {
    transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
</style>
