import { httpClient } from '@modules/chat/chat.dependencies'
import type { ServiceResponse } from '@modules/chat/chat.dependencies'
import type { ChatDtoMessage, ChatDtoRoom, ChatDtoSendMessageRequest } from '@modules/chat/chat.dto'

export async function getChats(): Promise<ServiceResponse<ChatDtoRoom[]>> {
  return httpClient.get('chat/chats')
}

export async function getMessagesService(chatId: string, direction: 'UP' | 'DOWN', cursorTime?: string): Promise<ServiceResponse<ChatDtoMessage[]>> {
  // const messages: ChatDtoMessage[] = Array.from({ length: 250 }, (_, index) => String(index + 1)).map(index => ({
  //   attachment: [],
  //   createdAt: index,
  //   id: String(index),
  //   text: String(index),
  //   userId: String(index),
  //   user: {
  //     avatar: '',
  //     id: '',
  //     name: 'user',
  //     role: [],
  //   },
  // }))
  //
  // if (!cursorTime) {
  //   return {
  //     success: true,
  //     response: messages.slice(0, 50),
  //   }
  // }
  // else {
  //   console.log(cursorTime)
  //   const messageIndex = messages.findIndex(message => message.createdAt === cursorTime) + 1
  //
  //   return new Promise(resolve => setTimeout(() => resolve({
  //     success: true,
  //     response: messages.slice(messageIndex, messageIndex + 50),
  //   }), 200))
  // }

  return httpClient.get(`chat/chats/${chatId}/messages`, {
    params: {
      direction,
      cursorTime,
      limit: 50,
    },
  })
}

export async function sendMessageService(chatId: string, payload: ChatDtoSendMessageRequest): Promise<ServiceResponse<any>> {
  return httpClient.post(`chat/chats/${chatId}/messages`, payload)
}

export async function deleteMessageService(chatId: string, messageId: string): Promise<ServiceResponse<any>> {
  return httpClient.delete(`chat/admin/chats/${chatId}/message/${messageId}`)
}

export async function deleteMessagesService(chatId: string, params: { userId: string }): Promise<ServiceResponse<any>> {
  return httpClient.delete(`chat/admin/chats/${chatId}/messages`, { params })
}

export async function banMessage(banName: string, userId: string, messageId: string, reason: string, durationInSeconds: number): Promise<ServiceResponse<any>> {
  return httpClient.post(`/auth/admin/bans/${userId}/${banName}`, { messageId, reason, durationInSeconds })
}

export async function getBanNames(): Promise<ServiceResponse<Array<string>>> {
  return httpClient.get('auth/admin/bans/names')
}
